import { action, thunk } from "easy-peasy";

const pubnub = {
    chatModal : false,
    loggedInUser : '',
    pubnubstate: {},
    videoCall : {},
    currentChatUserDetails : {},
    chatUsers : [],
    directChannels : [],
    channelCreated : "",
    messages : [],
    channelMembers : [] ,
    chatType : '',
    setUUIDState : action((state, payload) => {
        let pub = state.pubnubstate
        pub.setUUID(payload)
        return {
            ...state,
            pubnubstate: pub
        };
    }),
    subscribeToChannels: action((state, payload) => {
        let pub = state.pubnubstate
        pub.subscribe({
            channels: [...payload],
            withPresence: true,
        });
        return {
            ...state,
            pubnubstate: pub,
        };
    }),
    setPubNubState : action((state, payload) => {
        return {
            ...state,
            pubnubstate: payload
        };
    }),
    sendMessageState : action((state, payload) => {
        let pub = state.pubnubstate
        pub.publish(payload)
        return {
            ...state,
            pubnubstate: pub
        };
    }),
    addEventListenerState : action((state, payload) => {
        let pub = state.pubnubstate
        pub.addListener(payload);
        return {
            ...state,
            pubnubstate: pub
        };
    }),
    videoCallState : action((state, payload) => {
        return {
            ...state,
            videoCall: payload
        };
    }),
    setPubNubActions : thunk(async (actions, payload) => {
        actions.setPubNubState(payload)
    }),
    subscribeToChannelsActions : thunk(async (actions, payload) => {
        actions.subscribeToChannels(payload)
    }),
    loginUUID : thunk(async (actions, payload) => {
        actions.setUUIDState(payload)
    }),
    sendMessage : thunk(async (actions, payload) => {
        actions.sendMessageState(payload)
    }),
    addEventListener : thunk(async (actions, payload) => {
        actions.addEventListenerState(payload)
    }),
    videoCallAction : thunk(async (actions, payload) => {
        actions.videoCallState(payload)
    }),
    updateState: action((state, payload) => {
        return {
          ...state,
          ...payload,
        };
      }),
};


export default pubnub