import { useStoreState } from 'easy-peasy';
import React, { useState } from 'react'
import Courses from './courses/Courses';

import Document from './documents/Document';
import Jobs from './jobs/Jobs';
import './myBox.css'
import Products from './products/Products';
import Videos from './videos/Videos';
import Connections from './connections/Connections';
import Modal from '../../modal/Modal'
import EmailModal from '../../common/EmailModal';
import VideoModal from '../../common/VideoModal';
import BussinessCard from '../../common/BussinessCard';
import Speakers from './speakers/Speaker_fav';
import Staffs from './staff/staff_fav';
import Sessions from './sessions/Session_fav';
import Exhibitors from './exhibitors/Exhibito_fav';


const MyBox = () => {

    const [activeScreen, setActiveScreen] = useState("Speakers")
    const { userDetails, eventConfig } =
        useStoreState((state) => state.state);
    const [openEmailModel, setopenEmailModel] = useState(false)
    const [openVideoModel, setopeVideoModel] = useState(false)
    const [currentVideo, setcurrentVideo] = useState('')
    const [emailData, setemailData] = useState('')
    const [bussinessCard, setBussinessCard] = useState('');
    const [openBussinessCard, setOpenBussinessCard] = useState(false)

    
    //Event configure based ( MYBOX -> properties shown )
    const course_shown_status=eventConfig.data[0].courses_board_enabled;
    const job_shown_status=eventConfig.data[0].jobs_board_enabled;
    const product_shown_status=eventConfig.data[0].product_catalogue_enabled;
    
    return (
        <div className="mybox-container">
            <Modal
                open={openEmailModel}
                onClose={() => {
                    setopenEmailModel((value) => false);
                }}
                widith="450px"
                height="300px"
            >
                <EmailModal  data={emailData}/>
            </Modal>
            <Modal
                open={openVideoModel}
                onClose={() => {
                    setopeVideoModel((value) => false);
                }}
                widith="50%"
                height="50%"
            >
                <VideoModal video_path={currentVideo} />
            </Modal>
            <Modal
                open={openBussinessCard}
                onClose={() => {
                    setOpenBussinessCard((value) => false);
                }}
                widith="450px"
                height="300px"
            >
                <BussinessCard bussinessCard={bussinessCard} />
            </Modal>
            <div className="mybox-body">
                <div className="mybox-header">
                    {/* <span>Favourite List / Swagbag List</span> */}
                </div>
                <div className="mybox-navigation">
                    {/* <div
                        className={`mybox-nav-option ${activeScreen === 'Connections' ? 'active' : ''}`}
                        onClick={() => setActiveScreen(() => "Connections")}
                    >
                        <span>Attendee</span>
                    </div> */}
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Speakers' ? 'active' : ''}`}
                        onClick={() => setActiveScreen(() => "Speakers")}
                    >
                        <span>Speakers</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Staff' ? 'active' : ''}`}
                        onClick={() => setActiveScreen(() => "Staff")}
                    >
                        <span>Staff</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Sessions' ? 'active' : ''}`}
                        onClick={() => setActiveScreen(() => "Sessions")}
                    >
                        <span>Sessions</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Exhibitors' ? 'active' : ''}`}
                        onClick={() => setActiveScreen(() => "Exhibitors")}
                    >
                        <span>Exhibitors</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Document' ? 'active' : ''}`}
                        onClick={() => setActiveScreen(() => "Document")}
                    >
                        <span>Documents</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Videos' ? 'active' : ''}`}
                        onClick={() => setActiveScreen(() => "Videos")}
                    >
                        <span>Videos</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Jobs' ? 'active' : ''}`}
                        style={{'display':((job_shown_status.localeCompare('Yes')==0)?'block':'none')}}
                        onClick={() => setActiveScreen(() => "Jobs")}
                    >
                        <span>Jobs</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Courses' ? 'active' : ''}`}
                        style={{'display':((course_shown_status.localeCompare('Yes')==0)?'block':'none')}}
                        onClick={() => setActiveScreen(() => "Courses")}
                    >
                        <span>Courses</span>
                    </div>
                    <div
                        className={`mybox-nav-option ${activeScreen === 'Products' ? 'active' : ''}`}
                        style={{'display':((product_shown_status.localeCompare('Yes')==0)?'block':'none')}}
                        onClick={() => setActiveScreen(() => "Products")}
                    >
                        <span>Products</span>
                    </div>
                </div>
                <div className="mybox-border" />
                <div className="mybox-data">
                    {activeScreen == "Document" ? (
                        <Document event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id} />
                    ) : activeScreen == "Videos" ? (
                        <Videos event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id} 
                            videoModal={(video_path) => { 
                                setcurrentVideo(() => video_path)
                                setopeVideoModel(true)
                            }} />
                    ) : activeScreen == "Products" ? (
                        <Products event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id} />
                    ) : activeScreen == "Exhibitors" ? (
                        <Exhibitors event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id} 
                            swag_type="exhibitors"/>
                    ): activeScreen == "Speakers" ? (
                        <Speakers event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id}
                            swag_type = "speaker" />
                    ):
                    activeScreen == "Staff" ? (
                        <Staffs event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id}
                            swag_type = "Staff" />):
                             activeScreen == "Courses" ? (
                        <Courses event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id} />
                    ) : activeScreen == "Jobs" ? (
                        <Jobs event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id} />
                    ) :activeScreen == "Sessions" ? (
                        <Sessions event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id} 
                            swag_type="session"/>
                    ) :activeScreen == "Connections" ? (
                        <Connections event_id={eventConfig?.data?.[0]?.event_id}
                            user_id={userDetails?.id}  
                            emailModal={(data) => {
                                setemailData(() => data)
                                setopenEmailModel(true)
                            }} 
                            bussinessCardModal={(data) => { 
                                setBussinessCard(() => data)
                                setOpenBussinessCard(true)
                            }}/>
                            // <Connections event_id='3'
                            // user_id='18'  
                            // emailModal={(data) => {
                            //     setemailData(() => data)
                            //     setopenEmailModel(true)
                            // }} 
                            // bussinessCardModal={(data) => { 
                            //     setBussinessCard(() => data)
                            //     setOpenBussinessCard(true)
                            // }}/>
                    ) : ""}
                </div>
            </div>
        </div>
    );
}

export default MyBox
