import axios from 'axios';

const axiosConn = axios.create();

  //  axiosConn.defaults.baseURL = 'https://dev-app-2.hexafair.com/platform/resources/api/'
  axiosConn.defaults.baseURL = 'https://app.hexafair.com/platform/resources/api/'

// if (process.env.REACT_APP_NODE_ENV === 'LOCAL') {
//     axiosConn.defaults.baseURL = process.env.REACT_APP_BASE_URL_LOCAL;            
// } if (process.env.REACT_APP_NODE_ENV === 'DEVELOPMENT') {
//     axiosConn.defaults.baseURL = process.env.REACT_APP_BASE_URL_DEV;            
// }if (process.env.REACT_APP_NODE_ENV === 'PRODUCTION') {
//     axiosConn.defaults.baseURL = process.env.REACT_APP_BASE_URL_PROD;            
// }else {
             
// }

export default axiosConn;