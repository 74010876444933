import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { useStoreState } from "easy-peasy";
import { useState } from "react";
import { Button } from "reactstrap";
import { sendResourcesEmail as sendResourcesEmailAPI } from "../../../../store/restApi/boothLiveApi";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";
import { validateEmail } from "../../utils/validation";
import Modal from "../Modal";

const styles = {
    input: {
        padding: "5px 10px",
        border: "1px solid black",
        "&:focus": {
            outline: "none",
        },
        // width: "400px",
    },
};

const CustomAlert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};
const Email = (props) => {    
    const { classes, handleModalClose } = props;
    let links = props?.selected ? props?.selected : null;
 

    console.log(links instanceof Object);
    console.log(links instanceof Array);
    const { eventConfig } = useStoreState((state) => state.state);
    // links = "<a>" + links?.join(",\n ") + "</a>";
    links = links.reduce((acc, link) => {        
        return (typeof link?.document_path!="undefined" && typeof link?.document_title!="undefined")?( acc + `${link?.document_path} - ${link?.document_title} `):(acc + `<a href=${link}>Video Link</a><br>`);        
        
    }, "");

    
    const { boothDetails, userDetails } = useStoreState(
        (state) => state.boothLiveState
    );
    
    const [mailSent, setMailSent] = useState(false);
    const [requestSent, setRequestSent] = useState(true);
    const sendResourcesEmail = () => {
        let toEmail = document?.getElementById("resourcesEmailToEmail")?.value,
            fromEmail = document?.getElementById(
                "resourcesEmailFromEmail"
            )?.value,
            subject = document?.getElementById("resourcesEmailSubject")?.value,
            message = document?.getElementById("resourcesEmailMessage")?.value;

        if (
            toEmail == "" ||
            fromEmail == "" ||
            subject == "" ||
            message == ""
        ) {
            setMailSent("incompleteForm");
            return;
        }
        if (!validateEmail(toEmail) || !validateEmail(fromEmail)) {
            setMailSent("detailsError");
            return;
        }
        let payload = {
            from_email: fromEmail,
            to_email: toEmail,
            message: message,
            subject: subject,
            booth_id: boothDetails?.booth?.[0]?.booth_id,
            event_id: eventConfig?.data?.[0]?.event_id,
        };
       // console.log("pl", payload);
        setMailSent("loading");
        sendResourcesEmailAPI(payload)?.then((response) => {
           // console.log("resourcesEmail", response);
            // setMailSent("success");
            setRequestSent(false);
        });
        
    };
    // console.log("emailProps", props);
    
    return (
        <>
            <Grid container justify="center" justifyContent="space-between" direction="column" sm={12} md={12} lg={12} xs={12}>
                <Grid
                    container
                    item
                    justifyContent="space-between"
                    // justify="flex start"
                    direction="column"
                    sm={12} md={12} lg={12} xs={12}
                    style={{ textAlign: "center" }}
                >
                    <Grid item  justifyContent="space-between" className="h4 text-center p-2" sm={12} md={12} lg={12} xs={12}>
                        Email
                        {/* <Button style={{ float: "right" }}>Email All</Button> */}
                    </Grid>
                    <Grid container item justify="center" justifyContent="space-between" sm={12} md={12} lg={12} xs={12}>  
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "left",
                                // justifyContent:'space-around',
                                whiteSpace:"pre-line",
                                padding: "auto",
                                // width: "100%",
                                width:'auto',
                                marginTop: 60,
                                gap: 15,
                            }}
                        >
                            <div style={{justifyContent:'space-between'} }>
                                <div> To Email Address *</div>
                                <div>
                                    {" "}
                                    <input
                                        type="email"
                                        id="resourcesEmailToEmail"
                                        className={classes.input}
                                    />
                                </div>
                            </div>
                            <div>
                                <div> From Email Address *</div>
                                <div>
                                    {" "}
                                    <input
                                        type="email"
                                        id="resourcesEmailFromEmail"
                                        className={classes.input}
                                    />
                                </div>
                            </div>
                            <div>
                                <div> Subject *</div>
                                <div>
                                    <input
                                        type="text"
                                        id="resourcesEmailSubject"
                                        className={classes.input}
                                    />
                                </div>
                            </div>
                            <div>
                                <div> Your Message *</div>
                                <div>
                                    {" "}
                                    {/* <div dangerouslySetInnerHTML={{__html: `${links}`}}> */}
                                    <textarea
                                        id="resourcesEmailMessage"
                                        className={classes.input}
                                        defaultValue={links}
                                        // defaultValue={dangerouslySetInnerHTML={__html: `${links}`}}
                                        rows={5}
                                    />
                                    {/* </div> */}
                                    {/* <div> Your Message *</div>
                                    <textarea
                                        id="resourcesEmailMessage"
                                        className={classes.input}
                                        rows={5}
                                    /> */}
                                </div>
                            </div>
                            <div style={{ textAlign: "center" }}>
                                <Button
                                    style={{
                                        background: "#1c1d1f",
                                        borderRadius: 50,
                                        padding: "6px 25px",
                                        marginBottom: 15,
                                        border: "none",
                                    }}
                                    onClick={() => {
                                        sendResourcesEmail();
                                    }}
                                >
                                    Send
                                </Button>
                            </div>
                        </div>
                    </Grid>
                </Grid>
            </Grid>
            <Snackbar
                open={mailSent == "incompleteForm"}
                autoHideDuration={2000}
                onClose={() => {
                    setMailSent(false);
                }}
            >
                <CustomAlert severity="error">Incomplete Details!</CustomAlert>
            </Snackbar>
            <Snackbar
                open={mailSent == "detailsError"}
                autoHideDuration={2000}
                onClose={() => {
                    setMailSent(false);
                }}
            >
                <CustomAlert severity="error">
                    Please enter valid detais!
                </CustomAlert>
            </Snackbar>
            <Snackbar
                open={mailSent == "error"}
                autoHideDuration={2000}
                onClose={() => {
                    setMailSent(false);
                }}
            >
                <CustomAlert severity="error">Failed!</CustomAlert>
            </Snackbar>
            {mailSent == "loading" ? (
                <>
                    <Modal
                        show={mailSent == "loading" || mailSent == "success"}
                        status={"success"}
                        message={"Message Sent Successfully!"}
                        loading={requestSent}
                        acknowledgement={true}
                        handleModalClose={() => {
                            setMailSent("done");
                            handleModalClose();
                        }}
                    />
                </>
            ) : null}
       </> 
    );
};

export default withStyles(styles)(Email);
