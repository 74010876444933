import React from 'react'
import conference_ico from "../../assets/conference-ico.png";
import {useStoreState } from "easy-peasy";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import VideoCall from "@material-ui/icons/VideoCall";
import agenda from "../../assets/agenda.png";
import QA from "../../assets/q&a.png";
import poll from "../../assets/polls.png";


function AuditorumBottonButton({ qa, polls, conference }) {
// Validation value from api taken and used for poll and QA menu in conference page
const {eventConfig} = useStoreState((state)=> state.state)
let poll_enabled = eventConfig?.data[0]?.poll_for_conference_enabled
let qa_enabled = eventConfig?.data[0]?.q_and_a_for_conference_enabled
    return (
     
        <div
            style={{
                position: "absolute",
                zIndex: 999,
                bottom: '20px',
                width: "min-content",
                display: 'flex',
                background: "#0739ff", padding: "1px", borderRadius: "5px",
                left: '50%',
                transform: 'translateX(-50%)',
                cursor: 'pointer'
            }}
        >

            {qa_enabled == "Yes"?(<div style={{ margin: '0px 1px', padding: '1px' }} onClick={qa}>
                <img
                    src={QA}
                    width="50"
                    //style={{ margin: "1px" }}
                    height="50"

                />
            </div>) : null}
           { poll_enabled == "Yes" ?(<div style={{ margin: '0px 1px', padding: '1px' }} onClick={polls}>
                <img
                    src={poll}
                    width="60"
                    //style={{ margin: "1px" }}
                    height="50"

                />
            </div>): null}
            <div style={{ margin: '0px 1px', padding: '1px' }} onClick={conference}>
                <img
                    src={agenda}
                    width="50"
                    //style={{ margin: "1px" }}
                    height="50"

                />
            </div>
        </div>
    )
}

export default AuditorumBottonButton
