import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import { Canvas } from "@react-three/fiber";
import { MapControls, OrbitControls } from "@react-three/drei";
import { Suspense } from "react";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Table } from "reactstrap";
import { Carousel, CarouselItem } from "reactstrap";
import { Button as RTButton } from "reactstrap";
import CustomCarousel from "../common/CustomCarousel";
import "../../index.css";
import { useStoreState, useStoreActions } from "easy-peasy";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "../common/Button";
import ReactPlayer from "react-player";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import PanToolIcon from "@material-ui/icons/PanTool";
import InfoIcon from "@material-ui/icons/Info";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import {
    product_buynowurl_log,
    sendProductEnquiryEmail as sendProductEnquiryEmailAPI,
} from "../../../../store/restApi/boothLiveApi";
import Modal from "../Modal";
import { product_log as productLogApi, SwagBag as SwagBagApi } from "../../../../store/restApi/boothLiveApi";
import { product_buynowurl_log as productBuynowurlLogApi } from "../../../../store/restApi/boothLiveApi";
import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { useContext } from "react";
import { ProductContext } from "../Stall";
import "./productModal.css";
import BarLoader from "react-spinners/BarLoader";
import { AiFillWindows } from "react-icons/ai";
import Modelfinal from "./Modelfinal";

//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove, SwagBag} from "../../../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
//------------------------My Favourite Function--------------------------//

const styles = (theme) => ({
    modalContainer: {
        [theme.breakpoints.up("lg")]: {
            // height: "600px",
            width: "950px",
        },
        [theme.breakpoints.down("md")]: {
            // height: "480px",
            width: "800px",
        },
        [theme.breakpoints.down("sm")]: {
            // height: "480px",
            width: "500px",
        },
        [theme.breakpoints.down("xs")]: {
            // height: "316px",
            width: "500px",
        },
    },
    modal: {
        margin: "0",
        [theme.breakpoints.up("lg")]: {
            height: "600px",
            width: "950px",
        },
        [theme.breakpoints.up("md")]: {
            height: "505px",
            width: "800px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "480px",
            width: "500px",
        },
        [theme.breakpoints.down("xs")]: {
            height: "316px",
            width: "500px",
        },
    },
    table: {
        textAlign: "center",
        borderColor: "#1c1d1f",
        "& th": {
            borderColor: "#1c1d1f",
        },
        "& td": {
            borderColor: "#1c1d1f",
        },
        "& tr": {
            borderColor: "#1c1d1f",
        },
        "& thead": {
            borderColor: "#1c1d1f",
        },
    },
    carousel: {
        border: "2px solid #1c1d1f",
        borderBottom: "none",
        padding: "10px",
    },
    onlyImageCarousel: {},
    productsGalleryImage: {
        [theme.breakpoints.up("lg")]: {
            height: "540px",
            width: "760px",
            margin: "0 30px",
        },
        [theme.breakpoints.down("md")]: {
            height: "469px",
            width: "660px",
        },
        [theme.breakpoints.down("sm")]: {
            height: "285px",
            width: "400px",
        },
        [theme.breakpoints.down("xs")]: {
            height: "285px",
            width: "400px",
        },
    },
});

const VideoPlayButton = () => {
    return <Button OpenIcon={PlayArrowIcon} />;
};

const ProductsModal = ({
    classes,
    productsData,
    setShowProductsModalTabs,
    activeProductsModalTab,
    enabledProductsTabs,
    setEnabledProductsTabs,
    initialIndex,
    initialDisplayMode,
    productAll,
    setProductAll,
    // activeIndex,
    // setActiveIndex
}) => {
    console.log("proddata", productsData);
    const [activeIndex, setActiveIndex] = useState(initialIndex);
    const [galleryIndex, setGalleryIndex] = useState(0);
    const [displayMode, setDisplayMode] = useState(initialDisplayMode);
    const [showProductsEnquiry, setShowProductsEnquiry] = useState(false);

    const { productsDetails, clickedMeshItem, model_360, boothDetails,exhibitorInfo } =
        useStoreState((state) => state.boothLiveState);
    const { getProductsDetails } = useStoreActions(
        (state) => state.boothLiveState
    );
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    // if(Object.keys(productsDetails).length > 0)
    let event = boothDetails?.event?.[0]?.event_id;
    const productDocuments =
        productsDetails?.[productsData[activeIndex]?.id]?.product_documents;
    const productVideos =
        productsDetails?.[productsData[activeIndex]?.id]?.product_videos;
    const productImages =
        productsDetails?.[productsData[activeIndex]?.id]?.product_images;
    let product3d = productsData[activeIndex]?.product_3d_model;
    //console.log(productDocuments,productVideos,productImages);
    useEffect(() => {
        // check enabled tabs
        if (displayMode == "carousel") {
            setShowProductsModalTabs(true);
        } else setShowProductsModalTabs(false);
    }, [displayMode]);

    
/*------------My faourite functionality----------------------------*/
const [favourite, setfavourite] = useState(productsData);
// let favouritestatus_payload = {
//     user_id: userDetails?.id,
//     favourite_type: 'product',       
//     event_id: eventConfig?.data?.[0]?.event_id,
//     booth_id:boothDetails.booth[0].booth_id,
//     exhibitor_id:exhibitorInfo?.exhibitor_id
// };
// useEffect(() => {
//     swagBagProducts(favouritestatus_payload).then((response) => {
//         if(favourite.length==0)
//         {                            
//             setfavourite(productsData);
//         }    
//     })
//     .catch((error) => {
//         //console.log(error);
//     }); 
// }, []);

const swag_products = (id) => {
    const payload = {
        user_id: userDetails?.id,
        favourite_type: "product",
        action_id: id,
        event_id: eventConfig?.data?.[0]?.event_id,
        booth_id: boothDetails?.booth[0].booth_id
    };
   
    SwagBagApi(payload).then((response) => {
        // console.log(response?.message);
    });
};


const setFavorite_fn=(idx,id,status)=>{       
setfavourite(favValue => 
    [...favValue].map(el => 
        el.id === id ? ({...el, flag_status:status}) : el)
    )      

let productsData_dummy=productsData;        
productsData_dummy[idx].flag_status=status;
updateState({ productsData: productsData_dummy});    

let favourite_payload = {
  user_id: userDetails?.id,
  favourite_type: 'product',
  action_id:id,
  event_id: eventConfig?.data?.[0]?.event_id,
};
if(status)
{
    myFavouriteAdd(favourite_payload).then((response) => {
     console.log(response);            
    })
    .catch((error) => {
        //console.log(error);
    });
}
else
{
    myFavouriteRemove(favourite_payload).then((response) => {
        console.log(response);            
       })
       .catch((error) => {
           //console.log(error);
       });
}  
};
/*------------My faourite functionality----------------------------*/


    const checkEnabledTabs = () => {
        let temp = {
            info: true,
            videos: true,
            documents: true,
            gallery: true,
            zoom: true,
        };
        if (productDocuments?.[0] == null) temp.documents = false;
        if (productVideos?.[0] == null) temp.videos = false;
        if (productImages?.[0] == null) temp.gallery = false;
        if (product3d == "") temp.zoom = false;
        setEnabledProductsTabs(temp);
    };

    useEffect(() => {
        checkEnabledTabs();
    }, [activeIndex]);
   
    const openfuncprod = (index) => {
        if (productsData?.length > 0) {
            let prodid = productsData[index].id;
            getProductsDetails({ event_id: event, product_id: prodid }).then(
                (response) => {
                    setActiveIndex(index);
                    setDisplayMode("carousel");
                }
            );
        }
    };


    const getmodel = (activeIndex) => {
        updateState({ model_360: productsData[activeIndex].product_3d_model });
    };


    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    //Tracking
    const { eventConfig, userDetails } = useStoreState((state) => state.state);

    const view_products = (id) => {
        const payload = {
            user_id_type: userDetails?.user_type,
            user_id: userDetails?.id,
            event_id: eventConfig?.data[0]?.event_id,
            product_id: id,
        };
        productLogApi(payload).then((response) => {
            // console.log(response?.message);
        });
    };

    const buynow_click = (id,redirect_link) => {
        const payload = {
            user_id_type: userDetails?.user_type,
            user_id: userDetails?.id,
            event_id: eventConfig?.data[0]?.event_id,
            product_id: id,
        };
        productBuynowurlLogApi(payload).then((response) => {
            window.open(redirect_link);
        });
    };
    //end

    const slides = favourite?.map((product, index) => {
        return (
            <CarouselItem
                className={"custom-tag " + classes.carousel}
                tag="div"
                key={index}
            >
                <Grid container style={{ display: "flex" }}>
                    <Grid
                        xs={7}
                        style={{
                            padding: "auto",
                            display: "flex",
                            justify: "center",
                            justifyContent: "center",
                            alignItems: "center",
                            // width: "100%",
                            // height: "100%",
                            // padding: "auto 0",
                            height: "540px",
                        }}
                    >
                        <img
                            src={`${product["product_image"]}`}
                            width="100%"
                            height="auto"
                            style={{
                                padding: 10,
                            }}
                        />
                    </Grid>

                    <Grid
                        xs={5}
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                        }}
                    >
                        {" "}
                        <Grid
                            dangerouslySetInnerHTML={{
                                __html: `${product?.product_title}`,
                            }}
                            item
                            xs={12}
                            style={{ fontWeight: "bold" }}
                        >
                            {/* {product["product_title"]} */}
                        </Grid>
                        <Grid
                            dangerouslySetInnerHTML={{
                                __html: `${product?.product_sku}`,
                            }}
                            item
                            xs={12}
                        >
                            {/* {product["product_sku"]} */}
                        </Grid>
                        <Grid item xs={12} style={{ fontWeight: "bold" }}>
                            &#8377;
                            {product["currency_code"] +
                                " " +
                                product["saleprice"]}
                        </Grid>
                        <Grid item xs={12}>
                            
                                <RTButton
                                color="primary"
                                    style={{
                                        background: "#1c1d1f",
                                        color: "white",
                                        borderRadius: 0,
                                        border: "none",
                                        margin: "10px",
                                    }}
                                    onClick={() => {
                                        buynow_click(product["id"],product["buynowurl"]);
                                    }}                                    
                                >
                                    Buy Now
                                </RTButton>
                           
                            <RTButton
                             color="primary"
                                style={{
                                    background: "#1c1d1f",
                                    color: "white",
                                    borderRadius: 0,
                                    border: "none",
                                    margin: "10px",
                                }}
                                onClick={() => {
                                    // sendProductEnquiryEmailAPI({});
                                    setShowProductsEnquiry(true);
                                }}
                            >
                                Enquire
                            </RTButton>
                        </Grid>
                        <Grid item xs={12}>
                            {/* <RTButton
                                style={{
                                    background: "#1c1d1f",
                                    color: "white",
                                    borderRadius: 0,
                                    border: "none",
                                }}
                                onClick={() => {
                                    // sendProductEnquiryEmailAPI({});
                                    setShowProductsEnquiry(true);
                                }}
                            >
                                Enquire
                            </RTButton> */}
                        </Grid>
                        <Grid
                            item
                            xs={12}
                            dangerouslySetInnerHTML={{
                                __html: `${product?.product_description
                                    ?.substring(0, 80)
                                    ?.split("?. ")
                                    ?.slice(0, 1)
                                    ?.join("?. ")}`,
                            }}
                        >
                            {/* {product["product_description"]
                                .split(". ")
                                .slice(0, 3)
                                .join(". ")} */}
                        </Grid>
                    </Grid>
                </Grid>
            </CarouselItem>
        );
    });

    const next = () => {
        const nextIndex =
            galleryIndex === productImages?.length - 1 ? 0 : galleryIndex + 1;
        setGalleryIndex(nextIndex);
    };

    const previous = () => {
        const nextIndex =
            galleryIndex === 0 ? productImages?.length - 1 : galleryIndex - 1;
        setGalleryIndex(nextIndex);
    };

    const productsGalleryTabSlides = productImages?.[0]
        ? productImages?.map((image, index) => {
              return (
                  <CarouselItem
                      className={"custom-tag " + classes.onlyImageCarousel}
                      tag="div"
                      key={index}
                  >
                      <Grid
                          style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              width: "auto",
                          }}
                          //   justify="center"
                      >
                          <Grid item style={{ paddingRight: 10 }}>
                              <Button
                               color="primary"
                                  OpenIcon={ChevronLeftIcon}
                                  handleClick={previous}
                              />
                          </Grid>
                          <Grid item>
                              <img
                                  alt={image.image_title}
                                  src={`${image.image_path}`}
                                  //   width="760px"
                                  //   height="540px"
                                  className={classes.productsGalleryImage}
                                  style={{
                                      padding: "auto",
                                  }}
                              />
                          </Grid>
                          <Grid item style={{ paddingLeft: 10 }}>
                              <Button
                               color="primary"
                                  OpenIcon={ChevronRightIcon}
                                  handleClick={next}
                              />
                          </Grid>
                      </Grid>
                  </CarouselItem>
              );
          })
        : [];
    return (
        <div className={"bg-white rounded " + classes.modalContainer}>
            {displayMode == "overview" ? (
                <Table
                    bordered
                    className={classes.table + " " + classes.modal}
                    style={{ width: "950px" }}
                >
                    <thead
                        style={{
                            background: "#1c1d1f",
                            color: "white",
                            borderColor: "#1c1d1f",
                        }}
                    >
                        <tr>
                            <th></th>
                            <th>Product</th>
                            <th>Description</th>
                            <th>Price</th>
                            <th></th>
                            <th>Add To Swagbag</th>
                        </tr>
                    </thead>
                    <tbody>
                        {productsData.map((product, index) => {
                            return (
                                <tr>
                                    <td>
                                        <img
                                            src={`${product["product_image"]}`}
                                            // height="120"
                                            width="100px"
                                            // style={{ maxHeight: "80%" }}
                                        />
                                    </td>
                                    <td
                                        dangerouslySetInnerHTML={{
                                            __html: `${product?.product_title}`,
                                        }}
                                    ></td>
                                    <td
                                        dangerouslySetInnerHTML={{
                                            __html: `${product?.product_description
                                                .substring(0, 150)
                                                .concat("...")}`,
                                        }}
                                    ></td>
                                    <td
                                        dangerouslySetInnerHTML={{
                                            __html:
                                                `${product?.currency_code}` +
                                                " " +
                                                `${product?.saleprice}`,
                                        }}
                                    ></td>
                                    <td
                                        style={{
                                            minWidth: "110px",
                                            cursor: "pointer",
                                            fontWeight: "bold",
                                        }}
                                        onClick={() => {
                                            // setActiveIndex(index);
                                            // checkEnabledTabs()
                                            // setDisplayMode("carousel");
                                            openfuncprod(index);
                                            getmodel(index);
                                            view_products(product["id"]);
                                            
                                            
                                        }}
                                    >
                                        <RTButton
                                         color="primary"
                                            style={{
                                                background: "#1c1d1f",
                                                color: "white",
                                                borderRadius: 0,
                                                border: "none",
                                                "font-size": "smaller",
                                            }}
                                        >
                                            View details
                                        </RTButton>
                                    </td>
                                    <td
                                        style={{
                                            minWidth: "110px",
                                            cursor: "pointer",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        <div
                                            onClick={() =>
                                                
                                                setFavorite_fn(
                                                    
                                                    index,
                                                    product?.id,
                                                    true,
                                                    swag_products(product["id"])
                                                    
                                                )

                                                
                                            }
                                        >
                                            {product.flag_status ? (
                                                <RemoveCircleIcon />
                                            ) : (
                                                <AddCircleIcon />
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            ) : null}

            {displayMode == "carousel" ? (
                activeProductsModalTab == "info" ? (
                    <div>
                        <CustomCarousel
                            activeIndex={activeIndex}
                            setActiveIndex={setActiveIndex}
                            content={slides}
                            setDisplayMode={setDisplayMode}
                            enableProductTabs={true}
                            onlyImageCarousel={false}
                        />
                    </div>
                ) : null
            ) : null}
            {displayMode == "carousel" ? (
                activeProductsModalTab == "zoom" ? (
                    <>
                        <Modelfinal />
                    </>
                ) : null
            ) : null}

            {displayMode == "carousel" ? (
                activeProductsModalTab == "documents" &&
                productDocuments?.[0] ? (
                    <Table
                        bordered
                        className={classes.table + " " + classes.modal}
                    >
                        <tbody>
                            {productDocuments?.map((document, index) => {
                                return (
                                    <tr>
                                        <td style={{ fontWeight: "bold" }}>
                                            {document.document_title}
                                        </td>
                                        <td
                                            style={{
                                                minWidth: "110px",
                                                cursor: "pointer",
                                            }}
                                            onClick={(event) =>
                                                openInNewTab(
                                                    document.document_path
                                                )
                                            }
                                        >
                                            <Button
                                             color="primary"
                                                style={{
                                                    height: 100,
                                                    width: 100,
                                                }}
                                                OpenIcon={CloudDownloadIcon}
                                                handleClick={(event) =>
                                                    openInNewTab(
                                                        document.document_path
                                                    )
                                                }
                                            ></Button>
                                            Download here
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                ) : null
            ) : null}
            {displayMode == "carousel" ? (
                activeProductsModalTab == "videos" && productVideos?.[0] ? (
                    <div className={classes.modal} style={{ width: "auto" }}>
                        {productVideos?.map((video, index) => {
                            return (
                                <Grid
                                    item
                                    xs={12}
                                    sm={
                                        productsDetails[
                                            productsData[activeIndex].id
                                        ].product_videos.length /
                                            2 >=
                                        1
                                            ? 4
                                            : 12
                                    }
                                    md={
                                        productsDetails[
                                            productsData[activeIndex].id
                                        ].product_videos.length /
                                            3 >=
                                        1
                                            ? 4
                                            : 12
                                    } // lg={3}
                                    style={{ padding: 5 }}
                                >
                                    <div>
                                        <ReactPlayer
                                            width="inherit"
                                            height="auto"
                                            url={video.video_path}
                                            controls={true}
                                            // playIcon={<>Play</>}
                                            // light={true}
                                            // playIcon={VideoPlayButton}
                                        />
                                        <div className="small">
                                            {video.video_title}
                                        </div>
                                    </div>
                                </Grid>
                            );
                        })}
                    </div>
                ) : null
            ) : null}

            {displayMode == "carousel" ? (
                activeProductsModalTab == "gallery" && productImages?.[0] ? (
                    <div className={classes.modal} style={{ width: "auto" }}>
                        <CustomCarousel
                            activeIndex={galleryIndex}
                            setActiveIndex={setGalleryIndex}
                            content={productsGalleryTabSlides}
                            setDisplayMode={setDisplayMode}
                            onlyImageCarousel={true}
                        />
                    </div>
                ) : null
            ) : null}
            {showProductsEnquiry ? (
                <Modal
                    handleModalClose={() => {
                        setShowProductsEnquiry(false);
                    }}
                    show={showProductsEnquiry}
                    productEnquiry={true}
                    productId={productsData?.[activeIndex]?.id}
                />
            ) : null}
        </div>
    );
};

export default withStyles(styles)(ProductsModal);
