import React, { useState, useEffect } from "react";
import { CarouselItem, Table, Button } from "reactstrap";
import { Grid, span } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomCarousel from "../common/CustomCarousel";
import { useStoreState, useStoreActions } from "easy-peasy";
import { applyJob as applyJobAPI } from "../../../../store/restApi/boothLiveApi";
import { job_log as jobLogApi } from "../../../../store/restApi/boothLiveApi";
import { validateEmail } from "../../utils/validation";
import AcknowledgementModal from "./AcknowledgementModal";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove, swagBagJobs } from "../../../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
//------------------------My Favourite Function--------------------------//

const styles = (theme) => ({
    modal: {
        margin: 0,
        width: "100%",
    },
    table: {
        margin: "10px auto",
        textAlign: "center",
        borderColor: "#1c1d1f",
        "& th": {
            borderColor: "#1c1d1f",
        },
        "& td": {
            borderColor: "#1c1d1f",
        },
        "& tr": {
            borderColor: "#1c1d1f",
        },
        "& thead": {
            borderColor: "#1c1d1f",
        },
    },
    carousel: {
        border: "2px solid #1c1d1f",
        borderBottom: "none",
        padding: "10px",
    },
    textarea: {
        border: "1px dashed #1c1d1f",
        padding: "5px 10px",
        "&:focus": {
            border: "1px dashed #1c1d1f",
            padding: "5px 10px",
        },
    },
    input: {
        padding: "5px 10px",
        border: "1px solid black",
        "&:focus": {
            outline: "none",
        },
        width: "100%",
    },
});
const CustomAlert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};
const JobsModal = ({ classes, jobsData }) => {
    //console?.log("jobsData", jobsData);
    const [activeIndex, setActiveIndex] = useState(0);
    const [displayMode, setDisplayMode] = useState("overview");
    const [selectedJob, setSelectedJob] = useState({});
    const [jobApplied, setJobApplied] = useState(false);
    const [jobStatus, setJobStatus] = useState("");
    const [requestSent, setRequestSent] = useState(true);
    const { eventConfig,userDetails } = useStoreState((state) => state.state);
    const { boothDetails, exhibitorInfo } = useStoreState(
        (state) => state?.boothLiveState
    );
    console.log('booth', boothDetails?.booth[0]?.booth_id)

     /*------------My faourite functionality----------------------------*/
     const { updateState } = useStoreActions((state) => state.boothLiveState);
     const [favourite, setfavourite] = useState(jobsData);
        // let favouritestatus_payload = {
        //     user_id: userDetails?.id,
        //     favourite_type: 'job',       
        //     event_id: eventConfig?.data?.[0]?.event_id,
        //     booth_id:boothDetails.booth[0].booth_id,
        //     exhibitor_id:exhibitorInfo?.exhibitor_id
        // };
        // useEffect(() => {
        //     swagBagJobs(favouritestatus_payload).then((response) => {
        //         if(favourite.length==0)
        //         {                              
        //             setfavourite(response);
        //         }    
        //     })
        //     .catch((error) => {
        //         //console.log(error);
        //     }); 
        // }, []);

        const view_job = (id) =>{
            //Tracking job Api calling
            const payload = {
                visitor_user_id_type: userDetails?.user_type,
                visitor_user_id: userDetails?.id,
                event_id: eventConfig?.data[0]?.event_id,
                job_id: id               
            };
    
            jobLogApi(payload).then((response) => {
               // console.log(response?.message);               
            });
        }
        
    const setFavorite_fn=(idx,id,status)=>{       
        setfavourite(favValue => 
            [...favValue].map(el => 
                el.id === id ? ({...el, flag_status:status}) : el)
            )      
       
        let jobsData_dummy=jobsData;        
        jobsData_dummy[idx].flag_status=status;
        updateState({ jobsData: jobsData_dummy});    

        let favourite_payload = {
          user_id: userDetails?.id,
          favourite_type: 'job',
          action_id:id,
          event_id: eventConfig?.data?.[0]?.event_id,
          booth_id: boothDetails?.booth[0]?.booth_id
        };
        if(status)
        {
            myFavouriteAdd(favourite_payload).then((response) => {
             console.log(response);            
            })
            .catch((error) => {
                //console.log(error);
            });
        }
        else
        {
            myFavouriteRemove(favourite_payload).then((response) => {
                console.log(response);            
               })
               .catch((error) => {
                   //console.log(error);
               });
        }  
      };
    /*------------My faourite functionality----------------------------*/

    const applyJob = () => {
        let intro = document?.getElementById("applyJobCV")?.value,
            jobId = selectedJob?.id,
            name = document?.getElementById("applyJobName")?.value,
            email = document?.getElementById("applyJobEmail")?.value;

        if (email == "" || name == "" || !validateEmail(email)) {
            setJobApplied("incompleteForm");
            return;
        }
        const payload = {
            attendee_id: userDetails?.id ? userDetails?.id : "",
            job_id: jobId,
            notes: intro,
           // request_made_by_name: name,
            //request_made_by_email_address: email,
            event_id: eventConfig?.data?.[0]?.event_id,
        };
        //console?.log(payload, "submit");
        setRequestSent(true);
        setJobApplied("loading");
        applyJobAPI(payload)?.then((response) => {
            //console?.log("applied", response);           
            if (response?.restatus == "success" )
            {
             setJobApplied("success");
            }
            else
            {
                setJobApplied("error");
                setJobStatus(response?.remesssage);
            }
            setRequestSent(false);
        });
    };


    //Tracking
    // const view_job = (id) =>{
    //     //Tracking job Api calling
    //     const payload = {
    //         visitor_user_id_type: userDetails?.user_type,
    //         visitor_user_id: userDetails?.id,
    //         event_id: eventConfig?.data[0]?.event_id,
    //         job_id: id               
    //     };

    //     jobLogApi(payload).then((response) => {
    //        // console.log(response?.message);               
    //     });
    // }


    const slides = favourite?.map((job, index) => {
        return (
            <CarouselItem
                className={"custom-tag " + classes?.carousel}
                tag="div"
                key={index}
            >
                {/* <Grid container style={{ display: "flex" }}> */}
                <Grid
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: 15,
                    }}
                >
                    {" "}
                    <Grid
                        dangerouslySetInnerHTML={{
                            __html: `${job?.job_title}`,
                        }}
                        item
                        xs={12}
                        style={{ fontWeight: "bold" }}
                    >
                        {/* {job?.job_title} */}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <b>Course Duration : </b>
                        {course?.course_duration}
                    </Grid> */}
                    <Grid
                        dangerouslySetInnerHTML={{
                            __html: ` <b>Job Location : </b>${job?.job_title}`,
                        }}
                        item
                        xs={12}
                    >
                        {/* <b>Job Location : </b>
                        {job?.job_location} */}
                    </Grid>
                    {/* <Grid item xs={12}>
                        <b>Course Fee : </b>
                        {course?.course_fees} Per Year
                    </Grid> */}
                    <Grid
                        dangerouslySetInnerHTML={{
                            __html: `${job?.job_description}`,
                        }}
                        item
                        xs={12}
                    >
                        {/* {job?.job_description} */}
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                        color="primary"
                            style={{
                                background: "#1c1d1f",
                                borderRadius: 0,
                                padding: "4px 20px",
                                marginBottom: 15,
                                margin: 15,
                                border: "none",
                            }}
                            onClick={() => {
                                setSelectedJob(job);
                                setDisplayMode("form");
                            }}
                        >
                            Apply
                        </Button>
                        {job?.job_url != "" ? (
                            <>
                                <Button
                                color="primary"
                                    style={{
                                        background: "#1c1d1f",
                                        borderRadius: 0,
                                        padding: "4px 20px",
                                        marginBottom: 15,
                                        margin: 15,
                                        border: "none",
                                    }}
                                    onClick={() => {
                                        window.open(
                                            `${job?.job_url}`,
                                            "location=yes,height=570,width=520,scrollbars=yes,status=yes"
                                        );
                                    }}
                                >
                                    Website
                                </Button>
                            </>
                        ) : null}
                    </Grid>
                </Grid>
            </CarouselItem>
        );
    });

    return (
        <div className={"bg-white rounded " + classes?.modal}>
            {jobApplied != "success" && jobApplied != "loading" ? (
                <>
                    {displayMode == "overview" ? (
                        <Table bordered className={classes?.table}>
                            <thead
                                style={{
                                    background: "#1c1d1f",
                                    color: "white",
                                    borderColor: "#1c1d1f",
                                }}
                            >
                                <tr>
                                    <th style={{ minWidth: 150 }}>Job ID</th>
                                    <th>Job Title</th>
                                    <th>Location</th>
                                    <th>Job Details</th>
                                    <th></th>
                                    <th>Favorite</th>
                                </tr>
                            </thead>
                            <tbody>
                                {jobsData?.map((job, index) => {
                                    return (
                                        <tr>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${job?.code}`,
                                                }}
                                            ></td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${job?.job_title}`,
                                                }}
                                            ></td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${job?.job_location}`,
                                                }}
                                            ></td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${job?.job_description
                                                        ?.substring(0, 80)
                                                        ?.split("?. ")
                                                        ?.slice(0, 1)
                                                        ?.join("?. ")}`,
                                                }}
                                            >
                                                {/* {job?.job_description 
                                                    ?.substring(0,80)
                                                    ?.split("?. ")
                                                    ?.slice(0, 1)
                                                    ?.join("?. ")} */}
                                            </td>
                                            <td
                                                style={{
                                                    minWidth: "110px",
                                                    cursor: "pointer",
                                                    fontWeight: "bold",
                                                }}
                                                onClick={() => {
                                                    setActiveIndex(index);
                                                    setDisplayMode("carousel");
                                                    view_job(job?.id);
                                                }}
                                            >
                                                <Button
                                                color="primary"
                                                    style={{
                                                        "font-size": "smaller",
                                                        "background-color":
                                                            "black",
                                                    }}
                                                >
                                                    View Details
                                                </Button>
                                            </td>
                                            <td
                                                style={{
                                                    minWidth: "110px",
                                                    cursor: "pointer",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                <div
                                                    onClick={() =>
                                                        setFavorite_fn(
                                                            index,
                                                            job?.id,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {job.flag_status ? (
                                                        <FavoriteIcon />
                                                    ) : (
                                                        <FavoriteBorderIcon />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                          

                    ) : null}
                    {displayMode == "carousel" ? (
                        <div>
                            <CustomCarousel
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                                content={slides}
                                setDisplayMode={setDisplayMode}
                            />
                        </div>
                    ) : null}
                    {displayMode == "form" ? (
                        <div
                            style={{
                                padding: "10px",
                                color: "#1c1d1f",
                                border: "1px solid #1c1d1f",
                            }}
                        >
                            <Grid
                                style={{
                                    display: "flex",
                                    gap: 15,
                                    flexDirection: "column",
                                }}
                            >
                                <Grid item xs={12}>
                                    <b>Job title :</b> {selectedJob?.job_title}
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: 10,
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <b>Name *</b>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <input
                                            className={classes?.input}
                                            id="applyJobName"
                                            name="name"
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: 10,
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <b>Email *</b>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <input
                                            className={classes?.input}
                                            id="applyJobEmail"
                                            name="email"
                                            type="email"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <b>Covering Letter :</b>
                                </Grid>
                                <Grid item xs={12}>
                                    <textarea
                                        rows="5"
                                        className={classes?.textarea}
                                        style={{ minWidth: "400px" }}
                                        id="applyJobCV"
                                    ></textarea>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                    color="primary"
                                        style={{
                                            background: "#1c1d1f",
                                            borderRadius: 0,
                                            padding: "4px 20px",
                                            marginBottom: 15,
                                            border: "none",
                                        }}
                                        onClick={applyJob}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                            <Snackbar
                                open={jobApplied == "incompleteForm"}
                                autoHideDuration={2000}
                                onClose={() => {
                                    setJobApplied(false);
                                }}
                            >
                                <CustomAlert severity="error">
                                    Incomplete Details!
                                </CustomAlert>
                            </Snackbar>
                            <Snackbar
                                open={jobApplied == "error"}
                                autoHideDuration={2000}
                                onClose={() => {
                                    setJobApplied(false);
                                }}
                            >
                                <CustomAlert severity="error">
                                    {jobStatus}
                                </CustomAlert>
                            </Snackbar>
                        </div>
                    ) : null}
                </>
            ) : (
                <>
                    <AcknowledgementModal
                        status={"success"}
                        message={"Applied Successfully!"}
                        loading={requestSent}
                    />
                </>
            )}
            {/* <Snackbar
                open={jobApplied == "success"}
                autoHideDuration={2000}
                onClose={() => {
                    setJobApplied(false);
                }}
            >
                <CustomAlert severity="success">
                    Applied successfully!
                </CustomAlert>
            </Snackbar> */}
        </div>
    );
};

export default withStyles(styles)(JobsModal);

// <Grid
//             container
//             className={"bg-white rounded " + classes?.modal}
//             direction="column"
//         >
//             {jobsData?.map((job, index) => {
//                 return (
//                     <Grid
//                         key={index}
//                         item
//                         className={"shadow rounded mb-3 " + classes?.jobCard}
//                         style={{
//                             background: index % 2 == 0 ? "#f0f0f0" : "",
//                             cursor: "default",
//                         }}
//                         xs={12}
//                     >
//                         <div style={{ marginBottom: "0?.2rem" }}>
//                             <span
//                                 onClick={() => {
//                                     showJobDesc == index
//                                         ? setShowJobDesc(-1)
//                                         : setShowJobDesc(index);
//                                 }}
//                             >
//                                 {job["job_employer"]},{"  "}
//                                 {job["job_location"] + "  "}
//                             </span>
//                             <IconButton
//                                 onClick={() => {
//                                     showJobDesc == index
//                                         ? setShowJobDesc(-1)
//                                         : setShowJobDesc(index);
//                                 }}
//                                 style={{ height: "1rem", width: "1rem" }}
//                             >
//                                 <ExpandMore />
//                             </IconButton>
//                             <span>
//                                 {/* <div
//                                     style={{
//                                         padding: "0?.1rem 0?.5rem",
//                                         margin: "0",
//                                         display: "inline-block",
//                                         float: "right",
//                                         background: "#d1ecf1",
//                                         color: "#0c5460",
//                                         borderRadius: "0?.15rem",
//                                     }}
//                                 >
//                                     Apply
//                                 </div> */}
//                             </span>
//                         </div>
//                         <div className="text-muted">
//                             <span>
//                                 (
//                                 {job["job_title"] + ", Rs " + job["job_salary"]}
//                                 )
//                             </span>
//                         </div>
//                         <Collapse isOpen={showJobDesc == index ? true : false}>
//                             <span>{job["job_description"]}</span>
//                         </Collapse>
//                     </Grid>
//                 );
//             })}
//         </Grid>
