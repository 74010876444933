import ReactPlayer from "react-player";
import { withStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
    modal: {
        [theme.breakpoints.up("lg")]: {
            // height: "600px",
            //width: "950px",
            width: "auto",
            height: "auto",
        },
        [theme.breakpoints.down("md")]: {
            // height: "480px",
            //width: "600px",
            width: "auto",
            height: "auto",
        },
        [theme.breakpoints.down("xs")]: {
            // height: "316px",
            //width: "500px",
            width: "auto",
            height: "auto",
        },
    },
});

const SessionVideoModal = (props) => {
    //console.log("myProps", props);
    let video_url="https://player.vimeo.com/video/"+props?.videoUrl;
    return (
        <div className={props.classes.modal}>
            <ReactPlayer
                width="100%"
                height="100%"
                //url={props?.videoUrl}
                url={video_url}
                controls={true}
                playing={true}
            />
        </div>
    );
};

export default withStyles(styles)(SessionVideoModal);
