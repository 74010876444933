//
// This example assumes you are importing mux-embed from npm
// View this code on codesandbox: https://codesandbox.io/s/mux-data-hls-js-react-ucvvh
//
import React, { useEffect, useRef, useState } from "react";
import Hls from "hls.js";
import mux from "mux-embed";
import { useStoreState, useStoreActions } from "easy-peasy";
import { video } from "can-autoplay";

const VideoPlayer = (props) => {
    const { mux_playing, streamUrl } = useStoreState(
        (state) => state.boothLiveState
    );
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    const [ran, setRan] = useState(false);
    let playback = "f9G6MzrGDB7OTsHuHKy3NwAoOwZRIFvVb015iAHtDCDo";
    const videoRef = useRef(null);
    const src = streamUrl;
    // const src = "https://stream.mux.com/wfF9i5xSfVzdqn24nGmD6Zetlc5fgksgYU23Az4ChnM.m3u8";

    useEffect(() => {
        updateState({ canPlayMuxLink: true });
        let hls;
        if (videoRef.current) {
            const video = videoRef.current;
            const initTime = Date.now();

            if (video.canPlayType("application/vnd.apple.mpegurl")) {
                // This will run in safari, where HLS is supported natively
                video.src = src;
            } else if (Hls.isSupported()) {
                // This will run in all other modern browsers
                hls = new Hls();
                if(typeof src!="undefined")
                {                
                hls.loadSource(src);
                hls.attachMedia(video);
                }
            }

            mux.monitor(video, {
                debug: false,
                // pass in the 'hls' instance and the 'Hls' constructor
                hlsjs: hls,
                Hls,
                data: {
                    env_key: "tvaa9hlrf1kr2lv764trnp4en", // required
                    // Metadata fields
                    player_name: "Main Player", // any arbitrary string you want to use to identify this player
                    player_init_time: initTime,
                    // ...
                },
            });
        }

        return () => {
            if (hls) {
                hls.destroy();
            }
        };
    }, [videoRef, streamUrl]);

    return (
        <video
            style={{ objectFit: "initial" }}
            id="muxplayer"
            controls
            // loop={true}
            autoPlay={true}
            ref={videoRef}
            // onLoad=
            // onCanPlay={() => updateState({ canPlayMuxLink: true })}
            // onPlay={() => {
            //     console.log("Play");
            //     updateState({ mux_playing: true });
            // }}
            // onPause={() => {
            //     updateState({ mux_playing: false });
            // }}
            width="760"
            height="700"
        />
    );
};

export default VideoPlayer;
