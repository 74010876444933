import React, { useEffect, useState } from "react";
import Righticon from "../../../assets/rightarrow.png";
import Lefticon from "../../../assets/leftarrow.png";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useHistory } from "react-router-dom";
import { getBoothsList as getBoothsListAPI } from "../../../../../store/restApi/api";
import isEmpty from "lodash";
import styled from "styled-components";

const BoothNav = () => {

    //style values for booth navigation buttons 

    const BoothnavOverall = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    display:flex;
    position:absolute;
    width:auto;
    height:auto;
    top:10px;
    z-index:1;
    right:40px;
    font-family: 'Poppins', sans-serif;

    @media (max-width:1024px){
       top:10px;
       right:30px;

    }

    `

    const BoothnavContents = styled.div`
    display:flex;
    justify-content:center;
    align-items:center;
    margin-right:10px;


    `

    const BoothnavImg = styled.img.attrs(props => ({
  src:props?.Lefticon || props?.Righticon  
}))`
    width:35px;
    height:35px;
    cursor:pointer;

       @media (max-width:1024px){
        width:30px;
    height:30px;

    }

    `
    const BoothnavInfo = styled.div`
     display:flex;
     font-size:13px;
     font-weight:600;
     margin-left:5px;

      @media (max-width:1024px){
        font-size:10px;

    }
    `
    const history = useHistory();
    const { eventConfig } = useStoreState((state) => state.state);
    //const [exhibitorsList, setExhibitorsList] = useState([]);

    const { updateState } = useStoreActions((state) => state.boothLiveState);
    let { exhibitorsList, boothNavigation, boothDetails } = useStoreState(
        (state) => state.boothLiveState
    );

    useEffect(() => {
        if (typeof exhibitorsList == "undefined") {
            getBoothsListAPI({
                eventId: eventConfig?.data?.[0]?.event_id,
            }).then((res) => {
                updateState({ exhibitorsList: res });

                for (let b = 0; b < res.length; b++) {
                    if (
                        res[b]?.booths_list[0]?.booth_details[0]?.booth_id ==
                        boothDetails?.booth[0]?.booth_id
                    ) {
                        updateState({ boothNavigation: b });
                    }
                }
            });
            //console.log(exhibitorsList);
        }
    }, []);

    useEffect(() => {
        if (typeof exhibitorsList != "undefined") {
            for (let b = 0; b < exhibitorsList.length; b++) {
                if (
                    exhibitorsList[b]?.booths_list[0]?.booth_details[0]
                        ?.booth_id == boothDetails?.booth[0]?.booth_id
                ) {
                    updateState({ boothNavigation: b });
                }
            }
        }
    }, [boothDetails]);

    const boothNavRightFn = () => {
        if (typeof boothNavigation == "undefined") {
            updateState({ boothNavigation: 0 });
        } else {
            if (boothNavigation < exhibitorsList.length) {
                boothNavigation = boothNavigation + 1;
                updateState({ boothNavigation: boothNavigation });
                history.push(
                    `/booth/` +
                        exhibitorsList[boothNavigation]?.booths_list[0]
                            ?.booth_details[0]?.booth_id
                );
            }
        }
    };

    const boothNavLeftFn = () => {
        if (typeof boothNavigation == "undefined") {
            updateState({ boothNavigation: 0 });
        } else {
            if (boothNavigation > 0) {
                boothNavigation = boothNavigation - 1;
                updateState({ boothNavigation: boothNavigation });
                history.push(
                    `/booth/` +
                        exhibitorsList[boothNavigation]?.booths_list[0]
                            ?.booth_details[0]?.booth_id
                );
            }
        }
    };

    return (
        <>
  <BoothnavOverall >
            {typeof exhibitorsList != "undefined" ? (
                boothNavigation != 0 ? (
                    <BoothnavContents onClick={boothNavLeftFn}>
                        <BoothnavImg id="rotate" Lefticon={Lefticon} />
                        <BoothnavInfo > Previous</BoothnavInfo>
                    </BoothnavContents>
                ) : null
            ) : null}

            {typeof exhibitorsList != "undefined" ? (
                boothNavigation < exhibitorsList.length - 1 ? (
                  
                    <BoothnavContents onClick={boothNavRightFn}>
                        <BoothnavImg Righticon={Righticon}/>
                        <BoothnavInfo> Next </BoothnavInfo>
                        </BoothnavContents>
                    
                ) : null
            ) : null}
</BoothnavOverall >
        </>
    );
};

export default BoothNav;
