import * as THREE from "three";
import React, { useEffect, useRef, useState } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Html } from "@react-three/drei";
import Button from "./common/Button";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
    "@keyframes blinker": {
        "0%": { opacity: 0 },
        "25% ": { opacity: 0.5 },
        "50%": { opacity: 1 },
        "75%": { opacity: 0.5 },
        "100%": { opacity: 0 },
    },
    menuItemContainerDiv: {
        animationName: "$blinker",
        animationDuration: "3s",
        animationTimingFunction: "ease-out",
        animationIterationCount: "infinite",
    },
    numberContainerDiv: {
        backgroundColor: "#1c1d1f80",
        color: "black",
        borderRadius: "45px",
        width: "25px",
        height: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        cursor: "pointer",
    },
    numberDiv: {
        backgroundColor: "#fff",
        borderRadius: "40px",
        width: "15px",
        height: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    cancelButton: {
        // marginBottom: '10px',
        // top: theme.spacing(10),
        right: theme.spacing(2.5),
    },
});

export const manageNavigationButtonDisplay = (length) => {
    for (let i = 0; i < length; i++) {
        if (
            document.getElementById("navigateModelButton" + i).style.display ==
            "none"
        )
            document.getElementById("navigateModelButton" + i).style.display =
                "block";
    }
};

const Clickable = (props) => {
    const { classes } = props;
    const { positions, showClickableBack } = useStoreState(
        (state) => state.state
    );
    // const [display, setDisplay] = useState({ index: null, diaplay: "block" });
    const { updateState } = useStoreActions((state) => state.state);
    const { isSmallScreen } = useStoreState((state) => state.state);
    const [positionsArr, setPositionsArr] = useState([]);
    const [initialPositionsArr, setinitialPositionsArr] = useState(undefined);
    // const [showBack, setShowBack] = useState(false);
    const { camera, gl } = useThree();
    let canvas;
    useFrame(() => {
        canvas = gl.domElement;
    });

    useEffect(() => {
        if (positions) {
            setinitialPositionsArr(positions[0]);
            setPositionsArr([...positions]);
        }
    }, []);

    useEffect(() => {
        positionsArr?.shift();
        updateState({
            no_of_coordinates: positionsArr?.length,
        });
    }, [positionsArr]);

    useEffect(() => {
        positionsArr?.map((i, index) => {
            if (i.name === "presentation") {
                // console.log(i.name, index);
                updateState({ presentationZoneIndex: index });
            }
        });
    }, [positionsArr]);
    const changeCameraHandler = (index) => {
        camera.fov = 75;
        camera.updateProjectionMatrix();
        manageNavigationButtonDisplay(positionsArr?.length);

        if (positionsArr[index].name === "presentation") {
            updateState({ isPresentationZone: true });
        }
        document.getElementById("navigateModelButton" + index).style.display =
            "none";
        //console.log(positionsArr[index].cameraPosition);
        camera.position.set(
            JSON.parse(positionsArr[index].cameraPosition)[0],
            JSON.parse(positionsArr[index].cameraPosition)[1],
            JSON.parse(positionsArr[index].cameraPosition)[2]
        );
        camera.lookAt(
            new THREE.Vector3(
                JSON.parse(positionsArr[index].cameraLookAt)[0],
                JSON.parse(positionsArr[index].cameraLookAt)[1],
                JSON.parse(positionsArr[index].cameraLookAt)[2]
            )
        );
        updateState({
            isPresentationVisible: positionsArr[index]?.isVisible?.presentation,
            showClickableBack: true,
        });
        // camera.updateProjectionMatrix();
        // updateState({ showBack: true });
        // camera.updateProjectionMatrix();
    };

    return (
        <>
            {positionsArr?.map((i, index) => {
                return (
                    <>
                        <mesh
                            position={JSON.parse(i.navButtonPosition)}
                            key={index}
                        >
                            {/* <sphereGeometry args={[1, 32, 32]} /> */}
                            <meshBasicMaterial color="white" />

                            <Html center zIndexRange={[1, 0]}>
                                <div
                                    className={
                                        "blink " + classes.menuItemContainerDiv
                                    }
                                    id={"navigateModelButton" + index}
                                    onClick={() => changeCameraHandler(index)}
                                >
                                    <div className={classes.numberContainerDiv}>
                                        <div className={classes.numberDiv}>
                                            <span>{index + 1}</span>
                                        </div>
                                    </div>
                                </div>
                            </Html>
                        </mesh>
                    </>
                );
            })}
        </>
    );
};

export default withStyles(styles)(Clickable);
