import React, { useEffect, useState } from "react";
// import "./documents.css";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import Download from "../../../../assets/download-file.png";
import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";


const Staffs = ({ event_id, user_id }) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
        {
            id: "exhibitor_company_name",
            displayName: "Company Name",
        },
        {
            id: "phone_number",
            displayName: "Contact Number",
        },
        // {
        //     id: "image",
        //     displayName: "Speaker Image",
        // },
        {
            id:"remove_favourite_businesscard",
            displayName: "Remove",
        }
    ];
    const [child, setChild] = useState([]);

    // res?.data

    useEffect(() => {
        async function fetchStaff() {

        let payload_data={
            event_id: event_id,
            user_id: user_id,
            swag_type : "staff_businesscard",
        };
        SwagListApi(payload_data)
            .then((res) => setChild(() => 
           res.staff_businesscards))
            .catch((err) => {
               // console.log(err);
            });
    }
    fetchStaff();
        return () => {
            setChild(() => []);
        };
    }, []);

    const actionsUI = () => {
        return (
            <>
                <div>
                    
                </div>
            </>
        );
    };

    return (
        <Listing
            header={header}
            child={child}
            actions={actionsUI}
        />
    );
};

export default Staffs;
