import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import BussinessCard from "../../../common/BussinessCard";
import email from "../../../../assets/email.png";
import textchat from "../../../../assets/textchat.png";
import businesscard from "../../../../assets/businesscard.png";
// import { myConnections as myConnectionsApi } from "../../../../store/restApi/api";
import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";

const Connections = ({ event_id, user_id, emailModal, bussinessCardModal }) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
        {
            id: "first_name",
            displayName: "FIRST NAME",
        },
        {
            id: "last_name",
            displayName: "LAST NAME",
        },
        {
            id: "online_status",
            displayName: "ONLINE STATUS",
        },
        {
            id: "job_title",
            displayName: "JOB TITLE",
        },
        {
            id:"remove_favourite_attendee",
            displayName: "Remove",
        }
    ];
    const [child, setChild] = useState([]);
    const actionsUI = (data) => {
      // console.log(data)
        return (
            <>
                {/* <div>
                    <img src={textchat} alt="textchat" />
                    <br />
                    <span>Text Chat</span>
                </div>
                <div onClick={() => {
                    
                     emailModal(data)
                }}>
                    <img src={email} alt="email" />
                    <br />
                    <span>Email</span>
                </div>
                <div onClick={() => bussinessCardModal()}>
                    <img src={businesscard} alt="BussinessCard" />
                    <br />
                    <span>Bussiness Card</span>
                </div> */}
            </>
        );
    };

    useEffect(() => {
        async function fetchVideos() {
            // await fetch(`${baseUrl}/my_connections.php`, {
            //     method: "post",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //         event_id: event_id,
            //         user_id: user_id,
            //     }),
            // })
            // .then((res) => res.json())
            // .then((res) => {
            //     let data = res.data
            //     let response = [];
            //     data?.map(({profile, businesscard}) => {
            //        if(typeof businesscard!="undefined")
            //        { 
            //         let obj = {
            //                 businesscard_contact_person : businesscard.businesscard_contact_person,
            //                 exhibitor_company_name : profile.exhibitor_company_name,
            //                 businesscard_email : businesscard.businesscard_email,
            //                 phone : businesscard.businesscard_phone_no_country_code + "" +businesscard.businesscard_phone_no,
            //                 user_id : profile.user_id

            //             }
            //             response.push(obj)                        
            //         }
            //     })
            //     setChild(() => response)
            // })
            // .catch((err) => {
            //    // console.log(err);
            // });


                let payload_data={
                    event_id: event_id,
                    user_id: user_id,
                    swag_type : "attendee"
                };
                SwagListApi(payload_data)
                // .then((res) => {res.attendee_profile
                    //     let data = res.attendee_profile
                    //     let response = [];
                    //     data?.map(({profile, businesscard}) => {
                    //        if(typeof businesscard!="undefined")
                    //        { 
                    //         let obj = {
                    //                 businesscard_contact_person : "",
                    //                 exhibitor_company_name : profile.exhibitor_company_name,
                    //                 businesscard_email : businesscard.businesscard_email,
                    //                 phone : businesscard.businesscard_phone_no_country_code + "" +businesscard.businesscard_phone_no,
                    //                 user_id : profile.user_id
        
                    //             }
                    //             response.push(obj)                        
                    //         }
                    //     })
                    //     setChild(() => response)
                    // })
                    .then((res) => setChild(() =>res.attendee_profile))
                    .catch((err) => {
                       console.log(err);
                    });
                
        }
        
        fetchVideos();
        return () => {
            setChild(() => []);
        };
    }, []);

    
  

    return (
        <Listing
            header={header}
            child={child}
            actions={actionsUI}
            actionHeader=""
        />
    );
};

export default Connections;
