import React, { useEffect, useState } from "react";
// import "./documents.css";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import Download from "../../../../assets/download-file.png";
import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";


const Sessions = ({ event_id, user_id }) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
       
        {
            id: "session_title",
            displayName: "Session Title",
        },
        {
            id: "session_start_time",
            displayName: "Session Start Time",
        },
        {
            id: "session_end_time",
            displayName: "Session End Time",
        },
        {
            id:"remove_favourite_session",
            displayName: "Remove",
        }
    ];
    const [child, setChild] = useState([]);



    useEffect(() => {
        async function fetchSpeakers() {

        let payload_data={
            event_id: event_id,
            user_id: user_id,
            swag_type : "session",
        };
        SwagListApi(payload_data)
            .then((res) => setChild(() =>res.sessions))
            .catch((err) => {
               // console.log(err);
            });
    }
    fetchSpeakers();
        return () => {
            setChild(() => []);
        };
    }, []);
    const actionsUI = () => {
        return (
            <>
                <div>
                    
                </div>
            </>
        );
    };

    return (
        <Listing
            header={header}
            child={child}
            actions={actionsUI}
        />
    );
};

export default Sessions;
