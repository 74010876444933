import { makeStyles } from "@material-ui/core/styles";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import DescriptionIcon from "@material-ui/icons/Description";
import InfoIcon from "@material-ui/icons/Info";
import LibraryBooksIcon from "@material-ui/icons/LibraryBooks";
import LocalMallIcon from "@material-ui/icons/LocalMall";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import WorkIcon from "@material-ui/icons/Work";
import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import appointment from "../../../../assets/appointment.png";
import businesscard from "../../../../assets/businesscard-1.png";
import conference from "../../../../assets/conference-ico.png";
import contact_us from "../../../../assets/contact-us.png";
import courses from "../../../../assets/couses.png";
import documents from "../../../../assets/documents.png";
import info from "../../../../assets/info.png";
import job from "../../../../assets/job.png";
import products from "../../../../assets/products.png";
import sharebutton from "../../../../assets/speeddail icons/Share.png";
import chat from "../../../../assets/speeddail icons/Text chat.png";
import video from "../../../../assets/speeddail icons/Video.png";
import video_chat from "../../../../assets/video-chat.png";
//-------------------------Chat popup-------------------------
import Modal1 from "../../../../components/modal/Modal";
import EventChat from "../../../../components/pages/chat/Chat";
import "../../../../components/pages/chat/simple-chat.scss";
import { businesscard_log as businesscardLogApi } from "../../../../store/restApi/boothLiveApi";
import { videocall as videocallAPI } from "../../utils/pubnub";
import Modal from "../Modal";
import SocialShareDial from "../SocialShareDial";
import "./boothMenu.css";



const useStyles = makeStyles((theme) => ({
    root: {
        // height: 750,
        // transform: "translateZ(0px)",
        flexGrow: 1,
        "& .MuiFab-primary": {
            backgroundColor: "#a3a6a8",
        },

        "& .MuiSpeedDialIcon-root": {
            height: "inherit",
        },
    },
    menuIconButtonContainer: {
        position: "fixed",
        zIndex: 1300,
        top:
            window.innerWidth < 769 || window.innerHeight < 500
                ? "15%"
                : "10vh",
        right: theme.spacing(2.5),
        // borderRadius: 40,
        backgroundImage: "linear-gradient(90deg,#f66464,#0632ff 60%,#4eb589)",
        padding: "3px",
    },
    menuIcon: {
        color: "white",
        backgroundColor: "black",
        borderRadius: 40,
        fontSize: "2.3rem",
        padding: "6px",
    },
    menuContainerPaper: {
        backgroundColor: "rgba(29, 30, 31, 0.75)",
        marginTop: theme.spacing(3.5),
    },
    menuItemContainerDiv: {
        justifyContent: "start",
        alignItems: "center",
        display: "flex",
        flex: 1,
        color: "white",
    },
    numberContainerDiv: {
        backgroundColor: "rgba(255, 255, 255, 0.25)",
        color: "black",
        borderRadius: "45px",
        width: "25px",
        height: "25px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
    },
    numberDiv: {
        backgroundColor: "#fff",
        borderRadius: "40px",
        width: "15px",
        height: "15px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
}));

/*------------Chat functionality - display on popup---------------------*/
const Chat = () => {
    return <EventChat />;
};
/*------------Chat functionality - display on popup---------------------*/

const actions = [
    { icon: <PlayCircleOutlineIcon />, name: "Videos" },
    { icon: <DescriptionIcon />, name: "Documents" },
    { icon: <InfoIcon />, name: "Business Information" },
    { icon: <ContactMailIcon />, name: "BusinessCard" },
    // { icon: <EmailIcon />, name: "Send Email" },
    { icon: <WorkIcon />, name: "Jobs" },
    { icon: <LibraryBooksIcon />, name: "Courses" },
    { icon: <LocalMallIcon />, name: "Products" },
    // { icon: <HouseIcon />, name: "Properties" },
    { icon: <></>, name: "ConferenceSessions" },
    { icon: <></>, name: "Contact Us" },
    { icon: <></>, name: "AppointmentBooking" },
];



export default function OpenIconSpeedDial({ handleModalOpen }) {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [hidden, setHidden] = React.useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const pubAction = useStoreActions((state) => state.pubNub);

    const history = useHistory();
    const {
        exhibitorInfo,
        boothData,
        jobsData,
        productsData,
        productsDetails,
        coursesData,
        boothVideos,
        boothDocument,
        boothDetails,
        boardsEnabled,
        businessCard,
        sessionsList,
        boothRoomDetails,
        boothRoomId,
    } = useStoreState((state) => state.boothLiveState);
    let { socialIconState } = useStoreState((state) => state.boothLiveState);

    // const [pesentationZoneAlert, setPesentationZoneAlert] = useState(
    //     ((boothDetails?.booth_config[0]?.broadcasting_enabled!=null && boothDetails?.booth_config[0]?.broadcasting_enabled!="" && boothDetails?.booth_config[0]?.broadcasting_enabled!="No")?false:true));

    let menuIndex = 0;
    // have to remove the corresponding actions and state variables
    // as shown below, that's why have kept here right now
    const {
        getJobs,
        getProducts,
        getCourses,
        getProductsDetails,
        getCountryCodes,
        getSessionsList,
        getPlaceHolderMesh,
        updateState,
    } = useStoreActions((state) => state.boothLiveState);
    const { eventConfig, userDetails } = useStoreState((state) => state.state);
    const { chatModal } = useStoreState((state) => state.pubNub);
    const { currentChatUserDetails, staffDirectChatStatus } = useStoreState(
        (state) => state.pubNub
    );
    const exhibitor_id = exhibitorInfo?.exhibitor_id;

    useEffect(() => {
        handleModalOpen(modalOpen);

        //console.log("modal", modalOpen);
    }, [modalOpen]);

    useEffect(() => {
        updateState({ socialIconState: false });
    }, []);

    const shareDialFn = (model_name) => {
        if (model_name == "share") {
            if (typeof socialIconState == "undefined") {
                updateState({ socialIconState: true });
            } else {
                updateState({ socialIconState: !socialIconState });
            }
        } else if (model_name === "video_chat") {
            videocallAPI(
                businessCard[0],
                userDetails,
                pubAction.videoCallAction,
                pubAction.sendMessage
            );
        } else if (model_name === "text_chat") {
            showMyChat(businessCard[0]);
        } else if (model_name === "BusinessCard") {
            //Business card Log
            const payload = {
                attendee_id: userDetails.id,
                booth_id: boothDetails.booth[0].booth_id,
                user_type: userDetails.user_type,
                event_id: eventConfig?.data?.[0]?.event_id,
            };

            businesscardLogApi(payload).then((response) => {
                //console.log(response?.message);
            });
        }
        setModalOpen(model_name);
    };

    // const event_id = boothDetails?.event?.[0]?.event_id;
    const event_id = eventConfig?.data?.[0]?.event_id;
    const booth_id = boothDetails?.booth?.[0]?.booth_id;
    const [showChatModal, setShowChatModal] = useState(false);
    const [showInitChatStaff, setShowInitChatStaff] = useState(null);
    const speedDialEnabledOptions = {
        Jobs:
            boardsEnabled?.jobs_board_enabled == "Yes" &&
            jobsData !== null &&
            jobsData?.data !== "" &&
            typeof jobsData !== "undefined"
                ? true
                : false,
        Courses:
            boardsEnabled?.courses_board_enabled == "Yes" &&
            coursesData !== null&&
            typeof coursesData !== "undefined" 
                ? true
                : false,
        Products:
            boardsEnabled?.product_catalogue_enabled == "Yes" &&
            productsData !== null && 
            typeof productsData !== "undefined" &&
            productsData.length > 0
                ? true
                : false,
        BusinessCard:
            boothDetails?.booth_staffs[0] !== null &&
            boothDetails?.booth_staffs.length > 0
                ? true
                : false,
        Videos: boothDetails?.booth_video > 0 && boothDetails?.booth_video[0] !== null ? false : true,
        Documents: boothDetails?.booth_document[0] !== null ? true : false,
        AppointmentBooking:
            boothDetails?.booth?.[0]?.appointment_booking_url != ""
                ? false
                : true,
        ConferenceSessions: (((typeof sessionsList != "undefined") && sessionsList!='') ? true : false),
        text_chat:
            eventConfig?.data?.[0]?.chat_feature_is_enabled.localeCompare(
                "Yes"
            ) == 0 &&
            boothDetails?.booth_staffs[0] !== null &&
            boothDetails?.booth_staffs.length > 0
                ? true
                : false,
        video_chat:
            eventConfig?.data?.[0]?.attendee_to_exhibitor_video_chat_is_enabled.localeCompare(
                "Yes"
            ) == 0 &&
            boothDetails?.booth_staffs[0] !== null &&
            boothDetails?.booth_staffs.length > 0
                ? true
                : false,
    };

    useEffect(() => {
        getJobs({
            exhibitor_id: exhibitor_id,
            event_id: event_id,
            booth_id: booth_id,
        });
        getCourses({
            exhibitor_id: exhibitor_id,
            event_id: event_id,
            booth_id: booth_id,
        });
        getProducts({
            exhibitor_id: exhibitor_id,
            event_id: event_id,
            booth_id: booth_id,
        });
        getSessionsList({ event_id: event_id, booth_id: booth_id });
        getCountryCodes();
        getPlaceHolderMesh({ booth_id: booth_id, event_id: event_id });
        setShowInitChatStaff(true);

       
    }, []);

    let modalCloseHandler = () => {
        setModalOpen(false);
    };
    let modalVideoCloseHandler = () => {
        setModalOpen(false);
    };

    let modalDocumentCloseHandler = () => {
        setModalOpen(false);
    };

    let modalEmailCloseHandler = () => {
        setModalOpen(false);
    };

    let modalBusinessCloseHandler = () => {
        setModalOpen(false);
    };

    let modalJobsCloseHandler = () => {
        setModalOpen(false);
    };

    let modalCoursesCloseHandler = () => {
        setModalOpen(false);
    };

    let modalProductsCloseHandler = () => {
        setModalOpen(false);
    };

    const handleBCOpenContactUsModal = () => {
        setModalOpen("Send Email");
    };
    const handleModalClose = () => {
        setModalOpen(false);
    };

    const [chatOpened, setChatOpened] = useState(false);

    const showMyChat = () => {
        if (boothRoomId !== 0 ) {

            let {room_id, room_title  } = boothRoomDetails

            let chatUserObj = {
                displayName: room_title,
                user_id: room_id,
                email_id : room_title,
                type : 'GROUP'
            };

            pubAction.updateState({
                currentChatUserDetails: chatUserObj,
            });
            setShowInitChatStaff(false);
        }
        setShowChatModal(true);
        
        pubAction.updateState({ staffDirectChatStatus: true });
        pubAction.updateState({ chatType: 'GROUP' });

    };

    return (
        <div className={classes.root}>
            <div
                className="booth-menu-container"
                style={{ bottom: "3rem", zIndex: "1300" }}
            >
                <div
                    className="boothIcons"
                    onClick={() => shareDialFn("Business Information")}
                >
                    <img src={info} id="info" />
                </div>
                {speedDialEnabledOptions?.BusinessCard ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("BusinessCard")}
                    >
                        <img src={businesscard} id="businesscard" />
                    </div>
                ) : null}
                {speedDialEnabledOptions?.AppointmentBooking ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("AppointmentBooking")}
                    >
                        <img src={appointment} id="appointment" />
                    </div>
                ) : null}
                {speedDialEnabledOptions?.Videos ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("Videos")}
                    >
                        <img src={video} id="videos" />
                    </div>
                ) : null}
                {speedDialEnabledOptions?.ConferenceSessions ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("ConferenceSessions")}
                    >
                        <img src={conference} id="conference" />
                    </div>
                ) : null}
                {speedDialEnabledOptions?.Documents ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("Documents")}
                    >
                        <img src={documents} id="documents" />
                    </div>
                ) : null}
                {speedDialEnabledOptions?.Products ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("Products")}
                    >
                        <img src={products} id="products" />
                    </div>
                ) : null}
                {speedDialEnabledOptions?.Jobs ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("Jobs")}
                        id="j_click"
                        
                    >
                        <img src={job} id="jobs" />
                    </div>
                ) : null}
                {speedDialEnabledOptions?.Courses ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("Courses")}
                    >
                        <img src={courses} id="courses" />
                    </div>
                ) : null}
                <div
                    className="boothIcons"
                    onClick={() => shareDialFn("Contact Us")}
                >
                    <img src={contact_us} id="contactus" />
                </div>
                {speedDialEnabledOptions?.text_chat ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("text_chat")}
                    >
                        <img src={chat} id="textchat" />
                    </div>
                ) : null}
                {/* {speedDialEnabledOptions?.video_chat ? (
                    <div
                        className="boothIcons"
                        onClick={() => shareDialFn("video_chat")}
                    >
                        <img src={video_chat} id="videochat" />
                    </div>
                ) : null} */}
                <div
                    className="boothIcons"
                    onClick={() => shareDialFn("share")}
                >
                    <img src={sharebutton} id="share" />
                </div>
                {/* <IconComponent
                                width={width}
                                height={height}
                                img={img}
                                ui={ui}
                                fumc={() => shareDialFn(name)}
                            /> */}
            </div>
            <div>
                <SocialShareDial />
            </div>
            {exhibitorInfo ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "Business Information"}
                    close={modalCloseHandler}
                    title={exhibitorInfo["exhibitor_company_name"]}
                    description={exhibitorInfo["exhibitor_company_overview"]}
                    image={exhibitorInfo["exhibitor_company_profile_image"]}
                    url={exhibitorInfo["exhibitor_company_url"]}
                    type="exhibitor"
                />
            ) : null}
            {boothVideos ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "Videos"}
                    close={modalVideoCloseHandler}
                    title={exhibitorInfo["exhibitor_company_name"]}
                    videoData={boothVideos}
                    url={exhibitorInfo["exhibitor_company_url"]}
                    type="video"
                    showVideoPlayer={true}
                />
            ) : null}
            {boothDocument ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "Documents"}
                    close={modalDocumentCloseHandler}
                    document={true}
                    title={exhibitorInfo["exhibitor_company_name"]}
                    documentData={boothDocument}
                    url={exhibitorInfo["exhibitor_company_url"]}
                    type="document"
                />
            ) : null}

            <Modal
                handleModalClose={handleModalClose}
                show={modalOpen == "Send Email"}
                title={"Send Email"}
                close={modalEmailCloseHandler}
                email={true}
            />
            <Modal1
                open={chatModal}
                onClose={() =>  pubAction.updateState({ chatType: 'GROUP'  , chatModal: false }) }
                widith="70%"
                height="80%"
            >
                <EventChat />
            </Modal1>

            {exhibitorInfo ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "BusinessCard"}
                    title="Business Card"
                    close={modalBusinessCloseHandler}
                    businessCard={boothDetails?.booth_staffs}
                    type="business"
                />
            ) : null}
            {jobsData ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "Jobs"}
                    title="Jobs"
                    close={modalJobsCloseHandler}
                    jobsData={jobsData}
                />
            ) : null}
            {productsData ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "Products"}
                    title="Products"
                    close={modalProductsCloseHandler}
                    productsData={productsData}
                    productsDetails={productsDetails}
                />
            ) : null}
            {coursesData ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "Courses"}
                    title="Courses"
                    close={modalCoursesCloseHandler}
                    coursesData={coursesData}
                />
            ) : null}

            {businessCard ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "BusinessCard"}
                    title="Business Card"
                    close={modalBusinessCloseHandler}
                    businessCard={businessCard}
                    handleBCOpenContactUsModal={handleBCOpenContactUsModal}
                    chatClose={(val) => pubAction.updateState({ chatType: 'GROUP'  , chatModal: val })}
                />
            ) : null}
            {boothDetails ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "AppointmentBooking"}
                    title="Appointment Booking"
                    close={modalBusinessCloseHandler}
                    url={boothDetails?.booth?.[0]?.appointment_booking_url}
                    appointment={true}
                />
            ) : null}
            {sessionsList && sessionsList.length > 0 ? (
                <Modal
                    handleModalClose={handleModalClose}
                    show={modalOpen == "ConferenceSessions"}
                    title="Conference Sessions"
                    sessionsList={sessionsList}
                    // close={modalBusinessCloseHandler}
                />
            ) : null}
            <Modal
                handleModalClose={handleModalClose}
                show={modalOpen == "Contact Us"}
                title="Contact Us"
                contactUs={true}
            />

            <Modal1
                open={showChatModal}
                onClose={() => setShowChatModal((value) => false)}
                widith="70%"
                height="80%"
            >
                <Chat />
            </Modal1>

            {/*Presentation zone ( Alert for booth model )*/}
            {/* <Snackbar
                open={pesentationZoneAlert}
                //autoHideDuration={2000}
                onClick={()=>setPesentationZoneAlert(false)}
                style={{paddingBottom:"100px",cursor:"pointer"}}
            >
                <Alert severity="error">
                    Manage my space section
                </Alert>
            </Snackbar> */}
        </div>
    );
}
