import React from "react";
import { withStyles } from "@material-ui/core/styles";
import { Spinner } from "reactstrap";

const styles = (theme) => ({
  modal: {
    width: "75vw",
    [theme.breakpoints.up("sm")]: {
      width: "50vw",
    },
    [theme.breakpoints.up("md")]: {
      width: "40vw",
    },
    [theme.breakpoints.up("lg")]: {
      width: "35vw",
    },
  },
});

const CenteredSpinner = ({ classes }) => {
  return (
    <center className={classes.modal}>
      <Spinner color="primary" style={{ float: "center", margin: "auto" }} />
    </center>
  );
};

export default withStyles(styles)(CenteredSpinner);