import React, { useState } from "react";
import Agenda from "../agenda/Agenda";
import Speakers from "../speakers/Speakers";
import "./conferencesession.css";

const ConferenceSession = ({event_id , attendee_id}) => {
  const [isAgenda, setIsAgenda] = useState(true);
  const [isSpeakers, setIsSpeakers] = useState(false);

  const switchOpions = (option) => {
    if (option === "agenda") {
      setIsAgenda(true);
      setIsSpeakers(false);
    } else {
      setIsAgenda(false);
      setIsSpeakers(true);
    }
  };

  return (
    <div className="conference_container">
      <div className="conference_header">
        <span
          style={{ borderBottom: `${isAgenda ? "3px solid #1029f7" : ""}` }}
          onClick={() => switchOpions("agenda")}
        >
          AGENDA
        </span>
        <span
          style={{ borderBottom: `${isSpeakers ? "3px solid #1029f7" : ""}` }}
          onClick={() => switchOpions("speakers")}
        >
          SPEAKERS
        </span>
      </div>

      {isAgenda && <Agenda  event_id={event_id} attendee_id = {attendee_id}/>}

      {isSpeakers && (
        <div className="agenda_container">
          <div className="speaker-container">
            <Speakers  event_id={event_id} />
          </div>
        </div>
      )}
    </div>
  );
};

export default ConferenceSession;
