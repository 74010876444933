import { thunk, action, persist } from "easy-peasy";
import {
    getExhibitorInfo as getExhibitorInfoAPI,
    getBoothData as getBoothDataAPI,
    sendEmail as sendEmailAPI,
    getJobs as getJobsAPI,
    getJobDetails as getJobDetailsAPI,
    getProducts as getProductsAPI,
    getProductsDetails as getProductsDetailsAPI,
    getPlaceHolderMesh as getPlaceHolderMeshAPI,
    getCourses as getCoursesAPI,
    getCourseDetails as getCourseDetailsAPI,
    getBoothDetails as getBoothDeatailsAPI,
    login as loginAPI,
    register as registerAPI,
    getCountryCodes as getCountryCodesAPI,
    getSessionsList as getSessionsListAPI,
    getSessionsListAuditorium as getSessionsListAuditoriumAPI,
    getSessionsDetailsForAuditorium as getSessionsDetailsForAuditoriumAPI,
    getSessionsDetailsForBooth as getSessionsDetailsForBoothAPI
} from "../restApi/boothLiveApi";

export default persist({
    boothRoomDetails : '',
    boothRoomId:0,
    exhibitorInfo: {},
    boothData: {},
    emailData: {},
    stallBoundingBox: {},
    speed: null,
    boundings: null,
    jobsData: [],
    jobDetailsData:[],
    productsData: [],
    courseData: [],
    courseDetailsData: [],
    productsDetails: {},
    placeholdersMesh: [],
    coursesData: [],
    boothDetails: {},
    boothConfig: {},
    userDetails: {},
    cameraPosition: [],
    cameraLookAt: [],
    positionArray: [],
    lookAtArray: [],
    meshArray: [],
    boothLoaded: false,
    isLoggedIn: false,
    mutestate: true,
    boothDetailsLoading: true,
    productDetailsLoading: true,
    productsDataLoading: true,
    isSmallScreen: false,
    clickedMeshItem: "",
    countryCodes: [],
    sessionsList: [],
    sessionsListAuditorium: [],
    sessionsDetailAuditorium: [],
    sessionsDetailBooth: [],
    streamUrl: "",
    livestreaming_method:"",
    isMux: false,
    canPlayMuxLink: false,
    cameraState: undefined,
    positions: [],
    isPresentationVisible: undefined,
    no_of_coordinates: undefined,
    videoStarted: false,
    stream_playing: false,
    stream_volume: 0.1,
    stream_muted: true,
    isPresentationZone: false,
    mux_playing: false,
    presentationZoneIndex: undefined,
    getExhibitorInfo: thunk((actions, payload) => {
        return getExhibitorInfoAPI(payload).then((response) => {
            // console.log("resps ex", response);
            return actions.updateState({ exhibitorInfo: response.data[0] });
        });
    }),
    getBoothData: thunk((actions, payload) => {
        return getBoothDataAPI(payload).then((response) => {
            return actions.updateState({ boothData: response });
        });
    }),
    sendEmail: thunk((actions, payload) => {
        return sendEmailAPI(payload)
            .then((response) => {
                // console.log("resposne state.js", response);
                return actions.updateState({ emailData: response });
            })
            .catch((err) => {
                // window.alert("error")
                return 0;
            });
    }),
    getCountryCodes: thunk((actions, payload) => {
        return getCountryCodesAPI().then((response) => {
            return actions.updateState({ countryCodes: response });
        });
    }),
    getSessionsListAuditorium: thunk((actions, payload) => {
        return getSessionsListAuditoriumAPI(payload).then((response) => {
            return actions.updateState({ sessionsListAuditorium: response });
        });
    }),
    getSessionsDetailsForAuditorium: thunk((actions, payload) => {
        return getSessionsDetailsForAuditoriumAPI(payload).then((response) => {
            return actions.updateState({ sessionsDetailAuditorium: response });
        });
    }),
    getSessionsDetailsForBooth: thunk((actions, payload) => {
        return getSessionsDetailsForBoothAPI(payload).then((response) => {
            return actions.updateState({ sessionsDetailBooth: response });
        });
    }),
    getSessionsList: thunk((actions, payload) => {
        
        return getSessionsListAPI(payload).then((response) => {
            let ismux = false;
            let streamurl ='';
            if(response?.Status?.localeCompare('failed')!=0)
            {
                streamurl= response?.[0].session_list?.[0].on_demand_video_url;
                if (
                    response?.[0].session_list?.[0].livestreaming_method ==
                        "Mux.com" &&
                    response?.[0].session_list?.[0]["Channel_URL/ID"]
                ) {
                    ismux = true;
                    streamurl = response?.[0].session_list?.[0]["Channel_URL/ID"];
                }
            
                return actions.updateState({
                    sessionsList: response,
                    streamUrl: streamurl,
                    isMux: ismux,
                });
            }
            else{
                return actions.updateState({
                    sessionsList: [],
                    streamUrl: streamurl,
                    isMux: ismux,
                });
            }


        });
    }),
    login: thunk((actions, payload) => {
        //console.log("pay", payload);
        return loginAPI(payload)
            .then((response) => {
                //console.log("resposne loginAPI", response);
                return response;
            })
            .catch((err) => {
                // window.alert("error")
                //console.log("errOOOOORRR");
                return 0;
            });
    }),
    register: thunk((actions, payload) => {
        //console.log("pay", payload);
        return registerAPI(payload)
            .then((response) => {
                //console.log("resposne registerAPI", response);
                return response, 1;
            })
            .catch((err) => {
                // window.alert("error")
                //console.log("errOOOOORRR");
                return 0;
            });
    }),
    getJobs: thunk((actions, payload) => {
        return getJobsAPI(payload).then((response) => {
            return actions.updateState({ jobsData: response });
        });
    }),
    getJobDetails: thunk((actions, payload) => {
        return getJobDetailsAPI(payload).then((response) => {
            return actions.updateState({ jobDetailsData: response });
        });
    }),
    getCourses: thunk((actions, payload) => {
        return getCoursesAPI(payload).then((response) => {
            // console.log("response prrrrr", response);
            return actions.updateState({ courseData: response });
            // return actions.updateState({ productsDataLoading: false });
        });
    }),
    getCourseDetails: thunk((actions, payload) => {
        return getCourseDetailsAPI(payload).then((response) => {
            // console.log("response prrrrr", response);
            return actions.updateState({ courseDetailsData: response });
            // return actions.updateState({ productsDataLoading: false });
        });
    }),
    getProducts: thunk((actions, payload) => {
        return getProductsAPI(payload).then((response) => {
             console.log("product response", response);
            return actions.updateState({ productsData: response });
            // return actions.updateState({ productsDataLoading: false });
        });
    }),
    getProductsDetails: thunk((actions, payload) => {
        return getProductsDetailsAPI(payload).then((response) => {
            let tempPayload = {};
            tempPayload["" + payload.product_id] =
                response?.product_detail?.[0];
            // console.log("temp pl", tempPayload);
            return actions.updateProductsDetails(tempPayload);
        });
    }),

    getPlaceHolderMesh: thunk((actions, payload) => {
        return getPlaceHolderMeshAPI(payload).then((response) => {
           // console.log("placeholderssss", response);
            return actions.updateState({ placeholdersMesh: response });
        });
    }),

    getCourses: thunk((actions, payload) => {
        return getCoursesAPI(payload).then((response) => {
            //console.log(response);
            return actions.updateState({ coursesData: response });
        });
    }),
    getBoothDetails: thunk((actions, payload) => {
        actions.updateState({ boothDetailsLoading: true });
        return getBoothDeatailsAPI(payload)
            .then((response) => {
                // console.log("booth details are loading", response);
                let boothDetails = response?.[0]?.booth_content?.[0];
                let boardsEnabled = boothDetails?.booth_config?.[0];
                let exhibitorInfo = boothDetails?.exhibitor_information?.[0];
                let boothPosters = boothDetails?.booth_poster;
                let boothLogos = boothDetails?.booth_logo;
                let boothDocument = boothDetails?.booth_document;
                let boothVideos = boothDetails?.booth_video;
                let boothStaffs = boothDetails?.booth_staffs;
                let boothAssets = boothDetails?.booth?.[0];
                let boothUrl = boothDetails?.booth?.[0]?.booth_url;
                let positionArray,
                    meshArray,
                    lookAtArray,
                    cameraLookAt,
                    cameraPosition,
                    positions;
                cameraPosition = JSON.parse(boothAssets?.cameraPosition?.[0]);
                positionArray = JSON.parse(boothAssets?.positionArray?.[0]);
                lookAtArray = JSON.parse(boothAssets?.lookAtArray?.[0]);
                positions = boothAssets?.const_positions;
               // console.log(positions);
                // meshArray = JSON.parse(boothAssets?.meshArray?.[0]);
                cameraLookAt = JSON.parse(boothAssets?.cameraLookAt?.[0]);
                // cameraLookAt[0] = cameraLookAt[0]
                // cameraLookAt[1] = cameraLookAt[1] - 6
                // cameraLookAt[2] = cameraLookAt[2] - 5
                // debugger
                let BusinessCard = [];

                for(let l=0;l<boothDetails?.booth_staffs.length;l++)
                {
                    BusinessCard[l]=boothDetails?.booth_staffs[l];
                }              
                return actions.updateState({
                    boothDetails: boothDetails,
                    boothDetailsLoading: false,
                    boardsEnabled: boardsEnabled,
                    exhibitorInfo: exhibitorInfo,
                    boothPosters: boothPosters,
                    boothLogos: boothLogos,
                    boothDocument: boothDocument,
                    boothVideos: boothVideos,
                    boothStaffs: boothStaffs,
                    boothAssets: boothAssets,
                    positionArray: positionArray,
                    cameraLookAt: cameraLookAt,
                    cameraPosition: cameraPosition,
                    meshArray: meshArray,
                    lookAtArray: lookAtArray,
                    positions: positions,
                    businessCard: BusinessCard,
                });
            })
            .catch((error) => {
               // console.log("here", error);
                return actions.updateState({
                    boothDetailsLoading: false,
                    boothDetailsError: {
                        status: true,
                        message: "Oops, wrong booth url",
                    },
                });
            });
    }),
    updateProductsDetails: action((state, payload) => {
        // console.log("pddd", state.productsDetails);
        return {
            ...state,
            productsDetails: { ...state.productsDetails, ...payload },
        };
    }),
    updateState: action((state, payload) => {
        return {
            ...state,
            ...payload,
        };
    }),
});
