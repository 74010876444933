import { thunk, action, persist } from "easy-peasy";
import isEmpty from "lodash/isEmpty";
import {
    getEventConfig as getEventConfigAPI,
    login as loginAPI,
    getCountryCodes as getCountryCodesAPI,
    register as registerAPI,
} from "../restApi/api";
export default {
    positions: undefined,
    showBack: false,
    showClickableBack: false,
    no_of_coordinates: undefined,
    countryCodes: [],
    eventConfig: {
        loading: true,
        data: undefined,
        error: undefined,
        eventCode: undefined,
        statusCode: undefined,
    },
    isLoggedIn: false,
    sessionsList: [],
    streamUrl: "",
    streamProvide:"",
    isMux:true,
    videoStarted: false,
    videoElement: null,
    stream_playing: false,
    stream_volume: 0.1,
    stream_muted: true,
    mux_playing: false,
    isAuditoriumConferenceModalVisible: false,
    getEventConfig: thunk((actions, payload) => {
        return getEventConfigAPI(payload)
            .then((response) => {
                return actions.updateState({
                    eventConfig: {
                        eventCode: payload.event_code,
                        loading: false,
                        data: response?.event_details,
                        statusCode: response?.Status_code,
                        error: response?.Status == "Failed",
                    },
                });
            })
            .catch((err) => {
                return actions.updateState({
                    eventConfig: err || {
                        loading: false,
                        error: "500",
                        eventCode: undefined,
                        statusCode: err?.status_code,
                    },
                });
            });
    }),
    login: thunk((actions, payload) => {
        return loginAPI(payload)
            .then((response) => {
               
                return response;
            })
            .catch((err) => {
                return 0;
            });
    }),
    register: thunk((actions, payload) => {
        return registerAPI(payload)
            .then((response) => {
                return response;
            })
            .catch((err) => {
                return 0;
            });
    }),
    getCountryCodes: thunk((actions, payload) => {
        return getCountryCodesAPI().then((response) => {
            return actions.updateState({ countryCodes: response });
        });
    }),
    updateState: action((state, payload) => {
        return {
            ...state,
            ...payload,
        };
    }),
};
