import React from 'react';
import Vimeo from '@u-wave/react-vimeo'; // eslint-disable-line import/no-unresolved
import ReactPlayer from 'react-player';
// import { StoreProvider, useStoreActions, useStoreState } from "easy-peasy";



class VimeoPlayer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      videoIndex: 0,
      volume: 1,
      paused: false,
    };

    this.handlePause = this.handlePause.bind(this);
    this.handlePlayerPause = this.handlePlayerPause.bind(this);
    this.handlePlayerPlay = this.handlePlayerPlay.bind(this);
    this.handleVolume = this.handleVolume.bind(this);
  }

  handlePause(event) {
    this.setState({
      paused: event.target.checked,
    });
  }

  handlePlayerPause() {
    this.setState({ paused: true });
  }

  handlePlayerPlay() {
    this.setState({ paused: false });
  }

  handleVolume(event) {
    this.setState({
      volume: parseFloat(event.target.value),
    });
  }

  selectVideo(index) {
    this.setState({ videoIndex: index });
  }

  render() {
    const { paused, volume } = this.state;

    
  console.log("stream", this.props)
    return (
          // <Vimeo
          //   video={this.props.streamUrl}
            // width={760}
            // height={800}  //760,800
          //   volume={volume}
          //   paused={paused}
          //   onPause={this.handlePlayerPause}
          //   onPlay={this.handlePlayerPlay}
          // />

          <ReactPlayer 
          // url="https://vimeo.com/731757974"
            url={`https://vimeo.com/${this.props.streamUrl}`}
            width={760}
            height={800}  //760,800
            playing={true}
          />
    );
  }
}

export default VimeoPlayer;