import React from "react";




const Modelfinal_QR =({model_3d})=>{
  
      
    if(typeof model_3d!=='undefined')
    {
    
          return(
              <>
              <model-viewer alt="model" src={model_3d}  loading="eager" progress-bar   ar ar-modes="webxr scene-viewer quick-look" environment-image="neutral"
               exposure="1" shadow-softness="1" poster="shared-assets/models/NeilArmstrong.webp" 
               seamless-poster shadow-intensity="2" camera-controls enable-pan ></model-viewer>
              </>
          )
          }        
      };


export default Modelfinal_QR