import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "reactstrap";
import "../../../index.css";
import "typeface-montserrat";
import { Link } from "react-router-dom";
import useStyles from "./Changepassword.style";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Typography } from "@material-ui/core";
import LoginRegisterSidebar from "../loginRegisterSiderbar/LoginRegisterSidebar";
import { change_password as change_passwordAPI } from "../../../store/restApi/api";
import './changestyle.css';

const Changepassword = (props) => {
    const [errors, setErrors] = useState("");
    // const [validateState, setValidate] = useState(false);

    // const [response, setResponse] = useState("");
    const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { updateState } = useStoreActions((state) => state.state);
    const { isLoggedIn, eventConfig,forget_email } = useStoreState((state) => state.state);
    document.querySelector('title').innerText=eventConfig?.data?.[0]?.event_title;
    const { login } = useStoreActions((state) => state.state);
    const classes = useStyles();
    const [state, setState] = useState({
        newpassword: "",
        confrimpassword: "",
    });
    useEffect(() => {
        setState({
            newpassword: "",
            confrimpassword: "",
        });
    }, []);
    useEffect(() => {
        if (isLoggedIn) {
            if (props?.location?.from) {
                props.history.push(props.location.from);
            } else props.history.push("/");
        }
    }, [isLoggedIn]);
    function validate(response) {
        //console.log("claed", response);
        let temp = { ...errors };
        temp.newpassword =
            state.newpassword.length == 0
                ? "This field is required"                
                : null;
        temp.confrimpassword =
            state.confrimpassword.length == 0
            ? "This field is required"                
            : null;
      
        if (temp.newpassword === null && temp.confrimpassword === null) {            
            if(state.newpassword.localeCompare(state.confrimpassword)==0)
            {
                if(state.newpassword.length >=6)
                {
                    setErrors(false);
                    return true;
                }
                else
                {
                    temp.newpassword ="Password ( Atleast 6 characters long )";
                    temp.confrimpassword ="";
                    setErrors({ ...temp });
                    return false;
                }                
            }
            else{
                temp.newpassword ="";
                temp.confrimpassword ="Password not matching";
                setErrors({ ...temp });
                return false;
            }          
        }
        else
        {
            setErrors({ ...temp });
            return false;
        }       
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    }

    const submitHandler = (e) => {       
        if (validate()) {
            const payload = {
                email_id: forget_email,                
                event_id: eventConfig?.data?.[0]?.event_id,
                method: 4,
                user_id: "",
                password:state.confrimpassword 
            };            
            change_passwordAPI(payload)?.then((response) => {                 
                if(response.Status.localeCompare("Success")==0)
                {
                    props.history.push("/Login");
                    setErrors(false);
                }            
            }); 
        }
    };
    return (
        <div className="change-overall">
            <div className="change-eventinfo">
                <div style={{ backgroundColor: "#1500fa", padding: "50% 15px",}}>
                    <LoginRegisterSidebar />
                </div>
            </div>
            <div className="change-content">
                   <div className="change-content-logo">
                        <img
                            height="50px"
                            width="100px"
                            src={eventConfig?.data?.[0]?.event_logo}
                        ></img>
                    </div>
                    <div className="change-content-main">
                    <div className="change-content-sub">
                    <div className="change-content-maintext">
                    
                    <div className="change-content-maintext-title">
                            <div className="change-content-maintext-titletext">
                               Update Password
                            </div>
                    </div>
                    </div>
                    <div className="change-content-maincontent">
                     <div className="change-content-maincontent-wrapper">
                        <div className="change-content-inputname">
                            <span>New Password *</span>
                        </div>
                        
                            <input
                                type="password"
                                className="change-content-inputbox"
                                placeholder=""
                                id="newpwd"
                                onChange={handleChange}
                                name="newpassword"
                                required
                            />
                            <div className="change-content-errors">
                            {errors?.newpassword != "" ? (
                                <div
                                style={{ color: "red", textAlign: "left" ,fontSize:"11px"}}
                                    
                                >
                                    {errors?.newpassword}
                                </div>
                            ) : null}</div>
                            </div>
                        

                       <div className="change-content-maincontent-wrapper">
                          <div className="change-content-inputname">
                             <span>Confirm Password *</span>
                          </div>
                          <input
                                type="password"
                                className="change-content-inputbox"
                                placeholder=""
                                id="confrimpwd"
                                onChange={handleChange}
                                name="confrimpassword"
                                required
                            />
                            <div className="change-content-errors">
                            {errors?.confrimpassword != "" ? (
                                <div
                                style={{ color: "red", textAlign: "left" ,fontSize:"11px"}}
                                >
                                    {errors?.confrimpassword}
                                </div>
                            ) : null}</div>
                            </div>
                        
                       </div>
                        <div className="change-content-bottomtext">
                        <div className="change-content-bottomtext-button" onClick={() => {props.history.push("/Login");}}>
                            <div className="change-content-bottomtext-button-wrapper" >
                               <span style={{color:"white"  , textAlign:"center" , justifyContent:"center"}}> Back </span>
                            </div>
                        </div>
                

                        <div className="change-content-bottomtext-button" onClick={submitHandler}>
                            <div className="change-content-bottomtext-button-wrapper" >
                               <span style={{color:"white"  , textAlign:"center" , justifyContent:"center"}}> Next </span>
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
                    </div>
                </div>
            
        
    );
};

export default Changepassword;
