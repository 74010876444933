import { withStyles } from "@material-ui/core/styles";
import { Grid, Grow, IconButton, Slider } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import PauseIcon from "@material-ui/icons/Pause";
import { useEffect, useState } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import SessionsModal from "./Modals/SessionsModal";
import Modal from "../components/Modal";
import Button from "./common/Button";

const styles = (theme) => ({
    container: {
        position: "absolute",
        // width: "284px",
        // padding: "auto",
        // width: "100vw",
        // margin: "auto",
        left: "50%",
        transform: "translateZ(0px)",
        zIndex: 100,
        bottom: 25,
        // top: 320,
        // backgroundColor: "black",
    },
    controlButtons: {
        width: "48px",
        height: "48px",
        margin: "0 5px",
    },
});

const StreamControls = (props) => {
    const { classes } = props;
    const [play, setPlay] = useState(true);
    const [showStreamModal, setShowStreamModal] = useState(false);
    const [showVolumeControl, setShowVolumeControl] = useState(false);
    const [volumeValue, setVolumeValue] = useState(50);
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    const { stream_playing, stream_muted, stream_volume, sessionsList } =
        useStoreState((state) => state.boothLiveState);
    let reactplayer = document.getElementById("reactplayer");
    //     ?
    //     .getElementsByTagName("video")[0];
    // reactplayer.style.objectFit = "initial";
    useEffect(() => {
        if (reactplayer) {
            reactplayer.getElementsByTagName("video")[0].style.objectFit =
                "initial";
        }
    }, [reactplayer]);
   // console.log("reeact", reactplayer);
    useEffect(() => {
       // console.log(stream_volume);
        if (stream_volume <= 0.05) {
           // console.log("muted");
            updateState({ stream_muted: true });
        } else {
            updateState({ stream_muted: false });
        }
    }, [stream_volume]);
    const handleFullScreen = () => {
        let reactplayer = document
            .getElementById("reactplayer")
            .getElementsByTagName("video")[0];

        if (reactplayer) {
            reactplayer.allowFullscreen = true;
            reactplayer.requestFullscreen();
            //console.log(reactplayer, "volume");
        }
    };
    const handleVolumeChange = (event, newValue) => {
        updateState({ stream_volume: newValue / 100 });
    };
    return (
        <div className={classes.container} id="streamingControlsContainer">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "auto",
                    borderRadius: "30px",
                    background: "rgb(22, 23, 24)",
                    padding: "5px 0",
                    position: "relative",
                    left: "-50%",
                }}
                onMouseLeave={() => {
                   // console.log("enter");
                    setShowVolumeControl(false);
                }}
                id="streamControls"
            >
                {stream_playing ? (
                    <Button
                        OpenIcon={PauseIcon}
                        handleClick={() => {
                            setPlay(true);
                            updateState({ stream_playing: false });
                        }}
                        customIconContainer={classes.controlButtons}
                    />
                ) : (
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    //         setPlay(true);
                    //         updateState({ stream_playing: false });
                    //     }}
                    // >
                    //     <PauseIcon />
                    // </IconButton>
                    <Button
                        OpenIcon={PlayArrowIcon}
                        handleClick={() => {
                            setPlay(false);
                            updateState({ stream_playing: true });
                        }}
                        customIconContainer={classes.controlButtons}
                    />
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    //         setPlay(false);
                    //         updateState({ stream_playing: true });
                    //     }}
                    // >
                    //     <PlayArrowIcon />
                    // </IconButton>
                )}
                {stream_muted ? (
                    <Button
                        OpenIcon={VolumeOffIcon}
                        handleClick={() => {
                            setPlay(false);
                            updateState({
                                stream_muted: false,
                                stream_volume: 0.1,
                            });
                        }}
                        customIconContainer={classes.controlButtons}
                    />
                ) : (
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    //         // setMute(false);
                    //         updateState({
                    //             stream_muted: false,
                    //             stream_volume: 0.1,
                    //         });
                    //     }}
                    // >
                    //     <VolumeOffIcon />
                    // </IconButton>
                    <Button
                        OpenIcon={VolumeOffIcon}
                        handleClick={() => {
                            updateState({
                                stream_muted: true,
                                stream_volume: 0,
                            });
                        }}
                        handleMouseOver={() => {
                           // console.log("enter");
                            setShowVolumeControl(true);
                        }}
                        customIconContainer={classes.controlButtons}
                    />
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    //         // setMute(true);
                    //         updateState({
                    //             stream_muted: true,
                    //             stream_volume: 0,
                    //         });
                    //     }}
                    //     onMouseOver={() => {
                    //         console.log("enter");
                    //         setShowVolumeControl(true);
                    //     }}
                    // >
                    //     <VolumeUpIcon />
                    // </IconButton>
                )}

                <Grow in={showVolumeControl} timeout={500}>
                    <span>
                        <Slider
                            style={{
                                position: "relative",
                                top: "10px",
                                width: "100px",
                                margin: "0 5px",
                                display: showVolumeControl ? "block" : "none",
                                opacity: showVolumeControl ? 1 : 0,
                                transition: "all 2s linear",
                                color: "white",
                            }}
                            // onMouseLeave={() => {
                            //     console.log("enter");
                            //     setShowVolumeControl(false);
                            // }}
                            value={stream_volume * 100}
                            onChange={handleVolumeChange}
                            aria-labelledby="continuous-slider"
                        />
                    </span>
                </Grow>
                <Button
                    OpenIcon={FullscreenIcon}
                    handleClick={() => {
                        handleFullScreen();
                    }}
                    customIconContainer={classes.controlButtons}
                />
                {/* <IconButton
                    className="shadow"
                    style={{ background: "white", margin: "0 5px" }}
                    onClick={() => {
                        handleFullScreen();
                    }}
                >
                    <FullscreenIcon />
                </IconButton> */}
                <Button
                    OpenIcon={OndemandVideoIcon}
                    handleClick={() => {
                        setShowStreamModal(true);
                        return <SessionsModal />;
                    }}
                    customIconContainer={classes.controlButtons}
                />
                {/* <IconButton
                    className="shadow"
                    style={{ background: "white", margin: "0 5px" }}
                    onClick={() => {
                        setShowStreamModal(true);
                        return <SessionsModal />;
                    }}
                >
                    <OndemandVideoIcon />
                </IconButton> */}
            </div>
            {showStreamModal ? (
                <Modal
                    handleModalClose={() => {
                        setShowStreamModal(false);
                    }}
                    show={showStreamModal}
                    title="Conference Sessions"
                    sessionsList={sessionsList}
                    // close={modalBusinessCloseHandler}
                />
            ) : null}
        </div>
    );
};

export default withStyles(styles)(StreamControls);
