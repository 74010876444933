import React, { useEffect, useState } from "react";
import { withRouter } from 'react-router';
import { useStoreState } from "easy-peasy";
import isEmpty from "lodash";
import { getBoothsList as getBoothsListAPI } from "../../../store/restApi/api";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import { booth_visitor_log as boothVisitorLogApi } from "../../../store/restApi/boothLiveApi";
import { businesscard_log as businesscardLogApi } from "../../../store/restApi/boothLiveApi";
import styled from "styled-components";

//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove } from "../../../store/restApi/boothLiveApi"
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
//------------------------My Favourite Function--------------------------//


function Exhibitorlist(){

 const fdata=[];

// Style values for exhibitor list 

const ExhibitorlistOverall = styled.div`
 @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

  display:flex;
  min-width:100%;
  min-height:100%;
  justify-content:center;
  align-items:center;
  font-family: 'Poppins', sans-serif;
`

const ExhibitorlistWrapper = styled.div`
 display:flex;
 justify-content:center;
 width:70%;
 height:400px; 
 flex:1;
 flex-wrap:wrap;
 gap:10px;
 margin:60px;
 overflow-y:auto;

 @media(max-width:1024px){
       height:200px;
    }
`
const ExhibitorContents = styled.div`
display:flex;
flex-direction:column;
align-items:center;
width:180px;
height:180px;
margin:5px;
padding:20px;
gap:5px;
border-radius:10px;
box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
cursor:pointer;

 @media(max-width:1024px){
        width:80px;
        height:80px;
        border-radius:5px;
        padding:10px;
    }

 &:hover {
     transform:scale(1.02);
    }
`
const ExhibitorImgOverall = styled.div`
display:flex;
justify-content:center;
width:80px;
height:80px;

@media(max-width:1024px){
        width:40px;
        height:40px
    }

`

const ExhibitorImg = styled.img.attrs(props => ({
  src: props.data.booth_logo 
}))`
width:80px;
height:80px;

@media(max-width:1024px){
        width:40px;
        height:40px
    }

`

const ExhibitorTitle = styled.div`
display:flex;
width:100%;
height:100%;
justify-content:center;
align-items:center;
font-weight:600;
font-size:12px;

@media(max-width:1024px){
       font-size:5px;
    }

`

const ExhibitorTitleValue = styled.div`
display:flex;
justify-content:center;
width:100%;
`
    const history = useHistory();
    // const classes = useStyles();  

    const clickhandler = name => {
        //console.log("delete", name);
    };
    const [eList, setEList] = useState([]);   
    const { eventConfig , userDetails } = useStoreState((state) => state.state);   
    const { boothDetails } = useStoreState((state) => state?.boothLiveState);
    const [favourite, setfavourite] = useState([]);
    

    useEffect(() => {
        if (isEmpty(eList)) {
            getBoothsListAPI({
                eventId: eventConfig?.data?.[0]?.event_id,
            }).then((res) => {
                // console.log(res);
                setEList(res);

               
            });
        }
    }, []);

    useEffect(() => {      
        if (typeof eList!="undefined") {           
            for(let l=0;l<eList?.length;l++)
            {
                fdata[l]={
                    booth_id:eList[l].booths_list[0].booth_details[0].booth_id,
                    booth_exhibitor_name:eList[l].booths_list[0].booth_details[0][' booth_exhibitor_name'],
                    booth_logo:eList[l].booths_list[0].booth_details[0]['booth_logo'],
                    flag_status:eList[l].booths_list[0].booth_details[0]['flag_status']
                };
                    
            }
            setfavourite(fdata);
        }
    },[eList]);

   
   

    const boothNavFn = (booth_id) =>{
        //window.location.href='#/booth/'+booth_id;
        history.push(`/booth/`+booth_id);

        if(typeof boothDetails != "undefined" && Object.keys(boothDetails).length !== 0)
        {
            if(booth_id!=boothDetails?.booth[0]?.booth_id)
            {
                //booth visitor update
                const payload_update = {
                    visitor_user_id_type: userDetails?.user_type,
                    visitor_user_id: userDetails?.id,
                    event_id: eventConfig?.data[0]?.event_id,
                    booth_id: boothDetails?.booth[0]?.booth_id, 
                    location: "1",  
                    action: "2",            
                };

                boothVisitorLogApi(payload_update).then((response) => {
                   // console.log(response?.message);               
                });


                //booth visitor insert
                const payload_insert = {
                    visitor_user_id_type: userDetails?.user_type,
                    visitor_user_id: userDetails?.id,
                    event_id: eventConfig?.data[0]?.event_id,
                    booth_id: booth_id, 
                    location: "1",  
                    action: "1",            
                };

                boothVisitorLogApi(payload_insert).then((response) => {
                    //console.log(response?.message);               
                });


                    //Business card log
                    const business_payload = {
                        attendee_id: userDetails.id,
                        booth_id: booth_id,
                        user_type: userDetails.user_type,      
                        event_id: eventConfig?.data?.[0]?.event_id,
                    };

                    // businesscardLogApi(business_payload).then((response) => {
                    //     console.log(response?.message);        
                    // });
            }
        }
        else
        {          
            //Tracking booth visitor Api calling
            const payload = {
                visitor_user_id_type: userDetails?.user_type,
                visitor_user_id: userDetails?.id,
                event_id: eventConfig?.data[0]?.event_id,
                booth_id: booth_id, 
                location: "1",  
                action: "1",            
            };

            boothVisitorLogApi(payload).then((response) => {
                //console.log(response?.message);               
            });
        } 
    };
   
    /*------------------------------------------------>Favourite Functionals<----------------------------------------------- */
      const setFavorite_fn=(index,booth_id,status)=>{   
        
        setfavourite(favValue => 
            [...favValue].map(el => 
                el.booth_id === booth_id ? ({...el, flag_status:status}) : el)
            )      
       
        
 
        let favourite_payload = {
          user_id: userDetails?.id,
          favourite_type: 'exhibitor',
          action_id: booth_id,
          event_id: eventConfig?.data?.[0]?.event_id,
        //booth_id: boothDetails?.booth[0]?.booth_id
        };
        console.log("exp", favourite_payload)
        if(status)
        {
            myFavouriteAdd(favourite_payload).then((response) => {
             console.log(response);            
            })
            .catch((error) => {
                //console.log(error);
            });
        }
        else
        {
            myFavouriteRemove(favourite_payload).then((response) => {
                console.log(response);            
               })
               .catch((error) => {
                   //console.log(error);
               });
        }  
      };
    
      /*------------------------------------------------>Favourite Functionals<----------------------------------------------- */

    return (
        <>
            <ExhibitorlistOverall>
                <ExhibitorlistWrapper>
                    {favourite.map((data, index) => (
                        <ExhibitorContents
                            key={"exhibitor_" + index}                            
                        >
                            <div onClick={() => boothNavFn(data.booth_id)}>
                            <ExhibitorImgOverall>
                                <ExhibitorImg data={data} />
                            </ExhibitorImgOverall>
                            <ExhibitorTitle>
                                <ExhibitorTitleValue>
                                    {data.booth_exhibitor_name}
                                </ExhibitorTitleValue>                               
                            </ExhibitorTitle>
                            </div>

                             <div
                                    onClick={() =>
                                        setFavorite_fn(index,data.booth_id, true)
                                    }
                                >
                                    {(data.flag_status) ? (
                                        <FavoriteIcon />
                                    ) : (
                                        <FavoriteBorderIcon />
                                    )}
                                </div>
                        </ExhibitorContents>
                    ))}
                </ExhibitorlistWrapper>
            </ExhibitorlistOverall>
        </>
    );
}

export default withRouter (Exhibitorlist);   