import React from "react";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

const styles = (theme) => ({
    iconContainer: {
        backgroundImage: "linear-gradient(90deg,#f66464,#0632ff 60%,#4eb589)",
        padding: "3px",
        height: 40,
        width: 40,
        cursor: "pointer",
        zIndex: 9999,
    },
    iconContainerBlack: {
        color: "white",
        backgroundColor: "black",
        borderRadius: 40,
        fontSize: "2.3rem",
        padding: "3px",
        height: "60px",
        width: 50,
        cursor: "pointer",
    },
});

const Button = React.forwardRef((props, ref) => {
    const {
        classes,
        customIconContainer,
        handleClick,
        anchorEl,
        OpenIcon,
        CloseIcon,
        isSquare,
        id,
        handleMouseOver = undefined,
    } = props;

    return (
        <>
            {CloseIcon && OpenIcon ? (
                <IconButton
                    ref={ref}
                    className={
                        classes.iconContainer + " " + customIconContainer
                    }
                    onClick={handleClick}
                    id={id ? id : null}
                >
                    {!anchorEl ? <OpenIcon /> : <CloseIcon />}
                </IconButton>
            ): (
                <>
                    <IconButton
                        ref={ref}
                        className={
                            classes.iconContainer + " " + customIconContainer
                        }
                        style={{
                            borderRadius: isSquare ? 5 : 40,
                            height: props.small ? 30 : null,
                            width: props.small ? 30 : null,
                        }}
                        onClick={(e) => {
                            handleClick(e);

                            e?.stopPropagation();
                        }}
                        id={id ? id : null}
                        onMouseEnter={(e) => {
                            if (handleMouseOver) handleMouseOver();
                            e?.stopPropagation();
                        }}
                    >
                        {
                            <OpenIcon
                                style={{
                                    background: "black",
                                    color: "white",
                                    padding: !props.small ? "6px" : "3px",
                                    borderRadius: isSquare ? 5 : 40,
                                    height: "100%",
                                    width: "100%",
                                }}
                            />
                        }
                    </IconButton>{" "}
                    {props?.content}
                </>
            )}
        </>
    );
});

export default withStyles(styles)(Button);
