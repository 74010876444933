import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import {QRCodeUrl} from "../../../../store/restApi/api";

const Event_conferences = () => {

    const { eventConfig } = useStoreState((state) => state.state);  
    const event_id= eventConfig?.data?.[0]?.event_id;
    const event_title= eventConfig?.data?.[0]?.event_title;
    const event_code= eventConfig?.eventCode;     
  
    const [sessionListAuditoriumData, setsessionListAuditoriumData] = useState(undefined);
    const [loader, setLoader] = useState(undefined);
    
    const { getSessionsListAuditorium } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );

    useEffect(() => {       
        if (event_id) {            
            getSessionsListAuditorium({                              
                event_id: event_id                             
            }).then((res)=>{                                                                         
                setsessionListAuditoriumData(res.payload?.sessionsListAuditorium);          
            });
            document.title = event_title+" - Auditorium Sessions List";
        }
    }, []);
    useEffect(() => {
        if(typeof sessionListAuditoriumData=="undefined")
        {    
            setLoader(true); 
        } 
        else
        {
            setLoader(undefined);
        }  
        console.log(sessionListAuditoriumData);       
    }, [sessionListAuditoriumData]);

   const conference_details_fn =(session_id) =>{
    let link=QRCodeUrl+"event-conferences-details?session_id="+session_id+"&event_code="+event_code+"";
    window.open(link,"_self");
   };

    return (      
       
        <div style={{ padding: "100px 0" }}>
        {(loader)?(
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                <Grid item className="h4 text-center p-2">
                No Data Found
                </Grid>
            </Grid>       
        ):(
        <Grid container justify="center" direction="column">
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                
                {(typeof sessionListAuditoriumData!="undefined")?(
                    <>
                    <Grid item className="h4 text-center p-2">
                        {event_title}
                    </Grid>
                    <Grid item className="h6 text-center">
                        Event - Sessions
                    </Grid>  
                    </> 
                ):(null)}

        
               
{sessionListAuditoriumData?.sessionList?.map((session_data, i) => {
                return (            
              
               session_data?.hall_list?.map((hall_session_data, i1) => {
                
                return(   
                
                   
                    <Grid
                        container
                        item
                        justify="center"
                        style={{
                            margin: 5,
                            padding:10,
                            display:"flex",
                            flexWrap:"nowrap"
                        }}
                        key={"eventconference_"+i}
                    >
                        {hall_session_data?.streaming_list[0]?.session_title}
                        <Grid
                            item
                            xs={10}
                            md={6}
                            className="font-weight-bold"
                            style={{
                                borderBottom:
                                    i == hall_session_data?.length - 1
                                        ? "none"
                                        : "1.5px solid #1c1d1f",
                                        padding:10
                            }}
                        >                                       
                            
                            {/* <b className="etitile">{hall_session_data?.streaming_list[0]?.session_title}</b>   */}
                            <div className="small">
                            <h6><b>{hall_session_data?.streaming_list[0]?.session_start_date}</b></h6>
                            </div>  
                            <div className="small">
                            <h6><b>{hall_session_data?.streaming_list[0]?.session_start_time}-{hall_session_data?.streaming_list[0]?.session_end_time}</b></h6>
                           </div> 
                        </Grid>
                
                        <Grid
                           item
                           style={{
                               display: "flex",
                               flexDirection: "column",
                               padding: 5,
                           }}
                        >                                       
                           <div className="small">
                                <Button onClick={()=>conference_details_fn(hall_session_data?.streaming_list[0]?.session_id)}>View Details</Button>
                                </div>                          
                        </Grid>                         
                
                
                    </Grid>   
                    );           
             })        
                );

            })}                        
                       
                        </Grid>
                      </Grid>
        )}
                        
                      </div> 
        );
};
export default Event_conferences;
