import CancelIcon from "@material-ui/icons/Cancel";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { Grid } from "@material-ui/core";
import CenteredSpinner from "../common/CenteredSpinner";

const AcknowledgementModal = (props) => {
    return (
        <Grid container style={{ width: 300 }}>
            {props?.loading ? (
                <>
                    <CenteredSpinner />
                </>
            ) : (
                <>
                    <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", padding: 15 }}
                    >
                        {props.status == "success" ? (
                            <CheckCircleIcon
                                style={{
                                    color: "green",
                                    height: 50,
                                    width: 50,
                                }}
                            />
                        ) : (
                            <CancelIcon
                                style={{ color: "red", height: 50, width: 50 }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        style={{ textAlign: "center", padding: 10 }}
                    >
                        <b>
                            {props?.message
                                ? props?.message
                                : "Default message"}
                        </b>
                    </Grid>
                </>
            )}
        </Grid>
    );
};

export default AcknowledgementModal;
