import { StoreProvider, useStoreActions, useStoreState } from "easy-peasy";
import React, { Suspense, useEffect } from "react";
import Router from "./routes/Router";
import store from "./store";
import { pubnubConfig } from "./pubNub/constants/PubNubConstant";
import { messageHandler } from "./pubNub/handler/useMessageHandler";
import PubNub from "pubnub";
import { PubNubProvider } from "pubnub-react";
import Orientation from "./Orientation";
import {pubnubUtilty} from "./pubNub/utils/PubNubUtilty";
export const  Api_Key = "Rt6600OXc#$cBWzSdLnB@9r84";

function App() {
    const actions = useStoreActions((state) => state.pubNub);
    const {
        pubnubstate,
      } = useStoreState((state) => state.pubNub);
    
      const pubnubObject = new PubNub({
        ...pubnubConfig,
      });
    
      const leaveApplication = () => {
        pubnubObject.unsubscribeAll();
      };
    
      const handleMessage = (event) => {
        if (event) {
          messageHandler(event, actions);
        }
      };
    
    //   useEffect(() => {
    //     //pubnubUtilty.deleteSpaceV2(pubnubObject, '4190')
    //    // pubnubUtilty.getUsersAllDetailsV2(pubnubObject)
    //    actions?.setPubNubActions(pubnubObject);
    //    actions?.addEventListener({ message: handleMessage });
    //    window?.addEventListener("beforeunload", leaveApplication);
   
    //    return () => {
    //      actions.removeEventListener({ message: handleMessage });
    //      window.removeEventListener("beforeunload", leaveApplication);
    //    };
    //  }, []);

    useEffect(() => {
      actions?.setPubNubActions(pubnubObject);
      actions?.addEventListener({ message: handleMessage });
      window?.addEventListener("beforeunload", leaveApplication);
    
      return () => {
        pubnubObject.removeListener({ message: handleMessage });
        window.removeEventListener("beforeunload", leaveApplication);
      };
    }, []);
  

    return (
        <>
            <Orientation />
            <PubNubProvider client={pubnubObject}>
                <StoreProvider store={store}>
                    <Suspense fallback={<></>}>
                        <Router />
                    </Suspense>
                </StoreProvider>
            </PubNubProvider>
        </>
    );
}

export default App;
