import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState , Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { useLoader } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'
import Forbidden from "../../components/common/Forbidden";
import ReactPlayer from "react-player";
import { Html, useProgress } from '@react-three/drei'
import './alertCommon.css';
import './products.css';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import ImageIcon from '@material-ui/icons/Image';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import PanToolIcon from '@material-ui/icons/PanTool';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import InfoIcon from '@material-ui/icons/Info';
import Rotate90DegreesCcwIcon from '@material-ui/icons/Rotate90DegreesCcw';
import CloseIcon from '@material-ui/icons/Close';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import GetAppIcon from '@material-ui/icons/GetApp';
import Modal from "../Modal";
import Modelfinal_QR from "./Modelfinal_QR";






const Products = (props) => {
    
    const { boardsEnabled } = useStoreState((state) => state.boothLiveState);
    const {  exhibitorInfo } = useStoreState((state) => state.boothLiveState)
    const { eventConfig } = useStoreState((state) => state.state);  
    const event_id= eventConfig?.data?.[0]?.event_id;
    const event_code= eventConfig?.eventCode;
    
    
    const [boothData, setBoothData] = useState(undefined)
    const [products, setProducts] = useState(undefined);
    const [oneProduct , setOneProduct] = useState([]);
    const [imageIndex , setImageIndex] = useState(0);
    const [videoIndex , setVideoIndex] = useState(0);
    const [productIndex, setProductIndex] = useState();
    const [oneAction , setOneAction] = useState("info");
    const [oneTitle , setOneTitle] = useState("Info");
    const [showProductsEnquiry, setShowProductsEnquiry] = useState(false);


    const { getProducts } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );

    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const { getProductsDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );

    var val = '';
    var maxImg = '';
    var minImg = '';
    var imgLength = '';
    var maxVid = '';
    var minVid = '';
    var vidLength = '';


    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };

    useEffect(() => { 
               
            if (boothId && event_id) {
                getBoothDetails({
                    booth_id: boothId,                  
                    event_id: event_id                   
                }).then((res)=>{                                               
                    setBoothData(res.payload);             
                });
            }

    }, [boothId]);
    let exhibitor_id= boothData?.exhibitorInfo?.exhibitor_id;
    useEffect(() => {
        
        if (boothData ) {
            getProducts({
                booth_id: boothId,
                event_id: event_id,              
                exhibitor_id: exhibitor_id               
            }).then((res)=>{                              
                setProducts(res.payload.productsData);           
            })
            document.title = boothData?.boothAssets?.booth_meta_title+" - Products";
        }
    }, [boothData]);




    useEffect(() =>{
        if(products?.length > 0){

            // setOneProduct(products[productIndex])
          
          val = products[productIndex]?.id
          
            getProductsDetails({
                booth_id: boothId,
                event_id: event_id,
                exhibitor_id: exhibitor_id,
                product_id:val,

            }).then((res)=>{
                setOneProduct(res?.payload?.[val])
             
            })

            if(oneProduct?.length > 0){
               imgLength = oneProduct?.product_images?.length;
               maxImg = imgLength - 1;
               minImg = 0

               vidLength = oneProduct?.product_videos?.length;
               maxVid = vidLength - 1;
               minVid = 0

            }
            
        }

    },[productIndex , boothData])

    let model_3d = products?.[productIndex]?.product_3d_model;

    const bynow_fn = (buy_url) =>{
        window.open(buy_url);
    };


    const Info=()=>{
        return(
            <>  
                               <div className="qr-product-detail-common-content">
                                   { oneProduct?.product?.[0]?.product_image !== "undefined" || "" || null ? <div className="qr-product-detail-image">
                                       <img style={{width:"100%", height:"100%", padding:"10px"}} src={oneProduct?.product?.[0]?.product_image}/>
                                   </div> : null}
                                   <div className="qr-product-detail-info">
                                       <div className="qr-product-detail-title" dangerouslySetInnerHTML={{__html:`${oneProduct?.product?.[0]?.product_title}`.substring(0,30) + `${oneProduct?.product?.[0]?.product_title?.length > 30 ? "..." : ""}`}}></div>
                                       <div className="qr-product-detail-code" dangerouslySetInnerHTML={{__html:`${oneProduct?.product?.[0]?.product_sku}`}}></div>
                                       <div className="qr-product-detail-actions">
                                       {oneProduct?.product?.[0]?.buynowurl !== "" ?<div className="qr-product-detail-action-button-common" onClick={()=>bynow_fn(oneProduct?.product?.[0]?.buynowurl)}>
                                               <span>Buy Now</span>
                                           </div>: null}
                                           <div className="qr-product-detail-action-button-common" onClick={()=> setShowProductsEnquiry(true)}>
                                               <span>Enquire</span>
                                           </div>
                                       </div>
                                       <div className="qr-product-detail-description">
                                           <span dangerouslySetInnerHTML={{__html:`${oneProduct?.product?.[0]?.product_description}`}}>
                                            
                                           </span>
                                       </div>
                                   </div>
                               </div>
            </>
        )
    }

    const Image=()=>{
        return(
          <>
          <div className="qr-product-image-overall">
              <div className="qr-product-image-common-button" onClick={()=>{if(imageIndex > minImg){setImageIndex(imageIndex - 1)}}}><ArrowLeftIcon/></div>
              <div className="qr-product-image-center">
                  <img src={oneProduct?.product_images?.[imageIndex]?.image_path} />
              </div>
              <div className="qr-product-image-common-button" onClick={()=>{if(imageIndex < maxImg){setImageIndex(imageIndex + 1)}}}><ArrowRightIcon/></div>
          </div>
          </>
        )
    }

    const Video=()=>{
        return(
            <>
            <div className="qr-product-video-overall">
              <div className="qr-product-video-common-button" onClick={()=>{if(videoIndex > minVid){setVideoIndex(videoIndex - 1)}}}><ArrowLeftIcon /></div>
              <div className="qr-product-video-center">
                  <ReactPlayer  url={oneProduct?.product_videos?.[videoIndex]?.video_path} controls={true} />
              </div>
              <div className="qr-product-video-common-button" onClick={()=>{if(videoIndex < maxVid){setVideoIndex(videoIndex + 1)}}}><ArrowRightIcon/></div>
          </div>

            </>
        )
    }

    const Document=()=>{
        return(
            <>
            <div className="qr-document-table-overall">
                <div className="qr-document-contentlist">
                  {oneProduct?.product_documents?.map((document , docindex)=>{ 
                  return(
                  <div className="qr-document-common" key={docindex}>
                        <div className="qr-document-title">
                            <div className="document-title" dangerouslySetInnerHTML={{__html:`${document?.document_title}`}}>
                                
                            </div>
                        </div>
                        <div className="qr-document-description">
                            <div className="document-description" dangerouslySetInnerHTML={{__html:`${document?.document_description}`}}>
                            </div>
                        </div>
                        <div className="qr-document-action">
                            <div className="qr-document-download-btn"   onClick={(event) =>
                                                openInNewTab(
                                                    document?.document_path
                                                )
                                            }>
                                <GetAppIcon color="white"/>
                            </div>
                        </div>
                    </div>); })}
                </div>
            </div>
            </>
        )
    }


        const Model=()=>{
            return(
                <>
                <div className="qr-model-view-overall">
                    <div className="qr-model-view-preview-overall">
                       <Modelfinal_QR model_3d={model_3d}/>
                    </div>
                </div>
                </>
            )
        }

    const AlertMessage =()=>{
        return(
            <div class="alertcommon-overall">
            <div class="alertcommon-wrapper">
                <span class="alertcommon-text">No products to view at the moment</span>
            </div>
        </div>
        )
    }
   
    return (
    <>
            {boardsEnabled?.hybrid_format_enabled == "No" ? (
                <Forbidden />
            ) : (
                <>
               { products?.[0] !== null ? (
                   <>

            { productIndex == null ? (
                <>
               <div className="qr-products-overall">
                   <div className="qr-products-header">
                       <span>Products List</span>
                   </div>
                   <div className="qr-products-list-content">
                   {products?.map((product,i)=>{ 
                       return (
                   <div className="qr-products-single-common" key={product?.id}>
                   <div className="qr-products-title">
                       <h5 dangerouslySetInnerHTML={{__html:`${product?.product_title}`.substring(0,40) + `${product?.product_title?.length > 40 ? ("...") : ("")}`}}></h5>
                   </div>
                   <div className="qr-products-actions">
                       
                       <div className="qr-products-common">
                       <div className="qr-products-action-button-common" onClick={()=> setProductIndex(i) }><span>View Details</span></div>
                       </div>

                   </div>
                   </div>);})}
                   </div>
                    {/* <Modal
                        show={showEmailModal}
                        handleModalClose={() => {
                            setShowEmailModal(false);
                        }}
                        qrEmail={true}
                        selected={documentsData}
                    /> */}
                </div>
                </>) : (<>
                <div className="qr-product-detail-full">
                    <div className="qr-product-detail-nav">
                    <div></div>
                    <div className="qr-product-detail-header"><span dangerouslySetInnerHTML={{__html:`${oneProduct?.product?.[0]?.product_title}`.substring(0,30) +  `${oneProduct?.product?.[0]?.product_title?.length > 30 ? "..." : ""}`}}></span></div>
                    <div className="qr-products-action-button-common" onClick={()=>{
                        setProductIndex(null);
                        setOneProduct([]);
                        setOneTitle("Info");
                        setOneAction("info");
                    }}>
                        <CloseIcon/>
                    </div>
                
                </div>
                <div className="qr-products-list-content">
                <div className="qr-product-detail-common-overall">
                               
                               <div className="qr-product-detail-common-tab-single">
                                   <div className={oneAction == "info" ? "qr-common-icon-activate" : "qr-common-icon" }  onClick={()=>{
                                       setOneAction("info") ; setOneTitle("Info") ; 
                                   }}><InfoIcon/></div>
                            {oneProduct?.product_images?.[0] !==null  ? <div className={oneAction == "image" ? "qr-common-icon-activate" : "qr-common-icon" }   onClick={()=>{
                                setOneAction("image") ; setOneTitle("Images")
                            }}><ImageIcon/></div> : null }
                                  {oneProduct?.product_videos?.[0] !==null  ?  <div className={oneAction == "video" ? "qr-common-icon-activate" : "qr-common-icon" }   onClick={()=>{
                                      setOneAction("video") ; setOneTitle("Videos")
                                  }}
                                  ><PlayCircleOutlineIcon/></div>: null}
                                  {oneProduct?.product_documents?.[0] !==null  ?  <div className={oneAction == "document" ? "qr-common-icon-activate" : "qr-common-icon" }  onClick={()=>{
                                      setOneAction("document")  ; setOneTitle("Documents")
                                  }}><InsertDriveFileIcon/></div>: null}
                                   {products?.[productIndex]?.product_3d_model !== "" || null || undefined ? <div className={oneAction == "surround" ? "qr-common-icon-activate" : "qr-common-icon" }  onClick={()=>{
                                       setOneAction("surround") ; setOneTitle("3D View")
                                   }}><Rotate90DegreesCcwIcon/></div>: null}
                                </div>

                           
                    <div className="qr-product-detail-common-content-wrapper">
                   { oneAction == "info" ? <Info/> : null}
                   { oneAction == "image" ? <Image/> : null}
                   { oneAction == "video" ? <Video/> : null}
                   { oneAction == "document" ? <Document/> : null}
                   { oneAction == "surround" ? <Model/> : null}
                   {showProductsEnquiry ? (
                <Modal
                    handleModalClose={() => {
                        setShowProductsEnquiry(false);
                        setOneAction("info")
                    }}
                    show={showProductsEnquiry}
                    productEnquiry={true}
                    productId={products?.[productIndex]?.id}
                />
            ) : null}
                    </div>
                    <div className="qr-product-detail-common-right-end"></div>
                    </div>

                </div>
                </div>
                </>)}
                
                
                
                
                
                
                
              </>  ): (<AlertMessage/>) }










                </>
                )
            }
    </>   
    );
};

export default Products;
