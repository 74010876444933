import React, { useState, useEffect } from "react";
import { Button as RTButton } from "reactstrap";
import { Grid, Typography, Hidden } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "react-bootstrap";
import userIcon from "../../assets/user-icon.png";
import callIcon from "../../assets/call-icon.png";
import EmailIcon from "@material-ui/icons/Email";
import Button from "../../components/common/Button";
import DuoOutlinedIcon from "@material-ui/icons/DuoOutlined";
import PersonIcon from '@material-ui/icons/Person';
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import ChatBubbleOutlinedIcon from "@material-ui/icons/ChatBubbleOutlined";
import Modal from "../Modal";
import { useStoreState, useStoreActions } from "easy-peasy";
import VCard from "vcard-creator";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import './businessvcard.css';

const BusinessCard = () => {

    
    const [appointmentOpened, setAppointmentOpened] = useState(false);
    const [appointmentURL, setAppointmentURL] = useState("");
    const [contactOpened, setContactOpened] = useState(false);
    const { boothDetails, boardsEnabled } = useStoreState(
        (state) => state.boothLiveState
    );
    
    const { eventConfig } = useStoreState((state) => state.state);
    const eventId = eventConfig?.data?.[0]?.event_id;

    const [businessVCard, setBusinessVCard] = useState("");   
    const [businessCard, setbusinessCard] = useState(undefined); 
    // const isSmallScreen = false;
    const downloadBusinessVCard = (index_val) => {
        if(typeof businessCard !="undefined")
        { 
        const tempBusinessVCard = new VCard();
        tempBusinessVCard
            .addName(
                businessCard[index_val]?.contact_person_last_name,
                businessCard[index_val]?.contact_person_first_name
            )
            .addCompany(businessCard[index_val]?.exhibitor_name)
            .addJobtitle(businessCard[index_val]?.staff_designation)
            .addPhoneNumber(businessCard[index_val]?.staff_phone_num)
            .addPhotoURL(businessCard[index_val]?.staff_profile_image)
            .addEmail(businessCard[index_val]?.company_email)
            .addURL(businessCard[index_val]?.appointment_booking_url);            
            setBusinessVCard(tempBusinessVCard.toString());

            if(businessVCard!="")
            {             
                const element = document.createElement("a");
                const file = new Blob([tempBusinessVCard.toString()], {
                    type: "text/plain",
                });
                element.href = URL.createObjectURL(file);
                element.download = `${businessCard[index_val]?.contact_person_first_name}.vcf`;
                document.body.appendChild(element); // Required for this to work in FireFox
                element.click();
            }
        }
    };

    const setAppointmentOpenedFn = (open_status,open_url) =>{
        setAppointmentOpened(open_status);
        setAppointmentURL(open_url);
    };

   
   

    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );

    useEffect(() => {   
        if(boothId && eventId)
        {
            getBoothDetails({
                booth_id: boothId,
                // booth_url: "https://dev-app-lite.hexafair.com/#/supernatural",
                event_id: eventId,
                //booth_url: "https://www.booth.live/#/it-company-demo-booth",
            }).then((res)=>{                                               
                setbusinessCard(res?.payload?.boothDetails?.booth_staffs);                              
            }); 
        }    
    }, [boothId]);

    useEffect(() => {
        if(typeof businessCard !="undefined")
        {         
       
        }
    },[businessCard]);

  
    return (
        <>
           {boardsEnabled?.hybrid_format_enabled == "No" ? (
    <Forbidden />
) : (
  
    
    <div className="businessvcard-overall">
    {(typeof businessCard=="undefined")?(
        <Grid
            container
            item
            justify="center"
            style={{
                margin: 5,
            }}                
            direction="column"
        >
            <Grid item className="h4 text-center p-2">
            No Data Found
            </Grid>
        </Grid> 
    ):(                   
        <>    
        <div className="businessvcard-wrapper"> 
            {businessCard?.map((businesscard, idx) => {
            return (
                <>
 

        <div className="businessvcard-card">
             <div className="businessvcard-data">
            <div className="businessvcard-image-container" key={"bcard_div1_"+idx}>
            {businesscard.staff_profile_image !== ""   ?  <img
                                        src={
                                            businesscard?.staff_profile_image
                                        }
                                        style={{
                                            display: "inline-block",
                                            width: "100%",
                                            height: "100%",  
                                        }}
                                    ></img> 
                                : <PersonIcon style={{width:"100%" , height:"100%", backgroundColor:"#737373" , color:" white"}} /> }
            </div>
            <div  className="businessvcard-contents-container"  key={"bcard_div2_"+idx}>
                    <div className="businessvcard-contents-name-container" >
                        <img
                             style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "5px",
                            }}
                            src={userIcon}
                        ></img>
                        <div className="businessvcard-contents-name">
                            <span  className="subtitle2" style={{fontWeight:"700"}}>
                                {businesscard?.contact_person_first_name +
                                    " " +
                                    businesscard?.contact_person_last_name}
                            </span>
                            <span
                                variant="subtitle2"
                                style={{
                                    fontSize: "10px",
                                    fontWeight:"600",
                                }}
                            >
                                {businesscard?.staff_designation}
                            </span>
                        </div>
                    </div>
                    <div  className="businessvcard-contents-phone">
                        <img
                            style={{
                                width: "30px",
                                height: "30px",
                                marginRight: "5px",
                            }}
                            src={callIcon}
                        ></img>
                        <div>
                            <span variant="subtitle2" style={{fontWeight:"500"}}>
                                {businesscard?.staff_phone_num}
                            </span>
                        </div>
                    </div>
                    <div className="businessvCardIcons" >
                        {/* <Button
                            handleClick={() => {
                                setContactOpened(true);
                            }}
                            isSquare={true}
                            style={{ height: 100, width: 100 }}
                            OpenIcon={EmailIcon}
                        ></Button> */}
                        {/* <Button
                isSquare={true}
                style={{ height: 100, width: 100 }}
                OpenIcon={ChatBubbleOutlinedIcon}
            ></Button> */}
                        {boothDetails?.booth_config?.[0]
                            ?.video_chat_enabled == "Yes" && (businesscard?.staff_videochat_url !== "" ) ? (
                            <Button
                                isSquare={true}
                                style={{ height: 100, width: 100 }}
                                OpenIcon={DuoOutlinedIcon}
                                handleClick={() => {
                                    window?.open(
                                        businesscard?.visitor_videochat_url,
                                        "_blank"
                                    );
                                }}
                            ></Button>
                        ) : null}

                       {businesscard?.appointment_booking_url !== ""  ? <Button
                            isSquare={true}
                            style={{ height: 100, width: 100 }}
                            OpenIcon={EventOutlinedIcon}
                            handleClick={() => {
                                // setAppointmentOpened(true);
                                setAppointmentOpenedFn(true,businesscard?.appointment_booking_url);
                            }}
                        ></Button> : null}
                    </div>
                
            </div>
            </div>



            <RTButton
            style={{
                background: "#1c1d1f",
                border: "none",
                margin: 10,
            }}
            onClick={()=>downloadBusinessVCard(idx)}
        >
            Download vCard
        </RTButton>




            
            </div>


            {contactOpened ? (
                <Modal
                    handleModalClose={() => {
                        setContactOpened(false);
                    }}
                    show={contactOpened}
                    title="Contact Us"
                    contactUs={true}
                />
            ) : null}
            {appointmentOpened ? (
                <Modal
                    appointment={true}
                    handleModalClose={setAppointmentOpened}
                    show={appointmentOpened}
                    url={businesscard?.appointment_booking_url}
                />
            ) : null}
            
            </>
            );
            })}
            




   
        
        
        
            </div>
        </>
        )}
    </div>
   
)}
    </>
    );
};

export default BusinessCard;
