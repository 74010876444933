import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import { QRCodeUrl } from "../../../../store/restApi/api";
import './jd.css'

const JobDetails = () => {

    const { exhibitorInfo } = useStoreState((state) => state.boothLiveState);

    const { eventConfig } = useStoreState((state) => state.state);
    const event_id = eventConfig?.data?.[0]?.event_id;
    const event_code = eventConfig?.eventCode;

    const [boothData, setBoothData] = useState(undefined);
    const [jobsData, setJobsData] = useState(undefined);
    const [loader, setLoader] = useState(undefined);

    const { getJobDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );

    const [jobId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("job_id")
    );

    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );

    useEffect(() => {
        if (boothId === "" || jobId === "" || boothId === "null" || jobId === "null") {
            setLoader(true);
        }
        if (boothId && event_id) {
            getBoothDetails({
                booth_id: boothId,
                event_id: event_id
            }).then((res) => {
                setBoothData(res.payload);
            });
        }
    }, [boothId]);

    useEffect(() => {
        let exhibitor_id = boothData?.exhibitorInfo?.exhibitor_id;
        if (boothData && (boothId !== "" && jobId !== "" && boothId !== "null" && jobId !== "null")) {
            getJobDetails({
                booth_id: boothId,
                event_id: event_id,
                exhibitor_id: exhibitor_id,
                job_id: jobId,
            }).then((res) => {
                setJobsData(res.payload.jobDetailsData);
            });

        }
    }, [boothData]);

    useEffect(() => {
        if (typeof jobsData !== "undefined" && typeof boothData !== "undefined") {
            if (jobsData == "") {
                setLoader(true);
            }
            else {
                setLoader(undefined);
            }
            document.title = jobsData?.job_title + " - " + exhibitorInfo?.exhibitor_company_name;
        }
        console.log(jobsData)
    }, [jobsData, boothData]);

    //Back to course list page
    const back_fn = () => {
        let link = QRCodeUrl + "jobs?booth_id=" + boothId + "&event_code=" + event_code + "";
        window.open(link, "_self");
    };

    return (


        <div style={{ padding: "100px 0" }}>
            <>
                {(loader) ? (
                    <Grid
                        container
                        item
                        justify="center"
                        style={{
                            margin: 5,
                        }}
                        direction="column"
                    >
                        <Grid item className="h4 text-center p-2">
                            No Data Found
                        </Grid>
                    </Grid>
                ) : (
                    <>
                        {(jobsData != "") ? (
                            <Grid container justify="center" direction="column">

                                <Grid
                                    container
                                    item
                                    justify="center"
                                    style={{
                                        padding: 30,
                                    }}
                                    direction="column"
                                >
                                    {(typeof jobsData != "undefined") ? (
                                        <>
                                            <Grid item className="h4 text-center p-2">
                                                {exhibitorInfo?.exhibitor_company_name}
                                            </Grid>
                                            <Grid item className="h6 text-center">
                                                Jobs
                                            </Grid>
                                        </>
                                    ) : (null)}

                                    <Grid item className="text-right">
                                        <Button
                                            style={{ background: "#1c1d1f" }}
                                            onClick={() => {
                                                back_fn()
                                            }}
                                        >
                                            Back
                                        </Button>
                                    </Grid>

                                    <Grid
                                        container
                                        item
                                        justify="center"
                                        style={{
                                            margin: 5,
                                            padding: 10
                                        }}
                                    >
                                        <Grid
                                            item
                                            xs={10}
                                            md={6}
                                            className="font-weight-bold"
                                            style={{
                                                borderBottom: "1.5px solid #1c1d1f",
                                                padding: '10px'
                                            }}
                                        >
                                            <b className="jtitle">{jobsData?.job_title}</b>
                                           

                                                <div>
                                                <br/>
                                                        <label style={{fontSize:"100%",fontWeight:'bolder'}}>Location: </label>{jobsData?.job_location}
                                                        <br/>
                                                </div>
                                            

                                            <div className="Description">{jobsData?.job_description}</div>

                                        </Grid>
                                        <Grid
                                            item
                                            xs={2}
                                            style={{
                                                display: "flex",
                                                justifyContent: "right",
                                                borderBottom: "1.5px solid #1c1d1f",
                                                padding: 10
                                            }}
                                        >

                                            <Grid
                                                item
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                    padding: 5,
                                                    justifyContent:'flex-start'
                                                }}
                                            >
                                                {/* <div>
                                                    <label style={{fontSize:"90%",fontWeight:'bolder'}}>Location: </label>{jobsData?.job_location}
                                                </div> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>


                            </Grid>
                        ) : (null)}
                    </>
                )}
            </>
        </div>
    );
};

export default JobDetails;



