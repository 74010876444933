import ContactSupportOutlinedIcon from "@material-ui/icons/ContactSupportOutlined";
import PollOutlinedIcon from "@material-ui/icons/PollOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import React from "react";
import "./menu.css";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../common/Button";

const Menu = ({ children, type , close}) => {


  return (
    <>
    <div className="menu_container">
      <div className="menu_left_container" onClick={close}></div>
      <Button
         customIconContainer='menuclose'
          OpenIcon={CloseIcon}
          handleClick={(event) => {
            close();
          }}
        ></Button>
      <div className="menu_right_container">
        <div className="menu_inner_container">
          <div className="top_container">
            {type === "CHAT" && (
              <div className="menu_buttons">
                <QuestionAnswerOutlinedIcon className="menu_icons" />
                <span className="option_title">CHAT</span>
              </div>
            )}

            {type === "QA" && (
              <div className="menu_buttons">
                <ContactSupportOutlinedIcon className="menu_icons" />
                <span className="option_title">Q & A</span>
              </div>
            )}

            {type === "POLLS" && (
              <div className="menu_buttons">
                <PollOutlinedIcon className="menu_icons" />
                <span className="option_title">POLL</span>
              </div>
            )}
          </div>
          <div className="bottom_container">{children}</div>
        </div>
      </div>
    </div>
    </>
  );
};

export default Menu;
