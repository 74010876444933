import React from "react";
import "./listing.css";
import { useStoreState, useStoreActions } from "easy-peasy";
import {  myFavouriteRemove } from "../../../../store/restApi/boothLiveApi";
import { ErrorTypes } from "hls.js";

const Listing = ({ header, child, actions, actionHeader }) => {
  console.log(child)

  const { eventConfig,userDetails } = useStoreState((state) => state.state);
  const { boothDetails, exhibitorInfo } = useStoreState(
    (state) => state?.boothLiveState
);



  /*----------------------Remove favourite function-------------------------------*/
    const setFavoriteRemove_fn=(type,action_id,event)=>{
        //Just hide the row 
        event.target.parentNode.parentNode.style.display='none';

        //---------Favourite disabled
        let favourite_payload = {
            user_id: userDetails?.id,
            favourite_type: type,
            action_id:action_id,
            event_id: eventConfig?.data?.[0]?.event_id,
            // booth_id:boothDetails?.booth[0]?.booth_id
            // booth_id:1
          };

        myFavouriteRemove(favourite_payload).then((response) => {
            return response;
        })
        .catch((error) => {
               //console.log(error);
        })
    };
  /*----------------------Remove favourite function-------------------------------*/

  //attendee,speakers,exhibitorlist
  const setFavoriteRemove_ae=(type,action_id,event)=>{
    //Just hide the row 
    event.target.parentNode.parentNode.style.display='none';

    //---------Favourite disabled
    let favourite_payload = {
        user_id: userDetails?.id,
        favourite_type: type,
        action_id:action_id,
        event_id: eventConfig?.data?.[0]?.event_id,
        // booth_id:boothDetails?.booth[0]?.booth_id
      };

    myFavouriteRemove(favourite_payload).then((response) => {
        return response;
    })
    .catch((error) => {
           //console.log(error);
    })
};

    return (
        <div className="listing-users-container">
            <div className="listing-users-header">
                {header?.map((data, index) => (
                    <div key={index}>
                        <span>{data.displayName}</span>
                    </div>
                ))}
                <div>
                    <span>{actionHeader}</span>
                </div>
            </div>

            <div>
            {child?.map((dataChild, index) => {
                dataChild.SNO = index + 1;
                return (
                    <div className="listing-users" key={index}>
                        {header?.map((data, index) => (
                            <div key={index}>

                                {(data.id.split("_")[0]!=="remove")?(
                                <span>{( ((dataChild[data.id]+"").length) ? ( (dataChild[data.id]+"").substring(0, 50) +"") : (dataChild[data.id]) )}</span>
                                ):(
                                    <>
                                    {(data.id.split("_")[2]=="product")?(
                                        <button onClick={(event) => setFavoriteRemove_fn( (data.id.split("_")[2]), dataChild.product_id,event )}>Remove</button>
                                    ):(null)} 
                                    {(data.id.split("_")[2]=="course")?(
                                        <button onClick={(event) => setFavoriteRemove_fn( (data.id.split("_")[2]), dataChild.course_id,event )}>Remove</button>
                                    ):(null)}  
                                    {(data.id.split("_")[2]=="job")?(
                                        <button onClick={(event) => setFavoriteRemove_fn( (data.id.split("_")[2]), dataChild.job_id,event )}>Remove</button>
                                    ):(null)}  
                                     {(data.id.split("_")[2]=="video")?(
                                        <button onClick={(event) => setFavoriteRemove_fn( (data.id.split("_")[2]), dataChild.video_id,event )}>Remove</button>
                                    ):(null)}  
                                    {(data.id.split("_")[2]=="document")?(
                                        <button onClick={(event) => setFavoriteRemove_fn( (data.id.split("_")[2]), dataChild.document_id,event )}>Remove</button>
                                    ):(null)} 
                                    {(data.id.split("_")[2]=="exhibitor")?(
                                        <button onClick={(event) => setFavoriteRemove_ae( (data.id.split("_")[2]), dataChild.booth_id,event )}>Remove</button>
                                    ):(null)}  
                                     {(data.id.split("_")[2]=="session")?(
                                        <button onClick={(event) => setFavoriteRemove_fn( (data.id.split("_")[2]), dataChild.session_id,event )}>Remove</button>
                                    ):(null)}  
                                     {(data.id.split("_")[2]=="businesscard")?(
                                        <button onClick={(event) => setFavoriteRemove_fn( "staff_businesscard", dataChild.staff_id,event )}>Remove</button>
                                    ):(null)} 
                                    {(data.id.split("_")[2]=="speaker")?(
                                        <button onClick={(event) => setFavoriteRemove_ae( (data.id.split("_")[2]), dataChild.speaker_id,event )}>Remove</button>
                                    ):(null)}  
                                    {(data.id.split("_")[2]=="attendee")?(
                                        <button onClick={(event) => setFavoriteRemove_ae( (data.id.split("_")[2]), dataChild.attendee_id,event )}>Remove</button>
                                    ):(null)}                          
                                    </>
                                )}
                               
                            </div>
                        ))}
                        <div className="listing-users-actions">{actions(dataChild)}</div>
                    </div>
                );
            })}
            </div>
        </div>
    );
};

export default Listing;
