import React, { useEffect, useState } from "react";
import "./documents.css";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import Download from "../../../../assets/download-file.png";
import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";


const Document = ({ event_id, user_id }) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
        {
            id: "document",
            displayName: "DOCUMENT TITLE",
        },
        // {
        //     id: "exhibitor",
        //     displayName: "EXHIBITOR NAME",
        // },
        // {
        //     id: "downloaded_at",
        //     displayName: "DATE & TIME VIEWED",
        // },
        {
            id:"remove_favourite_document",
            displayName: "Remove",
        }
    ];
    const [child, setChild] = useState([]);

    const dowloadDocument = (path) => {
       
        const newWindow = window.open(path, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    }
    const actionsUI = (path) => {

        let url = path.document_path
        return (
            <>
                <div onClick={() => dowloadDocument(url)}>
                    <img src={Download} alt="person" />
                    <br />
                    <span>Download</span>
                </div>
            </>
        );
    };

    useEffect(() => {
        async function fetchDocuments() {
        //     await fetch(`${baseUrl}/mybox-documents-viewed.php`, {
        //         method: "post",
        //         headers: { "Content-Type": "application/json" },
        //         body: JSON.stringify({
        //             event_id: event_id,
        //             user_id: user_id,
        //         }),
        //     })
        //         .then((res) => res.json())
        //         .then((res) => setChild(() => res.data))
        //         .catch((err) => {
        //            // console.log(err);
        //         });
        // }

        let payload_data={
            event_id: event_id,
            user_id: user_id,
            swag_type : "document",
        };
        SwagListApi(payload_data)
            .then((res) => setChild(() => res?.documents))
            .catch((err) => {
               // console.log(err);
            });
    }
        fetchDocuments();
        return () => {
            setChild(() => []);
        };
    }, []);

    return (
        <Listing
            header={header}
            child={child}
            actions={actionsUI}
            actionHeader="DOWNLOAD"
        />
    );
};

export default Document;
