import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import Download from "../../../../assets/download-file.png";
import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";

const Courses = ({ event_id, user_id }) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
        {
            id: "code",
            displayName: "Code",
        },
        {
            id: "course_title",
            displayName: "Course Title",
        },
        {
            id: "college_info",
            displayName: "Name",
        },
        {
            id: "course_location",
            displayName: "Location",
        },


        {
            id: "Applied_Time",
            displayName: "Applied Time",
        },
        {
            id: "Applied_Status",
            displayName: "Status",
        },
        {
            id:"remove_favourite_course",
            displayName: "Remove",
        }
        // {
        //     id: "exhibitor",
        //     displayName: "Exhibitor",
        // },
        // {
        //     id: "viewed_at",
        //     displayName: "DATE & TIME VIEWED",
        // },
    ];
    const [child, setChild] = useState([]);

    const actionsUI = () => {
        return (
            <>
               
            </>
        );
    };

    useEffect(() => {
        async function fetchDocuments() {
            // await fetch(`${baseUrl}/mybox_courses_viewed.php`, {
            //     method: "post",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //         event_id: event_id,
            //         user_id: user_id,
            //     }),
            // })
            //     .then((res) => res.json())
            //     .then((res) => setChild(() => res.data))
            //     .catch((err) => {
            //         //console.log(err);
            //     });

            let payload_data={
                event_id: event_id,
                user_id: user_id,
                swag_type:"course"
            };
            SwagListApi(payload_data)
                .then((res) => setChild(() => res.courses))
                .catch((err) => {
                    //console.log(err);
                });
        }
        fetchDocuments();
        return () => {
            setChild(() => []);
        };
    }, []);

    return (
        <Listing
            header={header}
            child={child}
            actions={() => {}}
            actionHeader=""
        />
    );
};

export default Courses;
