import React, { useState } from 'react'
import { baseUrl } from "../../store/restApi/api";
import { useStoreState } from 'easy-peasy';

const EmailModal = ({data}) => {

    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [errorMessage, setErrorMessage] = useState('')
    const { userDetails, eventConfig } = useStoreState((state) => state.state);
    const [successMessage, setsuccessMessage] = useState('')

    const sendMail = () => {

        if(subject === '' ){
            setErrorMessage(() => 'Kindly fil the subject for the mail.')
            return false;
        }

        if(message === ''){
            setErrorMessage(() => 'Kindly fil the message for the mail.')
            return false;
        }

        setErrorMessage(() => '')

         fetch(`${baseUrl}/connect-send-email-to-attendee.php`, {
            method: "post",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({
                message_sent_by_user_id: userDetails?.id,
                message_sent_to_user_id: data.user_id,
                message: message,
                subject: subject,
                event_id : eventConfig?.data?.[0]?.event_id
            }),
        })
            .then((res) => res.json())
            .then((res) => setsuccessMessage(() => 'Mail Sent Successfully'))
            .catch((err) => {
                //console.log(err);
            });
    }


    return (
        <div style={{
            display: "flex",
            justifyContent: "space-between",
            position: "absolute",
            width: "calc(100% - 50px)",
            height: "calc(100% - 35px)",
            flexDirection: 'column'
        }}>
            {
                errorMessage && <span style={{color : 'red' , fontWeight : '600'}}>{errorMessage}</span>
            }

            {
                successMessage && <span style={{color : 'green' , fontWeight : '600'}}>{successMessage}</span>
            }
            <div>
                <span style={{ fontSize: '14px' }}>Please enter your email address and your message here.</span>
            </div>
            <div>
                <span style={{ width: '50%', clear: 'left', textAlign: 'left', paddingRight: '10px', fontSize: '14px', float: 'left' }}>Email Address *</span>
                <input type='email' placeholder="" style={{ float: 'left' }} disabled value={data.businesscard_email}></input>
            </div>
            <div>
                <span style={{ width: '50%', clear: 'left', textAlign: 'left', paddingRight: '10px', fontSize: '14px', float: 'left' }}>Subject *</span>
                <input type='email' placeholder="" style={{ float: 'left' }} value={subject} onChange={(e) => setSubject(e.target.value)}></input>
            </div>
            <div>
                <span style={{ width: '50%', clear: 'left', textAlign: 'left', paddingRight: '10px', fontSize: '14px', float: 'left' }}>Your Message *</span>
                <textarea style={{ float: 'left' }} value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
            </div>
            <div onClick={sendMail}>
                <span style={{ backgroundColor: "blue", 
                color: 'white', 
                borderRadius: "8px", 
                border: 'none',
                 padding: '3px 20px', 
                 float: 'right', 
                 fontWeight: '600' 
                 }}>Send</span>
            </div>

        </div>
    )
}

export default EmailModal
