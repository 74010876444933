import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { Button } from "reactstrap";
import { sendProductEnquiryEmail as sendProductEnquiryEmailAPI } from "../../../../store/restApi/boothLiveApi";
import { useStoreState } from "easy-peasy";
import React, { useState, useMemo, useEffect } from "react";
import { Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";
import { validateEmail, validatePhone } from "../../utils/validation";
// import countryList from "react-select-country-list";
// import Modal from "../Modal";
import AcknowledgementModal from "./AcknowledgementModal";
import { getCountryCodes as getCountryCodesApi } from "../../../../store/restApi/boothLiveApi";

const styles = (theme) => ({
    input: {
        padding: "5px 10px",
        border: "1px solid black",
        "&:focus": {
            outline: "none",
        },
        width: "100%",
    },
});

const Alert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const ProductEnquiryModal = (props) => {
 
    const { classes, productId } = props;
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const { eventConfig, userDetails } = useStoreState((state) => state.state);
    const [requestSent, setRequestSent] = useState(true);
    const [countryCodes, setCountryCodes] = useState([]);
   
    let { boothDetails } = useStoreState(
        (state) => state?.boothLiveState
    );
    // const countryOptions = useMemo(() => countryList().getData(), []);


    useEffect(()=>{
       
            getCountryCodesApi("")?.then((response) => {               
                setCountryCodes(response);
             });             
       
    },[]);


    const handleSubmit = () => {
        // console.log("ud", countryOptions);
        let firstName = document.getElementById(
                "productEnquiryFirstName"
            ).value,
            lastName = document.getElementById("productEnquiryLastName").value,
            email = document.getElementById("productEnquiryEmail").value,
            message = document.getElementById("productEnquiryMessage").value,
            country = document.getElementById("productEnquiryCountry").value,
            phno = document.getElementById("productEnquiryPhone").value,
            subject = document.getElementById("productEnquirySubject").value;

        if (
            firstName == "" ||
            lastName == "" ||
            email == "" ||
            message == "" ||
            // subject == "" ||
            !validateEmail(email) ||
            !validatePhone(phno)
        ) {
            if (
                (!validateEmail(email) && email != "") ||
                (!validatePhone(phno) && phno != "")
            ) {
                setFormSubmitted("error");
                return;
            }
            setFormSubmitted("incomplete");
            return;
        }
        const payload = {
            request_made_by_user_id: userDetails?.id ? userDetails?.id : "",
            request_made_to_exhibitor_id:
                boothDetails?.exhibitor_information?.[0]?.exhibitor_id,
            request_made_by_name: firstName + " " + lastName,
            request_made_by_email_address: email,
            message: message,
            subject: subject,
            phone_number: phno,
            country: country,
            event_id: eventConfig?.data?.[0]?.event_id,
            booth_id: boothDetails?.exhibitor_information?.[0]?.booth_id,
            company:  boothDetails?.exhibitor_information?.[0]?.exhibitor_company_name,
            product_id: productId,
            enquiry_type: "product"
        };
        //console.log("enquiry", payload);
        setFormSubmitted("loading");
        setRequestSent(true);
        sendProductEnquiryEmailAPI(payload).then((response) => {
            console.log("succ message", response?.message)
            setSuccessMessage(response?.message);
           // console.log(response);
            setFormSubmitted("success");
            setRequestSent(false);
        });
    };

    return (
        <div>
            {formSubmitted != "loading" && formSubmitted != "success" ? (
                <>
                    <Grid
                        container
                        direction="row"
                        // xs={12}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "auto",
                        }}
                    >
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                        >
                            <Grid item>First Name *</Grid>
                            <Grid item>
                                <input
                                    className={classes.input}
                                    type="text"
                                    id="productEnquiryFirstName"
                                    defaultValue={
                                        userDetails?.nicename?.split(" ")?.[0]
                                    }
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                        >
                            <Grid item>Last Name *</Grid>
                            <Grid item>
                                <input
                                    className={classes.input}
                                    type="text"
                                    id="productEnquiryLastName"
                                    defaultValue={
                                        userDetails?.nicename?.split(" ")?.[1]
                                    }
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        // xs={12}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "auto",
                        }}
                    >
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                            xs={6}
                        >
                            <Grid item>Country / Region</Grid>
                            <Grid item>
                                <select
                                    className={classes.input}
                                    style={{ padding: "7px 10px" }}
                                    id="productEnquiryCountry"
                                    name="country"
                                >
                                    {countryCodes?.map((country) => {
                                        return (
                                            <>
                                                <option
                                                    className={classes.input}
                                                    key="country"
                                                    style={{
                                                        maxWidth: "80%",
                                                        overflow: "hidden",
                                                        width: "50px",
                                                    }}
                                                    value={
                                                        country?.phone_country_code?.split(
                                                            "("
                                                        )?.[0]
                                                    }
                                                >
                                                    {
                                                        country?.phone_country_code
                                                    }
                                                </option>
                                            </>
                                        );
                                    })}
                                </select>
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                        >
                            <Grid item>Phone number</Grid>
                            <Grid item>
                                <input
                                    className={classes.input}
                                    id="productEnquiryPhone"
                                    name="phno"
                                    type="tel"
                                    pattern="[0-9]{10}"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        // xs={12}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "auto",
                        }}
                    >
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                        >
                            <Grid item>Email Address *</Grid>
                            <Grid item>
                                <input
                                    className={classes.input}
                                    id="productEnquiryEmail"
                                    name="email"
                                    type="text"
                                    defaultValue={userDetails?.email}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                        >
                            <Grid item>Company</Grid>
                            <Grid item>
                                <input
                                    className={classes.input}
                                    id="productEnquiryCompany"
                                    name="company"
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        direction="row"
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            width: "auto",
                        }}
                    >
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                            xs={12}
                        >
                            <Grid item>Subject</Grid>
                            <Grid item>
                                <input
                                    className={classes.input}
                                    id="productEnquirySubject"
                                    name="company"
                                    type="text"
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                padding: 10,
                            }}
                            xs={12}
                        >
                            <Grid item>Message *</Grid>
                            <Grid item>
                                <textarea
                                    className={classes.input}
                                    id="productEnquiryMessage"
                                    name="message"
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item style={{ textAlign: "center" }}>
                        <Button
                            onClick={() => {
                                // handleClose();
                                // setAcknowledgementOpen(true);
                                handleSubmit();
                            }}
                            style={{
                                color: "white",
                                background: "#1d1c1f",
                                padding: "6px 40px",
                                borderRadius: 20,
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </>
            ) : (
                <>
                    <AcknowledgementModal
                        status={"success"}
                        message={successMessage}
                        loading={requestSent}
                    />
                </>
            )}
            {/* <Modal
                acknowledgement={true}
                show={acknowledgementOpen}
                handleModalClose={() => {
                    setAcknowledgementOpen(false);
                }}
                status={"success"}
                message={successMessage}
            /> */}

            <Snackbar
                open={formSubmitted == "incomplete"}
                autoHideDuration={2000}
                onClose={() => {
                    setFormSubmitted(false);
                }}
            >
                <Alert severity="error">
                    Please fill the mandatory fields!
                </Alert>
            </Snackbar>
            <Snackbar
                open={formSubmitted == "error"}
                autoHideDuration={2000}
                onClose={() => {
                    setFormSubmitted(false);
                }}
            >
                <Alert severity="error">Please fill valid values!</Alert>
            </Snackbar>
            {/* <Snackbar
                open={formSubmitted == "success"}
                autoHideDuration={2000}
                onClose={() => {
                    setFormSubmitted(false);
                }}
            >
                <Alert severity="success">{successMessage}</Alert>
            </Snackbar> */}
        </div>
    );
};

export default withStyles(styles)(ProductEnquiryModal);
