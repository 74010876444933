import ReactPlayer from "react-player"
import React from "react";
const VideoModal = ({ video_path }) => {
    return (
        <div style={{
            fontFamily: "sans-serif",
            textAlign: "center"
        }}>
            <ReactPlayer
            style={{position : 'absolute'}}
                width="45vw"
                height="44vh"
                url={video_path}
                controls={true}
            />
        </div>

    )
}

export default VideoModal