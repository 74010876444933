import CloseIcon from "@material-ui/icons/Close";
import { useStoreActions, useStoreState } from "easy-peasy";
import React from "react";
import "./videoCall.css";
import { useHistory } from "react-router-dom";

const VideoCallNotification = ({ messagePayload }) => {
  const { userDetails } = useStoreState((state) => state.state);
  const { videoCallAction, sendMessage , updateState } = useStoreActions(
    (state) => state.pubNub
  );
  const history = useHistory();
  const closeNotification = () => {
    if (messagePayload?.userMetadata?.chatType !== "MESSAGE_NOTIFICATION") {
      let chatType = "";
      if (
        messagePayload?.userMetadata?.FromUUID === userDetails?.id ||
        messagePayload?.userMetadata?.chatType == "VIDEO-CALL-REJECT"
      ) {
        chatType = "VIDEO-CALL-CANCEL";
      } else {
        chatType = "VIDEO-CALL-REJECT";
      }
      let payLoad = {
        channel: messagePayload?.userMetadata?.FromUUID,
        message: messagePayload?.userMetadata?.ToUserName,
        meta: {
          ToUUID: messagePayload?.userMetadata?.FromUUID,
          ToUserName: messagePayload?.userMetadata?.fromUser,
          FromUUID: userDetails?.id,
          fromUser: userDetails?.displayname,
          chatType: chatType,
          chatURL: "",
        },
      };
      sendMessage(payLoad);
      videoCallAction({});
    }else {
      videoCallAction({});
    }
  };

  const acceptVideocall = () => {
    let payLoad = {
      channel: messagePayload?.userMetadata?.FromUUID,
      message: messagePayload?.userMetadata?.fromUser,
      meta: {
        ToUUID: messagePayload?.userMetadata?.FromUUID,
        ToUserName: messagePayload?.userMetadata?.fromUser,
        FromUUID: userDetails?.id,
        fromUser: userDetails?.displayname,
        // channelId: "new-chnnel",
        chatType: "VIDEO-CALL-ACCEPT",
        chatURL: messagePayload?.userMetadata?.chatURL,
      },
    };
    sendMessage(payLoad);
    videoCallAction(payLoad);
  };

  const viewMessage = () => {
    updateState({
      currentChatUserDetails: {},
    });
  
    history.push("/Chat");
    videoCallAction({});
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {parseInt(messagePayload?.userMetadata?.FromUUID) === parseInt(userDetails?.id) &&
        messagePayload?.userMetadata?.chatType !== "MESSAGE_NOTIFICATION" && (
          <>
            <div className="notifyclose" onClick={closeNotification}>
              <CloseIcon />
            </div>
            <div className="videoCall-container">
              <div className="videocall-message">
                <div className="videocall-type">Dialing To...</div>
                <div className="videocall-name">
                  {messagePayload?.userMetadata?.ToUserName}
                </div>
              </div>
            </div>
          </>
        )}

      {parseInt(messagePayload?.userMetadata?.FromUUID) !== parseInt(userDetails?.id)  ? (
        <>
          <div className="notifyclose" onClick={closeNotification}>
            <CloseIcon />
          </div>
          <div className="videoCall-container">
            <div className="videocall-message">
              <div className="videocall-name">{messagePayload?.message}</div>
              {messagePayload?.userMetadata?.chatType ===
              "VIDEO-CALL-REJECT" ? (
                <div className="videocall-type">
                  Is Rejected your call request.
                </div>
              ) : messagePayload?.userMetadata?.chatType === "VIDEO" ? (
                <div className="videocall-type">Is Calling you.</div>
              ) : messagePayload?.userMetadata?.chatType ===
                "MESSAGE_NOTIFICATION" ? (
                <div className="videocall-type">1 New Message !!</div>
              ) : (
                <div className="videocall-type"></div>
              )}
            </div>
            {messagePayload?.userMetadata?.chatType === "VIDEO" && (
              <div className="videocall-actions" onClick={acceptVideocall}>
                <span className="video-call-accept">accept</span>
              </div>
            )}
              {messagePayload?.userMetadata?.chatType === "MESSAGE_NOTIFICATION" && (
              <div className="videocall-actions" onClick={viewMessage}>
                <span className="video-call-accept">View Message</span>
              </div>
            )}
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default VideoCallNotification;