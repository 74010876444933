import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useEffect, useState } from "react";
import reactDom from "react-dom";
import "./notificationView.css";
import VideoCallNotification from "./videoCall/VideoCallNotification";
import VideoCallWindow from "./videoCallWindow/VideoCallWindow";
import FloatingMenuBar from '../../components/floatingMenuBar/FloatingMenuBar'
import close from '../../assets/close-icon.png'
const NotificationView = () => {
    const { videoCall } = useStoreState((state) => state.pubNub);
    const [notificationType, setNotificationType] = useState("");
    const [messagePayload, setMessagePayload] = useState("");

    const { videoCallAction } = useStoreActions((state) => state.pubNub);

    const closeNotification = () => {
        videoCallAction({});
    };

    useEffect(() => {
        let userMetadata = {};
        if (videoCall) {
            if (videoCall?.meta) {
                userMetadata = videoCall?.meta;
                videoCall.userMetadata = userMetadata;
            } else if (videoCall?.userMetadata) {
                userMetadata = videoCall?.userMetadata;
            }

            setMessagePayload(videoCall);
            setNotificationType(userMetadata?.chatType);
        }
        return () => {
            setMessagePayload(() => "");
            setNotificationType(() => "");
        };
    }, [videoCall]);

    return reactDom.createPortal(
        <div
            className={`${
                notificationType === "VIDEO-CALL-ACCEPT"
                    ? "videoCall-cont"
                    : "notificationView-container"
            }`}
        >
            {notificationType === "VIDEO-CALL-ACCEPT" && (
               <FloatingMenuBar  videoCallClose={closeNotification}/>
            )}
            {notificationType === "VIDEO-CALL-ACCEPT" && (
                <div className="">
                    <span onClick={closeNotification}>
                    <img src={close} alt="close" className="closed"/>
                    </span>
                </div>
            )}

            {notificationType === "VIDEO"  || notificationType === "VIDEO-CALL-REJECT" || notificationType === "MESSAGE_NOTIFICATION"  ? (
                <VideoCallNotification messagePayload={messagePayload} />
            ) : notificationType === "VIDEO-CALL-ACCEPT" ? (
                <VideoCallWindow payload={messagePayload?.userMetadata?.chatURL} />
            ) : (
                ""
            )}
        </div>,
        document.getElementById("notification")
    );
};

export default NotificationView;
