import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import {QRCodeUrl} from "../../../../store/restApi/api";

const BoothConferenceDetails = () => {

    const { exhibitorInfo } = useStoreState((state) => state.boothLiveState);
    const { eventConfig } = useStoreState((state) => state.state);
    const eventId = eventConfig?.data?.[0]?.event_id;
    const event_title = eventConfig?.data?.[0]?.event_title;
    const event_code= eventConfig?.eventCode; 
    
    const { sessionsList } = useStoreState(
        (state) => state.boothLiveState
    );
    const [loader, setLoader] = useState(undefined);
    const [boothData, setBoothData] = useState(undefined);
    
    const [boothSessionsData, setBoothSessionsData] = useState(undefined);

    const { getSessionsList } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );
    const [sessionId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("session_id")
    );
    const { getSessionsDetailsForBooth } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    useEffect(() => {    
        console.log(boothSessionsData)   
    }, [boothSessionsData]);
    useEffect(() => {
        if (boothId && eventId && sessionId) {
            getSessionsDetailsForBooth({
                booth_id: boothId,               
                event_id: eventId, 
                session_id: sessionId             
            }).then((res)=>{                
                setBoothSessionsData(res.payload?.sessionsDetailBooth);             
            });

            getBoothDetails({
                booth_id: boothId,                  
                event_id: eventId                   
            }).then((res)=>{                                               
                setBoothData(res.payload);                              
            });            
        }
    }, [boothId]);

    useEffect(() => {  
        if (boothData) {         
            document.title = boothData?.boothAssets?.booth_meta_title+" - Conferences";
        }      
    }, [boothData]);

    //Back to view page
    const back_fn = () =>{      
        let link=QRCodeUrl+"booth-conferences?booth_id="+boothId+"&event_code="+event_code+"";
        window.open(link,"_self");
    };
    
    return (      
        <div style={{ padding: "100px 0" }}>
        {(loader)?(
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                <Grid item className="h4 text-center p-2">
                No Data Found
                </Grid>
            </Grid>       
        ):(
        <Grid container justify="center" direction="column">
            <Grid
                container
                item
                justify="center"
                style={{
                    padding: 30,
                }}                
                direction="column"
            >
                {(typeof boothSessionsData!="undefined")?(
                    <>
                    <Grid item className="h4 text-center p-2">
                        {exhibitorInfo?.exhibitor_company_name}
                    </Grid>
                    <Grid item className="h6 text-center">
                        Booth - Sessions
                    </Grid>  
                    </> 
                ):(null)}   
                <Grid item className="text-right">
                    <Button
                        style={{ background: "#1c1d1f" }}
                        onClick={() => {
                            back_fn()
                        }}
                    >
                        Back
                    </Button>
                </Grid>                             
                                
                <Grid
                    container
                    item
                    justify="center"
                    style={{
                        margin: 5,
                        padding:10,
                        display:"flex",
                        flexWrap:"nowrap",
                        justifyContent:"flex-start",
                    }}
                    
                >
                    <Grid
                        item
                        xs={10}
                        md={6}
                        
                        className="font-weight-bold"                       
                    >               
                        <div className="small muted">
                        <b className="etitile">{boothSessionsData?.session_detail?.session_title}</b><br/>
                        <div style={{fontSize:'16px'}}><br/>
                        <b>{boothSessionsData?.session_detail?.session_start_date} 
                        <br/>                       
                        {boothSessionsData?.session_detail?.session_start_time}-{boothSessionsData?.session_detail?.session_end_time}
                        </b>  
                        </div>
                        </div> 
                       
                        <div className="small muted">
                            {boothSessionsData?.session_detail?.session_description}           
                        </div>                      
                    </Grid>    

                    <Grid
                      item
                      style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 5,                           
                      }}
                      
                    >  
                        {/* <b>{boothSessionsData?.session_detail?.session_start_date} 
                        <br/>                       
                        {boothSessionsData?.session_detail?.session_start_time} - 
                        <br/>  
                        {boothSessionsData?.session_detail?.session_end_time}
                        </b>              */}
                                           
                    </Grid> 
                </Grid>    

            {boothSessionsData?.speaker_detail?.map((speaker_details, i) => {
                return(
                 <Grid
                  container
                  item
                  justify="center"
                  style={{
                      margin: 5,
                      padding:10,
                      display:"flex",
                      flexWrap:"nowrap",
                      justifyContent:"flex-start",
                  }}
                  key={"boothsession_"+i}                   
                >
                   
                   
                   <Grid
                        item
                        xs={3}
                        sm={1}
                        md={1}
                        lg={1}
                        className="font-weight-bold" 
                                               
                    >     
                        {/* <div className="small muted">
                        <b>{boothSessionsData?.session_detail?.session_title}</b>
                        </div>                    */}
                        <img src={speaker_details?.image} width="50" height="50"  style={{borderRadius:"50%"}}/>                                                                 
                    </Grid>  

                    <Grid
                         item
                         xs={2}
                         md={2}
                         
                         className="font-weight-bold"
                    >                        
                        <div className="small muted">
                           <b>{speaker_details?.name}</b>           
                        </div>
                        <div className="small muted">
                            {speaker_details?.designation}           
                        </div>                        
                        <div className="small muted">
                            <b>{speaker_details?.company}</b>         
                        </div>  
                                        
                    </Grid> 
                </Grid>                          
                 );
                })}
                          
                       
                        </Grid>
                      </Grid>
        )}
                        
                      </div> 
        );
};
export default BoothConferenceDetails;
