import React, { useState } from "react";
import "./questionAndAnswer.css";
import ThumbUpAltRoundedIcon from "@material-ui/icons/ThumbUpAltRounded";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import ReplyTwoToneIcon from "@material-ui/icons/ReplyTwoTone";

const QuestionComponent = ({
  question,
  Question_id,
  name,
  upvote,
  time,
  updateUpvote,
  deleteQA,
  edit,
  replay,
  selfUpvote,
  created_by,
}) => {
  const [showEdit, setShowEdit] = useState(false);
  const [updateQuestion, setUpdateQuestion] = useState(question);
  const [showReplay, setShowReplay] = useState(false);

  const handleSendButton = () => {
    edit(updateQuestion, Question_id);
    setShowEdit(false);
  };

  const handleVote = () => {
    
    if (selfUpvote.length) {
      updateUpvote(Question_id, 0);
    } else {
      updateUpvote(Question_id, 1);
    }
  };

  return (
    <div className="qa_topic" id={Question_id}>
      <div className="qa_topic_top">
        <div className="qa_topic_top_left">
          <span>TH</span>
        </div>
        <div className="qa_user_time_container">
          <div className="qa_userename">
            <span>{name === "" ? "Anonymous" : name}</span>
          </div>
          <div className="qa_time">
            <span>{time}</span>
          </div>
        </div>
        <div className="qa_top_right" onClick={handleVote}>
          <span>{upvote}</span>
          <ThumbUpAltRoundedIcon
            className={`qa_top_right_thumb ${selfUpvote.length ? "like" : "dislike"}`}
          />
        </div>
      </div>

      {showEdit ? (
        <div className="qa_topic_bottom">
          <div className="edit_qa_question">
            <textarea
              placeholder="Type your question"
              style={{ margin: "0px 0px 0px 16px", height: "24px" }}
              onChange={(e) => setUpdateQuestion(e.target.value)}
              value={updateQuestion}
            ></textarea>
          </div>
          <div className="send_button" onClick={handleSendButton}>
            <SendRoundedIcon
              style={{ fontSize: "1.2rem", cursor: "pointer" }}
            />
          </div>
        </div>
      ) : (
        <>
          <div className="qa_topic_bottom">
            <span>{question}</span>
          </div>
          <div className="qa_topic_bottom">
            {replay !== null && replay !== ""  && (
              <div
                className="qa_reply_count"
                onClick={() => setShowReplay(!showReplay)}
              >
                <span>reply</span>
                <ReplyTwoToneIcon
                  style={{ fontSize: "1.3rem", cursor: "pointer" }}
                />
              </div>
            )}

            <div style={{ marginLeft: "auto" }}>
              <EditRoundedIcon
                style={{ fontSize: "1.2rem", cursor: "pointer" }}
                onClick={() => setShowEdit(true)}
              />
              <DeleteOutlineIcon
                style={{ fontSize: "1.2rem", cursor: "pointer" }}
                onClick={() => deleteQA(Question_id)}
              />
            </div>
          </div>
          {showReplay && (
            <div className="qa_reply">
              <div className="qa_topic_bottom">
                <span style={{whiteSpace : 'pre-wrap'}}>
                  <strong>Replies :- </strong> {`${replay}`}
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default QuestionComponent;
