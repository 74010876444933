import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";

const Videos = (props) => {
    const { boothVideos, exhibitorInfo, boardsEnabled } = useStoreState(
        (state) => state.boothLiveState
    );
    const [videosData, setVideosData] = useState(undefined);
    const [playVideo, setPlayVideo] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const [loader, setLoader] = useState(undefined);
    const [boothData, setBoothData] = useState(undefined);

    

    const [videoUrl, setVideoUrl] = useState("");
    useEffect(() => {
        //console.log(boothVideos);
        setVideosData(boothVideos);
        console.log(videosData);
    }, [boothVideos]);
    const { eventConfig } = useStoreState((state) => state.state);
    const eventId = eventConfig?.data?.[0]?.event_id;

    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );
    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    useEffect(() => {
        if(boothId==="" || boothId==="null")
        {
            setLoader(true);  
        }  
        if (boothId && eventId) {
            getBoothDetails({
                booth_id: boothId,
                // booth_url: "https://dev-app-lite.hexafair.com/#/supernatural",
                event_id: eventId,
                //booth_url: "https://www.booth.live/#/it-company-demo-booth",
            }).then((res)=>{                                               
                setBoothData(res.payload);                                            
            });           
        }
    }, [boothId]);

    useEffect(() => {        
        if (boothData) {            
            document.title = boothData?.boothAssets?.booth_meta_title+" - Videos";
        }        
    }, [boothData]);

    useEffect(() => {         
            if(typeof videosData=="undefined")
            {
                setLoader(true);  
            }
            else
            {
                setLoader(undefined);
            }              
    }, [videosData]);

    return (
        <>
            {boardsEnabled?.hybrid_format_enabled == "No" ? (
                <Forbidden />
            ) : (
                <div style={{ padding: "100px 25px" }}>

                    <Grid container justify="center" direction="column">
                    {(loader)?(
                        <Grid
                            container
                            item
                            justify="center"
                            style={{
                                margin: 5,
                            }}                
                            direction="column"
                        >
                            <Grid item className="h4 text-center p-2">
                                No Data Found
                            </Grid>
                        </Grid>       
                    ):(
                        <>
                        <Grid
                            container
                            item
                            justify="center"
                            direction="column"
                            style={{ padding: 30 }}
                        >
                            {(typeof videosData!="undefined")?(                                
                            <>
                            <Grid item className="h4 text-center p-2">
                                {exhibitorInfo?.exhibitor_company_name}
                            </Grid>
                            <Grid item className="h6 text-center">
                                Videos
                            </Grid>
                            </>
                            ):(null)}
                            <Grid item className="text-right">
                                <Button
                                    style={{ background: "#1c1d1f" }}
                                    onClick={() => {
                                        let videosUrls = [];
                                        boothVideos?.map((v) => {
                                            videosUrls.push(v?.video_path);
                                        });
                                        setSelectedLinks(videosUrls);
                                        setShowEmailModal(true);
                                        // props.history.push({
                                        //     pathname: "/email",
                                        //     state: {
                                        //         selected: videosUrls,
                                        //     },
                                        // });
                                    }}
                                >
                                    Email All
                                </Button>
                            </Grid>
                        </Grid>
                        {videosData?.map((video, i) => {
                            return (
                                <Grid
                                    container
                                    item
                                    justify="center"
                                    style={{
                                        margin: 5,
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        md={6}
                                        className="font-weight-bold"
                                        style={{
                                            borderBottom:
                                                i == videosData?.length - 1
                                                    ? "none"
                                                    : "1.5px solid #1c1d1f",
                                        }}
                                    >
                                        {video?.video_title}
                                        <div className="small">
                                            {video?.video_description}
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            justifyContent: "right",
                                            borderBottom:
                                                i == videosData?.length - 1
                                                    ? "none"
                                                    : "1.5px solid #1c1d1f",
                                        }}
                                    >
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}
                                            onClick={() => {
                                                setVideoUrl(video?.video_id);                                                                                               
                                                setPlayVideo(true);
                                            }}
                                        >
                                            <IconButton style={{ padding: 5 }}>
                                                <PlayArrowIcon />
                                            </IconButton>
                                            <div className="small muted">
                                                Play
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}
                                            onClick={() => {
                                                setSelectedLinks([
                                                    video?.video_path,
                                                ]);
                                                setShowEmailModal(true);
                                                // props.history.push({
                                                //     pathname: "/email",
                                                //     state: {
                                                //         selected: [video?.video_path],
                                                //     },
                                                // });
                                            }}
                                        >
                                            <IconButton style={{ padding: 5 }}>
                                                <EmailIcon />
                                            </IconButton>
                                            <div className="small muted">
                                                Email
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}

                        </>
                        )}

                    </Grid>
                    {playVideo ? (
                        <Modal
                            sessionVideo={true}
                            videoUrl={videoUrl}                  
                            show={playVideo}
                            handleModalClose={() => {
                                setPlayVideo(false);
                            }}                         
                        />
                    ) : null}
                    <Modal
                        show={showEmailModal}
                        handleModalClose={() => {
                            setShowEmailModal(false);
                        }}
                        qrEmail={true}
                        selected={selectedLinks}
                    />
                </div>
            )}
        </>
    );
};

export default Videos;
