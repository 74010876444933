import { withStyles } from "@material-ui/core/styles";
import { useEffect } from "react";

const styles = (theme) => ({
    iframe: {
        maxWidth: "90vw",
        [theme.breakpoints.up("sm")]: {
            width: "52vw",
        },
        [theme.breakpoints.up("md")]: {
            width: "42vw",
        },
        [theme.breakpoints.up("lg")]: {
            maxWidth: "70vw",
            overflowY: "unset !important",
        },
        minHeight: 500,
    },
});

const AppointmentModal = (props) => {
    return (
        <iframe
            id="appointmentIframe"
            style={{ border: "none" }}
            className={props.classes.iframe}
            src={props.url}
        ></iframe>
    );
};

export default withStyles(styles)(AppointmentModal);
