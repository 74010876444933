import { useStoreActions, useStoreState } from "easy-peasy";
import { isEmpty } from "lodash";
import React, { memo, useEffect, useState } from "react";
import closeIcon from "../../assets/close-icon.png";
import menuIcon from "../../assets/menu-icon.png";
import BoothLiveHome from "../../boothLive/src/components/Home";
import { pubnubUtilty } from "../../pubNub/utils/PubNubUtilty";
import { serverApi } from "../../store/restApi/serverApi";
import { manageNavigationButtonDisplay } from "../Clickable";
import LogoSpinner from "../common/LogoSpinner";
import FloatingMenuBar from "../floatingMenuBar/FloatingMenuBar";

function Loading() {
    return (
        <div
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
                top: 0,
            }}
            id="iframeLoader"
        >
            <LogoSpinner
                style={{ position: "absolute", top: "50vh", left: "50vw" }}
            />
        </div>
    );
}
const Home = memo((props) => {
    const boothId = props.match.params.boothId;
    const { positions, showClickableBack, no_of_coordinates } = useStoreState(
        (state) => state.state
    );
    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const { userDetails, eventConfig } = useStoreState((state) => state.state);
    const {
        boothConfig,
        boothDetails,
        boothAssets,
        boothDetailsError,
        boothDetailsLoading,
        boothRoomId,
        boothRoomDetails
    } = useStoreState((state) => state.boothLiveState);
    const { updateState } = useStoreActions((state) => state.state);
    const { updateState: boothUpdateState } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    // const isRehydrated = useStoreRehydrated();
    const [cameraState, setCameraState] = useState(undefined);
    const [pointerLock, setPointerLock] = useState(false);
    const [snackbarConfig, updateSnackbarConfig] = useState(false);
    const [exhibitorsList, setExhibitorsList] = useState([]);
    const [menuOpen, setMenuOpen] = useState(false);
    const { pubnubstate } = useStoreState((state) => state.pubNub);

    useEffect(() => {
        boothUpdateState({
            boothDetails: {},
            boardsEnabled: undefined,
            exhibitorInfo: {},
            boothPosters: [],
            boothLogos: [],
            boothDocument: [],
            boothVideos: [],
            boothStaffs: [],
            boothAssets: {},
            positionArray: [],
            cameraLookAt: [],
            cameraPosition: [],
            meshArray: [],
            lookAtArray: [],
            positions: [],
            businessCard: {},
        });
        getBoothDetails({
            booth_id: boothId,
            event_id: eventConfig?.data?.[0]?.event_id,
        });

        serverApi
            .getAllBoothData(eventConfig?.data?.[0]?.event_id, "live", "1")
            .then(({ data }) => {
                let boothConfig = data.event_data.map(
                    (a) => a.booth_content[0].booth_config[0]
                );
                let booth = boothConfig.filter((a) => a.booth_id == boothId);
                boothUpdateState({
                    boothRoomId: booth[0].booth_group_chat_room_id,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    }, [boothId]);

    useEffect(() => {
        if (boothRoomId !== 0) {
            serverApi
                .getConversionById(
                    boothRoomId,
                    userDetails?.id,
                    eventConfig?.data?.[0]?.event_id
                )
                .then(({ data }) => {
                    boothUpdateState({ boothRoomDetails: data });

                    let { room_id } = data;
                    pubnubUtilty.getChannelMetaData(
                        pubnubstate,
                        room_id,
                        (status, response) =>
                            channelMetaDataHandler(status, response, data)
                    );
                })
                .catch((err) => {
                    console.log(err);
                });
        }
        return () => {};
    }, [boothRoomId]);

    const channelMetaDataHandler = (status, response, data) => {
        let { room_id, room_title } = data;

        if (status && status?.error && status?.errorData.status === 404) {
            pubnubUtilty.createSharedSpaceV2(
                pubnubstate,
                { id: room_id, name: room_title },
                undefined,
                room_id,
                (stat, resp) => createSharedSpaceV2Handler(stat, resp, data)
            );
        } else {
            pubnubUtilty.getMembersFromSpaceV2(
                room_id,
                pubnubstate,
                (stat, resp) => getMembersFromSpaceV2Handler(stat, resp, data)
            );
        }
    };

    const createSharedSpaceV2Handler = (status, response, data) => {
        let { room_id } = data;
        if (!status?.error) {
            pubnubUtilty.getMembersFromSpaceV2(
                room_id,
                pubnubstate,
                (stat, resp) => getMembersFromSpaceV2Handler(stat, resp, data)
            );
        }
    };

    const getMembersFromSpaceV2Handler = (status, response, data) => {
        let { room_id, host_details } = data;
        let hostUserId = host_details?.map((d) => d.user_id.toString());
        if (!status?.error) {
            let members = response.data.map((res) => res.uuid.id);
            let newHost = hostUserId?.filter((e) => !members.includes(e));

            if (newHost && newHost.length > 0) {
                pubnubUtilty.addUserToSpaceV2(
                    pubnubstate,
                    room_id,
                    hostUserId,
                    (status, response) => {
                        // any action we can do here
                    }
                );
            }
        }
    };
    
    const revertToOriginalPosition = () => {
        if (cameraState) {
            cameraState.position.set(
                JSON.parse(positions?.[0]?.cameraPosition)[0],
                JSON.parse(positions?.[0]?.cameraPosition)[1],
                JSON.parse(positions?.[0]?.cameraPosition)[2]
            );
            cameraState.lookAt(
                JSON.parse(positions?.[0]?.cameraLookAt)[0],
                JSON.parse(positions?.[0]?.cameraLookAt)[1],
                JSON.parse(positions?.[0]?.cameraLookAt)[2]
            );
            cameraState.updateProjectionMatrix();

            updateState({
                showClickableBack: false,
            });
            manageNavigationButtonDisplay(no_of_coordinates);
        }
    };
    return (
        <>
            {!isEmpty(boothDetails) && (
                <>
                    <div
                        style={{
                            top: "3rem",
                            position: "absolute",
                            zIndex: 1,
                            left: "1rem",
                            cursor: "pointer",
                        }}
                        onClick={() => setMenuOpen(!menuOpen)}
                    >
                        {menuOpen ? (
                            <img
                                alt="closeIcon"
                                src={closeIcon}
                                width={50}
                                height={50}
                                id="exhibitors"
                            />
                        ) : (
                            <img
                                alt="menuIcon"
                                src={menuIcon}
                                width={50}
                                height={50}
                                id="exhibitors"
                            />
                        )}
                    </div>
                    {menuOpen && (
                        <FloatingMenuBar
                            classes={{ img: "floating-img-booth", mt: "mt-4" }}
                        />
                    )}

                    <BoothLiveHome />
                </>
            )}
            {isEmpty(boothDetails) && <Loading />}
        </>
    );
});

export default Home;
