import React, { useState, useEffect } from "react";
import powerbylogo from "../../../assets/logo.png";
import { useStoreState, useStoreActions, useStore } from "easy-peasy";
import {
    breakOutRoom as breakOutRoomAPI,
    add_member as addAPI,
    remove_member as removeAPI,
} from "../../../store/restApi/api";
import { Divider, Grid, styled } from "@material-ui/core";
import "./breakoutroom.css";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import close from '../../../assets/close-icon.png'

const BreakoutRoom = () => {
    const store = useStore();
    const { eventConfig, userDetails, room_det, joinus_status, joinus_iframesrc } = useStoreState(
        (state) => state.state
    );
    const { updateState } = useStoreActions((state) => state.state);
    const [link, setLink] = useState([]);
    const [iframeSRC, setIframeSRC] = useState("");
    const [roomID, setRoomID] = useState(0);
    const [user, setUser] = useState([]);
    // console.log()
    const BootstrapTooltip = styled(({ className, ...props }: TooltipProps) => (
        <Tooltip {...props} arrow classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.arrow}`]: {
            color: theme.palette.common.black,
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.black,
        },
    }));

    useEffect(() => {
        breakOutRoomAPI({
            event_id: eventConfig?.data?.[0]?.event_id,
        }).then((res) => {
            updateState({ room_det: res?.[0]?.breakout_room_list });
            setLink(res?.[0]?.breakout_room_list);
        });
        if (typeof joinus_iframesrc != "undefined") {
            setIframeSRC(joinus_iframesrc);
            // console.log(joinus_iframesrc)
        }

    }, []);

    const reload_fn = () => {

        let payload = {
            event_id: eventConfig?.data?.[0]?.event_id,
            room_id: roomID,
            participants: userDetails?.id,
        };
        removeAPI(payload).then((res) => {
            updateState({ joinus_status: false });
            setUser(res?.data);
            window.location.reload();
            store.reset()
        });

    };

    const add_fn = (roomID) => {
        let payload = {
            event_id: eventConfig?.data?.[0]?.event_id,
            room_id: roomID,
            participants: userDetails?.id,
        };
        addAPI(payload).then((res) => {
            setUser(res?.data);
        });
    };

    const open_meeting = (index, room_id) => {

        updateState({ joinus_status: true, joinus_iframesrc: link?.[index]?.third_party_meeting_link });

        setRoomID(room_id);
        setIframeSRC(link?.[index]?.third_party_meeting_link);
    };



    return (
        <>
            {(iframeSRC != ""
                && joinus_status == true
            ) ? (
                <>

                    <img src={close} onClick={reload_fn} className="closed" />
                    {/* <button
                        type="button"
                        className="closebtn btn btn-primary"
                        onClick={reload_fn}
                        style={{ marginLeft: '8rem' }}
                    >
                        close
                    </button> */}
                    <iframe src={iframeSRC}
                        // style={{
                        //         width: '80%',
                        //         marginLeft: '10rem',
                        //         height: '80%'
                        // }}

                        className='frame'
                    />
                </>
            ) : (




                <section class="table-box">
                    <div>
                        <div class="table-common-box">

                            {typeof link !== "undefined" && link !== null && link !== "" ? link?.map((e, index) =>
                                <>
                                    <div class="box-violet">
                                        <div class="box-content">
                                            <h4>{e.breakout_room_title}</h4>
                                            <p>Want to discuss how
                                                event technology should play
                                                into your in-person event? Join Us!</p>
                                            {e.room_type === "private" ? (
                                                <button class="join-us"

                                                    onClick={() => {
                                                        open_meeting(
                                                            index,
                                                            link[index]
                                                                .breakout_room_id
                                                        );
                                                        add_fn(
                                                            link[index]
                                                                .breakout_room_id
                                                        );
                                                    }}

                                                >Join Us</button>
                                            ) : null}
                                            {e?.participants.length -
                                                e?.Total_no_of_users !==
                                                0 && e.room_type != "private" ? (

                                                <button class="join-us"

                                                    onClick={() => {
                                                        open_meeting(
                                                            index,
                                                            link[index]
                                                                .breakout_room_id
                                                        );
                                                        add_fn(
                                                            link[index]
                                                                .breakout_room_id
                                                        );
                                                    }}

                                                >Join Us</button>

                                            ) : null}
                                            <p class="seats">{e.Total_no_of_users - e?.participants.length} Seats Left</p>
                                        </div>
                                        <div class="table-round">

                                            {e.participants.map((part) =>
                                                <>


                                                    <div class="chair red">

                                                        <BootstrapTooltip title={part.participant_name}>

                                                            <div className="circle">


                                                                {part.participant_name
                                                                    .match(
                                                                        /(\b\S)?/g
                                                                    )
                                                                    .join(
                                                                        ""
                                                                    )
                                                                    .match(
                                                                        /(^\S|\S$)?/g
                                                                    )
                                                                    .join(
                                                                        ""
                                                                    )
                                                                    .toUpperCase()}

                                                            </div>
                                                        </BootstrapTooltip>

                                                    </div>
                                                </>
                                            )}
                                            <div class="client-logo"><img src={powerbylogo} /></div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <p className="text-justify font-weight-bold">No BreakoutRoom is Created</p>
                                </>
                            )}
                        </div>
                    </div>
                </section>
            )}
        </>
    );
};

export default BreakoutRoom;
