import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
const Documents = (props) => {
    const { boothDocument, exhibitorInfo, boardsEnabled } = useStoreState(
        (state) => state.boothLiveState
    );
    const { eventConfig } = useStoreState((state) => state.state);
    const eventId = eventConfig?.data?.[0]?.event_id;
    const [documentsData, setDocumentsData] = useState(undefined);
    const [loader, setLoader] = useState(undefined);
    const [boothData, setBoothData] = useState(undefined);

    const [showEmailModal, setShowEmailModal] = useState(false);
    const [selectedLinks, setSelectedLinks] = useState([]);
    const openInNewTab = (url) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
    };
    useEffect(() => {
        setDocumentsData(boothDocument);
    }, [boothDocument]);
    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );
    useEffect(() => {
        if(boothId==="" || boothId==="null")
        {
            setLoader(true);  
        } 
        if (boothId && eventId) {
            getBoothDetails({
                booth_id: boothId,
                // booth_url: "https://dev-app-lite.hexafair.com/#/supernatural",
                event_id: eventId,
                //booth_url: "https://www.booth.live/#/it-company-demo-booth",
            }).then((res)=>{                                               
                setBoothData(res.payload);                                            
            });;
        }
    }, [boothId]);
    useEffect(() => {        
        if (boothData) {            
            document.title = boothData?.boothAssets?.booth_meta_title+" - Documents";
        }        
    }, [boothData]);
    useEffect(() => {
        if(typeof documentsData!="undefined")
        {    
            if(documentsData=="" || typeof documentsData=="undefined")
            {
                setLoader(true);  
            }
            else
            {
                setLoader(undefined);
            }
        }
    }, [documentsData]);

    return (
        <>
            {boardsEnabled?.hybrid_format_enabled == "No" ? (
                <Forbidden />
            ) : (
                <div style={{ padding: "100px 0" }}>
                   {(loader)?(
                        <Grid
                            container
                            item
                            justify="center"
                            style={{
                                margin: 5,
                            }}                
                            direction="column"
                        >
                            <Grid item className="h4 text-center p-2">
                            No Data Found
                            </Grid>
                        </Grid>       
                    ):(
                    <Grid container justify="center" direction="column">
                        <Grid
                            container
                            item
                            justify="center"
                            direction="column"
                            style={{ padding: 30 }}
                        >
                            {(typeof documentsData!="undefined")?(                                
                            <>
                            <Grid
                                item
                                className="h4 text-center p-2"
                                // style={{ display: "flex", textAlign: "center" }}
                            >                                
                                {exhibitorInfo?.exhibitor_company_name}
                            </Grid>
                            <Grid item className="h6 text-center">
                                Documents
                            </Grid>
                            </>
                            ):(null)}
                            <Grid item className="text-right">
                                <Button
                                    style={{ background: "#1c1d1f" }}
                                    onClick={() => {
                                        let documentsUrls = [];
                                        boothDocument?.map((d,index) => {
                                            documentsUrls.push(
                                                d?.document_path
                                            );
                                        });
                                        setSelectedLinks(documentsUrls);
                                        setShowEmailModal(true);
                                        // props.history.push({
                                        //     pathname: "/email",
                                        //     state: {
                                        //         selected: documentsUrls,
                                        //     },
                                        // });
                                    }}
                                >
                                    Email All
                                </Button>
                            </Grid>
                        </Grid>
                        {documentsData?.map((document, i) => {
                            return (
                                <Grid
                                    container
                                    item
                                    justify="center"
                                    style={{
                                        margin: 5,
                                    }}
                                    key={"document_"+i}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        md={6}
                                        className="font-weight-bold"
                                        style={{
                                            borderBottom:
                                                i == documentsData?.length - 1
                                                    ? "none"
                                                    : "2px solid #1c1d1f",
                                        }}
                                    >
                                        {document?.document_title}
                                        <div className="small">
                                            {document?.document_description}
                                        </div>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            justifyContent: "right",
                                            borderBottom:
                                                i == documentsData?.length - 1
                                                    ? "none"
                                                    : "2px solid #1c1d1f",
                                        }}
                                    >
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}
                                            onClick={(event) =>
                                                openInNewTab(
                                                    document.document_path
                                                )
                                            }
                                        >
                                            <IconButton style={{ padding: 5 }}>
                                                <CloudDownloadIcon />
                                            </IconButton>
                                            <div className="small muted">
                                                Download
                                            </div>
                                        </Grid>
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}
                                            onClick={() => {
                                                setSelectedLinks([
                                                    document?.document_path,
                                                ]);
                                                setShowEmailModal(true);
                                                // props.history.push({
                                                //     pathname: "/email",
                                                //     state: {
                                                //         selected: [
                                                //             document?.document_path,
                                                //         ],
                                                //     },
                                                // });
                                            }}
                                        >
                                            <IconButton style={{ padding: 5 }}>
                                                <EmailIcon />
                                            </IconButton>
                                            <div className="small muted">
                                                Email
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            );
                        })}
                    </Grid>
                    )}
                    <Modal
                        show={showEmailModal}
                        handleModalClose={() => {
                            setShowEmailModal(false);
                        }}
                        qrEmail={true}
                        selected={documentsData}
                    />
                </div>
            )}
        </>
    );
};

export default Documents;
