import React, {
    Suspense,
    useRef,
    useEffect,
    useState,
    useMemo,
    useLayoutEffect,
} from "react";
import { Canvas, useLoader, useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import "../styles.css";
import Clickable from "./Clickable";
import { Physics } from "@react-three/cannon";
import SpeedDial from "./speedDial/SpeedDial";
import ZoomButtons from "./ZoomButtons";
import SocialShareDial from "./SocialShareDial";
import Slide from "@material-ui/core/Slide";
import { Snackbar } from "@material-ui/core";
import { useStoreState, useStoreActions, StoreProvider } from "easy-peasy";
import MuiAlert from "@material-ui/lab/Alert";
import ForumIcon from "@material-ui/icons/Forum";
import VideoCall from "@material-ui/icons/VideoCall";
import { Plane, Html, Reflector } from "@react-three/drei";
import Button from "./common/Button";
import CustomStall from "./Stall";
import LogoSpinner from "./common/LogoSpinner";
import Modal from "./Modal";
import VideoControls from "../components/videoControlsDial";
import AudioControls from "../components/audioControlsDial";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/styles";
import StreamControls from "./StreamControls";
import MuxStreamControls from "./MuxStreamControls";
import store from "../../../store/index";
import { videocall as videocallAPI } from '../utils/pubnub';
import RotateObject from "./pages/rotateObject/RotateObject";
import BoothNav from "./pages/boothNav/BoothNav";

function HtmlLoading() {
    return (
        <Html
            prepend="false"
            position
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
            }}
        >
            <LogoSpinner
                style={{ position: "absolute", top: "50vh", left: "50vw" }}
            />
        </Html>
    );
}

function Ground() {
    return (
        <Reflector
            resolution={512}
            args={[10, 10]}
            mirror={0.5}
            mixBlur={10}
            mixStrength={0.8}
            rotation={[-Math.PI / 2, 0, Math.PI / 2]}
            blur={[400, 100]}
        >
            <>
                {(Material, props) => (
                    <Material
                        color="blue"
                        metalness={0.5}
                        normalScale={[1, 1]}
                        {...props}
                    />
                )}
            </>
        </Reflector>
    );
}
function Loading() {
    return (
        <div
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
                zIndex:"999999"
            }}
        >
            <LogoSpinner
                style={{ position: "absolute", top: "50vh", left: "50vw" }}
            />
        </div>
    );
}

function Scene({
    activateControls,
    videoElement,
    setGlbModalLoading,
    eventId,
}) {
    return (
        <>
            {/* <Player></Player> */}
            <CustomStall
                video={videoElement}
                setLoading={setGlbModalLoading}
                eventId={eventId}
            />
            
            {/* <Ground /> */}
        </>
    );
}

const Terrain = () => {
    const height = useLoader(THREE.TextureLoader, "elevation.png");
    const normals = useLoader(THREE.TextureLoader, "normals.png");
    const colors = useLoader(THREE.TextureLoader, "colors.png");

    return (
        <group>
            <Plane
                rotation={[-Math.PI / 2, 0, 0]}
                position={[-10, -10, -10]}
                args={[64, 64, 1024, 1024]}
            >
                <meshStandardMaterial
                    attach="material"
                    color="white"
                // map={colors}
                // metalness={0.2}
                // normalMap={normals}
                // displacementMap={height}
                />
            </Plane>
        </group>
    );
};

const styles = (theme) => ({
    rotateIcon: {
        position: "absolute",
        right: theme.spacing(2.5),
    },
});

const Home = () => {
    //console.log(CustomStall);
   

    var booth_id = "";
    var booth_lights;

    const {
        boothDetails,
        isSmallScreen,
        positions,
        mux_playing,
        videoStarted,
        isPresentationZone,
        canPlayMuxLink,
        sessionsList,
        isMux,
        cameraPosition,
        cameraLookAt,
    } = useStoreState((state) => state.boothLiveState);
    const { eventConfig, userDetails } = useStoreState((state) => state.state);
    const { sendMessage, videoCallAction } = useStoreActions((state) => state.pubNub);

    const eventId = eventConfig?.data?.[0]?.event_id || "";
    booth_id = boothDetails["booth"][0]["booth_id"];
    const [isTawkToOpened, setIsTawkToOpened] = useState(false);

    booth_lights = JSON.parse(boothDetails["booth"][0]["lighting"]);


    const booth_light_config = () => {
        const light_list = [];

        for (
            var i = 0;
            i < Object.keys(booth_lights["rectarealight"]).length;
            i++
        ) {
            light_list.push(
                <rectAreaLight
                    key={"boothlight_"+i}
                    width={booth_lights["rectarealight"][i]["width"]}
                    height={booth_lights["rectarealight"][i]["height"]}
                    color={parseInt(
                        booth_lights["rectarealight"][i]["color"],
                        16
                    )}
                    intensity={booth_lights["rectarealight"][i]["intensity"]}
                    position={[
                        booth_lights["rectarealight"][i]["position"]["x"],
                        booth_lights["rectarealight"][i]["position"]["y"],
                        booth_lights["rectarealight"][i]["position"]["z"],
                    ]}
                    rotation={[
                        booth_lights["rectarealight"][i]["rotation"]["x"],
                        booth_lights["rectarealight"][i]["rotation"]["y"],
                        booth_lights["rectarealight"][i]["rotation"]["z"],
                    ]}
                    lookAt={[
                        booth_lights["rectarealight"][i]["lookat"]["x"],
                        booth_lights["rectarealight"][i]["lookat"]["y"],
                        booth_lights["rectarealight"][i]["lookat"]["z"],
                    ]}
                    penumbra={booth_lights["rectarealight"][i]["penumbra"]}
                />
            );
        }

        return <>{light_list}</>;
    };

    useLayoutEffect(() => {
        // alert("called");
        updateState({ isPresentationZone: false, showBack: false });
    }, []);

    const classes = makeStyles(styles);
    const [glbModelLoading, setGlbModalLoading] = useState(true);
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    const [videoElement, setVideoElement] = useState(undefined);
    const [pointerLock, setPointerLock] = useState(false);
    const [snackbarConfig, updateSnackbarConfig] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const enableRotate = () => {
        updateSnackbarConfig((updatedConfig) => !updatedConfig);

        setPointerLock((pointerLock) => !pointerLock);
    };

    const SlideTransition = (props) => {
        return <Slide {...props} direction="up" />;
    };
    // const changeCameraHandler = () => {
    //     //console.log(camera, "camera");
    //     camera.position.x = -21.1730899810791;
    //     camera.position.y = -1.1087614297866821;
    //     camera.position.z = 11.76551342010498;
    // };

    // including scripts
    useEffect(() => {
        // document.addEventListener("keypress", handleKeyPress);
        const gtagId = boothDetails?.booth?.[0]?.google_analytics_account_no;
        booth_id = gtagId;

        const tawktoCode = boothDetails?.booth?.[0]?.live_chat_code;
        if (tawktoCode?.length > 15) {
            // const script = document.createElement("script");
            // script.id = "tawkId";
            // script.async = true;
            // script.src = `https://embed.tawk.to/${tawktoCode}`;
            // script.charset = "UTF-8";
            // script.setAttribute("crossorigin", "*");
            // document.body.appendChild(script);
        }

        if (gtagId && gtagId != "") {
            const googleAnalyticsScript = document.createElement("script");
            googleAnalyticsScript.id = "gaScript";
            googleAnalyticsScript.async = true;
            googleAnalyticsScript.src = `https://www.googletagmanager.com/gtag/js?id=G-${gtagId}`;
            googleAnalyticsScript.charset = "UTF-8";
            googleAnalyticsScript.setAttribute("crossorigin", "*");
            document.body.appendChild(googleAnalyticsScript);
            document.getElementById("gaScript").onload = () => {
                window.dataLayer = window.dataLayer || [];
                function gtag() {
                    window.dataLayer.push(arguments);
                }
                gtag("js", new Date());

                gtag("config", `G-${gtagId}`);
            };
        }

        return () => {
            // window?.Tawk_API?.endChat();
            // window?.Tawk_API?.hideWidget();
        };
    }, []);

    const handleModalOpen = (isOpen) => {
        // //console.log("toggle");
        localStorage.setItem("activateControls", `${!isOpen}`);
        setIsModalOpen(isOpen);
    };

    useEffect(() => {
        var video = document.createElement("video");
        let video_source_path;
        let change = "";

        if (boothDetails?.booth_video[0] != null) {
            for (let v = 0; v < boothDetails?.booth_video?.length; v++) {
                if (boothDetails?.booth_video[v].featured_video == "1") {
                    video_source_path =
                        boothDetails?.booth_video?.[v]?.video_path;
                        change = "true only";
                        break;
                }
            }
            if(change !== "true only"){
                video_source_path = boothDetails?.booth_video?.[0]?.video_path;
            }
        }

        // console.log(change);
        video.src = video_source_path;
        video.loop = true;
        video.crossOrigin = "anonymous";
        video.preload = "auto";
        // video.muted = 'muted'
        video.setAttribute("id", "yourId");
        // video.load()
        // video.play()
        setVideoElement(video);
        // return () => {
        //     setVideoElement(undefined);
        //     //console.log("video removeed");
        // };
    }, []);

    /////////////////////////////
    const [modalOpen, setModalOpen] = useState(true);
    const handleModalClose = () => {
        setModalOpen(false);
    };
    const [open, setOpen] = React.useState(true);

    let modalCloseHandler = () => {
        // setInfoCalled(false);
        setModalOpen(false);
    };

    const Alert = (props) => {
        return <MuiAlert elevation={6} variant="filled" {...props} />;
    };
    const [zoomCamera, setZoomCamera] = useState(undefined);
    const [glScene, setGlScene] = useState(undefined);

    const meta = {
        title: boothDetails?.booth?.[0]?.booth_meta_title
            ? boothDetails?.booth?.[0]?.booth_meta_title
            : "",
        description: boothDetails?.booth?.[0]?.booth_meta_title
            ? boothDetails?.booth?.[0]?.booth_meta_title
            : "",
    };

    // console.log(JSON.parse(positions?.[0].cameraPosition));
    //console.log("YALLA", cameraLookAt);
    return (
        <>
            <Helmet>
                <title>{meta?.title}</title>
                <meta name="description" content={meta?.description} />
            </Helmet>
            <Modal
                tawkToChatOpen={isTawkToOpened}
                show={isTawkToOpened}
                tawkToCode={boothDetails?.booth?.[0]?.live_chat_code}
                handleModalClose={() => setIsTawkToOpened(false)}
            ></Modal>
            <>
                <div
                    style={{
                        position: "absolute",
                        width: "100vw",
                        height: "100vh",
                    }}
                >
                    <div style={{ width: "100%" }}>
                        {glbModelLoading && Loading()}
                        <StoreProvider store={store}>
                            {boothDetails?.booth?.[0]?.show_powered_by_logo ==
                                "yes" ? (
                                <>
                                    {/* <a
                                            href="https://www.hostmybooth.com/"
                                            target="_blank"
                                            style={{
                                                position: "absolute",
                                                zIndex: 10000,
                                                left: 15,
                                                bottom: 0,
                                            }}
                                        >
                                            <img
                                                src={VeeSpacesIcon}
                                                width="150px"
                                                height="80px"
                                            />
                                        </a> */}
                                </>
                            ) : null}
                            <div
                                style={{
                                    position: "absolute",
                                    zIndex: 10000,
                                    left: 15,
                                    bottom: 0,
                                }}
                            >
                                <Button linkButton={true} />
                            </div>
                            <Snackbar
                                message={"Camera Controls Activated"}
                                severity={"success"}
                                open={snackbarConfig}
                                autoHideDuration={3000}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "right",
                                }}
                                TransitionComponent={SlideTransition}
                            />

                            <SpeedDial
                                handleModalOpen={handleModalOpen}
                                onClick={({ camera, scene }) => {
                                    camera.lookAt(20, 20, 20);
                                    scene.background = "blue";
                                }}
                            />
                            <div
                                style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    position: "fixed",
                                    // top: "97%",
                                    bottom: 25,
                                    right: 150,
                                    zIndex: 20,
                                }}
                            >
                                {
                                    // eventConfig?.data[0].attendee_to_exhibitor_video_chat_is_enabled === "Yes" && (
                                    //     <Button
                                    //         OpenIcon={VideoCall}
                                    //         handleClick={(event) => {
                                    //             videocallAPI(boothDetails?.booth_staffs[0], userDetails, videoCallAction, sendMessage)
                                    //         }}
                                    //         isSquare={false}
                                    //     ></Button>
                                    // )
                                }
                            </div>

                            {/* <div
                                style={{
                                    flexDirection: "column",
                                    display: "flex",
                                    position: "fixed",
                                    // top: "97%",
                                    bottom: 25,
                                    right: 38,
                                    zIndex: 20,
                                }}
                            >
                                <Button
                                    OpenIcon={ForumIcon}
                                    handleClick={(event) => {
                                        // console.log(eve);
                                        setIsTawkToOpened((opened) => !opened);
                                    }}
                                    isSquare={false}
                                ></Button>
                            </div> */}
                            {/* <SocialShareDial /> */}
                            <RotateObject />
                            <BoothNav/>
                                <VideoControls
                                    videoElement={videoElement}
                                    setVideoElement={setVideoElement}
                                />
                                <AudioControls
                                    videoElement={videoElement}
                                    setVideoElement={setVideoElement}
                                />
                            {isPresentationZone && !isMux && sessionsList ? (
                                <StreamControls />
                            ) : null}
                            {isPresentationZone &&
                                isMux &&
                                sessionsList &&
                                canPlayMuxLink ? (
                                <MuxStreamControls />
                            ) : null}
                        </StoreProvider>
                    </div>
                </div>
                {zoomCamera && glScene && (
                    <StoreProvider store={store}>
                        <ZoomButtons
                            zoomCamera={zoomCamera}
                            glScene={glScene}
                            enableRotate={enableRotate}
                        />
                    </StoreProvider>
                )}
                <Canvas
                    data-tut="reacttour_lol"
                    id="canvas"
                    concurrent
                    // style={{height: "none", width:"none"}}
                    camera={{
                        far: 10000,
                        position: cameraPosition,
                    }}
                    onCreated={({ camera }) => {
                        camera.lookAt(
                            cameraLookAt[0],
                            cameraLookAt[1],
                            cameraLookAt[2]
                        );
                        camera.updateProjectionMatrix();
                    }}
                >
                    <StoreProvider store={store}></StoreProvider>
                    {booth_light_config()}
                    {/* <Sky
                            distance={450000}
                            sunPosition={[-1500, 700, 1500]}
                        /> */}
                    {/* <Stars /> */}
                    <Physics gravity={[0, 0, 0]}>
                        <StoreProvider store={store}>
                            {/* <Terrain/> */}
                            <Suspense fallback={<HtmlLoading />}>
                                <Scene
                                    setGlbModalLoading={setGlbModalLoading}
                                    activateControls={!isModalOpen}
                                    videoElement={videoElement}
                                    eventId={eventId}
                                />
                            </Suspense>
                            {/* <Ground /> */}
                            {/* { <Clickable /> } */}
                            {/*<ZoomButtons />*/}

                            <group position={[0, -1, 0]}>
                                {/* is this ground necessary? */}
                                {/* <Ground /> */}
                            </group>
                        </StoreProvider>
                    </Physics>
                </Canvas>
            </>
        </>
    );
};

export default Home;
