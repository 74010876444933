import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../components/common/Button";
import InfoIcon from "@material-ui/icons/Info";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import ExhibitorsListModal from "./ExhibitorsListModal";

const dialogStyles = (theme) => ({
    mainDialog: {
        "& .MuiPaper-root": {
            maxWidth: "90vw",
            zIndex: "99999991",
            // minHeight: "80vh",
            // [theme.breakpoints.up("sm")]: {
            //     width: "52vw",
            // },
            // [theme.breakpoints.up("md")]: {
            //     width: "42vw",
            // },
            [theme.breakpoints.up("lg")]: {
                maxWidth: "80vw",
                overflowY: "unset !important",
            },
            margin: "auto",
            overflowY: "unset !important",
        },
    },
    buttonContainer: {
        position: "absolute",
        top: "-20px",
        right: "-20px",
        pointer: "cursor",
    },
    productModalTabsContainer: {
        position: "absolute",
        top: "0px",
        left: "-50px",
        pointer: "cursor",
        display: "flex",
        flexDirection: "column",
    },
    productModalTabs: {
        background: "white",
        padding: 10,
        color: "#1c1d1f",
        marginBottom: 5,
        "&:hover": {
            opacity: 1,
            color: "#1c1d1f",
            background: "#ddd",
        },
    },
    activeProductsModalTab: {
        color: "#1c1d1f",
        background: "#ddd",
    },
    customPositionedDialog: {
        position: "absolute",
        bottom: 75,
        left: 236,
        padding: 0,
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CustomizedDialogs = (props) => {
    const { handleBCOpenContactUsModal, handleModalClose } = props;
    //console.log("modal");
    return (       
        <Dialog
            onClose={props.close}
            aria-labelledby="customized-dialog-title"
            open={props.show}
            maxWidth="xl"
            className={props.classes.mainDialog}
            classes={{
                paper: props?.customPosition
                    ? props.classes?.customPositionedDialog
                    : "",
            }}
            style={{
                zIndex: "99999991",
            }}
        >
            <DialogContent
                dividers
                style={{
                    background: props.dark ? "black" : "#fff",
                    color: props.dark ? "white" : "#1c1d1f",
                }}
            >
                {props?.exhibitorsList ? (
                    <ExhibitorsListModal {...props} />
                ) : null}
            </DialogContent>
            <DialogActions style={{ padding: 0 }}>
                <Button
                    customIconContainer={props.classes.buttonContainer}
                    OpenIcon={CloseIcon}
                    handleClick={(event) => {
                        handleModalClose();
                    }}
                ></Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(dialogStyles)(CustomizedDialogs);
