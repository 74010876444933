import React, { Suspense, useEffect, useState } from "react";
import "../styles.css";
import { Typography } from "@material-ui/core";
import {
    StoreProvider,
    useStoreState,
    useStoreRehydrated,
    useStoreActions,
} from "easy-peasy";
import store from "../store";
import { Route, HashRouter, Switch, Redirect } from "react-router-dom";
import publicRoutes from "./publicRoutes";
import privateRoutes from "./privateRoutes";
import { createBrowserHistory } from "history";
import LogoSpinner from "../components/common/LogoSpinner";
import FloatingMenuBar from "../components/floatingMenuBar/FloatingMenuBar";
import queryString, { stringify } from "query-string";
// import { Typography } from "@material-ui/core";
import isEmpty from "lodash/isEmpty";
import EventNotLive from "../components/pages/eventNotLive/EventNotLive";
import Logout from "../components/logout/Logout";
import ExhibitorsListPage from "../components/pages/ExhibitorsListPage";
import powerbylogo from "../assets/poweredby-logo.png";
import NotificationView from "../pubNub/notification/NotificationView";
import LeaderboardIco from "../components/leaderboard/LeaderBoard";
import useWindowSize from "../components/pages/usersList/useWindowSize";

function Loading() {
    return (
        <div
            className="loader"
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
                zIndex: 99999,
            }}
        >
            <LogoSpinner
                style={{ position: "absolute", top: "50vh", left: "50vw" }}
            />
        </div>
    );
}

const Router = (props) => {
    const width = useWindowSize();
    const isRehydrated = useStoreRehydrated();
    const { getEventConfig, updateState } = useStoreActions(
        (state) => state.state
    );
    const { eventConfig, isLoggedIn } = useStoreState((state) => state.state);
    //const params = queryString.parse(props.location);

    console.log();

    const eventConfigFetchedSuccessfully =
        !eventConfig?.loading && !eventConfig?.error;
    const history = createBrowserHistory();
    let evntcode_val="";
    if(typeof window.location.hash!="undefined")
    {
        if(typeof window.location.hash.split("?") !="undefined")
        {
        evntcode_val=new URLSearchParams(window.location.hash.split("?")[1]).get(
            "event_code"
        )
        }
    }
    const [event_code, setEventCode] = useState(evntcode_val);
    let prv_val="";
    if(typeof window.location.hash!="undefined")
    {
        if(typeof window.location.hash.split("?") !="undefined")
        {
            prv_val=new URLSearchParams(window.location.hash.split("?")[1]).get(
            "preview"
        ) || "0";
        }
    }
    const [preview] = useState(prv_val);

    useEffect(() => {
        if (isEmpty(event_code)) {
            let eventC = document
                ?.getElementById("event_code")
                ?.getAttribute("value");
            setEventCode(eventC);
        }
    }, []);
    useEffect(() => {
        if (
            isEmpty(eventConfig?.eventCode) ||
            eventConfig?.error ||
            (!isEmpty(event_code) && eventConfig?.eventCode != event_code)
        ) {
            getEventConfig({ event_code: event_code });
            updateState({ preview: preview });
        }
    }, [event_code]);
    useEffect(() => {
        let newEventCode = "";

        if(typeof window.location.hash!="undefined")
        {
            if(typeof window.location.hash.split("?") !="undefined")
            {
                newEventCode=new URLSearchParams(
                    window.location.hash.split("?")[1]
                ).get("event_code");
            }
        }

        if (newEventCode != eventConfig?.eventCode) {
            setEventCode(newEventCode);
        }
    }, [window.location.href]);
    const [lobbyGlb, setLobbyGlb] = useState(undefined);
    const morphedEventConfig = eventConfig?.data?.[0];
    let isLoginEnabled =
        morphedEventConfig?.event_status == "Live" ||
        (morphedEventConfig?.event_status == "over" &&
            morphedEventConfig?.enable_post_event_ondemand_videos == "Yes");
    return (
        <>
            {isRehydrated && eventConfigFetchedSuccessfully ? (
                <>
                    {preview == "0" && !isLoginEnabled && <EventNotLive />}
                    {(preview == "1" || (preview == "0" && isLoginEnabled)) && (
                        <HashRouter history={history}>
                            <Switch>
                                {publicRoutes.map((route, index) => {
                                    return (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            render={(props) => {
                                                return (
                                                    <route.component
                                                        {...props}
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                })}
                                {privateRoutes.map((route, index) => {
                                    return (
                                        <Route
                                            key={index}
                                            path={route.path}
                                            exact={route.exact}
                                            render={(props) => {
                                                return isLoggedIn ? (
                                                    <>
                                                        {route.path !==
                                                            "/booth/:boothId" && (
                                                            <FloatingMenuBar classes={ {img :'floating-img-others'}} />
                                                            // <FloatMenu />
                                                        )}
                                                        <NotificationView />

                                                        {eventConfig?.data?.[0]
                                                            ?.powered_by_logo_enabled ==
                                                            "Yes" && (
                                                            <img
                                                                className="brandLogo"
                                                                // src={
                                                                //     eventConfig
                                                                //         ?.data?.[0]
                                                                //         ?.event_logo
                                                                // }
                                                                src={
                                                                    powerbylogo
                                                                }
                                                                //width="150px"
                                                                // height="80px"
                                                                onClick={() => {
                                                                    window?.open(
                                                                        "https://www.hexafair.com/?utm_source=hexafairapp&utm_campaign=hexafair_powered_logo&utm_medium=website&utm_content=footer_img",
                                                                        "_blank"
                                                                    );
                                                                }}
                                                            />
                                                        )}
                                                       {width.width > 1000 ? (
                                                        <>
                                                        <div
                                                            style={{
                                                                backgroundColor:
                                                                    "white",
                                                                float: "left",
                                                                padding: "10px",
                                                                position:
                                                                    "absolute",
                                                                zIndex: 1,
                                                                width: "100vw",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    eventConfig
                                                                        ?.data?.[0]
                                                                        ?.event_logo
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    position:
                                                                        "absolute",
                                                                    top: ".5rem",
                                                                    left: 2,
                                                                    zIndex: 9999,
                                                                    height: "2rem",
                                                                    width: "6rem",
                                                                }}
                                                            />
                                                            <label
                                                                style={{
                                                                    fontFamily:
                                                                        "Open Sans",
                                                                    color: "blue",
                                                                    marginLeft:
                                                                        "6rem",
                                                                    fontWeight:
                                                                        "600",
                                                                }}
                                                            >
                                                                {
                                                                    eventConfig
                                                                        ?.data?.[0]
                                                                        ?.event_title
                                                                }
                                                            </label>
                                                        </div>
                                                        </>
                                                    ):null} 
                                                         {/* <div
                                                            style={{
                                                                backgroundColor:
                                                                    "white",
                                                                float: "left",
                                                                padding: "10px",
                                                                position:
                                                                    "absolute",
                                                                zIndex: 1,
                                                                width: "100vw",
                                                            }}
                                                        >
                                                            <img
                                                                src={
                                                                    eventConfig
                                                                        ?.data?.[0]
                                                                        ?.event_logo
                                                                }
                                                                style={{
                                                                    cursor: "pointer",
                                                                    position:
                                                                        "absolute",
                                                                    top: ".5rem",
                                                                    left: 2,
                                                                    zIndex: 9999,
                                                                    height: "2rem",
                                                                    width: "6rem",
                                                                }}
                                                            />
                                                            <label
                                                                style={{
                                                                    fontFamily:
                                                                        "Open Sans",
                                                                    color: "blue",
                                                                    marginLeft:
                                                                        "6rem",
                                                                    fontWeight:
                                                                        "600",
                                                                }}
                                                            >
                                                                {
                                                                    eventConfig
                                                                        ?.data?.[0]
                                                                        ?.event_title
                                                                }
                                                            </label>
                                                        </div> */}

                                                        <Suspense
                                                            fallback={
                                                                <>
                                                                    <Loading />
                                                                </>
                                                            }
                                                        >
                                                            <route.component
                                                                glb={lobbyGlb}
                                                                setGlb={
                                                                    setLobbyGlb
                                                                }
                                                                {...props}
                                                            />
                                                        </Suspense>
                                                    </>
                                                ) : (
                                                    <Redirect
                                                        to={{
                                                            pathname: "/login",
                                                            from: props
                                                                ?.location
                                                                .pathname,
                                                        }}                                                       
                                                    />
                                                );
                                            }}
                                        />
                                    );
                                })}
                            </Switch>
                        </HashRouter>
                    )}
                </>
            ) : (
                <>
                    {eventConfig?.error ? (
                        <div
                            style={{
                                flex: 1,
                                width: "100%",
                                height: "100%",
                                backgroundColor: "#e7e6ff",
                                alignItems: "center",
                                justifyContent: "center",
                                display: "flex",
                            }}
                        >
                            <Typography variant="h3">
                                Error, Invalid event
                            </Typography>
                        </div>
                    ) : (
                        <LogoSpinner />
                    )}
                </>
            )}
        </>
    );
};

export default Router;
