import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import {QRCodeUrl} from "../../../../store/restApi/api";

const Courses = () => { 

    const {  exhibitorInfo } = useStoreState((state) => state.boothLiveState);
    const { eventConfig } = useStoreState((state) => state.state);  
    const event_id= eventConfig?.data?.[0]?.event_id;
    const event_code= eventConfig?.eventCode;  

    
    const [boothData, setBoothData] = useState(undefined);
    const [courseData, setCourseData] = useState(undefined);
    const [loader, setLoader] = useState(undefined);
    
    const { getCourses } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );

    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );

    useEffect(() => {  
            if(boothId==="" || boothId==="null")
            {
                setLoader(true);  
            }  
            if (boothId && event_id) {
                getBoothDetails({
                    booth_id: boothId,                  
                    event_id: event_id                   
                }).then((res)=>{                                               
                    setBoothData(res.payload);                              
                });
            }            
    }, [boothId]);

    useEffect(() => {
        let exhibitor_id= boothData?.exhibitorInfo?.exhibitor_id;
        if (boothData) {            
            getCourses({
                booth_id: boothId,               
                event_id: event_id, 
                exhibitor_id:exhibitor_id               
            }).then((res)=>{                                                          
                setCourseData(res.payload?.coursesData);          
            });
            document.title = boothData?.boothAssets?.booth_meta_title+" - Courses";
        }
    }, [boothData]);

    useEffect(() => {
        if(typeof courseData!="undefined")
        {    
            if(courseData=="" || courseData==null)
            {
                setLoader(true);  
            }
            else
            {
                setLoader(undefined);
            }
        }     
    }, [courseData]);

    //Course details function 
    const course_details_fn = (course_details) =>{      
       let link=QRCodeUrl+"coursedetails?booth_id="+boothId+"&course_id="+course_details?.id+"&event_code="+event_code+"";
       window.open(link,"_self");
    };
   
    return (      
       
        <div style={{ padding: "100px 0" }}>
        {(loader)?(
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                <Grid item className="h4 text-center p-2">
                No Data Found
                </Grid>
            </Grid>       
        ):(
        <Grid container justify="center" direction="column">
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                {(typeof courseData!="undefined")?(
                    <>
                    <Grid item className="h4 text-center p-2">
                        {exhibitorInfo?.exhibitor_company_name}
                    </Grid>
                    <Grid item className="h6 text-center">
                        Courses
                    </Grid>  
                    </> 
                ):(null)}

        {courseData?.map((course_data, i) => {
                            return (                               
                                
                                <Grid
                                    container
                                    item
                                    justify="center"
                                    style={{
                                        margin: 5,
                                        padding:10,
                                        display:"flex",
                                        flexWrap:"nowrap"
                                    }}
                                    key={"courses_"+i}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        md={6}
                                        className="font-weight-bold"
                                        style={{
                                            borderBottom:
                                                i == course_data?.length - 1
                                                    ? "none"
                                                    : "1.5px solid #1c1d1f",
                                                    padding:10
                                        }}
                                    >                                       
                                        
                                        <b className="ctitle">{course_data?.course_title}</b>
                                        <div className="small" style={{width:'230px'}}>
                                        {course_data?.course_description.substring(0, 80)+"...."}
                                        </div>                                                                             
                                        
                                    </Grid>

                                 
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            justifyContent: "right",
                                            borderBottom:
                                                i == course_data?.length - 1
                                                    ? "none"
                                                    : "1.5px solid #1c1d1f",
                                                    padding:10
                                        }}
                                    >
                                        
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}                                           
                                        >                                          
                                            <div className="small">
                                            <Button onClick={()=>course_details_fn(course_data)}>View Details</Button>
                                            </div>                                            
                                        </Grid>
                                    </Grid>

                                </Grid>                             
                            );
                        })}
                        </Grid>
                      </Grid>
        )}
                        
                      </div> 
        );
};
export default Courses;
