import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "./common/Button";
import PrintIcon from "@material-ui/icons/Print";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import {
    EmailShareButton,
    FacebookShareButton,
    LinkedinShareButton,
    LivejournalShareButton,
    TelegramShareButton,
    MailruShareButton,
    RedditShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    WorkplaceShareButton,
    EmailIcon,
    FacebookIcon,
    LinkedinIcon,
    LivejournalIcon,
    MailruIcon,
    OKIcon,
    PinterestIcon,
    PocketIcon,
    RedditIcon,
    TelegramIcon,
    TumblrIcon,
    TwitterIcon,
    ViberIcon,
    VKIcon,
    WeiboIcon,
    WhatsappIcon,
    WorkplaceIcon,
} from "react-share";
import ShareIcon from "@material-ui/icons/Share";
import { useStoreState } from "easy-peasy";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
const useStyles = makeStyles((theme) => ({
    /*root: {
        // height: 10,
        // transform: "translateZ(0px)",
        // flexGrow: 1,
        "& .MuiFab-primary": {
            backgroundColor: "#059DC0",
        },
        "& .MuiSvgIcon-root": {
            height: "2em",
            marginRight: "3px"          
        },
    },*/
    speedDial: {
        position: "absolute",
        bottom: 0,
        right: theme.spacing(3),
    },
    menuContainer: {
        marginBottom: "100px",
    },
    menuContainerPaper: {
        // position: "absolute",
        // right: 0,

        width: "auto",
        backgroundColor: "rgba(0, 0, 0, 0)",
        marginBottom: theme.spacing(100),
        boxShadow: "none",
        top:"265px !important",
        left:"900px !important",
    },
}));

// const url = 'https://booth.live'

export default function OpenIconSpeedDial() {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(true);
    const [hidden, setHidden] = React.useState(false);
    const { isSmallScreen, boothConfig } = useStoreState(
        (state) => state.boothLiveState
    );
    let { socialIconState } = useStoreState(
        (state) => state.boothLiveState
    );
    const { eventConfig } = useStoreState((state) => state.state);     
    const url = window.location.href+"?event_code="+eventConfig?.eventCode;
   
    const handleVisibility = () => {
        setHidden((prevHidden) => !prevHidden);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const callApi = (arg) => {
        window.alert("API Call has to happen for " + arg + " !!");
        //console.log("arg", arg)
    };
    const actions = [
        {
            icon: (
                <FacebookShareButton url={url}>
                    <FacebookIcon
                        round
                        size={isSmallScreen ? "1rem" : "2rem"}
                    />
                </FacebookShareButton>
            ),
            name: "Facebook",
        },
        {
            icon: (
                <WhatsappShareButton url={url}>
                    <WhatsappIcon
                        round
                        size={isSmallScreen ? "1rem" : "2rem"}
                    />
                </WhatsappShareButton>
            ),
            name: "Whatsapp",
        },
        {
            icon: (
                <LinkedinShareButton url={url}>
                    <LinkedinIcon
                        round
                        size={isSmallScreen ? "1rem" : "2rem"}
                    />
                </LinkedinShareButton>
            ),
            name: "Linkedin",
        },
        {
            icon: (
                <TwitterShareButton url={url}>
                    <TwitterIcon round size={isSmallScreen ? "1rem" : "2rem"} />
                </TwitterShareButton>
            ),
            name: "Twitter",
        },
        {
            icon: (
                <EmailShareButton url={url}>
                    <EmailIcon round size={isSmallScreen ? "1rem" : "2rem"} />
                </EmailShareButton>
            ),
            name: "Email",
        },
        {
            icon: (
                <TelegramShareButton url={url}>
                    <TelegramIcon
                        round
                        size={isSmallScreen ? "1rem" : "2rem"}
                    />
                </TelegramShareButton>
            ),
            name: "Telegram",
        },
    ];

    return (
        <div
            style={{
                flexDirection: "column",
                display: "flex",
                position: "fixed",
                // top: "97%",
                bottom: 50,
                right: 120,
                zIndex: 20,
            }}
        >
            <div className={classes.speedDial}>
                {/* <Button
                    OpenIcon={ShareIcon}
                    handleClick={(event) => {
                        // console.log(eve);
                        setAnchorEl(event.target);
                    }}
                    isSquare={true}
                ></Button> */}
                <Menu
                    className={classes.menuContainer}
                    id="simple-menu"
                    classes={{ paper: classes.menuContainerPaper }}
                    keepMounted
                    //anchorEl={anchorEl}                    
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    transformOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                    }}
                    elevation={0}
                    open={Boolean(socialIconState)}
                    onClose={handleClose}
                >
                    {actions.map((action, index) => {
                        return (
                            <MenuItem
                                style={{ "&:hover": { background: "none" } }}
                                key={"socialshare_booth"+index}
                            >
                                {action.icon}
                            </MenuItem>
                        );
                    })}
                </Menu>
            </div>
        </div>
    );
}
