import { useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";

export function messageHandler(state, action) {
  if (state && action) {
    let userMetadata = state.userMetadata;

    switch (userMetadata?.chatType) {
      case "VIDEO":
        action.videoCallAction(state);
        break;
      case "VIDEO-CALL-REJECT":
        action.videoCallAction({});
        action.videoCallAction(state);
        break;
      case "VIDEO-CALL-ACCEPT":
        action.videoCallAction(state);
        break;
      case "MESSAGE_NOTIFICATION":
        action.videoCallAction(state);
        break;

      default:
        break;
    }
  }
}
