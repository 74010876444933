import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import {QRCodeUrl} from "../../../../store/restApi/api";

const Event_conferences_details = () => {

    const { eventConfig } = useStoreState((state) => state.state);  
    const event_id= eventConfig?.data?.[0]?.event_id;
    const event_title= eventConfig?.data?.[0]?.event_title;
    const event_code= eventConfig?.eventCode;     
  
    const [sessionDetailsAuditoriumData, setsessionDetailsAuditoriumData] = useState(undefined);
    const [sessionDetail, setsessionDetail] = useState(undefined);
    const [speakerDetail, setspeakerDetail] = useState(undefined);
    const [loader, setLoader] = useState(undefined);

    const [sessionId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("session_id")
    );
    
    const { getSessionsDetailsForAuditorium } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );


    useEffect(() => {       
        if (event_id && sessionId) {            
            getSessionsDetailsForAuditorium({                              
                event_id: event_id,
                session_id:sessionId                             
            }).then((res)=>{                                                                        
                setsessionDetailsAuditoriumData(res?.payload?.sessionsDetailAuditorium);          
                  
            });
            document.title = event_title+" - Auditorium Sessions List";
        }
    }, [sessionId]);

    useEffect(() => {
        if(typeof sessionDetailsAuditoriumData=="undefined")
        {    
            setLoader(true); 
        } 
        else
        {
            setLoader(undefined);
        }          
        console.log(sessionDetailsAuditoriumData?.data?.session_detail)  
    }, [sessionDetailsAuditoriumData]);

    //Back to view page
    const back_fn = () =>{      
        let link=QRCodeUrl+"event-conferences?event_code="+event_code+"";
        window.open(link,"_self");
    };
   
    return (      
      
        <div style={{ padding: "100px 0" }}>
        {(loader)?(
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                <Grid item className="h4 text-center p-2">
                No Data Found
                </Grid>
            </Grid>       
        ):(
        <Grid container justify="center" direction="column">
            <Grid
                container
                item
                justify="center"
                style={{
                    padding: 30,
                }}                
                direction="column"
            >
                {(typeof sessionDetailsAuditoriumData!="undefined")?(
                    <>
                    <Grid item className="h4 text-center p-2">
                        {event_title}
                    </Grid>
                    <Grid item className="h6 text-center">
                        Event - Sessions
                    </Grid>  
                    </> 
                ):(null)}   
                <Grid item className="text-right">
                    <Button
                        style={{ background: "#1c1d1f" }}
                        onClick={() => {
                            back_fn()
                        }}
                    >
                        Back
                    </Button>
                </Grid>                             
                                
                <Grid
                    container
                    item
                    justify="center"
                    style={{
                        margin: 5,
                        padding:10,
                        display:"flex",
                        flexWrap:"nowrap",
                        justifyContent:"flex-start",
                    }}
                    
                >
                    <Grid
                        item
                        xs={10}
                        md={6}
                        className="font-weight-bold"
                        
                    >               
                        <div className="small muted">
                        <b className="etitile">{sessionDetailsAuditoriumData?.data?.session_detail?.session_title}</b>
                        <div style={{fontSize:'16px'}}>
                            <br/>
                        <b>{sessionDetailsAuditoriumData?.data?.session_detail?.session_start_date} 
                        <br/>                       
                        {sessionDetailsAuditoriumData?.data?.session_detail?.session_start_time} - {sessionDetailsAuditoriumData?.session_detail?.session_end_time}
                        </b>  
                        </div>
                        </div>                        
                        <div className="small muted">
                            {sessionDetailsAuditoriumData?.data?.session_detail?.session_description}           
                        </div>                      
                    </Grid>   

                    <Grid
                      item
                      style={{
                          display: "flex",
                          flexDirection: "column",
                          padding: 5,                           
                      }}
                      
                    >  
                        {/* <b>{sessionDetailsAuditoriumData?.session_detail?.session_start_date} 
                        <br/>                       
                        {sessionDetailsAuditoriumData?.session_detail?.session_start_time} - 
                        <br/>  
                        {sessionDetailsAuditoriumData?.session_detail?.session_end_time}
                        </b>                                            */}
                    </Grid>
                </Grid>    

            {sessionDetailsAuditoriumData?.data?.speaker_detail?.map((speaker_details, i) => {      
                return(
                <Grid
                  container
                  item
                  justify="center"
                  style={{
                      margin: 5,
                      padding:10,
                      display:"flex",
                      flexWrap:"nowrap",
                      justifyContent:"flex-start",
                  }}
                  key={"eventsession_"+i}                   
                >
                    <Grid
                        item
                        xs={3}
                        sm={1}
                        md={1}
                        lg={1}
                        className="font-weight-bold" 
                        style={{marginRight:"1%"}}
                                               
                    >      
                        {/* <div className="small muted">
                        <b>{sessionDetailsAuditoriumData?.session_detail?.session_title}</b>
                        </div>              */}
                        <img className="img" src={speaker_details?.image} width="50" height="50" float="left"/> 
                                           
                    </Grid>      

                    <Grid
                      item
                      xs={3}
                      md={2}
                      className="font-weight-bold"
                    >                 
                        <div className="small muted">
                           <b>{speaker_details?.name}</b>           
                        </div>
                        <div className="small muted">
                            {speaker_details?.designation}           
                        </div>                        
                        <div className="small muted">
                            <b>{speaker_details?.company}</b>         
                        </div>                     
                    </Grid>          

                </Grid>  
                );
            })}


                          
                       
                        </Grid>
                      </Grid>
        )}
                        
                      </div> 
        );
};
export default Event_conferences_details;
