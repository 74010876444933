import Grid from "@material-ui/core/Grid";
import { withStyles } from "@material-ui/core/styles";
import { useEffect, useState  } from "react";
import { useStoreState } from "easy-peasy";
import { withRouter } from "react-router-dom";
import { booth_visitor_log as boothVisitorLogApi } from "../store/restApi/boothLiveApi";
import { businesscard_log as businesscardLogApi } from "../store/restApi/boothLiveApi";

const styles = (theme) => ({
    exhibitorContainer: {
        transitionDuration: "0.2s",
        cursor: "pointer",
        "&:hover": {
            background: "#efefef",
        },
    },
});

const ExhibitorsListModal = (props) => {
    const { exhibitorsList, classes } = props;
    const { eventConfig,userDetails } = useStoreState((state) => state.state);

    const { boothDetails } = useStoreState(
        (state) => state?.boothLiveState
    );

    //booth visitor tracking    
    const booth_tracking = (booth_id) =>{       

        
        if(typeof boothDetails != "undefined" && Object.keys(boothDetails).length !== 0)
        {
            if(booth_id!=boothDetails?.booth[0]?.booth_id)
            {
                //booth visitor update
                const payload_update = {
                    visitor_user_id_type: userDetails?.user_type,
                    visitor_user_id: userDetails?.id,
                    event_id: eventConfig?.data[0]?.event_id,
                    booth_id: boothDetails?.booth[0]?.booth_id, 
                    location: "1",  
                    action: "2",            
                };

                boothVisitorLogApi(payload_update).then((response) => {
                    //console.log(response?.message);               
                });


                //booth visitor insert
                const payload_insert = {
                    visitor_user_id_type: userDetails?.user_type,
                    visitor_user_id: userDetails?.id,
                    event_id: eventConfig?.data[0]?.event_id,
                    booth_id: booth_id, 
                    location: "1",  
                    action: "1",            
                };

                boothVisitorLogApi(payload_insert).then((response) => {
                    //console.log(response?.message);               
                });


                    //Business card log
                    const business_payload = {
                        attendee_id: userDetails.id,
                        booth_id: booth_id,
                        user_type: userDetails.user_type,      
                        event_id: eventConfig?.data?.[0]?.event_id,
                    };

                    businesscardLogApi(business_payload).then((response) => {
                        //console.log(response?.message);        
                    });
            }
        }
        else
        {          
            //Tracking booth visitor Api calling
            const payload = {
                visitor_user_id_type: userDetails?.user_type,
                visitor_user_id: userDetails?.id,
                event_id: eventConfig?.data[0]?.event_id,
                booth_id: booth_id, 
                location: "1",  
                action: "1",            
            };

            boothVisitorLogApi(payload).then((response) => {
                //console.log(response?.message);               
            });
        }       
    }
    //end

    return (
        <>
            <h4 className="text-muted bold pb-2 border-bottom border-5">
                Exhibitors
            </h4>
            <Grid container>
                {exhibitorsList?.map((exhibitor, i) => {
                    const boothId =
                        exhibitor?.booths_list?.[0]?.booth_details?.[0]
                            ?.booth_id;
                    return (
                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            key={i}
                            style={{
                                display: "flex",
                            }}
                            className={"rounded " + classes.exhibitorContainer}
                            onClick={() => {
                                props.handleModalClose();
                                props.history.push(`/booth/${boothId}`);
                                booth_tracking(boothId);
                            }}
                        >
                            <Grid item container alignItems="center">
                                <Grid
                                    item
                                    style={{
                                        width: "75px",
                                        // maxHeight: "80px",
                                        margin: 8,
                                        // display: "flex",
                                    }}
                                >
                                    <img
                                        src={
                                            exhibitor?.booths_list?.[0]
                                                ?.booth_details?.[0]?.booth_logo
                                        }
                                        width="100%"
                                        style={{
                                            padding: "auto",
                                            width: "100%",
                                            verticalAlign: "middle",
                                        }}
                                        // height="100%"
                                    />
                                </Grid>
                                <Grid item style={{ margin: "0 5px" }}>
                                    {
                                        exhibitor?.booths_list?.[0]
                                            ?.booth_details?.[0]?.[
                                            " booth_exhibitor_name"
                                        ]
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
            </Grid>
        </>
    );
};

export default withRouter(withStyles(styles)(ExhibitorsListModal));
