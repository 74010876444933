import React from "react";
import "./modal.css";
import ReactDOM from "react-dom";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../common/Button";


const chatModal = ({ open, children, onClose, widith, height }) => {
  if (!open) return null;

  

 
  return ReactDOM.createPortal(
    <>
      <div className="overlay"  />
      <div className="modal_container"  style={{width : widith, height : height} }>
        <Button
         customIconContainer='close'
          OpenIcon={CloseIcon}
          handleClick={(event) => {
            onClose();
          }}
          
          style={{marginRight: '-1rem', marginTop:'-1%'}}
        ></Button>
        {children}
      </div>
    </>,

    document.getElementById("portal")
  );
};

export default chatModal;
