import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import {QRCodeUrl} from "../../../../store/restApi/api";
const Boothconferences = () => {
    const { exhibitorInfo } = useStoreState((state) => state.boothLiveState);
    const { eventConfig } = useStoreState((state) => state.state);
    const eventId = eventConfig?.data?.[0]?.event_id;
    const event_title = eventConfig?.data?.[0]?.event_title;
    const event_code= eventConfig?.eventCode;
    
    const { sessionsList } = useStoreState(
        (state) => state.boothLiveState
    );
    const [loader, setLoader] = useState(undefined);
    const [boothData, setBoothData] = useState(undefined);
    
    const [boothSessionsData, setBoothSessionsData] = useState(undefined);

    const { getSessionsList } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );
    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    useEffect(() => {
        setBoothSessionsData(sessionsList); 
        console.log(boothSessionsData)   
    }, [sessionsList,boothSessionsData]);
    useEffect(() => {
        if(boothId==="" || boothId==="null")
        {
            setLoader(true);  
        }
        if (boothId && eventId) {
            getSessionsList({
                booth_id: boothId,               
                event_id: eventId,                
            }).then((res)=>{                
                setBoothSessionsData(res.payload.sessionsList);             
            });

            getBoothDetails({
                booth_id: boothId,                  
                event_id: eventId                   
            }).then((res)=>{                                               
                setBoothData(res.payload);                              
            });            
        }
    }, [boothId]);

    useEffect(() => {  
        if (boothData) {         
            document.title = boothData?.boothAssets?.booth_meta_title+" - Conferences";
        }      
    }, [boothData]);


    /*---------open livestreaming function--------------*/
    const open_livestreaming_fn = (streaming_url) =>{
        window.open(streaming_url);
    };

    useEffect(() => {
        if(typeof boothSessionsData!="undefined")
        {    
            if(boothSessionsData=="" || typeof boothSessionsData=="undefined")
            {
                setLoader(true);  
            }
            else
            {
                setLoader(undefined);
            }
        }
        console.log(boothSessionsData);
    }, [boothSessionsData]);

     //conference details function 
     const conference_details_fn = (session_id) =>{      
        let link=QRCodeUrl+"booth-conferences-details?booth_id="+boothId+"&session_id="+session_id+"&event_code="+event_code+"";
        window.open(link,"_self");
     };

    return (      
    <div style={{ padding: "100px 0" }}>
        {(loader)?(
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                <Grid item className="h4 text-center p-2">
                No Data Found
                </Grid>
            </Grid>       
        ):(
        <Grid container justify="center" direction="column">
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}
                direction="column"                
            >
                 {(typeof boothSessionsData!="undefined")?(
                    <>
                        <Grid item className="h4 text-center p-2">
                            {exhibitorInfo?.exhibitor_company_name}
                        </Grid>
                        <Grid item className="h6 text-center">
                            Booth - Sessions
                        </Grid>
                    </>
                 ):(null)}
        {boothSessionsData?.map((sessiondata, i) => {
                            return (
                                <>
                                <Grid
                                    container
                                    item
                                    justify="center"
                                    style={{
                                        margin: 5,
                                        padding:10,
                                        display:"flex",
                                        flexWrap:"nowrap"
                                    }}
                                    key={"sessiondata_"+i}
                                >
                                    {/* <Grid
                                        item
                                        xs={10}
                                        md={6}
                                        className="font-weight-bold"
                                        style={{
                                            borderBottom:
                                                i == boothSessionsData?.length - 1
                                                    ? "none"
                                                    : "1.5px solid #1c1d1f",
                                                    padding:10
                                        }}
                                    >                                        
                                        <div className="small">
                                        <h6><b>{sessiondata?.session_date}</b></h6>
                                        </div>
                                    </Grid> */}
                               
                       

                            {sessiondata?.streaming_list?.map((streamdata, index) => {
                                return(
                                    <>
                                    <Grid
                                         container
                                         item
                                         justify="center"
                                         style={{
                                             margin: 5,
                                             padding:10,
                                             display:"flex",
                                             flexWrap:"nowrap"
                                         }}
                                         key={"eventconference_"+index}
                                    >
                                        
                                        <Grid
                                           item
                                           xs={10}
                                           md={6}
                                           className="font-weight-bold"
                                           style={{
                                               borderBottom:
                                                   i == streamdata?.length - 1
                                                       ? "none"
                                                       : "1.5px solid #1c1d1f",
                                                       padding:10
                                           }}                                       
                                        >                                          
                                            <div className="small">
                                            <h6><b>{streamdata?.session_title}</b></h6>
                                            </div>
                                            <div className="small">
                                            <h6><b>{streamdata?.session_start_date}</b></h6>
                                            </div>  
                                            <div className="small">
                                            <h6><b>{streamdata?.session_start_time} - {streamdata?.session_end_time}</b></h6>
                                            </div>                                          
                                        </Grid>                        
                                        
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}                                           
                                        >                                          
                                            <div className="small">
                                            <Button onClick={()=>conference_details_fn(streamdata?.session_id)}>View Details</Button>
                                            </div>
                                            
                                        </Grid>
                                    </Grid>
                                    </>
                                );
                            })}
                            </Grid>
                            </>
                            );
                        })}
                        </Grid>
                      </Grid> 
        )}
                      </div>        
    );
};

export default Boothconferences;
