import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
//mui icons import list ------------------------>
import CallIcon from '@material-ui/icons/Call';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import LocationOnIcon from '@material-ui/icons/LocationOn';

//mui icons import list ------------------------>
import Grid from "@material-ui/core/Grid";
import styled from "styled-components";
import  Container  from '@material-ui/core/Container';
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import isEmpty from "lodash/isEmpty";
import VCard from "vcard-creator";

//API
import { editProfile as editProfileAPI } from "../../../../store/restApi/api";






const Attendee = () => {

    const { eventConfig } = useStoreState((state) => state.state);
    const event_id= eventConfig?.data?.[0]?.event_id;
    const event_title= eventConfig?.data?.[0]?.event_title;
    const event_code= eventConfig?.eventCode;
    const [attendeeData, setAttendeeData] = useState(undefined);
    const [attendeeCard, setAttendeeCard] = useState(undefined); 
    const [loader, setLoader] = useState(undefined);
    const [attendee_id] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("attendee_id")
    );
   


    const Overall = styled.div`
    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    display: flex;
    min-width: 100%;
    min-height: 100%;
    align-items:center;
    justify-content:center;
    flex-direction:column;
    font-family: 'Poppins', sans-serif;
    
    `
    const Top = styled.div`
    display:flex;
    width:100%;
    flex-direction:column;
    padding: 1%;
    align-items:center;
    background-color: #fe0056;


    `
    const Combined = styled.div`
      display:flex;
      flex-direction:column;
      width: 20%;
      box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
      
      @media(max-width:1024px){
        width:30%;
        
    }
    @media(max-width:425px){
        width:60%;
    }
    
    `
    const AttendeeimgOverall = styled.div`
    display:flex;
    border-radius:50%;
    width:100px;
    height:100px;
    margin:20px;
    background-color:white;

    @media(max-width:1024px){
        width:40px;
        height:40px
    }

    `
    let Attendeeimg = styled.img.attrs({
        src: `${((typeof attendeeData!="undefined")?attendeeData.profile_image:'')}`,        
      })`
    width:100px;
    height:100px;
    border-radius:50%;

    @media(max-width:1024px){
        width:40px;
        height:40px;
    }

    `

    const Attendeename = styled.div`
    display:flex;
    color:white;
    font-weight:600;
    font-size:25px;
    padding: 5px 0;

    @media (max-width:1024px){
        font-size:10px;

    }
    `
    const AttendeePosition = styled.div`
    display:flex;
    color:white;
    font-weight:300;
    font-size:15px;
    padding: 5px 0;
    margin-bottom: 5px;

    @media (max-width:1024px){
        font-size:8px;

    }
    `


    const Bottom = styled.div`
    display:flex;
    flex-direction:column;
    width:100%;
   
    background-color:#eaeaea;
    justify-content:center;
    align-items:center;
    padding:3%;
    `
    const InfoPanel = styled.div`
    display: flex;
    flex-direction:column;
    
    width:80%;
    margin-top:10px;
    border-radius:10px;
    

    @media (max-width:1024px){
        font-size:8px;
    }


    `
    const InfoTab = styled.div`
    display: flex;
    width: 100%;
    justify-content:left;
    height: auto;
    padding: 5px;
    border-bottom: 2px solid lightgrey;
    
    `
    const InfoTabValue = styled.div`
     display: flex;
     flex-direction:row;
     word-wrap:anywhere;
     justify-content:left;
     font-size:15px;
     align-items:center;
     color:#696969 ;
     flex:4;


     @media(max-width:1024px){
      font-size:10px;
       
    }
    `
    const InfoIcon = styled.div`
    display:flex;
    flex:1;
    width:100%;
    height:auto;
    align-items:center;
    justify-content:left;
    `
    const ButtonPanel = styled.div`
      display:flex;
      flex-direction:column;
      width:auto;
      padding:15px;
      margin: 15px 0 ;
      border-radius:5px;
      align-items:center;
      background-color:#fe0056;
      color:#ffffff;
      cursor:pointer;
      font-size:15px;
      margin-top:%;
      -webkit-box-shadow: 0 3px 15px -8px black;
  	   -moz-box-shadow: 0 3px 15px -8px black;
  	        box-shadow: 0 3px 15px -8px black;

            @media(max-width:1024px){
              font-size:8px;
            }
    `





    let payload={
        user_id:attendee_id,
        event_id:event_id,
        method:'display_details'
    };
    useEffect(()=>{  
        editProfileAPI(payload)?.then((response) => {               
            setAttendeeData(response.Attendee_profile_details[0]);
         });
    },[]);


     useEffect(() => {
        if(typeof attendeeData!="undefined")
        {
            if(attendeeData.attendee_first_name!=null)
            {
            setLoader(undefined);   
            
            const tempAttendeeVCard = new VCard();
            tempAttendeeVCard
                .addName(
                    attendeeData?.attendee_first_name,
                    attendeeData?.attendee_last_name
                )
                .addCompany(attendeeData?.company)
                .addJobtitle(attendeeData?.attendee_designation)
                .addPhoneNumber(attendeeData?.attendee_phone_num)
                .addPhotoURL(attendeeData?.profile_image)
                .addEmail(attendeeData?.attendee_websiteurl)
                //.addURL(businessCard[0]?.attendee_websiteurl);            
                setAttendeeCard(tempAttendeeVCard.toString());           
             
            }
            else{
                setLoader(true);
            }
            console.log(attendeeData);
        }  
        else
        {
            setLoader(true);
        }    
        
    }, [attendeeData]);

    
    const downloadAttendeeVCard = () => {     
        const element = document.createElement("a");
        const file = new Blob([attendeeCard], {
            type: "text/plain",
        });
        element.href = URL.createObjectURL(file);
        element.download = "Card.vcf";
        document.body.appendChild(element); 
        element.click();
    }; 



    return (
   <>
      
        {(loader)?(
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}
                direction="column"
            >
                <Grid item className="h4 text-center p-2">
                    No Data Found
                </Grid>
            </Grid>
        ):(
        <Overall>
            {(typeof attendeeData!="undefined")?(
                <>
            <Combined>
            <Top>
               <AttendeeimgOverall>
                   {/* <img src={attendeeData?.profile_image} width="25" height="25"/> */}
                   <Attendeeimg src={attendeeData.profile_image}/>
               </AttendeeimgOverall>
                    <Attendeename> 
                      {(attendeeData.attendee_first_name).charAt(0).toUpperCase() + attendeeData.attendee_first_name.slice(1)+" "+ (attendeeData.attendee_last_name).charAt(0).toUpperCase() + attendeeData.attendee_last_name.slice(1)} 
                      
                    </Attendeename>
                        <AttendeePosition> 
                          {(attendeeData.attendee_designation).charAt(0).toUpperCase() + attendeeData.attendee_designation.slice(1)} 
                        </AttendeePosition>
                        </Top>
                        <Bottom>
                        <InfoPanel>
                            <InfoTab>
                                <InfoIcon><CallIcon style={{color:"#fe0056" , marginRight:"2%" , marginLeft:"1%" , width:"30px" , height:"30px"}}/></InfoIcon>
                                <InfoTabValue>
                                {attendeeData.attendee_phone_code+" "+attendeeData.attendee_phone_num}
                                </InfoTabValue>
                            </InfoTab>
                            <InfoTab>
                                <InfoIcon><MailOutlineIcon style={{color:"#fe0056", marginRight:"2%" , marginLeft:"1%" , width:"30px " , height:"30px "}}/></InfoIcon>
                                <InfoTabValue>
                                {attendeeData.attendee_websiteurl}
                                </InfoTabValue>
                            </InfoTab>
                            <InfoTab>
                                <LocationOnIcon style={{ color:"#fe0056", marginRight:"2%" , marginLeft:"1%" , width:"30px" , height:"30px"}}/>
                                <InfoTabValue>
                                    
                                </InfoTabValue>
                            </InfoTab>

                        </InfoPanel>
                        <ButtonPanel>
                          <span onClick={()=>downloadAttendeeVCard()}>Download Vcard
                          </span>
                        </ButtonPanel>
                        <img src={attendeeData.qrcode_link} height="50" width="50"/>
                        </Bottom>
                        </Combined>
                        </>
            ):(null)}

                      </Overall>
        )}

     
      </>
        );
};
export default Attendee;
// 