/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useStoreActions, useStoreState } from "easy-peasy";
import "./userList.css";
import { baseUrl } from "../../../store/restApi/api";
// import Pagination from "./Pagination";
import User from "./User";
import LogoSpinner from "../../common/LogoSpinner";
import { Api_Key } from "../../../App";
import { userList as userListApi } from "../../../store/restApi/api";
import BreakoutRoom from "./BreakoutRoom";
import network_ico from "../../../assets/network lounge-ico.png";
import { CullFaceBack } from "three";
import {
    remove_member as removeAPI,
    breakOutRoom as room_id_API,
} from "../../../store/restApi/api";
import useWindowSize from "./useWindowSize";
import email from "../../../assets/email.png";
import User1 from "./User";
import "./user.css";
function Loading() {
    return (
        <div className="loader">
            <LogoSpinner className="spinner" />
        </div>
    );
}

const UserList = (props) => {
    const ref = useRef(null);
    const width = useWindowSize();
    const userId = props.match.params.userId;
    const eventId = props.match.params.eventId;
    const [users, setUsers] = useState([]);
    const [link, setLink] = useState("");
    const [displaymsg, setDisplayMsg] = useState("");
    const [error, setError] = useState("");
    const [recordloading, setRecordloading] = useState(true);
    const [hello, setHello] = useState([]);
    const [dataLimit, setDataLimit] = useState(10000);
    const [viewList, setViewList] = useState("people");

    const { eventConfig, room_det, userDetails, joinus_status } = useStoreState(
        (state) => state.state
    );

    const { updateState } = useStoreActions((state) => state.state);

    useEffect(() => {
        room_id_API({
            event_id: eventConfig?.data?.[0]?.event_id,
        }).then((res) => {
            updateState({ room_det: res?.[0]?.breakout_room_list });
            setLink(res?.[0]?.breakout_room_list);
        });
    }, []);
    const reload_fn = (room_id) => {
        // eslint-disable-next-line no-lone-blocks
        {
            room_det.map((res, index) => {
                let payload = {
                    event_id: eventConfig?.data?.[0]?.event_id,
                    room_id: res?.breakout_room_id,
                    participants: userDetails?.id,
                };
                removeAPI(payload).then((res) => {
                    updateState({ joinus_status: false });
                    // setUser(res?.data);
                    return res?.data;
                });
            });
        }
    };

    const Confirm = () => {
        if (joinus_status === true) {
            if (window.confirm("Are You Sure You Want to Leave Meeting?")) {
                reload_fn();
            } else {
                setViewList("breakoutroom");
            }
        }
    };
    useEffect(() => {
        let payload = { user_id: userId, event_id: eventId };
        userListApi(payload)
            .then((user) => {
                // console.log(user)
                setUsers(user?.data);
                setDisplayMsg(
                    user?.data?.length > 0 ? "" : "No users to display"
                );

                setRecordloading(false);
            })
            .catch((error) => setError(error.message));
    }, []);

    if (error) return <h1>{error}</h1>;

    return (
        <>
            <ul
                className="nav nav-pills nav-fill  networking userList-users-header"
                // style={{ position: "static", width: "90%" }}
            >
                <li className="nav-item">
                    <div></div>
                </li>
            </ul>

            {joinus_status !== true ? (
                <>
                    <ul className="nav nav-pills nav-fill userList-users-header position">
                        <li className="nav-item ">
                            <a
                                style={{
                                    backgroundColor: `${
                                        viewList == "people"
                                            ? "#0739ff"
                                            : "#d7d7d7"
                                    }`,
                                    color: "rgb(13, 13, 13)",
                                }}
                                className={`nav-link text-white${
                                    viewList.localeCompare("people")
                                        ? "active"
                                        : ""
                                }`}
                                onClick={() => {
                                    setViewList("people");
                                    Confirm();
                                }}
                            >
                                PEOPLE
                            </a>
                        </li>

                        {eventConfig?.data?.[0]?.breakout_room_is_enabled !=
                            "No" && typeof link !== "undefined" ? (
                            <li className="nav-item ">
                                <a
                                    style={{
                                        backgroundColor: `${
                                            viewList == "breakoutroom"
                                                ? "#0739ff"
                                                : "#d7d7d7"
                                        }`,
                                        color: "rgb(13, 13, 13)",
                                    }}
                                    className={`nav-link text-white${
                                        viewList.localeCompare("breakoutroom")
                                            ? "active"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        setViewList("breakoutroom");
                                    }}
                                >
                                    BREAKOUT ROOMS
                                </a>
                            </li>
                        ) : null}
                    </ul>
                </>
            ) : null}
            {viewList.localeCompare("people") == 0 ? (
                <>
                    {/*---------------------------------------------UserList Component--------------------------------------------*/}
                    {recordloading && Loading()}
                    <>
                        {/* <div className="d-flex mb-1 rounded-1 " style={{ cursor: "pointer" }}> */}
                        {/* <div className="mtulitgrid"> */}
                        {/* <div style={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gridGap: '2rem', padding: '1rem', marginLeft:'7%' }}> */}
                        <div className="parent">
                            <section class="categoryproduct">
                                <div class="container-fluid">
                                    <div class="row">
                                        {users != null ? (
                                            <>
                                                {users.map((user, index) => (
                                                    <User1
                                                        key={index}
                                                        data={user}
                                                    />
                                                ))}
                                            </>
                                        ) : (
                                            <p
                                                style={{
                                                    fontSize: "20px",
                                                    width: "100%",
                                                    textAlign: "center",
                                                    fontWeight: "600",
                                                    margin: 0,
                                                    padding: "5px",
                                                }}
                                            >
                                                {displaymsg}
                                            </p>
                                        )}
                                    </div>
                                </div>
                            </section>
                        </div>
                        {/* </div> */}

                        {/* </div> */}
                    </>
                    {/*---------------------------------------------UserList Component--------------------------------------------*/}
                </>
            ) : (
                <BreakoutRoom />
            )}
        </>
    );
};

export default UserList;
