import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "reactstrap";
import "../../../index.css";
import "typeface-montserrat";
import { Link } from "react-router-dom";
import useStyles from "./Login.style";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Typography } from "@material-ui/core";
import LoginRegisterSidebar from "../loginRegisterSiderbar/LoginRegisterSidebar";
import "./loginstyle.css";
import { divide } from "lodash";
import { pubnubUtilty } from "../../../pubNub/utils/PubNubUtilty";
import { serverApi } from "../../../store/restApi/serverApi";

const Login = (props) => {
    const [errors, setErrors] = useState("");
    // const [validateState, setValidate] = useState(false);

    // const [response, setResponse] = useState("");
    const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { updateState } = useStoreActions((state) => state.state);
    const { updateState: pubNubUpdateState } = useStoreActions((state) => state.pubNub);
    let { isLoggedIn, eventConfig, userDetails } = useStoreState(
        (state) => state.state
    );
    document.querySelector("title").innerText =
        eventConfig?.data?.[0]?.event_title;
    const { login } = useStoreActions((state) => state.state);
    const { loginUUID, subscribeToChannelsActions } = useStoreActions(
        (state) => state.pubNub
    );
    const { pubnubstate } = useStoreState((state) => state.pubNub);
    const [state, setState] = useState({
        username: "",
        password: "",
    });
    const [pageChange, setPageChange] = useState(true);
    const regUrl = eventConfig?.data?.[0]?.attendee_registration_url;
    // const regUrl = "https://dev-app-2.hexafair.com/attendee-registration-mobile/?event_code=IAjOSXYu";
     console.log(regUrl)

    useEffect(() => {
        const listener = (event) => {
            if (event.code === "Enter" || event.code === "NumpadEnter") {
                //console.log("Enter key was pressed. Run your function.");
                event.preventDefault();
                validate();
                submitHandler();
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    });

    useEffect(() => {
        setState({
            username: "",
            password: "",
        });
    }, []);
    useEffect(() => {
        if (isLoggedIn) {
            if (props?.location?.from) {
                props.history.push(props.location.from);
            } else props.history.push("/");
        }
    }, [isLoggedIn]);

    function validate(response) {
        //console.log("claed", response);
        let temp = { ...errors };
        temp.username =
            state.username.length == 0
                ? "This field is required"
                : emailPattern.test(state.username) === false
                ? "Invalid Email"
                : response === "Invalid Email"
                ? "Invalid Email"
                : null;
        temp.password =
            state.password.length == 0
                ? "This field is required"
                : response === "Invalid Password"
                ? "Invalid Password"
                : null;
        if (temp.username != null || temp.password != null) {
            setErrors({ ...temp });
            return false;
        } else if (temp.username === null && temp.password === null) {
            return true;
        }
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    }
    
    const isPubNubUserExist =   (status, res,userId) => {
        if (status?.error === true) {
            let payload = {
                event_id: eventConfig?.data?.[0]?.event_id,
                user_id: userId.toString(),
            };
            serverApi
                .getChatMembersDetailsById(payload)
                .then(({ data }) => {
                    pubnubUtilty.createUsersV2(data[0],pubnubstate,(status, ress) => {
                        if (status?.error === false) {
                            pubNubUpdateState({ loggedInUser: ress?.data });
                        }
                       
                    });
                })
                .catch((err) => {
                    console.log(err);
                });
        }else {
            pubNubUpdateState({ loggedInUser: res?.data });
        }
    }

    const submitHandler = (e) => {
        if (validate()) {
            login({
                username: state.username,
                password: state.password,
                //event_id: "3",
                event_id: eventConfig?.data?.[0]?.event_id,
            }).then((response) => {
                if (response.status == "OK") {
                    updateState({
                        isLoggedIn: true,
                        userDetails: response.user,
                        instructionPopupShow: true,
                    });
                    loginUUID(response.user?.id);
                    subscribeToChannelsActions([response.user?.id]);
                    
                    pubnubUtilty.getUsersDetailsV2(response.user?.id,pubnubstate,(statu,res) => isPubNubUserExist(statu,res,response.user?.id));
                } else if (response.Message == "Invalid password!") {
                    validate("Invalid Password");
                } else if (response.Status == "error") {
                    alert(response.Message);
                }
            });
        }
    };    
    return (
        <>
            <div className="login-overall">
                <div className="login-eventinfo">
                    <div
                        style={{
                            backgroundColor: "#0739ff",
                            padding: "55% 15px",
                        }}
                    >
                        <LoginRegisterSidebar />
                    </div>
                </div>
                <div className="login-content">
                    <div className="login-content-logo">
                        <img
                            width="100px"
                            height="50px"
                            src={eventConfig?.data?.[0]?.event_logo}
                        />
                    </div>

                    {pageChange ? (
                        <>
                            <div className="login-content-main">
                                <div className="login-content-sub">
                                    <div className="login-content-maintext-title">
                                        <div className="login-content-maintext-titletext">
                                            Sign In
                                        </div>
                                    </div>
                                    <div className="login-content-maintext-desc">
                                        <div className="login-content-maintext-desctext">
                                            Let's login to your personal account
                                        </div>
                                    </div>

                                    <div className="login-content-maincontent">
                                        <div className="login-content-maincontent-wrapper">
                                            <div className="login-content-inputname">
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Email *
                                                </span>
                                            </div>

                                            <input
                                                type="text"
                                                className="login-content-inputbox"
                                                placeholder="Enter your Email"
                                                id="loginEmail"
                                                onChange={handleChange}
                                                name="username"
                                                required
                                            />

                                            <div className="login-content-errors">
                                                {errors?.username != "" ? (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            textAlign: "left",
                                                            fontSize: "11px",
                                                        }}
                                                    >
                                                        {errors?.username}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                        <div className="login-content-maincontent-wrapper">
                                            <div className="login-content-inputname">
                                                <span
                                                    style={{
                                                        fontWeight: "bold",
                                                    }}
                                                >
                                                    Password *
                                                </span>
                                            </div>

                                            <input
                                                type="password"
                                                className="login-content-inputbox"
                                                placeholder="Enter your Password"
                                                id="loginPassword"
                                                required
                                                name="password"
                                                onChange={handleChange}
                                            />

                                            <div className="login-content-errors">
                                                {errors?.password != "" ? (
                                                    <div
                                                        style={{
                                                            color: "red",
                                                            textAlign: "left",
                                                            fontSize: "11px",
                                                        }}
                                                    >
                                                        {errors?.password}
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="login-content-bottomtext">
                                        <div className="login-content-bottomtext-forgotpass">
                                            <span
                                                style={{
                                                    color: "#1500fa",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => {
                                                    props.history.push(
                                                        "/forgetpassword"
                                                    );
                                                }}
                                            >
                                                Forgot Password?
                                            </span>
                                        </div>

                                        <div
                                            className="login-content-bottomtext-button"
                                            onClick={submitHandler}
                                        >
                                            <div className="login-content-bottomtext-button-wrapper">
                                                <span
                                                    style={{
                                                        color: "white",
                                                        textAlign: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    Sign In
                                                </span>
                                            </div>
                                        </div>

                                        <div className="login-pageswitch-wrapper">
                                            <span
                                                style={{
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                            >
                                                {" "}
                                                Don't have an account yet?
                                                &nbsp;
                                            </span>
                                            <span
                                                style={{
                                                    color: "#1500fa",
                                                    cursor: "pointer",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                }}
                                                onClick={() => {
                                                    setPageChange(false);
                                                }}
                                            >
                                                {" "}
                                                {`Register here`}{" "}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                    width: "100%",
                                }}
                            >
                                <br />
                                <div
                                    onClick={() => {
                                        setPageChange(true);
                                    }}
                                    style={{
                                        display: "flex",
                                        width: "max-content",
                                        alignItems: "center",
                                        padding: "0 5%",
                                        cursor: "pointer",
                                        height: "max-content",
                                    }}
                                >
                                    <button
                                        className="btn lg"
                                        style={{
                                            display: "flex",
                                            backgroundColor: "rgb(7, 57, 255)",
                                            width: "100%",
                                            borderRadius: "1%",
                                            padding: "5px",
                                            color: "rgb(250,250,250)",
                                            marginTop: "5px",
                                        }}
                                    >
                                        {" "}
                                        <ArrowBackIcon
                                            style={{
                                                color: "GrayText",
                                                fontSize: "100%",
                                                margin: "auto",
                                            }}
                                        />
                                        <a style={{ fontSize: "100%" }}>
                                            Login
                                        </a>
                                    </button>
                                </div>
                            </div>

                            <div className="embed-responsive embed-responsive-1by1">
                                <form type="submit" method="post">
                                    <iframe
                                        className="embed-responsive-item embed-responsive"
                                        title="reg"
                                        seamless="seamless"
                                        position="relative"
                                        scrollbehavior="smooth"
                                        width="100vw"
                                        height="200vh"
                                        scrolling="yes"
                                        sandbox="allow-forms allow-same-origin allow-scripts"
                                        src={regUrl}
                                    ></iframe>
                                </form>
                            </div>
                            {/* <div>
                                <iframe title='reg' seamless="seamless" position="relative" scrollbehavior="smooth" width="100vw" height="200vh" scrolling='yes' src={regUrl}></iframe>
                            </div> */}
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default Login;
