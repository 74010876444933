import React, { useState, useEffect } from "react";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CoursesModal from "./Modals/CoursesModal";
import ProductsModal from "./Modals/ProductsModal";
import DocumentsModal from "./Modals/DocumentsModal";
import JobsModal from "./Modals/JobsModal";
import VideosModal from "./Modals/VideosModal";
import EmailModal from "./Modals/EmailModal";
import ExhibitorInfoModal from "./Modals/ExhibitorInfoModal";
import BusinessCardModal from "./Modals/BusinessCardModal";
import InstructionsModal from "./Modals/InstructionsModal";
import ContactUsModal from "./Modals/ContactUsModal";
import CenteredSpinner from "./common/CenteredSpinner";
import Button from "../components/common/Button";
// import ExhibitorsListModal from "./";
import InfoIcon from "@material-ui/icons/Info";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import PhotoLibraryIcon from "@material-ui/icons/PhotoLibrary";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import ThreeDRotationIcon from '@material-ui/icons/ThreeDRotation';
import AppointmentModal from "./Modals/AppointmentModal";
import AcknowledgementModal from "./Modals/AcknowledgementModal";
import SessionsModal from "./Modals/SessionsModal";
import SessionVideoModal from "./Modals/SessionVideoModal";
import ProductEnquiryModal from "./Modals/ProductEnquiryModal";
import TawkToModal from "./Modals/TawkToModal.js";
import { isEmpty } from "lodash";

const productsModalTabs = [
    { icon: <InfoIcon />, tabName: "info" },
    { icon: <InsertDriveFileIcon />, tabName: "documents" },
    { icon: <PlayCircleOutlineIcon />, tabName: "videos" },
    { icon: <PhotoLibraryIcon />, tabName: "gallery" },
    { icon: <Rotate90DegreesCcwIcon />, tabName: "zoom" },
];

const dialogStyles = (theme) => ({
    mainDialog: {
        "& .MuiPaper-root": {
            maxWidth: "90vw",
            zIndex: "99999991",
            // minHeight: "80vh",
            // [theme.breakpoints.up("sm")]: {
            //     width: "52vw",
            // },
            // [theme.breakpoints.up("md")]: {
            //     width: "42vw",
            // },
            [theme.breakpoints.up("lg")]: {
                maxWidth: "80vw",
                overflowY: "unset !important",
            },
            margin: "auto",
            overflowY: "unset !important",
        },
    },
    buttonContainer: {
        position: "absolute",
        top: "-20px",
        right: "-20px",
        pointer: "cursor",
    },
    productModalTabsContainer: {
        position: "absolute",
        top: "0px",
        left: "-50px",
        pointer: "cursor",
        display: "flex",
        flexDirection: "column",
    },
    productModalTabs: {
        background: "white",
        padding: 10,
        color: "#1c1d1f",
        marginBottom: 5,
        "&:hover": {
            opacity: 1,
            color: "#1c1d1f",
            background: "#ddd",
        },
    },
    activeProductsModalTab: {
        color: "#1c1d1f",
        background: "#ddd",
    },
    customPositionedDialog: {
        position: "absolute",
        bottom: 75,
        // left: 50,
        padding: 0,
    },
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);

const CustomizedDialogs = (props , productAll , setProductAll) => {
    const { handleBCOpenContactUsModal, handleModalClose, chatClose } = props;

    const [showProductsModalTabs, setShowProductsModalTabs] = useState(false);
    const [activeProductsModalTab, setActiveProductsModalTab] =
        useState("info");
    const [modalOpen, setModalOpen] = useState(false);
    const [enabledProductsTabs, setEnabledProductsTabs] = useState({
        info: true,
        videos: true,
        documents: true,
        gallery: true,
        zoom: true,
    });
    return (
        <Dialog
            onClose={props.close}
            aria-labelledby="customized-dialog-title"
            open={props.show}
            maxWidth="xl"
            className={props.classes.mainDialog}
            classes={{
                paper: props?.customPosition
                    ? props.classes?.customPositionedDialog
                    : "",
            }}
            style={{
                zIndex: "1301",
            }}
        >
            <DialogContent
                dividers
                style={{
                    background: props.dark ? "black" : "#fff",
                    color: props.dark ? "white" : "#1c1d1f",
                }}
            >
                 {/* {props?.exhibitorsList ? (
                    <ExhibitorsListModal {...props} /> ) : null} */}
                
                {props.videoData && props.videoData.length > 0 ? (
                    <VideosModal
                        videoData={props.videoData}
                        showVideoPlayer={props?.showVideoPlayer}
                        setVideoSrc={props?.setVideoSrc}
                        activeVideo={props?.activeVideo}
                        focussedVideosStartIndex={
                            props?.focussedVideosStartIndex
                        }
                        setFocussedVideosStartIndex={
                            props?.setFocussedVideosStartIndex
                        }
                    />
                ) : null}
                {props.type == "video" && !props.videoData ? (
                    <CenteredSpinner />
                ) : null}
                

                {props.documentData && props.documentData.length > 0 ? (
                    <DocumentsModal documentData={props.documentData} />
                ) : null}
                {props.type == "document" && !props.documentData ? (
                    <CenteredSpinner />
                ) : null}

                {props.description && props.description?.length > 0 ? (
                    <ExhibitorInfoModal
                        url={props.url}
                        title={props.title}
                        description={props.description}
                    />
                ) : null}
                {props.type == "exhibitor" && !props.description ? (
                    <CenteredSpinner />
                ) : null}

                {props.businessCard ? (
                    <BusinessCardModal
                        businessCard={props.businessCard}
                        handleOpenEmailModal={handleBCOpenContactUsModal}
                        handleModalClose={() => {
                            handleModalClose();
                        }}
                        chatOpen={chatClose}
                    />
                ) : null}
                {props.businessCard &&
                Object.keys(props.businessCard).length == 0 ? (
                    <CenteredSpinner />
                ) : null}

                {props.email ? <EmailModal /> : null}

                {props.jobsData && props.jobsData.length > 0 ? (
                    <JobsModal jobsData={props.jobsData} />
                ) : null}
                {props.jobsData?.length == 0 ? <CenteredSpinner /> : null}
                {props.productsData && props.productsData.length > 0 ? (
                    <ProductsModal
                        productsData={props.productsData}
                        setShowProductsModalTabs={setShowProductsModalTabs}
                        activeProductsModalTab={activeProductsModalTab}
                        setEnabledProductsTabs={setEnabledProductsTabs}
                        enabledProductsTabs={enabledProductsTabs}
                        initialIndex={
                            props.initialIndex ? props.initialIndex : 0
                        }
                        initialDisplayMode={
                            props.initialDisplayMode
                                ? props.initialDisplayMode
                                : "overview"
                        }
                        // activeIndex={activeProductIndex}
                        // setActiveIndex={setActiveProductIndex}
                    />
                ) : null}
                {props.productsData?.length == 0 ? <CenteredSpinner /> : null}

                {props.coursesData && props.coursesData.length > 0 ? (
                    <CoursesModal coursesData={props.coursesData} />
                ) : null}
                {props.coursesData?.length == 0 ? <CenteredSpinner /> : null}
                {props.instructions ? (
                    <InstructionsModal handleModalClose={handleModalClose} />
                ) : null}
                {props.sessionsList ? (
                    <SessionsModal
                        // modalOpen
                        // handleModalClose={() => {
                        //     console.log("close this modal");
                        //     handleModalClose();
                        //     // setCloseModalProgramatically(true);
                        // }}
                        sessionsList={props?.sessionsList}
                        handleModalClose={handleModalClose}
                    />
                ) : null}
                {props.appointment ? (
                    <AppointmentModal url={props.url} />
                ) : null}
                {props.contactUs ? (
                    <ContactUsModal
                        modalOpen
                        handleModalClose={() => {
                            // console.log("close this modal");
                            handleModalClose();
                            // setCloseModalProgramatically(true);
                        }}
                    />
                ) : null}
                {props.productEnquiry ? (
                    <ProductEnquiryModal
                        productId={props?.productId}
                        handleModalClose={() => {
                            // console.log("close this modal");
                            handleModalClose();
                            // setCloseModalProgramatically(true);
                        }}
                    />
                ) : null}
                {props.acknowledgement ? (
                    <AcknowledgementModal
                        status={props.status}
                        message={props.message}
                        loading={props.loading}
                    />
                ) : null}
                {props.sessionVideo ? (
                    <SessionVideoModal videoUrl={props?.videoUrl} />
                ) : null}
                {props.qrEmail ? (
                    <EmailModal
                        selected={props?.selected}
                        handleModalClose={props?.handleModalClose}
                    />
                ) : null}
                {props.tawkToChatOpen && !isEmpty(props.tawkToCode) ? (
                    <TawkToModal
                        handleModalClose={handleModalClose}
                        tawkToCode={props.tawkToCode}
                    />
                ) : null}
            </DialogContent>
            <DialogActions style={{ padding: 0 }}>
                {props.productsData &&
                props.productsData.length &&
                showProductsModalTabs > 0 ? (
                    <div className={props.classes.productModalTabsContainer}>
                        {productsModalTabs.map((tab, i) => {
                            // console.log("tab", tab, activeProductsTabs[tab.tabName])
                            // if(enabledProductsTabs[tab.tabName]) {
                            if (enabledProductsTabs[tab.tabName])
                                return (
                                    <IconButton
                                        key={tab.name}
                                        className={
                                            "rounded " +
                                            props.classes.productModalTabs +
                                            (tab.tabName ==
                                            activeProductsModalTab
                                                ? " " +
                                                  props.classes
                                                      .activeProductsModalTab
                                                : "")
                                        }
                                        onClick={() => {
                                            setActiveProductsModalTab(
                                                tab.tabName
                                            );
                                        }}
                                    >
                                        {tab.icon}
                                    </IconButton>
                                );
                        })}
                    </div>
                ) : null}
                <Button
                    customIconContainer={props.classes.buttonContainer}
                    OpenIcon={CloseIcon}
                    handleClick={(event) => {
                        setShowProductsModalTabs(false);
                        handleModalClose();
                    }}
                ></Button>
            </DialogActions>
        </Dialog>
    );
};

export default withStyles(dialogStyles)(CustomizedDialogs);
