import React, { Suspense, useCallback, useEffect, useState } from "react";
import * as THREE from "three";
import * as TWEEN from "tween.js";
import { useLoader, useThree, useFrame} from "@react-three/fiber";
import { useAspect } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Html } from "@react-three/drei";
import Vimeo from "@u-wave/react-vimeo";
// import MuxPlayer from "./Livestreaming/Mux/muxLivestream";
import sintelimage from "../assets/sintel1.png";
import { isEmpty } from "lodash";
import drag_img from "../assets/test.png";
import { PlainAnimator } from "three-plain-animator/lib/plain-animator";
import background from "../assets/2294472375_24a3b8ef46_o.jpg";
import info from "../assets/icon.jpg";
import { useHistory } from "react-router-dom";

import {
    getSponsorBannerList as getSponsorBannerListAPI,
    getSpeakersList as getSpeakersListAPI,
    getSponsorsLogo as getSponsorsLogoAPI,
    getEventConfig as getEventConfigAPI,
} from "../store/restApi/api";
import { useStoreActions,useStoreState } from "easy-peasy";


const params = {
    color: "#ffffff",
    scale: 0,
    flowX: 0.1,
    flowY: 0.1,
};
const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
    format: THREE.RGBFormat,
    generateMipmaps: true,
    minFilter: THREE.LinearMipmapLinearFilter,
    encoding: THREE.sRGBEncoding,
});
const cubeCamera = new THREE.CubeCamera(1, 100000, cubeRenderTarget);


const Booth = (props) => {
    
    //navigation declation section start
    const history = useHistory();
    const [rot_state, rotState] = useState(false);
    const { camera, gl, scene } = useThree();
    


    scene.add(cubeCamera);
    const {
        eventId,
        event_code,
        userDetails,
        eventConfig,
        glb,
        setGlb,
        helpdesk,
        videoElement,
        setVideoElement,
    } = props;
    const { updateState } = useStoreActions((state) => state.state);
    const { rotateDirection } = useStoreState((state) => state.state);
    document.querySelector("title").innerText =
        eventConfig?.data?.[0]?.event_title;
    let raycaster, intersects;
    let windowHalf = new THREE.Vector2(
        window.innerWidth / 2,
        window.innerHeight / 2
    );
    let mouse = new THREE.Vector2();
    let target = new THREE.Vector2();
    let dragging = false;
    raycaster = new THREE.Raycaster();
    const tar = React.useRef();
    const drag = React.useRef();
    const slide1 = React.useRef();
    const slide2 = React.useRef();
    const slide3 = React.useRef();
    /*get sponsor banner list*/
    const [appState, setAppState] = useState(null);
    const [speakerState, setSpeakerState] = useState(null);
    const [logoState, setLogoState] = useState(null);
    const [eventState, setEventState] = useState(null);
    let speakers_list_cou = 0;
    let sponsorlogo_list_cou = 0;

    let autoslide, sponsorlogo_slide;

    const drag_texture = useLoader(THREE.TextureLoader, drag_img);
    //navigation declation section end

    let url = props?.boothUrl || "";    
    const box = new THREE.Box3();

    const stall_ref = useCallback((node) => {}, []);
    THREE.Cache.enabled = true;

    useEffect(() => {
        if (isEmpty(glb) && !isEmpty(url)) {
            let loader = new GLTFLoader();
            loader.load(url, function (gltf) {
                setGlb(gltf);
                THREE.Cache.add("stallModal", gltf);
                //gltf.scene.visible=false; 
                gltf.scene.name="lobby_obj";               
                props.setLoading(false);
            });
        }
    }, [glb]);

    let tween;
    const texturePath = "https://i.imgur.com/Oj6RJV9.png";
    const animator = new PlainAnimator(
        new THREE.TextureLoader().load(drag_img),
        4,
        4,
        10,
        15
    );
    const animation_texture = animator.init();
    //naviagtion work section start

    const Cam_settings = () => {
        // if (helpdesk != null) {
        //     camera.fov = 45;
        //     camera.aspect = window.innerWidth / window.innerHeight;
        //     camera.near = 1;
        //     camera.far = 50000;
        //     camera.position.x = 10;
        //     camera.position.y = 5;
        //     camera.position.z = 40;
        //     camera.rotation.set(0, 0, 0);
        //     camera.updateProjectionMatrix();
        // }
        // else {
            camera.fov = 45;
            camera.aspect = window.innerWidth / window.innerHeight;
            camera.near = 1;
            camera.far = 50000;
            camera.position.x = 7.834710597991943;
            camera.position.z = 155;
            camera.position.y = 10;
            camera.rotation.set(0, 0, 0);
            camera.updateProjectionMatrix();
       // }
        return null;
    };
    const Event_listener = () => {
        setUpMouseHander(gl.domElement, doMouseMove, doMouseMove);
        document.addEventListener("mousemove", HandleMouseMove);
        
        //document.getElementById("helpdesk").addEventListener("click", showHelpDesk);
            //document.getElementById("rotate").addEventListener('click', rotate_firstclick);           
            // document.getElementById("lobby").addEventListener("click", showLobby);
        return null;
    };
    const rotate_firstclick = () =>{
        rotState(true); 
    }
    const Drag_plane = () => {
        return (
            <>
                <mesh
                    ref={drag}
                    rotation-x={Math.PI * -0.5}
                    position-z={120}
                    position-y={1.5}
                    position-x={8}
                    name={"dragplane"}
                    visible={false}
                    emissiveIntensity={5}
                >
                    <planeBufferGeometry args={[6, 6]} />

                    <meshStandardMaterial
                        map={animation_texture}
                        transparent={true}                     
                    />
                </mesh>
                <mesh ref={tar} name={"target"} position-y={1}>
                    <boxBufferGeometry args={[500, 1.3, 500]} />
                    <meshStandardMaterial color={"red"} visible={false} />
                </mesh>               
            </>
        );
    };
//     const showHelpDesk =() =>{
//         camera.fov = 45;
//         camera.aspect = window.innerWidth / window.innerHeight;
//         camera.near = 1;
//         camera.far = 50000;
//         camera.position.x = 10;
//         camera.position.y = 5;
//         camera.position.z = 40;
//         camera.rotation.set(0, 0, 0);
//         camera.updateProjectionMatrix();
// };
const showLobby = () =>{
        camera.fov = 45;
        camera.aspect = window.innerWidth / window.innerHeight;
        camera.near = 1;
        camera.far = 50000;
        camera.position.x = 7.834710597991943;
        camera.position.z = 155;
        camera.position.y = 10;
        camera.rotation.set(0, 0, 0);
        camera.updateProjectionMatrix();
};

useEffect(() => {
    Cam_settings();  
},[]);   
    useEffect(() => {
        if(rot_state==false)
        {
            rotateObjectfn(0);             
        }
        else
        {
            rotateObjectfn(rotateDirection);
        }  
    });    

    const rotateObjectfn = (rotateDir) =>{     
        if(rotateDir==0)
        {
            camera.rotation.y = 0;
        }
        else if(rotateDir==1)
        {
            camera.rotation.y = 1.5707963267948966;
        }
        else if(rotateDir==2)
        {
            camera.rotation.y = 3.141592653589793;            
        }
        else if(rotateDir==3)
        {
            camera.rotation.y = -1.5707963267948966;
        }  
        camera.updateProjectionMatrix();           
    };
    const HandleMouseMove = (event) => {
        if (event.which == 3) {
            mouse.x = event.clientX - windowHalf.x;
            mouse.y = event.clientY - windowHalf.x;
            target.x = (1 - mouse.x) * 0.01;
            camera.rotation.y += 0.05 * (target.x - camera.rotation.y);
        }
    };

    function doMouseMove(x, y, evt, prevX, prevY) {
        var a = (2 * x) / gl.domElement.width - 1;
        var b = 1 - (2 * y) / gl.domElement.height;
        raycaster.setFromCamera(new THREE.Vector2(a, b), camera);
        intersects = raycaster.intersectObjects(scene.children, true);

        if (intersects.length == 0) {
            return;
        } else {
            if (intersects[0].object.name.localeCompare("target") == 0) {
                var locationX = intersects[0].point.x;
                var locationZ = intersects[0].point.z;
                var coords = new THREE.Vector3(locationX, 0, locationZ);
                a = Math.min(75, Math.max(-65, coords.x));
                b = Math.min(130, Math.max(-50, coords.z));
                drag.current.position.set(a, 1.5, b);
            }
        }
    }
    //desktop mouse event
    function setUpMouseHander(
        element,
        mouseDownFunc,
        mouseDragFunc,
        mouseUpFunc
    ) {
        if (
            !element ||
            !mouseDownFunc ||
            !(typeof mouseDownFunc == "function")
        ) {
            throw "Illegal arguments in setUpMouseHander";
        }
        if (typeof element == "string") {
            element = document.getElementById(element);
        }
        if (!element || !element.addEventListener) {
            throw "first argument in setUpMouseHander is not a valid element";
        }
        //var dragging = false;
        var startX, startY;
        var prevX, prevY;

        function doMouseDown(evt) {
            if (evt.which == 1) {
               
                //when click auidtorium mesh directly open (auditorium page)
                mouse.x = (evt.clientX / window.innerWidth) * 2 - 1;
                mouse.y = -(evt.clientY / window.innerHeight) * 2 + 1;
                raycaster.setFromCamera(mouse, camera);
                const intersects = raycaster.intersectObjects(
                    scene.children,
                    true
                );
                if (typeof intersects[0] != "undefined") {
                    if (
                        intersects[0].object.parent.name.localeCompare(
                            "auditorium"
                        ) == 0
                    ) {
                        document.getElementById("auditorium").click();
                    } else if (
                        intersects[0].object.parent.name.localeCompare(
                            "exhibition"
                        ) == 0
                    ) {
                        document.getElementById("exhibitors").click();
                    } 
                    
      
                
                    if(intersects[0].object.name.localeCompare('target')==0)
                    {
                        if (drag.current != null) {
                            //camera rotate value settings
                            if (parseFloat(camera.rotation.y) == 0) {
                            // if (parseFloat(drag.current.position.z) > -50) {
                                    camera.position.copy(drag.current.position);
                                    camera.position.y = 10;
                                    camera.position.z =
                                        parseFloat(camera.position.z) + 40;
                                    var t=new THREE.Vector3( drag.current.position.x, 10, (parseFloat(drag.current.position.z)+38) );	
                                    tween_zoom(t);
                            // }
                            } else if (
                                parseFloat(camera.rotation.y) == 1.5707963267948966
                            ) {
                                // if (
                                //     parseFloat(drag.current.position.x) > -65 &&
                                //     parseFloat(drag.current.position.x) < 75
                                // ) {
                                    camera.position.copy(drag.current.position);
                                    camera.position.y = 10;
                                    camera.position.x =
                                        parseFloat(camera.position.x) + 40;
                                    var t=new THREE.Vector3( (parseFloat(drag.current.position.x)+38), 10, drag.current.position.z );	
                                    tween_zoom(t);
                                //}
                            } else if (
                                parseFloat(camera.rotation.y) == -1.5707963267948966
                            ) {
                                // if (
                                //     parseFloat(drag.current.position.x) > -65 &&
                                //     parseFloat(drag.current.position.x) < 75
                                // ) {
                                    camera.position.copy(drag.current.position);
                                    camera.position.y = 10;
                                    camera.position.x =
                                        parseFloat(camera.position.x) - 40;
                                    var t=new THREE.Vector3( (parseFloat(drag.current.position.x)-38), 10, drag.current.position.z );	
                                    tween_zoom(t);
                            // }
                            } else if (
                                parseFloat(camera.rotation.y) == 3.141592653589793
                            ) {
                                //if (parseFloat(drag.current.position.z) > -50) {
                                    camera.position.copy(drag.current.position);
                                    camera.position.y = 10;
                                    camera.position.z =
                                        parseFloat(camera.position.z) - 40;
                                    var t=new THREE.Vector3( drag.current.position.x, 10, (parseFloat(drag.current.position.z)-38) );	
                                    tween_zoom(t);
                            // }
                            }
                            camera.updateProjectionMatrix();
                        }
                    }
                }
            }

            var r = element.getBoundingClientRect();
            var x = evt.clientX - r.left;
            var y = evt.clientY - r.top;
            prevX = startX = x;
            prevY = startY = y;
            document.addEventListener("mousemove", doMouseMove);
            document.addEventListener("mouseup", doMouseUp);
        }

        function doMouseMove(evt) {
            var r = element.getBoundingClientRect();
            var x = evt.clientX - r.left;
            var y = evt.clientY - r.top;
            mouseDragFunc(x, y, evt, prevX, prevY, startX, startY);
        }

        function doMouseUp(evt) {
            //camera settings
            if (
                (parseFloat(camera.rotation.y) < 0.7 &&
                    parseFloat(camera.rotation.y) >= 0) ||
                (parseFloat(camera.rotation.y) > -0.7 &&
                    parseFloat(camera.rotation.y) <= 0)
            ) {
                camera.rotation.y = 0;
            } else if (
                parseFloat(camera.rotation.y) > 0.7 &&
                parseFloat(camera.rotation.y) > 0 &&
                parseFloat(camera.rotation.y) < 2.1
            ) {
                camera.rotation.y = 1.5707963267948966;
            } else if (
                parseFloat(camera.rotation.y) < -0.7 &&
                parseFloat(camera.rotation.y) < 0 &&
                parseFloat(camera.rotation.y) > -2.1
            ) {
                camera.rotation.y = -1.5707963267948966;
            } else if (
                (parseFloat(camera.rotation.y) > 2.1 &&
                    parseFloat(camera.rotation.y) > 0) ||
                (parseFloat(camera.rotation.y) < -2.1 &&
                    parseFloat(camera.rotation.y) < 0)
            ) {
                camera.rotation.y = 3.141592653589793;
            }
            camera.updateProjectionMatrix();
        }
        element.addEventListener("mousedown", doMouseDown);
    }
    //
    function tween_zoom(to)
    {	
        var f=camera.position;
        var tween = new TWEEN.Tween(f).to(to, 2000); // duration of tweening is 0.5 second
        tween.onUpdate(function() {});
        tween.start();
    }
    //naviagtion work section end


    useFrame(() => {
        animator.animate();
        TWEEN.update();
        //cubeCamera.update(gl, scene);
    });

    useEffect(() => {
        if (eventId)
            getSponsorBannerListAPI({ eventId: eventId }).then((data) =>
                setAppState(data)
            );
    }, [eventId]);

   useEffect(() => {
        if (event_code) {
            getEventConfigAPI({ event_code: event_code }).then((data) =>{
                setEventState(data)
            });
        }
    }, [event_code]);

    useEffect(() => {
        //getSponsorBannerListAPI().then((data) => );
        if (!isEmpty(glb?.scene)) {
            //glb.scene.add(water);            
        }
    }, [glb]);

    useEffect(() => {
        if (eventState != null && !isEmpty(glb?.scene)) {
            //lobby video config
            var video = document.createElement("video");
            video.loop = true;
            video.crossOrigin = "anonymous";
            video.load();
            video.pause();
            video.src = eventState.event_details?.[0]?.event_video_url;
            setVideoElement(video);
        }
    }, [eventState?.event_details, glb?.scene]);

    useEffect(() => {
        const playButton = document.getElementById("videoControPlay");
        if (videoElement?.src && playButton) {
            playButton.click();            
        }
    }, [videoElement?.src]);

    if (isEmpty(glb?.scene)) return <Drag_plane />;
    return (
        <>
            
            <Drag_plane />
            <Event_listener/>
            
          

             <primitive object={glb.scene} ref={stall_ref}>
                {glb.scene.children.map((i, index) => {
                    if (typeof i.material != "undefined") {                    
                        if (i.material.roughness < 0.5) {                                                                                      
                                i.material.roughness = 0;
                                i.material.envMap = cubeRenderTarget.texture;
                                i.material.envMap.encoding = THREE.sRGBEncoding;
                                i.material.envMap.mapping =
                                    THREE.ACESFilmicToneMapping;
                                i.material.needsUpdate = true;
                                i.add(cubeCamera);                         
                        }                     
                    }

                    if (i.name == "Speakers_list" || i.name == "spencer_logo") {
                        i.visible = false;
                    }

                    if (
                        i.name.split("__")[0].localeCompare("spencer_banner") ==
                        0
                    ) {
                        let id = parseInt(i.name.split("__")[1]);
                               
                        if (appState != null) {                            
                            if(typeof appState[id]!="undefined")
                            {
                                 console.log(appState[id].sponsor_banner,id)
                                i.material.map = new THREE.TextureLoader().load(
                                    appState[id].sponsor_banner
                                );
                                i.material.map.encoding = THREE.sRGBEncoding;                            
                                i.material.map.flipY = false;
                                i.material.needsUpdate = true; 
                            }                           
                        }
                    } else if (
                        i.name.localeCompare("banner_list") == 0 ||
                        i.name.localeCompare("exhibitors") == 0
                    ) {
                        i.material.transparent = true;
                        i.material.opacity = 0.3;
                        i.material.needsUpdate = true;
                    }

                    if (eventState != null) {
                        if (i.name == "videozone") {                            
                            return (
                                <mesh
                                    geometry={i.geometry}
                                    position={i.position}
                                    rotation={i.rotation}
                                    scale={i.scale}
                                    quaternion={i.quaternion}
                                    parent={i.parent}
                                    matrixWorld={i.matrixWorld}
                                    layers={i.layers}
                                    up={i.up}
                                    matrixAutoUpdate={true}
                                    matrixWorldNeedsUpdate={true}
                                    key={i+"lobby_ref"}
                                >
                                    {videoElement?.src && (
                                        <meshBasicMaterial>
                                            <videoTexture
                                                attach="map"
                                                encoding ={THREE.sRGBEncoding}
                                                args={[videoElement]}
                                            />
                                        </meshBasicMaterial>
                                    )}
                                </mesh>
                            );
                        }
                    }
                })}
            </primitive>
        </>
    );
};

export default Booth;
