import { Carousel } from "reactstrap";
import { withStyles } from "@material-ui/styles";
import { Grid } from "@material-ui/core";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { Button as RTButton } from "reactstrap";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";

const styles = (theme) => ({});

const CustomCarousel = (props) => {
    const { activeIndex, setActiveIndex, content, setDisplayMode } = props;

    const next = () => {
        const nextIndex =
            activeIndex === content.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };

    const previous = () => {
        const nextIndex =
            activeIndex === 0 ? content.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };
    if (!props.onlyImageCarousel) {
        return (
            <>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    interval={false}
                    slide={false}
                >
                    {content}
                    {/* <Grid
                        container
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "3px 0",
                            background: "#1c1d1f",
                            border: "none",
                        }}
                    >
                        <Grid
                            style={{
                                width: "5%",
                                margin: "0 3px",
                            }}
                            direction="prev"
                            onClick={previous}
                        >
                            <div
                                style={{
                                    height: "100%",
                                    textAlign: "center",
                                    border: "1px solid white",
                                }}
                            >
                                <ChevronLeftIcon style={{ color: "white" }} />
                            </div>
                        </Grid>
                        <Grid item style={{ textAlign: "center", flex: 1 }}>
                            <RTButton
                                style={{
                                    width: "100%",
                                    backgroundColor: "white",
                                    color: "#1c1d1f",
                                    borderRadius: 0,
                                    padding: "3px",
                                    border: "none",
                                }}
                                onClick={() => {
                                    setDisplayMode("overview");
                                }}
                            >
                                <ArrowUpwardIcon /> back to overview
                            </RTButton>
                        </Grid>
                        <Grid
                            style={{
                                width: "5%",
                                margin: "0 3px",
                            }}
                            direction="next"
                            onClick={next}
                        >
                            <div
                                style={{
                                    height: "100%",
                                    textAlign: "center",
                                    border: "1px solid white",
                                }}
                            >
                                <ChevronRightIcon style={{ color: "white" }} />
                            </div>
                        </Grid>
                    </Grid> */}
                </Carousel>
            </>
        );
    } else {
        return (
            <>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    interval={false}
                    slide={false}
                >
                    {/* <Grid container>
                        <Grid item xs={1}>
                            P
                        </Grid>
                        <Grid item xs={10}> */}
                    {content}

                </Carousel>
            </>
        );
    }
};

export default withStyles(styles)(CustomCarousel);
