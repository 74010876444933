import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Collapse, Spinner, Alert } from "reactstrap";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Card } from "react-bootstrap";
import ReactPlayer from "react-player";
import Button from "../common/Button";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import IconButton from "@material-ui/core/IconButton";
import Slider from "react-slick";
import { video_log as videoLogApi , SwagBag as SwagBagApi } from "../../../../store/restApi/boothLiveApi";

//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove } from "../../../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
//------------------------My Favourite Function--------------------------//

const styles = (theme) => ({
    modal: {},
});

const VideosModal = (props) => {
    const {
        videoData,
        classes,
        showVideoPlayer,
        activeVideo,
        setVideoSrc = () => {},
    } = props;
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
    };
    const start = props.focussedVideosStartIndex,
        end = (props.focussedVideosStartIndex + 3) % videoData.length;
    const videosWindow = [0, 1, 2];

    const { boothDetails, countryCodes } = useStoreState(
        (state) => state?.boothLiveState
    );   
    const { eventConfig,userDetails, exhibitorInfo } = useStoreState((state) => state.state);

    console.log("boothdetails",boothDetails);

    /*------------My faourite functionality----------------------------*/
    const { updateState  } = useStoreActions((state) => state.boothLiveState);
    const [favourite, setfavourite] = useState(videoData);
    const [error, setError] = useState();   

    //-----------Tracking video Api calling
    const handlePlay = (video_id) =>{
        //Tracking video Api calling
        const payload = {
            visitor_user_id_type: userDetails?.user_type,
            visitor_user_id: userDetails?.id,
            event_id: eventConfig?.data[0]?.event_id,
            video_id: video_id               
        };

        videoLogApi(payload).then((response) => {
            //console.log(response?.message);               
        });      
    };
    //-----------Tracking video Api calling
   


    const setFavorite_fn=(idx,vid,status)=>{     
        setfavourite(favValue => 
            [...favValue].map(el => 
                el.video_id === vid ? ({...el, flag_status:status}) : el)
            )      

        let videoData_dummy=videoData;        
        videoData_dummy[idx].flag_status=status;
        updateState({ videoData: videoData_dummy});  
        
         //-----------swagbag Api calling
         const payload = {
            
            user_id: userDetails?.id,
            event_id: eventConfig?.data[0]?.event_id,
            action_id : vid,
            favourite_type: "video", 
            booth_id:boothDetails?.booth[0]?.booth_id             
        };

        
        SwagBagApi(payload).then((response) => {
            //console.log(response?.message);               
        });     
        //-----------swagbag Api calling

        let favourite_payload = {
          user_id: userDetails?.id,
          favourite_type: 'video',
          action_id:vid,
          event_id: eventConfig?.data?.[0]?.event_id,
        };
        if(status)
        {
            
            myFavouriteAdd(favourite_payload).then((response) => {
             console.log(response);            
            })
            .catch((error) => {
                //console.log(error);
            });
        }
        else
        {
            myFavouriteRemove(favourite_payload).then((response) => {
                console.log(response);            
               })
               .catch((error) => {
                   //console.log(error);
               })
               
        }  
      };
      /*------------My faourite functionality----------------------------*/
     
      if (error || !Array.isArray(favourite)) {
        return <p>There was an error loading your data!</p>;
      }

    return (
        <div
            style={{
                display: "flex",
                alignItems: "center",
                minWidth: 400,
                position: "relative",
                // left: -200,
                // overflow: "scroll",
            }}
        >
            {" "}
            {!showVideoPlayer ? (
                <Grid item alignItems="center" justify="center">
                    <IconButton
                        onClick={() => {
                            //console.log("clicked here");
                            props?.setFocussedVideosStartIndex(
                                (props?.focussedVideosStartIndex +
                                    videoData.length -
                                    1) %
                                    videoData.length
                            );
                        }}
                        style={{
                            background: "black",
                            borderRadius: 0,
                            color: "white",
                            padding: 3,
                        }}
                    >
                        <ChevronLeftIcon />
                    </IconButton>
                </Grid>
            ) : null}
            <Grid
                container
                className={"rounded bg-white " + classes.modal}
                style={{
                    padding: "0",
                    display: "flex",
                    background: "white",
                    overflow: "scroll",
                }}
                alignItems="center"
                justify="center"
            >
                {/* <Slider {...settings}> */}
                {videosWindow.map((index) => {
                    const video =
                        videoData[
                            (props?.focussedVideosStartIndex + index) %
                                videoData.length
                        ];
                    return (
                        // <>
                        <Grid
                            item
                            xs={4}
                            style={{
                                padding: 10,
                            }}
                            onClick={() =>
                                setVideoSrc(video?.video_path, index)
                            }
                        >
                            {/* <div
                                style={{
                                    cursor: "pointer",
                                    padding: 7,
                                    background:
                                        index == activeVideo
                                            ? "red"
                                            : "#efefef",
                                    color:
                                        index == activeVideo
                                            ? "white"
                                            : "black",
                                }}
                            >
                                <div style={{ textAlign: "center" }}>
                                    <img
                                        width="auto"
                                        height="90px"
                                        src={video?.video_thumbnail}
                                    />
                                </div>
                                <div className="small" style={{ height: 40 }}>
                                    {video?.video_title}
                                </div>
                            </div> */}
                        </Grid>
                    );
                    // } else return null;
                })}
                {/* </Slider> */}
                {//videoData.map((video, index) => {
                    favourite.map((video, index) => {
                    if (showVideoPlayer && video!=null ) {
                        return (
                             <>
                            <Grid
                                item
                                xs={4}
                                style={{  overflow: "scroll" }}
                            >                            
                                <div className="videoDisplay">
                                    <ReactPlayer
                                        width="inherit"
                                        height="auto"
                                        url={video.video_path}
                                        // url={"https://player.vimeo.com/video/"+video.video_id}
                                        controls={true}
                                        //  playIcon={
                                        //      <Button onclick={testfn(this)}>play</Button>
                                        //  }
                                         onPlay={() => handlePlay(video.video_id)}
                                    />
                                    <div  dangerouslySetInnerHTML={{__html: `${video.video_title}`}} className="small">
                                        {/* {video.video_title} */}
                                    </div>
                                    <div onClick={() => setFavorite_fn(index,video.video_id,true)}>
                                         {(video.flag_status)?(<RemoveCircleIcon />):(<AddCircleIcon />)}
                                       
                                    </div>
                                </div>
                            </Grid>
                            </>
                        );
                    } else {
                        // console.log(
                        //     "this",
                        //     index,
                        //     start,
                        //     end,
                        //     index >= start && index < end
                        // );
                        return null;
                    }
                })}
            </Grid>
            {!showVideoPlayer ? (
                <Grid item>
                    <IconButton
                        onClick={() => {
                            //console.log("clicked here");
                            props?.setFocussedVideosStartIndex(
                                (props?.focussedVideosStartIndex + 1) %
                                    videoData.length
                            );
                        }}
                        style={{
                            background: "black",
                            borderRadius: 0,
                            color: "white",
                            padding: 3,
                        }}
                    >
                        <ChevronRightIcon />
                    </IconButton>
                </Grid>
            ) : null}
        </div>
    );
};

export default withStyles(styles)(VideosModal);
