import React, { useState, useEffect } from "react";
import { withStyles } from "@material-ui/core/styles";
import { Collapse, Spinner, Alert } from "reactstrap";
import { Grid, Typography, Hidden } from "@material-ui/core";
import ChatIcon from "@material-ui/icons/Chat";
import callIcon from "../../assets/call-icon.png";
import { Card } from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import userIcon from "../../assets/user-icon.png";
import EmailIcon from "@material-ui/icons/Email";
import PersonIcon from '@material-ui/icons/Person';
// import ChatIcon from "@material-ui/icons/Chat";
import Button from "../../components/common/Button";
import DuoOutlinedIcon from "@material-ui/icons/DuoOutlined";
import EventOutlinedIcon from "@material-ui/icons/EventOutlined";
import ChatBubbleOutlinedIcon from "@material-ui/icons/ChatBubbleOutlined";
import Modal from "../Modal";
// import PersonIcon from "@material-ui/icons/Person";
import VideoCall from "@material-ui/icons/VideoCall";
import { useStoreActions, useStoreState } from "easy-peasy";
import { memo } from "react";
import EventChat from "../../../../components/pages/chat/Chat";
// import callIcon from "../../assets/call-icon.png";
// import userIcon from "../../assets/user-icon.png";
// import Button from "../../components/common/Button";
// import { useStoreActions, useStoreState } from "easy-peasy";
import { videocall as videocallAPI } from "../../utils/pubnub";
// import VideoCall from "@material-ui/icons/VideoCall";
import CustomCarousel from "../common/CustomCarousel";
import { Carousel, CarouselItem } from "reactstrap";
import "./businessCardModal.css";
// import Modal from "../Modal";
import { object } from "prop-types";


//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove, swagBagVideos } from "../../../../store/restApi/boothLiveApi";
import { SwagBag as SwagBagApi } from "../../../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { Add } from "@mui/icons-material";
//------------------------My Favourite Function--------------------------//


const styles = (theme) => ({
    modal: {},
});



const BusinessCardModal = ({
    businessCard,
    classes,
    handleOpenEmailModal,
    handleModalClose,
    chatOpen
}) => {
    const isSmallScreen = window.innerWidth < 769 || window.innerHeight < 500;
    const [appointmentOpened, setAppointmentOpened] = useState(false);
    const [appointmentURL, setAppointmentURL] = useState("");
    const [contactOpened, setContactOpened] = useState(false);
    const { boothDetails } = useStoreState((state) => state.boothLiveState);
    const { eventConfig, userDetails , chatModel } = useStoreState((state) => state.state);
    const { updateState } = useStoreActions((state) => state.state);

    const { sendMessage, videoCallAction } = useStoreActions(
        (state) => state.pubNub
    );
    const { staffDirectChatStatus  } = useStoreState((state) => state.pubNub);
    const [showChatModal, setShowChatModal] = useState(false);
    const pubAction = useStoreActions((state) => state.pubNub);
 
    
   const showChatFn = (businesscard_details) => {                
    let chatUserObj = {
        displayName: businesscard_details?.contact_person_first_name+" "+businesscard_details?.contact_person_last_name,
        user_id: businesscard_details?.user_id,
        email_id : businesscard_details?.company_email,
        type : 'NORMAL'
    };
    pubAction.updateState({
        chatType: "NORMAL" ,
        currentChatUserDetails: chatUserObj,
    });
    chatOpen(() => {
        return true;
    });
    handleModalClose()
};
    const setAppointmentOpenedFn = (open_status,open_url) =>{
        setAppointmentOpened(open_status);
        setAppointmentURL(open_url);
    };

    const handleVideoCall = (idx) => {
        let staff = businessCard[idx];

        let fromUser = {id : staff?.user_id , displayName :  staff?.contact_person_first_name +" "+ staff?.contact_person_last_name}
        let toUser = {id : userDetails?.id , displayName : userDetails?.displayname}

        videocallAPI(
            toUser,
            fromUser,
            videoCallAction,
            sendMessage
        );
        handleModalClose();
    }   

     /*------------My faourite functionality----------------------------*/
    //  const { updateState  } = useStoreActions((state) => state.boothLiveState);
     const [favourite, setfavourite] = useState(businessCard);
     console.log(favourite)
     const [error, setError] = useState();   
     const setFavorite_fn=(idx,staff_id,status)=>{  
          
         setfavourite(favValue => 
             [...favValue].map(el => 
                 el.staff_id === staff_id ? ({...el, flag_status:status}) : el)
             )      
 
         let businessData_dummy=businessCard;        
         businessData_dummy[idx].flag_status=status;        
         updateState({ businessCard: businessData_dummy});    
    
           
         const payload = {
            
            user_id: userDetails?.id,
            event_id: eventConfig?.data[0]?.event_id,
            action_id : staff_id,
            favourite_type: "staff_businesscard", 
            booth_id:boothDetails?.booth[0]?.booth_id             
        };

        
        SwagBagApi(payload).then((response) => {
            //console.log(response?.message);               
        });     
        //-----------swagbag Api calling
       

         let favourite_payload = {
           user_id: userDetails?.id,
           favourite_type: 'staff_businesscard',
           action_id:businessCard[idx]?.staff_id,
           event_id: eventConfig?.data?.[0]?.event_id,
         };
         if(status)
         {
             
             myFavouriteAdd(favourite_payload).then((response) => {
              console.log(response);            
             })
             .catch((error) => {
                 //console.log(error);
             });
         }
         else
         {
             myFavouriteRemove(favourite_payload).then((response) => {
                 console.log(response);            
                })
                .catch((error) => {
                    //console.log(error);
                })
                
         }  
       };
       /*------------My faourite functionality----------------------------*/


    return (
        <div className="businesscard-overall">
            <div className="businesscard-wrapper">
                {favourite?.map( (businesscard,index) => {
                    return (
                        <>
                            <div
                            
                            className="businesscard-card" 
                            key={businesscard?.staff_id}>
                                <div className="businesscard-image-container">
                                {businesscard.staff_profile_image !== ""   ?  <img
                                        src={
                                            businesscard?.staff_profile_image
                                        }
                                        style={{
                                            display: "inline-block",
                                            width: "100%",
                                            height: "100%",  
                                        }}
                                    ></img> 
                                : <PersonIcon style={{width:"100%" , height:"100%", backgroundColor:"#737373" , color:" white"}} /> }
                                </div>







                                <div className="businesscard-contents-container">
                                    <div className="businesscard-contents-name-container">
                                        <img
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                marginRight: "5px",
                                            }}
                                            src={userIcon}
                                            alt=""
                                        ></img>

                                        <div className="businesscard-contents-name">
                                            <span class="subtitle2">
                                                {businesscard.contact_person_first_name +
                                                    " " +
                                                    businesscard.contact_person_last_name}
                                            </span>

                                            <span
                                                variant="subtitle2"
                                                style={{ fontSize: "10px" }}
                                            >
                                                {
                                                    businesscard.staff_designation
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="businesscard-contents-phone">
                                        <img
                                            style={{
                                                width: "30px",
                                                height: "30px",
                                                marginRight: "5px",
                                            }}
                                            src={callIcon}
                                        ></img>
                                        <div>
                                            <span variant="subtitle2"
                                                style={{ fontSize: "10px" }}
                                            >
                                                {
                                                    businesscard?.staff_phone_num
                                                }
                                            </span>
                                        </div>
                                    </div>
                                    <div className="businessCardIcons" key={index}>
                                        <Button
                                            handleClick={(e) => {
                                                showChatFn(businesscard);
                                            }}
                                            isSquare={true}
                                            style={{
                                                height: 100,
                                                width: 100,
                                                marginRight: 5,
                                            }}
                                            OpenIcon={ChatIcon}
                                        ></Button>

                                        <Button
                                            handleClick={() => {
                                                setContactOpened(true);
                                            }}
                                            isSquare={true}
                                            style={{
                                                height: 100,
                                                width: 100,
                                                marginRight: 5,
                                            }}
                                            OpenIcon={EmailIcon}
                                        ></Button>


                                        {/* <Button
                            isSquare={true}
                            style={{ height: 100, width: 100 }}
                            OpenIcon={ChatBubbleOutlinedIcon}
                        ></Button> */}
                                        {eventConfig?.data[0]
                                            .attendee_to_exhibitor_video_chat_is_enabled === 
                                        "Yes" && businesscard?.staff_videochat_url !== "" ? (
                                            <Button
                                                OpenIcon={VideoCall}
                                                isSquare={true}
                                                // handleClick={(event) => {
                                                //     videocallAPI(
                                                //         businessCard[idx],
                                                //         userDetails,
                                                //         videoCallAction,
                                                //         sendMessage
                                                //     );
                                                //     handleModalClose();
                                                // }}
                                                handleClick={(event) => handleVideoCall(index)}
                                            ></Button>
                                        ) : null}
                                         {businesscard?.appointment_booking_url !== ""  ?
                                        <Button
                                            isSquare={true}
                                            style={{ height: 100, width: 100 }}
                                            OpenIcon={EventOutlinedIcon}
                                            handleClick={() => {
                                                // setAppointmentOpened(true);
                                                setAppointmentOpenedFn(true,businesscard?.appointment_booking_url);
                                            }}
                                        ></Button> : null }
                                        <div onClick={() => setFavorite_fn(index,businesscard.staff_id,true)}>
                                         {(businesscard.flag_status)?(<RemoveCircleIcon />):(<AddCircleIcon />)}
                                       
                                    </div>
                                    </div>
                                    
                                </div>








                                {contactOpened ? (
                                    <Modal
                                        handleModalClose={() => {
                                            setContactOpened(false);
                                        }}
                                        show={contactOpened}
                                        title="Contact Us"
                                        contactUs={true}
                                    />
                                ) : null}
                                {appointmentOpened ? (
                                    <Modal
                                        appointment={true}
                                        handleModalClose={setAppointmentOpened}
                                        show={appointmentOpened}
                                        url={appointmentURL}
                                    />
                                ) : null}  
                            </div>
                        </>
                    );
                })}
            </div>
        </div>
    );
};

export default withStyles(styles)(BusinessCardModal);
