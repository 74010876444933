import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import {QRCodeUrl} from "../../../../store/restApi/api";

const CourseDetails = () => { 

    const {  exhibitorInfo } = useStoreState((state) => state.boothLiveState);
    const { eventConfig } = useStoreState((state) => state.state);  
    const event_id= eventConfig?.data?.[0]?.event_id;
    const event_code= eventConfig?.eventCode; 
    
    const [boothData, setBoothData] = useState(undefined);
    const [courseData, setCourseData] = useState(undefined);
    const [loader, setLoader] = useState(undefined);
    
   
    const { getCourseDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );
    const [courseId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("course_id")
    );

    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );

    useEffect(() => {  
            if(boothId==="" || courseId==="" || boothId==="null" || courseId==="null")
            {
                setLoader(true);  
            }   
            if (boothId && event_id) {
                getBoothDetails({
                    booth_id: boothId,                  
                    event_id: event_id                   
                }).then((res)=>{                                               
                    setBoothData(res.payload);                              
                });               
            }
    }, [boothId]);

    useEffect(() => {
        let exhibitor_id= boothData?.exhibitorInfo?.exhibitor_id;       
        if (boothData && (boothId!=="" && courseId!=="" && boothId!=="null" && courseId!=="null")) {                       
            getCourseDetails({
                booth_id: boothId,               
                event_id: event_id, 
                exhibitor_id:exhibitor_id,
                course_id:courseId           
            }).then((res)=>{                                                          
                setCourseData(res.payload?.courseDetailsData);          
            });
            
        }        
    }, [boothData]);

    useEffect(() => {
        if(typeof courseData!=="undefined" && typeof boothData!=="undefined")
        {    
            if(courseData=="" || courseData==null)
            {
                setLoader(true);  
            }
            else
            {
                setLoader(undefined);
            }
            document.title = courseData?.course_title+" - "+exhibitorInfo?.exhibitor_company_name;
        }        
        console.log(courseData)
    }, [courseData,boothData]); 
    
    //Back to course list page
    const back_fn = () =>{      
        let link=QRCodeUrl+"courses?booth_id="+boothId+"&event_code="+event_code+"";
        window.open(link,"_self");
     };
   
    return (   
     
       
        <div style={{ padding: "100px 0" }}>
        <>
        {(loader)?(
            <Grid
                container
                item
                justify="center"
                style={{
                    margin: 5,
                }}                
                direction="column"
            >
                <Grid item className="h4 text-center p-2">
                No Data Found
                </Grid>
            </Grid>       
        ):(
        <Grid container justify="center" direction="column">
            <Grid
                container
                item
                justify="center"
                style={{
                    padding: 30
                }}                
                direction="column"
            >
                {(typeof courseData!="undefined")?(
                    <>
                    <Grid item className="h4 text-center p-2">
                        {exhibitorInfo?.exhibitor_company_name}
                    </Grid>
                    <Grid item className="h6 text-center">
                        Courses
                    </Grid>   
                    </> 
                ):(null)}    


                <Grid item className="text-right">
                    <Button
                        style={{ background: "#1c1d1f" }}
                        onClick={() => {
                            back_fn()
                        }}
                    >
                        Back
                    </Button>
                </Grid>                              
                                
                                <Grid
                                    container
                                    item
                                    justify="center"
                                    style={{
                                        margin: 5,
                                        padding:10,
                                        display:"flex",
                                        flexWrap:"nowrap"
                                    }}                                   
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        md={6}
                                        className="font-weight-bold"
                                        style={{
                                            borderBottom:"1.5px solid #1c1d1f",
                                            padding:10
                                        }}
                                    >   

                                        
                                        <div className="small">
                                            <b className="ctitle">{courseData?.course_title}</b>
                                        </div>
                                        <br />
                                        <div className="small" >
                                            <b className="ctitle">Location: </b>{courseData?.course_location}
                                        </div>
                                        <br />
                                        <div className="small">
                                            <b className="ctitle">Fees: </b>{courseData?.course_fees}
                                        </div> 
                                        <br />
                                        
                                        {/* <div className="small">
                                        {courseData?.college_info}
                                        </div>                                         */}
                                        <div className="small">
                                        {courseData?.course_description}
                                        </div>
                                        
                                    </Grid>

                                 
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            justifyContent: "right",
                                            borderBottom: "1.5px solid #1c1d1f",
                                            padding:10
                                        }}
                                    >
                                        
                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}                                           
                                        >                                          
                                            {/* <div className="small">
                                            <b>Location: </b>{courseData?.course_location}
                                            </div>  
                                            <div className="small">
                                            <b>Fees: </b>{courseData?.course_fees}
                                            </div>                                           */}
                                        </Grid>
                                    </Grid>

                                </Grid>                             
                         
                        </Grid>
                      </Grid>
        )} 
                        </>
                      </div> 
                      
         
    );
};

export default CourseDetails;
