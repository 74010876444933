import { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import CardDeck from 'react-bootstrap/CardDeck';
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { useStoreState } from 'easy-peasy';
import './search.css'
import CloseIcon from "@material-ui/icons/Close";
import Button from '../common/Button';

const SearchPopup = (props) => {
  const history = useHistory()
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [activeType, setActiveType] = useState("all");
  const [isLoading, setIsLoading] = useState(false);
  const { eventConfig } = useStoreState((state) => state.state);
  const [showAll, setShowAll] = useState(false);
  const [showFullDescription, setShowFullDescription] = useState(false);
  const [expandedSection, setExpandedSection] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await axios.post(`https://app.hexafair.com/platform/resources/api/v2/event_search.php`, {
          event_id: eventConfig?.data?.[0]?.event_id,
        });
        console.log(response.data)
        setFilteredData(response.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };
    fetchData();
  }, [eventConfig.id, searchQuery]);

  const handleSearch = (e) => {
    const searchQuery = e.target.value.toLowerCase();
    setSearchQuery(searchQuery);
  }

  const handleTypeChange = (e) => {
    setActiveType(e.target.value);
  };

  const handleViewBooth = (boothId, itemType) => {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          let element;
          if (itemType === "jobs") {
            element = document.getElementById("j_click");
          } else if (itemType === "courses") {
            element = document.getElementById("courses");
          } else if (itemType === "product") {
            element = document.getElementById("products");
          } else if (itemType === "booth_session") {
            element = document.getElementById("conference")
          }
          else if (itemType === "auditorium_session") {
            element = document.getElementById("auditorium")
          }
          else if (itemType === "users") {
            element = document.getElementById("users");
          }
          if (element) {
            element.click();
            observer.disconnect();
          }
        }
      });
    });
    observer.observe(document.body, { childList: true, subtree: true });
    { itemType !== "auditorium_session" ? history.push(`/booth/${boothId}`) : (document.getElementById("auditorium").click()) }
  };
  const handleViewMore = (section) => {
    if (expandedSection === section) {
      setExpandedSection(null);
    } else {
      setExpandedSection(section);
    }
  };
  return (

    <Modal className='search-pop' show={props.show} >
      <Modal.Header>
        <Modal.Title>Search</Modal.Title>
        <Button
          customIconContainer='close'
          OpenIcon={CloseIcon}
          handleClick={props.onClose}
          style={{ marginRight: '-1rem', marginTop: '-1%' }}
        ></Button>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-3">

          <label className="mr-3" onClick={() => setActiveType("all")}>
            <input type="radio" value="all" checked={activeType === "all"} onChange={handleTypeChange} />
            All
          </label>
          <label className="mr-3" onClick={() => setActiveType("jobs")}>
            <input type="radio" value="jobs" checked={activeType === "jobs"} onChange={handleTypeChange} />
            Jobs
          </label>
          <label className="mr-3" onClick={() => setActiveType("courses")}>
            <input type="radio" value="courses" checked={activeType === "courses"} onChange={handleTypeChange} />
            Courses
          </label>
          <label className="mr-3" onClick={() => setActiveType("exhibitor")}>
            <input type="radio" value="exhibitor" checked={activeType === "exhibitor"} onChange={handleTypeChange} />
            Exhibitors
          </label>
          {/* <label className="mr-3" onClick={() => setActiveType("person")}>
            <input type="radio" value="person" checked={activeType === "person"} onChange={handleTypeChange} />
            People
          </label> */}
          <label className="mr-3" onClick={() => setActiveType("product")}>
            <input type="radio" value="product" checked={activeType === "product"} onChange={handleTypeChange} />
            Products
          </label>
          <label className="mr-3" onClick={() => setActiveType("booth_session")}>
            <input type="radio" value="booth_session" checked={activeType === "booth_session"} onChange={handleTypeChange} />
            Sessions
          </label>
          <label className="mr-3" onClick={() => setActiveType("auditorium_session")}>
            <input type="radio" value="auditorium_session" checked={activeType === "auditorium_session"} onChange={handleTypeChange} />
            Conference
          </label>
        </div>
        <input type="text" onChange={handleSearch} className="form-control mb-3" placeholder="Search..." />
        {isLoading ? (
          <p>Loading...</p>
        ) : searchQuery && filteredData.length === 0 ? (
          <p>No results found.</p>
        ) : (
          <CardDeck>
            {searchQuery ? (
              Array.isArray(filteredData) && filteredData
                .filter((item) => activeType === "all" || item.type === activeType)
                .filter((item) => item && (
                  item.type && item.type.toLowerCase().includes(searchQuery) ||
                  item.title && item.title.toLowerCase().includes(searchQuery)
                ))
                .map((item, index) => (
                  <Card key={index} style={{ flexBasis: '30%', marginBottom: '20px' }}>
                    <Card.Body>
                      {item && item.type === 'jobs' ? (
                        <>
                          <Card.Text><h4>{item.booth_name}</h4></Card.Text>
                          <Card.Title>{item.title}</Card.Title>
                          <Card.Text>
                            {item.description.substring(0, 100)}...
                          </Card.Text>
                          <Card.Text>{item.location}</Card.Text>

                          <button onClick={() => handleViewBooth(item.booth_id, "jobs")}>View Job</button>
                        </>

                      ) : item && item.type === 'courses' ? (
                        <>
                          <Card.Text><h4>{item.booth_name}</h4></Card.Text>
                          <Card.Title>{item.title}</Card.Title>
                          <Card.Text>
                            {item.description.substring(0, 100)}...
                          </Card.Text>
                          <Card.Text><b>{item.location}</b></Card.Text>

                          <button onClick={() => handleViewBooth(item.booth_id, "courses")}>View Course</button>
                        </>
                      ) : item && item.type === 'exhibitor' ? (
                        <>
                          <Card.Title>{item.title}</Card.Title>
                          <button onClick={() => handleViewBooth(item.booth_id)}>View Booth</button>
                        </>
                      ) : item && item.type === 'product' ? (

                        <>
                          <Card.Text><h4>{item.booth_name}</h4></Card.Text>
                          <Card.Title>{item.title}</Card.Title>
                          <Card.Text>
                            {item.description.substring(0, 100)}...
                          </Card.Text>
                          <Card.Text>{item.price}</Card.Text>
                          <button onClick={() => handleViewBooth(item.booth_id, "product")}>View Products</button>
                        </>
                      ) : item && item.type === 'booth_session' ? (
                        <>
                          <Card.Text><h4>{item.booth_name}</h4></Card.Text>
                          <Card.Title>{item.title}</Card.Title>
                          <Card.Text>
                            {item.description.substring(0, 100)}...
                          </Card.Text>

                          <Card.Text>{item.start_time}-{item.end_time}</Card.Text>

                          <button onClick={() => handleViewBooth(item.booth_id, "booth_session")}>View Sessions</button>
                        </>
                      ) : item && item.type === 'auditorium_session' ? (
                        <>
                          <Card.Title>{item.title}</Card.Title>
                          <Card.Text>
                            {item.description.substring(0, 100)}
                          </Card.Text>
                          <Card.Text>{item.start_time}-{item.end_time}</Card.Text>
                          <button onClick={() => handleViewBooth(item.booth_id, "auditorium_session")}>View Conference</button>
                        </>
                      ) : (null)}
                    </Card.Body>
                  </Card>
                ))
            ) : (
              <p>Type something to search...</p>
            )}
          </CardDeck>)}
      </Modal.Body>
    </Modal>



  );
};

export default SearchPopup;

