export const  pubNubHandler = {
    userMemberShipChannelHandler: (data, userId) => {
      
        let channels = data?.map(({ channel }) => {
            if (typeof channel !== "undefined") {
                if (channel?.custom?.user2_id === undefined) {
                    return {...channel , chatType : "GROUP"};
                } else if (channel?.custom?.user1_id === userId) {
                    return {
                        ...channel,
                        name: channel?.custom?.user2_displayName,
                        description: channel?.custom?.user2_description,
                        chatType : "ONE2ONE"
};
                } else {
                    return {
                        ...channel,
                        name: channel?.custom?.user1_displayName,
                        description: channel?.custom?.user1_description,
                        chatType : "ONE2ONE"
                    };
                }
            }
        });

        return channels;
    },

    filterChannelByChannelName: (channels, channelName) => {
        return channels.filter((channel) => channel?.name === channelName);
    },
};
