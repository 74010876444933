import React, { useState, useEffect } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import "reactstrap";
import "../../../index.css";
import "typeface-montserrat";
import { Link } from "react-router-dom";
import useStyles from "./Forgetpassword.style";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Typography } from "@material-ui/core";
import LoginRegisterSidebar from "../loginRegisterSiderbar/LoginRegisterSidebar";
import { forget_password as forget_passwordAPI } from "../../../store/restApi/api";
import './forgetstyle.css';

const Forgetpassword = (props) => {
    const [errors, setErrors] = useState("");
    // const [validateState, setValidate] = useState(false);

    // const [response, setResponse] = useState("");
    const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const { updateState } = useStoreActions((state) => state.state);
    const { isLoggedIn, eventConfig } = useStoreState((state) => state.state);
    document.querySelector('title').innerText=eventConfig?.data?.[0]?.event_title;   
    const classes = useStyles();
    const [state, setState] = useState({
        username: ""
    });
    useEffect(() => {
        setState({
            username: ""           
        });
        
    }, []);
    useEffect(() => {
        if (isLoggedIn) {
            if (props?.location?.from) {
                props.history.push(props.location.from);
            } else props.history.push("/");
        }
    }, [isLoggedIn]);
    function validate(response) {
        //console.log("claed", response);
        let temp = { ...errors };
        temp.username =
            state.username.length == 0
                ? "This field is required"
                : emailPattern.test(state.username) === false
                ? "Invalid Email"
                : response === "Invalid Email"
                ? "Invalid Email"
                : null;        
        if (temp.username != null) {
            setErrors({ ...temp });
            return false;
        } else if (temp.username === null) {
            return true;
        }
    }

    function handleChange(evt) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value,
        });
    }

    const submitHandler = (e) => {
        if (validate()) {           
            const payload = {
                email_id: state.username,                
                event_id: eventConfig?.data?.[0]?.event_id,
                method: 3,
                user_id: "",
                password: ""
            };
            updateState({forget_email:state.username});
            forget_passwordAPI(payload)?.then((response) => {                
                if(response.Status.localeCompare("Success")==0)
                {
                    props.history.push("/changepassword");
                    setErrors(false);
                }  
                else
                {
                    let temp = { ...errors };
                    temp.username =response.message;              
                    setErrors({ ...temp });
                }         
               
            });    
        }
    };
    return (
        <div className="forget-overall">
            <div className="forget-eventinfo">
                <div style={{ backgroundColor: "#1500fa", padding: "60% 15px",}}>
                    <LoginRegisterSidebar />
                </div>
            </div>
            
            <div className="forget-content">
                    <div className="forget-content-logo">
                        <img
                            height="50px"
                            width="100px"
                            src={eventConfig?.data?.[0]?.event_logo}
                        ></img>
                    </div>
                    <div className="forget-content-main">
                    <div className="forget-content-sub">
                <div className="forget-content-maintext">
                    

                    <div className="forget-content-maintext-title">
                            <div className="forget-content-maintext-titletext">
                                Recovery Password
                            </div>
                    </div>
                    </div>
                    <div className="forget-content-maincontent">
                       <div className="forget-content-maincontent-wrapper">
                        <div className="forget-content-inputname">
                            <span>Email *</span>
                        </div>
                        
                            <input
                                type="text"
                                className="forget-content-inputbox"
                                placeholder="Enter your Email"
                                id="loginEmail"
                                onChange={handleChange}
                                name="username"
                                required
                            />
                            <div className="forget-content-errors">
                            {errors?.username != "" ? (
                                <div
                                style={{ color: "red", textAlign: "left" ,fontSize:"11px"}}
                                >
                                    {errors?.username}
                                </div>
                            ) : null}</div>
                        
                        </div> 
                        </div>
                       
                        <div className="forget-content-bottomtext">
                        <div className="forget-content-bottomtext-button" onClick={() => { props.history.push("/Login");}}>
                            <div className="forget-content-bottomtext-button-wrapper" >
                                <span style={{color:"white"  , textAlign:"center" , justifyContent:"center"}}>Back</span>
                            </div>
                        </div>
                

                        <div className="forget-content-bottomtext-button" onClick={submitHandler}>
                            <div className="forget-content-bottomtext-button-wrapper" >
                               <span style={{color:"white"  , textAlign:"center" , justifyContent:"center"}}>Next</span> 
                            </div>
                        </div>
                        </div>
                        </div>
                        </div>
            </div>
                
            
        </div>
    );
};

export default Forgetpassword;
