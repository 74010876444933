import { withRouter } from "react-router-dom";
import ExhibitorsListPage from "../components/pages/ExhibitorsListPage";
import Home from "../components/pages/Home";
import AuditoriumPage from "../components/pages/AuditoriumPage";
import UserList from '../components/pages/usersList/UserList'
import MyBox from '../components/pages/myBox/MyBox'
import Chat from '../components/pages/chat/Chat'
import Socialwall from "../components/pages/socialwall/Socialwall";
import Exhibitorlist from "../components/pages/exhibitorlist/Exhibitorlist";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        path: "/",
        exact: true,
        component: withRouter(ExhibitorsListPage),
        strict: true,
    },
    {
        path: "/booth/:boothId",
        exact: true,
        component: withRouter(Home),
        strict: true,
    },
    {
        path: "/auditorium",
        exact: true,
        component: withRouter(AuditoriumPage),
        strict: true,
    },
    {
        path: "/userlist/:userId/:eventId",
        exact: true,
        component: withRouter(UserList),
        strict: true,
    },
    {
        path: "/mybox",
        exact: true,
        component: withRouter(MyBox),
        strict: true,
    },
    {
        path: "/socialwall",
        exact: true,
        component: withRouter(Socialwall),
        strict: true,
    },
    {
        path: "/exhibitorlist",
        exact: true,
        component: withRouter(Exhibitorlist),
        strict: true,
    },
    {
        path: "/Chat",
        exact: true,
        component: withRouter(Chat),
        strict: true,
    },
];
