import React, { useEffect, useState } from "react";
import "./videos.css";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import play from "../../../../assets/play.png";
import link from "../../../../assets/link.png";
import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";
const Videos = ({ event_id, user_id , videoModal}) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
        // {
        //     id: "exhibitor",
        //     displayName: "EXHIBITOR NAME",
        // },
        {
            id: "video_title",
            displayName: "DESCRIPTION",
        },
        {
            id: "viewed_at",
            displayName: "DATE & TIME VIEWED",
        },
        {
            id:"remove_favourite_video",
            displayName: "Remove",
        }
    ];
    const [child, setChild] = useState([]);
    const actionsUI = (data) => {
        let {video_path} = data
        return (
            <>
                <div onClick={() => videoModal(video_path)}>
                    <img src={play} alt="person" />
                    <br />
                    <span>Play Again</span>
                </div>
                {/* <div onClick={() => {navigator.clipboard.writeText(video_path)}}>
                    <img src={link} alt="person" />
                    <br />
                    <span>Copy Link</span>
                </div> */}
            </>
        );
    };

    useEffect(() => {
        async function fetchVideos() {
            // await fetch(`${baseUrl}/mybox-videos-viewed.php`, {
            //     method: "post",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //         event_id: event_id,
            //         user_id: user_id,
            //     }),
            // })
            //     .then((res) => res.json())
            //     .then((res) => setChild(() => res.data))
            //     .catch((err) => {
            //         //console.log(err);
            //     });

            let payload_data={
                event_id: event_id,
                user_id: user_id,
                swag_type : "video"
            };
            console.log(payload_data)
            SwagListApi(payload_data)
                .then((res) => setChild(() => res.videos))
                .catch((err) => {
                    //console.log(err);
                });




        }
        fetchVideos();
        return () => {
            setChild(() => []);
        };
    }, []);

    return (
        <Listing
            header={header}
            child={child}
            actions={actionsUI}
            actionHeader="ACTIONS"
        />
    );
};

export default Videos;
