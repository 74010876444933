import * as THREE from "three";
import React, { useState } from "react";
import { useThree, useFrame } from "@react-three/fiber";
import { Html } from "@react-three/drei";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { withStyles } from "@material-ui/core";
import { useStoreActions, useStoreState } from "easy-peasy";

const styles = (theme) => ({
    numberContainerDiv: {
        backgroundColor: "#1c1d1f80",
        color: "black",
        borderRadius: "45px",
        width: "25px",
        height: "25px",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        marginRight: 10,
        cursor: "pointer",
    },
    numberDiv: {
        backgroundColor: "#fff",
        borderRadius: "40px",
        width: "15px",
        height: "15px",
        display: "none",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
    },
    cancelButton: {
        right: theme.spacing(2.5),
    },
});

export const manageNavigationButtonDisplay = (no_of_coordinates) => {
    for (let i = 0; i < no_of_coordinates; i++) {
        if (
            document.getElementById("navigateModelButton" + i).style.display ==
            "none"
        )
            document.getElementById("navigateModelButton" + i).style.display =
                "block";
    }
};

const HomeHotspots = (props) => {
    const { showBack } = useStoreState((state) => state.state);
    // const [display, setDisplay] = useState({ index: null, diaplay: "block" });
    const { updateState } = useStoreActions((state) => state.state);
    const positionArray = [
        [0.9161262512207031, 20, 62.449127197265625],
        [0.7316133379936218, 19, 43.54599380493164],
    ];

    const lookAtArray = [
        [0.9081315199386255, 19.964007775496132, 61.449807106380334],
        [0.7236186067115442, 18.964007775496132, 42.54667371404635],
    ];

    const meshArray = [
        [8.9161262512207031, 19, 62.449127197265625],
        [0.7316133379936218, 18, 43.54599380493164],
    ];
    const { classes } = props;

    const { camera, gl } = useThree();
    let canvas;
    // useFrame(() => {
    //     canvas = gl.domElement;
    //     canvas.addEventListener("webglcontextlost", (e) => {
    //         window.location.reload();
    //     });
    // });

    const changeCameraHandler = (index) => {
        camera.fov = 75;
        camera.updateProjectionMatrix();
        manageNavigationButtonDisplay(meshArray?.length);
        document.getElementById("navigateModelButton" + index).style.display =
            "none";

        camera.position.set(
            positionArray[index][0],
            positionArray[index][1],
            positionArray[index][2]
        );
        camera.lookAt(
            new THREE.Vector3(
                lookAtArray[index][0],
                lookAtArray[index][1],
                lookAtArray[index][2]
            )
        );
        updateState({
            showBack: true,
        });
    };

    return (
        <>
            {meshArray?.map((i, index) => {
                return (
                    <>
                        <mesh position={i} key={index}>
                            <meshBasicMaterial color="white" />

                            <Html center zIndexRange={[1, 0]}>
                                <div
                                    className={classes.menuItemContainerDiv}
                                    id={"navigateModelButton" + index}
                                    onClick={() => changeCameraHandler(index)}
                                >
                                    <div className={classes.numberContainerDiv}>
                                        <div className={classes.numberDiv}>
                                            <span>{index + 1}</span>
                                        </div>
                                    </div>
                                </div>
                            </Html>
                        </mesh>
                    </>
                );
            })}
        </>
    );
};

export default withStyles(styles)(HomeHotspots);
