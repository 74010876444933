import { Html, Sky,OrbitControls } from "@react-three/drei";
import { Canvas, useLoader, useThree} from "@react-three/fiber";
import * as THREE from "three";
import { useStoreState, useStoreActions } from "easy-peasy";
import { isEmpty } from "lodash";
import React, { Suspense, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import AuditorumBottonButton from "../common/AuditorumBottomButton";
import MuxStreamControls from "../Livestreaming/Mux/MuxStreamControls";
import LogoSpinner from "../common/LogoSpinner";
import ConferenceSession from "../conferencesession/ConferenceSession";
import Logout from "../logout/Logout";
import Menu from "../menu/Menu";
import Modal from "../modal/Modal";


//Streaming Imports
import MuxPlayer from "../Livestreaming/Mux/muxLivestream";
import YoutubePlayer from "../Livestreaming/Youtube/Youtube";
import DacastPLayer from "../Livestreaming/Dacast/Dacast";
import VimeoPlayer from "../Livestreaming/Vimeo/vimeolivestream";
//Streaming Imports


import Polls from "../polls/Polls";
import QuestionAndAnswer from "../questionandanswer/QuestionAndAnswer";
import { StoreProvider } from "easy-peasy";
import store from "../../store";
import {
    getSponsorBannerList as getSponsorBannerListAPI,
} from "../../store/restApi/api";
import { booth_visitor_log as boothVisitorLogApi } from "../../store/restApi/boothLiveApi";



function Loading() {
    return (
        <div
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
                zIndex: 99999,
            }}
        >
            <LogoSpinner
                style={{ position: "absolute", top: "50vh", left: "50vw" }}
            />
        </div>
    );
}

const AuditoriumPage = (props) => {
   // const { camera } = useThree();
    let mouse = new THREE.Vector2();
    let target = new THREE.Vector2();
    let windowHalf = new THREE.Vector2(
        window.innerWidth / 2,
        window.innerHeight / 2
    );

    const [glbModelLoading, setGlbModalLoading] = useState(true);
    const [isConferenceList, setIsConferenceList] = useState(false);
    const { userDetails, eventConfig, isAuditoriumConferenceModalVisible, auditoriumVisitStatus } =
        useStoreState((state) => state.state);
    const { updateState } = useStoreActions((state) => state.state);
    const [isQA, setIsQA] = useState(false);
    const [isPolls, setIsPolls] = useState(false);
    const {streamUrl , streamProvide} = useStoreState((state) => state.state)
    
    /*---------sponsor banner------*/
    const [sponsorbanner, setSponsorBanner] = useState(null);

    useEffect(() => {        
            getSponsorBannerListAPI({ eventId: eventConfig?.data?.[0]?.event_id }).then((data) =>
                setSponsorBanner(data)
            );
    }, []);
    console.log("provide",streamProvide)
    console.log("stream", streamUrl)
    const glb = useLoader(
        GLTFLoader,
        eventConfig?.data?.[0]?.event_auditorium_3d_model_file,
        (onprogress = () => {
          //  console.log("loading");
        })
    );
    const Loader = () => <div>loading...</div>;  
    useEffect(() => {
        if (!isEmpty(glb.scene)) {
           // console.log("loaded");
          //  glb.scene.position.set(0,-10,0);
            setGlbModalLoading(false);
            updateState({ auditoriumVisitStatus: true });
            
            /*---------------------Auditorium Visit tracking-----------------------*/
                const payload_insert = {
                    visitor_user_id_type: userDetails?.user_type,
                    visitor_user_id: userDetails?.id,
                    event_id: eventConfig?.data[0]?.event_id,
                    booth_id: '', 
                    location: "5",  
                    action: "1",            
                };

                boothVisitorLogApi(payload_insert).then((response) => {
                   // console.log(response);               
                });
            /*---------------------Auditorium Visit tracking-----------------------*/
        }
    }, [glb]);

    const openQA = () => {
        setIsPolls(false);
        setIsQA(true);
        setIsConferenceList(false);
        updateState({
            isAuditoriumConferenceModalVisible: false,
        });
    };

    const openPolls = () => {
        setIsQA(false);
        setIsPolls(true);
        setIsConferenceList(false);
        updateState({
            isAuditoriumConferenceModalVisible: false,
        });
    };

    const openConference = () => {
        setIsConferenceList(true);
        updateState({
            isAuditoriumConferenceModalVisible: true,
        });
        setIsQA(false);
        setIsPolls(false);
    };

    /*-------------------------Tracking API calling------------------*/
    /*-------------------------Tracking API calling------------------*/
    
    return (
        <>
   
            <div
                style={{
                    position: "absolute",
                    width: "100vw",
                    height: "100vh",
                }}
            >
                <div style={{ bottom: "95px" }}>
                    {" "}
                    {streamProvide =="Mux.com" ?
                                            <MuxStreamControls /> : null }
                    
                </div>
                <div style={{ flex: 1 }}>
                    {" "}
                    <AuditorumBottonButton
                        qa={openQA}
                        polls={openPolls}
                        conference={openConference}
                    />
                </div>
            </div>

            {(isPolls || isQA) && (
                <div
                    style={{
                        position: "absolute",
                        width: "100vw",
                        height: "100vh",
                        zIndex: "1001",
                    }}
                >
                    {isPolls && (
                        <Menu
                            type="POLLS"
                            close={() => setIsPolls((value) => false)}
                        >
                            <Polls
                                close={() => setIsPolls((value) => false)}
                                event_id={eventConfig?.data?.[0]?.event_id}
                                user_id={userDetails?.id}
                            />
                        </Menu>
                    )}

                    {isQA && (
                        <Menu type="QA" close={() => setIsQA((value) => false)}>
                            <QuestionAndAnswer
                                close={() => setIsQA((value) => false)}
                                event_id={eventConfig?.data?.[0]?.event_id}
                                attendee_id={userDetails?.id}
                                hall_id="1"
                            />
                        </Menu>
                    )}
                </div>
            )}

            <Modal
                open={isAuditoriumConferenceModalVisible}
                onClose={() => {
                    setIsConferenceList((value) => false);
                    updateState({
                        isAuditoriumConferenceModalVisible: false,
                    });
                }}
                widith="70%"
                height="80%"
            >
                <ConferenceSession
                    attendee_id={userDetails?.id}
                    event_id={eventConfig?.data?.[0]?.event_id}
                />
            </Modal>

            <Suspense fallback={<></>}>
                {glbModelLoading && Loading()}

                <Canvas
                    style={{ height: "100%" , position : 'absolute'}}
                    id="canvas"
                    camera={{
                        far: 10000,
                    }}
                    onCreated={({ camera, scene }) => {
                        camera.position.set(0, 15, 10);
                        camera.updateProjectionMatrix();
                    }}                    
                >
                 <OrbitControls maxPolarAngle={1.5} minPolarAngle={1.5} enablePan={false} enableZoom={false} enableRotate={true} />
                     <rectAreaLight
                                width={600}
                               height={300}
                                color="0xffffff"
                                intensity={2}
                                position={[
                                0,50,0
                                ]}
                                rotation={[
                                   4.71238898038469, 0,0
                                ]}
                                lookAt={[
                                    0,0,0
                                ]}
                                penumbra={1}
                            />
                    <primitive object={glb.scene}></primitive>
                    {glb.scene.children.map((i, index) => {
                        //sponsor banner apply for auditorium
                        if (
                            i.name.split("__")[0].localeCompare("spencer_banner") ==
                            0
                        )
                        {
                            let id = parseInt(i.name.split("__")[1]);                               
                            if (sponsorbanner != null) {
                                if(typeof sponsorbanner[id]!="undefined")
                                {
                                    i.material.map = new THREE.TextureLoader().load(
                                        sponsorbanner[id].sponsor_banner
                                    );
                                    i.material.map.encoding = THREE.sRGBEncoding;                            
                                    i.material.map.flipY = false;
                                    i.material.needsUpdate = true; 
                                }                           
                            }
                        }
                        //sponsor banner apply for auditorium
                        if (i.name == "videozone_") {
                           // i.position.set(0,-10,0);
                            return (
                                <mesh
                                    geometry={i.geometry}
                                    position={i.position}
                                    // matrix={i.matrix}
                                    rotation={i.rotation}
                                    scale={i.scale}
                                    quaternion={i.quaternion}
                                    parent={i.parent}
                                    matrixWorld={i.matrixWorld}
                                    layers={i.layers}
                                    up={i.up}
                                    matrixAutoUpdate={true}
                                    matrixWorldNeedsUpdate={false} 
                                    key={"audit_"+index}                                   
                                >
                                    <Html
                                        transform
                                        distanceFactor={1}
                                        zIndexRange={[0, 0]}                                        
                                    >
                                        <StoreProvider store={store}>
                                        {streamProvide =="Mux" ?
                                            <MuxPlayer /> : null }
                                        
                                        {streamProvide =="Vimeo" ?
                                            <VimeoPlayer streamUrl={streamUrl}/> : null }

                                        {streamProvide =="Youtube" ?
                                            <YoutubePlayer streamUrl={streamUrl}/> : null }   
                                        {streamProvide =="Dacast" ?
                                            <DacastPLayer streamUrl={streamUrl} /> : null }
                                        </StoreProvider>
                                    </Html>
                                </mesh>
                            );
                        }
                    })}
                    <pointLight
                        castShadow
                        intensity={0.8}
                        position={[1000, 100, 0]}
                    />
                    <ambientLight intensity={0.6} />
                    <Sky distance={450000} sunPosition={[-1500, 700, 1500]} />
                    <ambientLight intensity={1} />
                </Canvas>
            </Suspense>
        </>
    );
};

export default withRouter(AuditoriumPage);
