import React, { useState, useEffect } from "react";
import { Table, CarouselItem, Button, Alert } from "reactstrap";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import CustomCarousel from "../common/CustomCarousel";
import { applyCourse as applyCourseAPI } from "../../../../store/restApi/boothLiveApi";
import { course_log as courseLogApi } from "../../../../store/restApi/boothLiveApi";
import { useStoreState, useStoreActions } from "easy-peasy";
import { validateEmail } from "../../utils/validation";
import AcknowledgementModal from "../Modals/AcknowledgementModal";
import MuiAlert from "@material-ui/lab/Alert";
import { Snackbar } from "@material-ui/core";

//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove, swagBagCourse } from "../../../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
//------------------------My Favourite Function--------------------------//

const styles = (theme) => ({
    modal: {
        margin: "0",
    },
    table: {
        margin: "10px auto",
        textAlign: "center",
        borderColor: "#1c1d1f",
        "& th": {
            borderColor: "#1c1d1f",
        },
        "& td": {
            borderColor: "#1c1d1f",
        },
        "& tr": {
            borderColor: "#1c1d1f",
        },
        "& thead": {
            borderColor: "#1c1d1f",
        },
    },
    carousel: {
        border: "2px solid #1c1d1f",
        borderBottom: "none",
        padding: "10px",
    },
    textarea: {
        border: "1px dashed #1c1d1f",
        padding: "5px 10px",
        "&:focus": {
            border: "1px dashed #1c1d1f",
            padding: "5px 10px",
        },
    },
    input: {
        padding: "5px 10px",
        border: "1px solid black",
        "&:focus": {
            outline: "none",
        },
        width: "100%",
    },
});

const CustomAlert = (props) => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CoursesModal = ({ coursesData, classes }) => {
   console.log("coursesData", coursesData);
    const [activeIndex, setActiveIndex] = useState(0);
    const [courseApplied, setCourseApplied] = useState(false);
    const [courseStatus, setCourseStatus] = useState("");
    const [displayMode, setDisplayMode] = useState("overview");
    const [selectedCourse, setSelectedCourse] = useState({});
    const [requestSent, setRequestSent] = useState(true);
    const [details, setDetails] = useState("")
    const { eventConfig,userDetails } = useStoreState((state) => state.state);
    // const [formError, setFormError] = useState(false);

    const { boothDetails,exhibitorInfo } = useStoreState(
        (state) => state?.boothLiveState
    );

    /*------------My faourite functionality----------------------------*/
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    const [favourite, setfavourite] = useState(coursesData);
    

    const view_course = (id) =>{
        //Tracking Course Api calling
        const payload = {
            visitor_user_id_type: userDetails?.user_type,
            visitor_user_id: userDetails?.id,
            event_id: eventConfig?.data[0]?.event_id,
            course_id: id            
        };
        courseLogApi(payload).then((response) => {
            // console.log(response?.message);               
         });
     };


    // useEffect(() => {        
    //     swagBagCourse(favouritestatus_payload).then((response) => {
    //         if(favourite.length==0)
    //         {        
    //             setfavourite(response);
    //         }    
    //     })
    //     .catch((error) => {
    //         //console.log(error);
    //     }); 
                
    // }, []);
    const setFavorite_fn=(idx,cid,status)=>{       
        setfavourite(favValue => 
            [...favValue].map(el => 
                el.id === cid ? ({...el, flag_status:status}) : el)
            )      
       
        let courseData_dummy=coursesData;        
        courseData_dummy[idx].flag_status=status;
        updateState({ coursesData: courseData_dummy});        

        let favourite_payload = {
            user_id: userDetails?.id,
            favourite_type: 'course',
            action_id:cid,
            event_id: eventConfig?.data?.[0]?.event_id,
            booth_id: boothDetails?.booth[0]?.booth_id
        };
        
        if(status)
        {
            myFavouriteAdd(favourite_payload).then((response) => {
             console.log(response);            
            })
            .catch((error) => {
                //console.log(error);
            });
        }
        else
        {
            myFavouriteRemove(favourite_payload).then((response) => {
                console.log(response);            
               })
               .catch((error) => {
                   //console.log(error);
               });
        }  
      };
      /*------------My faourite functionality----------------------------*/

    const applyCourse = () => {
        let intro = document?.getElementById("applyCourseIntroduction")?.value,
            courseId = selectedCourse?.id,
            name = document?.getElementById("applyCourseName").value,
            email = document?.getElementById("applyCourseEmail").value;

        if (email == "" || name == "" || !validateEmail(email)) {
            setCourseApplied("incompleteForm");
            return;
        }
        const payload = {
            attendee_id: userDetails?.id ? userDetails?.id : "",
            course_id: courseId,
            notes: intro,
           // request_made_by_name: name,
           // request_made_by_email_address: email,
            event_id: eventConfig?.data?.[0]?.event_id,
        };
       // console.log(payload, "submit");
        setCourseApplied("loading");
        setRequestSent(true);
        applyCourseAPI(payload).then((response) => {
             //console.log("applied", response);
            if (response?.restatus == "success") 
            {setCourseApplied("success");}
            else {                
            setCourseApplied("error");
            setCourseStatus(response?.remesssage);
            //console.log(courseStatus);
            }
            setRequestSent(false);
        });
    };

    // const detailCall= () => {

    // }

    //Tracking
    // const view_course = (id) =>{
    //     //Tracking Course Api calling
    //     const payload = {
    //         visitor_user_id_type: userDetails?.user_type,
    //         visitor_user_id: userDetails?.id,
    //         event_id: eventConfig?.data[0]?.event_id,
    //         course_id: id            
    //     };

    //     courseLogApi(payload).then((response) => {
    //        // console.log(response?.message);               
    //     });
    // };

    const slides = favourite?.map((course, index) => {
        return (
            <CarouselItem
                className={"custom-tag " + classes?.carousel}
                tag="div"
                key={index}
            >
                {/* <Grid container style={{ display: "flex" }}> */}
                <Grid
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                        gap: 15,
                    }}
                >
                    {" "}
                    <Grid dangerouslySetInnerHTML={{__html: `${course?.course_title}`}} item xs={12} style={{ fontWeight: "bold" }}>
                        {/* {course?.course_title} */}
                    </Grid>
                    <Grid dangerouslySetInnerHTML={{__html: `<b>Course Duration : </b>${course?.course_duration}`}}  item xs={12}>
                        {/* <b>Course Duration : </b>
                        {course?.course_duration} */}
                    </Grid>
                    <Grid dangerouslySetInnerHTML={{__html: `<b>Course Location : </b>${course?.course_location}`}}  item xs={12}>
                        {/* <b>Course Location : </b>
                        {course?.course_location} */}
                    </Grid>
                    <Grid dangerouslySetInnerHTML={{__html: `<b>Course Fee : </b>${course?.course_fees} Per Year`}} item xs={12}>
                        {/* <b>Course Fee : </b>
                        {course?.course_fees} Per Year */}
                    </Grid>
                    <Grid dangerouslySetInnerHTML={{__html: `${course?.course_description}`}} item xs={12}>
                        {/* {course?.course_description} */}
                    </Grid>
                  
                    <Grid item xs={12}>
                        <Button
                        color="primary"
                            style={{
                                background: "#1c1d1f",
                                borderRadius: 0,
                                padding: "4px 20px",
                                marginBottom: 15,
                                margin:15,
                                border: "none",
                            }}
                            onClick={() => {
                                setSelectedCourse(course);
                                setDisplayMode("form");
                            }}
                        >
                            Apply
                        </Button>

                        {( course?.courseurl !="")?(
                        <>
                        <Button
                        color="primary"
                            style={{
                                background: "#1c1d1f",
                                borderRadius: 0,
                                padding: "4px 20px",
                                marginBottom: 15,
                                margin:15,
                                border: "none",
                                
                            }}
                            onClick={() => {
                                window.open(`${course?.courseurl}`,  'location=yes,height=570,width=520,scrollbars=yes,status=yes');
                            }}
                        >
                           Website
                        </Button>
                            </>):(null)}
                    </Grid>


                        

                </Grid>
            </CarouselItem>
        );
    });

    return (
        <>
            {courseApplied != "success" && courseApplied != "loading" ? (
                <div className={"bg-white rounded " + classes?.modal}>
                    {displayMode == "overview" ? (
                        <Table bordered className={classes?.table}>
                            <thead
                                style={{
                                    background: "#1c1d1f",
                                    color: "white",
                                    borderColor: "#1c1d1f",
                                }}
                            >
                                <tr>
                                    <th style={{ minWidth: 150 }}>
                                        Group Code
                                    </th>
                                    <th>Course</th>
                                    <th>Location</th>
                                    <th>Course Details</th>
                                    <th></th>
                                    <th>Favourite</th>
                                </tr>
                            </thead>
                            <tbody>
                                {coursesData?.map((course, index) => {
                                    return (
                                        <tr>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${course?.code}`,
                                                }}
                                            ></td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${course?.course_title}`,
                                                }}
                                            ></td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${course?.course_location}`,
                                                }}
                                            ></td>
                                            <td
                                                dangerouslySetInnerHTML={{
                                                    __html: `${course?.course_description}`,
                                                }}
                                            >
                                                {/* {course?.course_description
                                                    ?.split("?. ")
                                                    ?.slice(0, 1)
                                                    ?.join("?. ")} */}
                                            </td>
                                            <td
                                                style={{
                                                    minWidth: "110px",
                                                    cursor: "pointer",
                                                    fontWeight: "bold",
                                                }}
                                                onClick={() => {
                                                    setActiveIndex(index);
                                                    setDisplayMode("carousel");
                                                    view_course(course?.id);
                                                }}
                                            >
                                                <Button
                                                color="primary"
                                                    style={{
                                                        "font-size": "smaller",
                                                        "background-color":
                                                            "black",
                                                    }}
                                                >
                                                    View Details
                                                </Button>
                                            </td>

                                            <td
                                                style={{
                                                    minWidth: "110px",
                                                    cursor: "pointer",
                                                    fontWeight: "bold",
                                                }}
                                               
                                            >
                                                <div
                                                    onClick={() =>
                                                        setFavorite_fn(
                                                            index,
                                                            course?.id,
                                                            true
                                                        )
                                                    }
                                                >
                                                    {course.flag_status ? (
                                                        <FavoriteIcon />
                                                    ) : (
                                                        <FavoriteBorderIcon />
                                                    )}
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    ) : null}
                    {displayMode == "carousel" ? (
                        <div>
                            <CustomCarousel
                                activeIndex={activeIndex}
                                setActiveIndex={setActiveIndex}
                                content={slides}
                                setDisplayMode={setDisplayMode}
                            />
                        </div>
                    ) : null}
                    {displayMode == "form" ? (
                        <div
                            style={{
                                padding: "10px",
                                color: "#1c1d1f",
                                border: "1px solid #1c1d1f",
                            }}
                        >
                            <Grid
                                style={{
                                    display: "flex",
                                    gap: 15,
                                    flexDirection: "column",
                                }}
                            >
                                <Grid item xs={12}>
                                    <b>Course title :</b>{" "}
                                    {selectedCourse?.course_title}
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: 10,
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <b>Name *</b>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <input
                                            className={classes?.input}
                                            id="applyCourseName"
                                            name="name"
                                            type="text"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid
                                    item
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        padding: 10,
                                    }}
                                >
                                    <Grid item xs={4}>
                                        <b>Email *</b>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <input
                                            className={classes?.input}
                                            id="applyCourseEmail"
                                            name="email"
                                            type="email"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <b>Introduction :</b>
                                </Grid>
                                <Grid item xs={12}>
                                    <textarea
                                        rows="5"
                                        className={classes?.textarea}
                                        style={{ minWidth: "400px" }}
                                        id="applyCourseIntroduction"
                                    ></textarea>
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                    color="primary"
                                        style={{
                                            background: "#1c1d1f",
                                            borderRadius: 0,
                                            padding: "4px 20px",
                                            marginBottom: 15,
                                            border: "none",
                                        }}
                                        onClick={applyCourse}
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                                {/* <Grid item xs={12}>
                                <Alert color="success">
                                    Successfully applied for the course!
                                </Alert>
                            </Grid> */}
                            </Grid>
                        </div>
                    ) : null}

                    {/* <Snackbar
                        open={courseApplied == "success"}
                        autoHideDuration={2000}
                        onClose={() => {
                            setCourseApplied(false);
                        }}
                    >
                        <CustomAlert severity="success">
                            Applied successfully!
                        </CustomAlert>
                    </Snackbar> */}
                    <Snackbar
                        open={courseApplied == "incompleteForm"}
                        autoHideDuration={2000}
                        onClose={() => {
                            setCourseApplied(false);
                        }}
                    >
                        <CustomAlert severity="error">
                            Incomplete Details!
                        </CustomAlert>
                    </Snackbar>
                    <Snackbar
                        open={courseApplied == "error"}
                        autoHideDuration={2000}
                        onClose={() => {
                            setCourseApplied(false);
                        }}
                    >
                        <CustomAlert severity="error">{courseStatus}</CustomAlert>
                    </Snackbar>
                </div>
            ) : (
                <>
                    <AcknowledgementModal
                        status={"success"}
                        message={"Applied Successfully!"}
                        loading={requestSent}
                    />
                </>
            )}
        </>
    );
};

export default withStyles(styles)(CoursesModal);

{
    /* <Grid item xs={12}>
                            <a
                                style={{ textDecoration: "none" }}
                                href={`${product["buynowurl"]}`}
                            >
                                <Button
                                    style={{
                                        background: "#1c1d1f",
                                        color: "white",
                                        borderRadius: 0,
                                        border: "none",
                                    }}
                                >
                                    Buy Now
                                </Button>
                            </a>
                        </Grid> */
}
{
    /* <Grid item xs={12}>
                            {product["product_description"]
                                ?.split("?. ")
                                ?.slice(0, 3)
                                ?.join("?. ")}
                        </Grid> */
}
{
    /* </Grid> */
}
{
    /* {coursesData?.map((course, index) => {
                return (
                    <Grid
                        key={index}
                        item
                        container
                        className={
                            "bg-white rounded shadow mb-3 " + classes?.courseCard
                        }
                    >
                        <div
                            item
                            style={{
                                margin: "0?.3rem 0?.5rem",
                                padding: "0?.1rem 0?.5rem",
                            }}
                        >
                            <Typography variant="subtitle1">
                                {course["course_title"]}
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className="text-muted"
                            >
                                {course["college_info"] +
                                    ", " +
                                    course["course_location"] +
                                    ", " +
                                    course["course_duration"]}
                            </Typography>

                            <div
                                style={{
                                    marginTop: "auto",
                                    marginBottom: "0?.75rem",
                                }}
                            >
                                <Alert
                                    style={{
                                        padding: "0?.1rem 0?.5rem",
                                        display: "inline-block",
                                        margin: "0",
                                    }}
                                    color="info"
                                >
                                    &#8377; {course["course_fees"]}
                                </Alert>
                                <Alert
                                    onClick={() => {
                                        showCourseDesc == index
                                            ? setShowCourseDesc(-1)
                                            : setShowCourseDesc(index);
                                    }}
                                    style={{
                                        padding: "0?.1rem 0?.5rem",
                                        display: "inline-block",
                                        margin: "0 0?.5rem",
                                        cursor: "default",
                                    }}
                                    color="primary"
                                >
                                    Details
                                </Alert>
                            </div>
                        </div>
                        <Collapse
                            isOpen={showCourseDesc === index}
                            className="rounded"
                            style={{
                                // padding: "0 1rem 0?.5rem 1rem",
                                background: "#f5f5f5",
                            }}
                            onClick={(e) => e?.preventDefault()}
                        >
                            <div style={{ padding: "0?.5rem 1rem" }}>
                                <Typography variant="subtitle2">
                                    {course["course_description"]}
                                </Typography>
                                
                            </div>
                        </Collapse>
                    </Grid>
                );
            })} */
}
