import React, { useState, useEffect } from "react";
import "./speakers.css";
import { baseUrl } from "../../store/restApi/api";
import Biomodal from "../speakers/Biomodal";
import { array, object } from "prop-types";
import { useStoreActions, useStoreState } from "easy-peasy";
import { getSpeakersList as getSpeakersListAPI } from "../../store/restApi/api";

//------------------------My Favourite Function--------------------------//
import {
    myFavouriteAdd,
    myFavouriteRemove,
} from "../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
//------------------------My Favourite Function--------------------------//

const Speakers = ({ event_id }) => {
    const [showmodal, Setshowmodal] = useState(false);
    const [speakers, setSpeakers] = useState([]);
    const [favourite, setfavourite] = useState();

    const { updateState } = useStoreActions((state) => state.state);
    const { eventConfig, userDetails , session_auditorium } = useStoreState((state) => state.state);
    const { boothDetails, exhibitorInfo } = useStoreState(
        (state) => state?.boothLiveState
    );
    useEffect(() => {
        async function fetchSpeakers() {
            let payload_data = {
                event_id: event_id,
            };
            getSpeakersListAPI(payload_data)
                .then((res) => {
                    setSpeakers(res);
                    setfavourite(res);
                })
                .catch((err) => {
                    //console.log(err);
                });
        }

        fetchSpeakers();

        return () => {
            setSpeakers();
        };
    }, []);

    /*------------------------------------------------>Favourite Functionals<----------------------------------------------- */
    const setFavorite_fn = (idx, speaker_id, status) => {
        setfavourite((favValue) =>
            [...favValue].map((el) =>
                el.speaker_id === speaker_id
                    ? { ...el, flag_status: status }
                    : el
            )
        );

        let favourite_payload = {
            user_id: userDetails?.id,
            favourite_type: "speaker",
            action_id: speaker_id,
            event_id: eventConfig?.data?.[0]?.event_id,
            flag_status: favourite
            //   booth_id: boothDetails?.booth[0]?.booth_id

        };
        if (status) {
            myFavouriteAdd(favourite_payload)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    //console.log(error);
                });
        } else {
            myFavouriteRemove(favourite_payload)
                .then((response) => {
                    console.log(response);
                })
                .catch((error) => {
                    //console.log(error);
                });
        }
    };

    /*------------------------------------------------>Favourite Functionals<----------------------------------------------- */
    const openmodal = (speakerid, speaker_overview) => {
        updateState({ speakerOverview: speaker_overview });
        Setshowmodal((prev) => !prev);
    };

    return (
        <>

{(session_auditorium !== null || typeof session_auditorium !== undefined)?(
    <>
    <Biomodal showmodal={showmodal} Setshowmodal={Setshowmodal} />



{favourite?.map(
    ({
        speaker_id,
        speaker_image,
        speaker_name,
        speaker_position,
        speaker_company,
        speaker_overview,
        flag_status,
        index,
    }) => (
        
            <>
            <div className="card-container" key={speaker_id}>
            <div className="upper-container">
                <div className="image-container">
                    <img
                        src={speaker_image}
                        alt={speaker_name}
                        style={{ color: "#fff", fontSize: "12px" }}
                    />
                </div>
            </div>
            <div className="lower-container">
                <div>
                    <span className="speaker_name">
                        {speaker_name}
                    </span>
                    <br />
                    <span className="speaker_position">
                        {speaker_position}
                    </span>
                    {/* <br /> */}
                    <span className="speaker_company">
                        {(speaker_company.substring("10")+"...")}
                    </span>
                    <br/>
                    {/* <div style={{ marginTop: "1rem" }}> */}
                        <button
                            className="btn"
                            onClick={() =>
                                openmodal(
                                    speaker_id,
                                    speaker_overview
                                )
                            }
                        >
                            Bio
                        </button>
                    {/* </div> */}
                </div>
                <div
                    onClick={() =>
                        setFavorite_fn(index, speaker_id, true)
                    }
                >
                    {flag_status ? (
                        <FavoriteIcon
                            style={{ backgroundColor: "white" }}
                        />
                    ) : (
                        <FavoriteBorderIcon
                            style={{ backgroundColor: "white" }}
                        />
                    )}
                </div>
            </div>
        </div>
            </>
        
        
    )
)}
    </>

):null }
            
        </>
    );
};

export default Speakers;