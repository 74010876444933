import React from 'react';
import { withRouter } from 'react-router';
import './Socialwall.css'

function Socialwall(){

    return(
        <div className='socialwall-wrapper'>
            <div className='socialwall'>
            <iframe src="https://social.hexafair.com/index.php/social-streaming/?stream_id=3" style={{background:'#EDF2F6'}} frameBorder="0" width='100%' height='100%' seamless="seamless"></iframe>
            </div>
        </div>
    )
}

export default withRouter (Socialwall);   