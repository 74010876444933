import { useStoreActions, useStoreState } from "easy-peasy";
import React, { useState } from "react";
import { logout , notInterested } from "../../store/restApi/api";
import ConfirmationPopup from "../confirmatiompopup/ConfirmationPopup";
import FeedBack from "../feedback/FeedBack";
import Modal from "../modal/Modal";
import UserDropDown from "../userDropDown/UserDropDown";
import { useHistory } from "react-router-dom";
import EditProfile from "../editProfile/EditProfile";

const Logout = () => {
    const { userDetails, eventConfig } = useStoreState((state) => state.state);
    const [openFeedback, setOpenFeedback] = useState(false);
    const [updateProfile, setUpdateProfile] = useState(false);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const { updateState } = useStoreActions((state) => state.state);
    const [logoutFlag, setLogoutFlag] = useState(false);
    const history = useHistory();
    const handleLogout = () => {
        setLogoutFlag(() => true);
        if (
            userDetails?.event_feedback_given === 0 &&
            userDetails?.event_feedback_not_interested === 0 &&
            eventConfig.data[0]?.feeback_form_enabled == "Yes"
        ) {
            setOpenConfirmation((value) => true);
        } else {
            logoutcaller()
            //setOpenConfirmation((value) => true);
        }
    };

    const logoutcaller = async () => {
        let logoutObj = {
            user_id: userDetails?.id,
            user_type: userDetails?.user_type,
            event_id: eventConfig?.data?.[0]?.event_id,
        };
        await logout(logoutObj);
        updateState({
            isLoggedIn: false,
            userDetails: {},
        });
        history.push("/login");
    };

    
    const submitNotInsterested = async () => {
        let logoutObj = {
            user_id: userDetails?.id,
            user_type: userDetails?.user_type,
            event_id: eventConfig?.data?.[0]?.event_id,
        };
        await notInterested(logoutObj);
        await logout(logoutObj);
        updateState({
            isLoggedIn: false,
            userDetails: {},
        });
        history.push("/login");
    };

    return (
        <>
            <Modal
                open={openConfirmation}
                onClose={() => {
                    setOpenConfirmation((value) => !value)
                    logoutcaller();
                }}
                widith="500px"
                height="200px"
            >
                <ConfirmationPopup
                    message="Thank You Please take a moment to fill out feedback form. 
                            We appreciate your feedback."
                    yesTrigger={() => {
                        setOpenConfirmation((value) => !value);
                        setOpenFeedback((value) => true);
                    }}
                    noTrigger={() => {
                        setOpenConfirmation((value) => !value);
                        submitNotInsterested();
                    }}
                />
            </Modal>
            <Modal
                open={openFeedback}
                onClose={() => {
                    setOpenFeedback((value) => false);
                }}
                widith="50%"
                height="70%"
            >
                <FeedBack
                    user_id={userDetails?.id}
                    event_id={eventConfig?.data?.[0]?.event_id}
                    logout={logoutcaller}
                    logutflag={logoutFlag}
                />
            </Modal>

            <Modal
                open={updateProfile}
                onClose={() => {
                    setUpdateProfile((value) => false);
                }}
                widith="70%"
                height="80%"
            >
                <EditProfile
                    user_id={userDetails?.id}
                    event_id={eventConfig?.data?.[0]?.event_id}
                    onClose={() => {
                        setUpdateProfile((value) => false);
                    }}
                />
            </Modal>
            <div            
            >
                <UserDropDown
                    exit={handleLogout}
                    feedBack={() => {
                        setLogoutFlag(() => false);
                        setOpenFeedback((value) => true);
                    }}
                    feedBackflag={userDetails?.event_feedback_given}
                    updateProfile={() => {
                        setUpdateProfile(true);
                    }}
                />
            </div>
        </>
    );
};

export default Logout;
