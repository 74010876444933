import React, { useState, useEffect } from "react";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Collapse, Spinner, Alert } from "reactstrap";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { Table } from "reactstrap";
import IconButton from "@material-ui/core/IconButton";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import Button from "../common/Button";
import { document_log as documentLogApi, SwagBag as SwagBagApi } from "../../../../store/restApi/boothLiveApi";
//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove, swagBagDocuments } from "../../../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';

//------------------------My Favourite Function--------------------------//

const styles = (theme) => ({
    modal: {
        margin: "0",
    },
    table: {
        margin: "10px auto",
        textAlign: "center",
        borderColor: "black",
        "& th": {
            borderColor: "black",
        },
        "& td": {
            borderColor: "black",
        },
        "& tr": {
            borderColor: "black",
        },
        "& thead": {
            borderColor: "black",
        },
    },
});

const DocumentsModal = ({ documentData, classes }) => {

    //Tracking process
    const { boothDetails, countryCodes } = useStoreState(
        (state) => state?.boothLiveState
    );
    const { eventConfig,userDetails, exhibitorInfo } = useStoreState((state) => state.state);

    /*------------My faourite functionality----------------------------*/
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    const [favourite, setfavourite] = useState(documentData);
    //    let favouritestatus_payload = {
    //        user_id: userDetails?.id,
    //        favourite_type: 'document',       
    //        event_id: eventConfig?.data?.[0]?.event_id,
    //        booth_id:boothDetails.booth[0].booth_id,
    //        exhibitor_id:exhibitorInfo?.exhibitor_id
    //    };
    //    useEffect(() => {
    //     swagBagDocuments(favouritestatus_payload).then((response) => {
    //            if(favourite.length==0)
    //            {                      
    //                setfavourite(response?.booth_document);
    //            }    
    //        })
    //        .catch((error) => {
    //            //console.log(error);
    //        }); 
    //    }, []);

    const openInNewTab = (url,doc_id) => {

        //Tracking document Api calling
        const payload = {
            visitor_user_id_type: userDetails?.user_type,
            visitor_user_id: userDetails?.id,
            event_id: eventConfig?.data[0]?.event_id,
            document_id: doc_id               
        };
        
        SwagBagApi(payload).then((response) => {
           // console.log(response?.message);               
        });
        //end

    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
};
       
   const setFavorite_fn=(idx,id,status)=>{       
       setfavourite(favValue => 
           [...favValue].map(el => 
               el.document_id === id ? ({...el, flag_status:status}) : el)
           )      
      
       let documentData_dummy=documentData;        
       documentData_dummy[idx].flag_status=status;
       updateState({ documentData: documentData_dummy});    

       const payload = {
            
        user_id: userDetails?.id,
        event_id: eventConfig?.data[0]?.event_id,
        action_id : id,
        favourite_type: "document", 
        booth_id:boothDetails?.booth[0]?.booth_id             
    };

    
    SwagBagApi(payload).then((response) => {
        //console.log(response?.message);               
    }); 

       let favourite_payload = {
         user_id: userDetails?.id,
         favourite_type: 'document',
         action_id:id,
         event_id: eventConfig?.data?.[0]?.event_id,
       };
       if(status)
       {
           myFavouriteAdd(favourite_payload).then((response) => {
            console.log(response);            
           })
           .catch((error) => {
               //console.log(error);
           });
       }
       else
       {
           myFavouriteRemove(favourite_payload).then((response) => {
               console.log(response);            
              })
              .catch((error) => {
                  //console.log(error);
              });
       }  
     };
   /*------------My faourite functionality----------------------------*/

    // const openInNewTab = (url,doc_id) => {

    //         //Tracking document Api calling
    //         const payload = {
    //             visitor_user_id_type: userDetails?.user_type,
    //             visitor_user_id: userDetails?.id,
    //             event_id: eventConfig?.data[0]?.event_id,
    //             document_id: doc_id               
    //         };
            
    //         documentLogApi(payload).then((response) => {
    //            // console.log(response?.message);               
    //         });
    //         //end

    //     const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    //     if (newWindow) newWindow.opener = null;
    // };
    return (
        <div className={classes.modal}>
            <Table bordered className={classes.table}>
                <tbody className="documentTbody" >
                    {favourite.map((document, index) => {
                        if (document) {
                            return (
                                <tr>
                                    <td dangerouslySetInnerHTML={{__html: `${document.document_title}`}} className="boothDocument" style={{ fontWeight: "bold" }}>
                                        {/* {document.document_title} */}
                                        
                                    </td>
                                    <td dangerouslySetInnerHTML={{__html: `${document.document_description}`}} className="boothDocument" style={{ padding:"0", margin:'0px' }}>
                                        {/* {document.document_description} */}
                                        {/* {document.write(document.document_description)} */}
                                    </td>
                                    <td   className="boothDocumentDownloadButton"
                                        style={{
                                            minWidth: "110px",
                                            cursor: "pointer",
                                        }}
                                        // onClick={(event) =>
                                        //     openInNewTab(document.document_path,document.document_id)
                                        // }
                                    >
                                        <Button
                                            style={{ height: 100, width: 100 }}
                                            OpenIcon={CloudDownloadIcon}
                                            handleClick={(event) =>
                                                openInNewTab(document.document_path,document.document_id)
                                            }
                                        ></Button>
                                        Download here
                                    </td>
                                    <td>  
                                        <div onClick={() => setFavorite_fn(index,document.document_id,true)}>
                                         {(document.flag_status)?(<RemoveCircleIcon />):(<AddCircleIcon />)}                                       
                                        </div>
                                    </td>
                                </tr>
                            );
                        }
                    })}
                </tbody>
            </Table>
        </div>
    );
};

export default withStyles(styles)(DocumentsModal);
