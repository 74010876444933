import { useState } from "react";
import { useStoreState } from "easy-peasy";
import "./productModal.css";




const Modelfinal =()=>{
    let {  model_360  } = useStoreState(
        (state) => state.boothLiveState)
        const [lightslide ,  setLightSlide] = useState("1")
      
    if(typeof model_360!== "undefined")
    {
    
          return(
              <>
              <div className="total">
              <div className="modelviewform">
               <div className="modelviewcontainer"> <span>LIGHT LEVEL</span><input type="range" value={lightslide} step="0.1" id="lightRange" min={1} max={3} onChange={()=>{ setLightSlide(document.getElementById('lightRange').value)}}  className="modelsliders"/></div> 
                </div>
              <model-viewer alt="model" src={model_360}  loading="eager" progress-bar   ar ar-modes="webxr scene-viewer quick-look" environment-image="neutral"
               exposure={lightslide} shadow-softness="1" poster="shared-assets/models/NeilArmstrong.webp" 
               seamless-poster shadow-intensity="2" camera-controls enable-pan ></model-viewer>
              </div>

              </>
          )
          }        
      };


export default Modelfinal