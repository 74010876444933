import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import Download from "../../../../assets/download-file.png";
// import { myBoxJobsView as myBoxJobsViewApi } from "../../../../store/restApi/api";

import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";
const Jobs = ({ event_id, user_id }) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
        {
            id: "code",
            displayName: "Code",
        },
        {
            id: "job_title",
            displayName: "Job Title",
        },
        {
            id: "job_location",
            displayName: "Location",
        },
        {
            id: "job_employer",
            displayName: "Employer",
        },
        {
            id: "Applied_Status",
            displayName: "Status",
        },
        {
            id: "Applied_Time",
            displayName: "Applied Time",
        },
        {
            id:"remove_favourite_job",
            displayName: "Remove",
        }
    ];
    const [child, setChild] = useState([]);

    const actionsUI = () => {
        return (
            <>
                <div>
                    <img src={Download} alt="person" />
                    <br />
                    <span>Download</span>
                </div>
            </>
        );
    };

    useEffect(() => {
        async function fetchDocuments() {
            // await fetch(`${baseUrl}/mybox_jobs_viewed.php`, {
            //     method: "post",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //         event_id: event_id,
            //         user_id: user_id,
            //     }),
            // })
            // .then((res) => res.json())

            let payload_data={
                event_id: event_id,
                user_id: user_id,
                swag_type:"job"
            };
            SwagListApi(payload_data)
                .then((res) =>{
                    setChild(() => res.jobs)
                })
                .catch((err) => {
                   // console.log(err);
                });
        }
        fetchDocuments();
        return () => {
            setChild(() => []);
        };
    }, []);

    return (
        <Listing
            header={header}
            child={child}
            actions={() => {}}
            actionHeader=""
        />
    );
};

export default Jobs;
