import React from "react";

function SubList({ value, active, update }) {
  return (
    <span
      style={{
        borderBottom: `${active === value ? "3px solid #1029f7" : ""}`,
      }}
      onClick={() => update(value)}
    >
      {value}
    </span>
  );
}

export default SubList;
