//-------------------------------------------LIVE Keys----------------------------------------------------------//
export const pubnubConfig = {
    publishKey: "pub-c-5aa8890f-4f6b-4eff-bcca-864b383bee76",
    subscribeKey: "sub-c-8d7513c0-7c65-4dcc-ab8d-edbe3a1f20cd",
};

export const videoCallURL = "https://meet.jit.si/";
//-------------------------------------------LIVE Keys----------------------------------------------------------//

//-------------------------------------------DEVAPP-2 Keys----------------------------------------------------------//

// export const pubnubConfig = {
//     publishKey: "pub-c-8f8cfd54-e447-4b41-a39a-d4cfd526ec28",
//     subscribeKey: "sub-c-1e1b2d73-fdf5-4a58-ac05-1051f822ceed",
// }

// export const videoCallURL = "https://meet.jit.si/"

//-------------------------------------------DEVAPP-2 Keys----------------------------------------------------------//
