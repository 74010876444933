import React from "react";
import { Router, Switch, Route, withRouter, Redirect } from "react-router-dom";
import ExhibitorsListPage from "../components/pages/ExhibitorsListPage";
import Login from "../components/pages/login/Login";
import Register from "../components/pages/register/Register";
import Forgetpassword from "../components/pages/forgetPassword/Forgetpassword";
import Changepassword from "../components/pages/forgetPassword/Changepassword";
import EventNotLive from "../components/pages/eventNotLive/EventNotLive";
import Documents from "../boothLive/src/components/pages/Documents";
import Videos from "../boothLive/src/components/pages/Videos";
import Appointment from "../boothLive/src/components/pages/Appointment";
import BusinessCard from "../boothLive/src/components/pages/BusinessCard";
import Boothconferences from "../boothLive/src/components/pages/Boothconferences";
import Jobs from "../boothLive/src/components/pages/Jobs";
import Products from "../boothLive/src/components/pages/Products";
import Courses from "../boothLive/src/components/pages/Courses";
import CourseDetails from "../boothLive/src/components/pages/CourseDetails";
import ProductDetails from "../boothLive/src/components/pages/ProductDetails";
import JobDetails from "../boothLive/src/components/pages/JobDetails";
import Event_conferences from "../boothLive/src/components/pages/Event_conferences";
import Event_conferences_details from "../boothLive/src/components/pages/Event_conferences_details";
import BoothConferenceDetails from "../boothLive/src/components/pages/BoothConferenceDetails";
import attendeeDetails from "../boothLive/src/components/pages/Attendee";
import DacastPLayer from "../components/Livestreaming/Dacast/Dacast";
import VimeoPlayer from "../components/Livestreaming/Vimeo/vimeolivestream";
import YoutubePlayer from "../components/Livestreaming/Youtube/Youtube";
// eslint-disable-next-line import/no-anonymous-default-export
export default [
    {
        path: "/login",
        exact: true,
        component: withRouter(Login),
        strict: true,
    },
    {
        path: "/register",
        exact: true,
        component: withRouter(Register),
        strict: true,
    },
    {
        path: "/forgetpassword",
        exact: true,
        component: withRouter(Forgetpassword),
        strict: true,
    },
    {
        path: "/changepassword",
        exact: true,
        component: withRouter(Changepassword),
        strict: true,
    },
    {
        path: "/event-not-live",
        exact: true,
        component: withRouter(EventNotLive),
        strict: true,
    },
    {
        path: "/documents",
        exact: true,
        component: withRouter(Documents),
        strict: true,
    },
    {
        path: "/booth-conferences",
        exact: true,
        component: withRouter(Boothconferences),
        strict: true,
    },
    {
        path: "/event-conferences",
        exact: true,
        component: withRouter(Event_conferences),
        strict: true,
    },
    {
        path: "/event-conferences-details",
        exact: true,
        component: withRouter(Event_conferences_details),
        strict: true,
    },
    {
        path: "/booth-conferences-details",
        exact: true,
        component: withRouter(BoothConferenceDetails),
        strict: true,
    },
    {
        path: "/jobs",
        exact: true,
        component: withRouter(Jobs),
        strict: true,
    },
    {
        path: "/products",
        exact: true,
        component: withRouter(Products),
        strict: true,
    },
    {
        path: "/courses",
        exact: true,
        component: withRouter(Courses),
        strict: true,
    },
    {
        path: "/coursedetails",
        exact: true,
        component: withRouter(CourseDetails),
        strict: true,
    },
    {
        path: "/coursedetails",
        exact: true,
        component: withRouter(CourseDetails),
        strict: true,
    },
    {
        path: "/jobdetails",
        exact: true,
        component: withRouter(JobDetails),
        strict: true,
    },
    {
        path: "/productdetails",
        exact: true,
        component: withRouter(ProductDetails),
        strict: true,
    },
    {
        path: "/videos",
        exact: true,
        component: withRouter(Videos),
        strict: true,
    },
    {
        path: "/appointment",
        exact: true,
        component: withRouter(Appointment),
        strict: true,
    },
    {
        path: "/businessCard",
        exact: true,
        component: withRouter(BusinessCard),
        strict: true,
    },
    {
        path: "/attendee",
        exact: true,
        component: withRouter(attendeeDetails),
        strict: true,
    },
    {
        path: "/dacast",
        exact: true,
        component: withRouter(DacastPLayer),
        strict: true,
    },
    // {
    //     path: "/vimeo",
    //     exact: true,
    //     component: withRouter(VimeoPlayer),
    //     strict: true,
    // },
    // {
    //     path: "/youtube",
    //     exact: true,
    //     component: withRouter(YoutubePlayer),
    //     strict: true,
    // },
];
