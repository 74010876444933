import React , {useRef ,useEffect , useCallback} from 'react'
import {useSpring , animated} from 'react-spring'
import {AiOutlineClose} from "react-icons/ai"
import './biomodal.css';
import { useStoreActions, useStoreState } from "easy-peasy";





const Biomodal = ({showmodal,Setshowmodal }) => {
    const Modalref = useRef()

    const {speakerOverview} = useStoreState((state) => state.state);
    
    const closemodal= e =>{
        if(Modalref.current === e.target){
            Setshowmodal(false);
        }
    }


    const animation = useSpring(
        {
            config:{
                duration:200
            },
            opacity: showmodal ? 1 : 0 ,
            transform : showmodal ? 'translateY(0%)' : 'translateY(-100%)'
        }
    )
    
    
    const keypress = useCallback(e=>{
        if(e.key === "Escape" && showmodal){
            Setshowmodal(false);
        }
    },[showmodal , Setshowmodal])
    
    useEffect(()=>{
        document.addEventListener('keydown',keypress);
        return
    })
    return (
        <>
        {showmodal  ? (
            <div className="background" ref={Modalref} onClick={closemodal}> 
                <animated.div style={animation}>
        <div className="container " >
           <div className="text" >{speakerOverview}</div>
           <div className="logo"> 
           <div className="logo-outer">
           <div className="logo-container" onClick={()=>Setshowmodal(prev=>!prev)}>
           <AiOutlineClose aria-label="close modal"  />
           </div>
           </div>
           </div>
           
        </div>
        </animated.div> 
        </div>) : null }

        
        
       
    </>)}

    

export default React.memo(Biomodal)

