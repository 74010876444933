import React, { useState } from "react";
import call from "../../assets/call-icon.png";
import email from "../../assets/email.png";
import textchat from "../../assets/textchat.png";
import videochat from "../../assets/videochat.png";
import user from "../../assets/user-icon.png";
import { useStoreActions, useStoreState } from "easy-peasy";
import { serverApi } from "../../store/restApi/serverApi";
import { videoCallURL } from "../../pubNub/constants/PubNubConstant";
// import Modal1 from '../modal/Modal';
import ChatModal from "../modal/ChatModal";
import EventChat from "../pages/chat/Chat";
import useWindowSize from "../pages/usersList/useWindowSize";
import fb_logo from "../../assets/fb-logo.png";
import li_logo from "../../assets/linkedin-icon-512x512-vkm0drb1.png"
import skype from "../../assets/skype.png"

const Chat = () => {
    return (
        <EventChat />
    );
};
const BussinessCard = ({ bussinessCard }) => {
    // console.log(bussinessCard)
    const [showChatModal, setShowChatModal] = useState(false);
    const [profile, setProfile] = useState(bussinessCard?.profile_det);
    const { userDetails, eventConfig } = useStoreState((state) => state.state);
    const { profile_det, v_call } = useStoreState((state) => state.state)
    const { sendMessage, videoCallAction, updateState } = useStoreActions((state) => state.pubNub);
    const pubAction = useStoreActions((state) => state.pubNub);
    const width = useWindowSize()

    const handleChat = () => {

        let payload = {
            event_id: eventConfig?.data?.[0]?.event_id,
            user_id: profile?.user_id,
        };

        serverApi
            .getChatMembersDetailsById(payload)
            .then(({ data }) => {
                let chatUserObj = {
                    displayName: data[0]?.name,
                    user_id: data[0]?.id,
                    email_id: data[0]?.email,
                    type: 'NORMAL'
                };
                updateState({ currentChatUserDetails: chatUserObj });
                pubAction.updateState({ chatType: "" });
                setShowChatModal(true);

            })
            .catch((err) => {
                console.log(err);
            });
    };

    const triggerVideoCall = () => {

        let payLoad = {
            channel: profile?.user_id,
            message: userDetails?.displayname,
            meta: {
                ToUUID: profile?.user_id,
                ToUserName: profile?.first_name,
                FromUUID: userDetails?.id,
                fromUser: userDetails?.displayname,
                chatType: "VIDEO",
                chatURL: videoCallURL + userDetails?.id
            },
        };
        document.getElementById("close").click();
        videoCallAction({})
        videoCallAction(payLoad)
        sendMessage(payLoad);

    }

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "space-between",
                position: "absolute",
                width: "calc(100% - 50px)",
                height: "calc(100% - 35px)",
            }}
        >
            <div style={{ padding: " 55px 20px" }}>
                <div style={{
                    marginTop: '-3rem',

                    position: 'absolute',
                    marginLeft: '6rem'
                }} >
                    <img
                        src={bussinessCard?.b_card?.businesscard_image}
                        alt="img"
                        style={{ width: "50px", height: "50px" }}
                    />
                </div>

            </div>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    marginRight: '10%'
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        marginTop: "5rem"
                    }}
                >

                    <img
                        src={user}
                        alt="user"
                        style={{ width: "30px", height: "30px" }}
                    />
                    <div style={{ fontSize: '14px' }}>
                        <div>
                            <span style={{ marginLeft: "5px" }}>
                                {bussinessCard?.b_card?.businesscard_contact_person}
                            </span>
                        </div>
                        <div>
                            <span style={{ marginLeft: "5px" }}>
                                {
                                    bussinessCard?.b_card?.businesscard_contact_person_designation
                                }
                            </span>
                        </div>
                    </div>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        marginTop: '5px'
                    }}
                >
                    <img
                        src={call}
                        alt="call"
                        style={{ width: "30px", height: "30px" }}
                    />
                    <span style={{ marginLeft: "5px" }}>
                        {(bussinessCard?.b_card?.businesscard_mobile_no_country_code != "" && bussinessCard?.b_card?.businesscard_mobile_no != "") ? (bussinessCard?.b_card?.businesscard_mobile_no_country_code + " " + bussinessCard?.b_card?.businesscard_mobile_no) : (bussinessCard?.b_card?.businesscard_phone_no_country_code + " " + bussinessCard?.b_card?.businesscard_phone_no)}
                    </span>
                </div>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        marginTop: '5px'
                    }}
                >
                    <img
                        src={email}
                        alt="email"
                        style={{ width: "30px", height: "30px" }}
                    />
                    <span style={{ marginLeft: "5px" }}>
                        {bussinessCard?.b_card?.businesscard_email}
                    </span>
                </div>
                {width.width <= 1000 ? (
                    <>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                                marginTop: '5px'
                            }}
                        >
                            {(eventConfig?.data?.[0]?.chat_feature_is_enabled.localeCompare(
                                "Yes"
                            ) == 0) ? (
                                <>

                                    <img
                                        src={videochat}
                                        alt="user"
                                        style={{ width: "30px", height: "30px" }}
                                        onClick={triggerVideoCall}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                        Video Chat

                                    </span>
                                </>
                            ) : null}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                fontSize: "14px",
                                marginTop: '5px',
                                borderBottom: '1px solid'
                            }}
                        >
                            {(eventConfig?.data?.[0]?.attendee_to_attendee_video_chat_is_enabled.localeCompare(
                                "Yes"
                            ) == 0 &&
                                eventConfig?.data?.[0]?.attendee_to_exhibitor_video_chat_is_enabled.localeCompare(
                                    "Yes"
                                ) == 0) ? (
                                <>
                                    <img
                                        src={textchat}
                                        alt="user"
                                        style={{ width: "30px", height: "30px" }}
                                        onClick={() => handleChat(profile)}
                                    />
                                    <span style={{ marginLeft: "5px" }}>
                                        TextChat

                                    </span>
                                </>
                            ) : null}

                        </div>
                    </>
                ) : null}
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        padding: "25px"
                    }}
                >

                    <span style={{ marginLeft: "5px" }}>
                        <a href="https://www.facebook.com/virat.kohli/" target="_blank">
                            <img
                                src={fb_logo}
                                alt="fb"
                                style={{ width: "30px", height: "30px", marginRight: '4px' }}
                            />
                        </a>
                        <a href="https://www.facebook.com/virat.kohli/" target="_blank">  <img
                            src={li_logo}
                            alt="fb"
                            style={{ width: "30px", height: "30px", marginRight: '4px' }}
                        /></a>
                        <a href="https://www.facebook.com/virat.kohli/" target="_blank">  <img
                            src={skype}
                            alt="fb"
                            style={{ width: "30px", height: "30px", marginRight: '4px' }}
                        /></a>


                        <>
                            <ChatModal
                                open={showChatModal}
                                onClose={() => setShowChatModal((value) => false)}

                                widith="70%"
                                height="80%"
                            >
                                <Chat />
                            </ChatModal>
                        </>

                    </span>
                </div>



            </div>
        </div>

    );
};

export default BussinessCard;
