import React, { useEffect, useState } from "react";
import SessionList from "./SessionList";
import { baseUrl } from "../../store/restApi/api";
import { useStoreState, useStoreActions } from "easy-peasy";
import SubList from "./SubList";
import { getSessionsListAuditorium as getSessionsListAuditoriumApi } from "../../store/restApi/boothLiveApi";

const Agenda = ({ event_id, attendee_id }) => {
    const [conferenceList, setConferenceList] = useState([]);
    const [activeHallNumber, setActiveHallNumber] = useState(`TRACK 1`);
    const [sessionActive, setSessionActive] = useState(0);
    const [streamingList, setStreamingList] = useState([]);
    const { updateState } = useStoreActions((state) => state.state);

    useEffect(() => {
        async function fetchConference() {
            // const response = await fetch(
            //   `${baseUrl}/session_list_auditorium.php`,

            //   {

            //     method: 'post',
            //     headers: { 'Content-Type': 'application/json' },
            //     body: JSON.stringify({
            //       "event_id": event_id,
            //       "attendee_id": attendee_id
            //     })

            //   }
            //   .then((res) => res.json())
            //   .catch((err) => {
            //     //console.log(err);
            //     //console.log(`${baseUrl}/session_list_auditorium.php`)
            //   });
            // setConferenceList(response.session_list);
            let payload_data = {
                event_id: event_id,
                attendee_id: attendee_id,
            };

            getSessionsListAuditoriumApi(payload_data)
                .then((res) => {
                    setConferenceList(res.session_list);
                    updateState({ session_auditorium: res.session_list });
                    // setConferenceList(res.sessionList);
                })
                .catch((err) => {
                    //console.log(err);
                    //console.log(`${baseUrl}/session_list_auditorium.php`)
                });
        }

        fetchConference();
        return () => {};
    }, [activeHallNumber, sessionActive]);

    useEffect(() => {
        setStreamingList([]);
        return () => {};
    }, [sessionActive]);

    useEffect(() => {
        if (conferenceList && conferenceList?.length !== 0) {
            if (sessionActive === 0) {
                setSessionActive(conferenceList[0]?.session_date);
            }
            updateState({ sessionsList: conferenceList });
        }
        return () => {};
    }, [conferenceList]);

    return (
        <div
            className="conference_header"
            style={{
                width: "calc(100% )",
                height: "calc(100% - 80px)",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <div className="conference_subheader">
                {conferenceList?.map(({ session_date }) => (
                    <SubList
                        key={session_date}
                        active={sessionActive}
                        update={(date) => {
                            setSessionActive(date);
                            setStreamingList([]);
                        }}
                        value={session_date}
                    />
                ))}
            </div>
            <div className="conference_subheader">
                {conferenceList
                    ?.find((a) => a.session_date == sessionActive)
                    ?.hall_list?.map(({ streaming_list }, index) => {
                        return (
                            <SubList
                                key={index}
                                active={activeHallNumber}
                                update={(i) => {
                                    setActiveHallNumber(() => i);
                                }}
                                value={`TRACK ${index + 1}`}
                            />
                        );
                    })}
            </div>
            <div className="agenda_container">
                <table className="agenda_table">
                    <thead className="agenda_head th">
                        <tr colSpan="4">
                            <th colSpan="4">
                                <span>Session </span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="agenda_body">
                        {conferenceList
                            ?.find((a) => a.session_date == sessionActive)
                            ?.hall_list?.map(({ streaming_list }) =>
                                streaming_list.map(
                                    (
                                        {
                                            Status,
                                            session_title,
                                            session_description,
                                            session_start_time,
                                            session_end_date,
                                            session_end_time,
                                            session_start_date,
                                            session_start_timezone,
                                            session_end_timezone,
                                            hall_id,
                                            session_id,
                                            channel_id,
                                            streaming_provider,
                                            session_rating,
                                        },
                                        index
                                    ) => {
                                        if (
                                            `TRACK ${parseInt(hall_id)}` ===
                                            activeHallNumber
                                        ) {
                                            return (
                                                <SessionList
                                                    key={`session${index}`}
                                                    title={session_title}
                                                    desc={session_description}
                                                    startDate={
                                                        session_start_date
                                                    }
                                                    startTime={
                                                        session_start_time
                                                    }
                                                    endDate={session_end_date}
                                                    endTime={session_end_time}
                                                    Status={Status}
                                                    startZone={
                                                        session_start_timezone
                                                    }
                                                    endZone={
                                                        session_end_timezone
                                                    }
                                                    session_id={session_id}
                                                    event_id={event_id}
                                                    attendee_id={attendee_id}
                                                    channel_id={channel_id}
                                                    streaming_provider={
                                                        streaming_provider
                                                    }
                                                    session_rating={
                                                        session_rating
                                                    }
                                                />
                                            );
                                        } else {
                                            return <tr></tr>;
                                        }
                                    }
                                )
                            )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default Agenda;
