import React, { useEffect, useState, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "./common/Button";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemote";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import IconButton from "@material-ui/core/IconButton";
import PauseCircleOutlineIcon from "@material-ui/icons/PauseCircleOutline";
import Forward10Icon from "@material-ui/icons/Forward10";
import Replay10Icon from "@material-ui/icons/Replay10";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
import Menu from "@material-ui/core/Menu";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Carousel from "react-multi-carousel";
import CloseIcon from "@material-ui/icons/Close";
import { useStoreState } from "easy-peasy";
import Modal from "./Modal";
import './audiovideorotate.css'

const useStyles = makeStyles((theme) => ({
   /* root: {
        height: 10,
        transform: "translateZ(0px)",
        flexGrow: 1,
        "& .MuiFab-primary": {
            backgroundColor: "#059DC0",
        },
        "& .MuiSvgIcon-root": {
            height: "2rem",
            marginRight: "3px"         
        },
    },*/
    speedDial: {
        position: "absolute",
        bottom: 0,
        right: theme.spacing(3),       
    },
    menuContainer: {
        marginBottom: "100px",       
    },
    menuContainerPaper: {
        // position: "absolute",
        // right: 0,
        width: "auto",
        backgroundColor: "rgba(0, 0, 0, 0)",
        boxShadow: "none",
        // marginTop: 10,
        marginLeft: -65,      
    },
    menuList: {
        display: "flex",
        padding: 0,
        backgroundColor: "#000",
    },
    iconButton: {
        "&:hover": {
            background: "#f00",
        },
    },
}));

export default function VideoControlsDial(props) {
    const { videoElement, setVideoElement } = props;  const classes = useStyles();
    const { boothVideos, exhibitorInfo, isSmallScreen } = useStoreState(
        (state) => state.boothLiveState
    );
    //click setclick are hacky way to reset the component buttons
    // console.log(videoElement , " videocontrol")
    const [click, setClick] = useState(false);
    const buttonRef = useRef();
    const [anchorEl, setAnchorEl] = React.useState(null);

    const [anchorElVideos, setAnchorElVideos] = React.useState(null);
    const [openVideosModal, setOpenVideosModal] = useState(false);
    const [activeVideo, setActiveVideo] = React.useState(0);

    const [focussedVideosStartIndex, setFocussedVideosStartIndex] = useState(0);
    // console.log("biitonfalf", buttonRef);
    const actions = [
        // {
        //     icon: (
        //         <IconButton
        //             id="videoControlVideosList"
        //             style={{
        //                 backgroundColor: !openVideosModal ? "#000" : "red",
        //                 borderRadius: 0,
        //                 padding: "8px 12px",
        //             }}
        //             className={classes.iconButton}
        //             onMouseEnter={(e) =>
        //                 (document.getElementById(
        //                     "videoControlVideosList"
        //                 ).style.background = "red")
        //             }
        //             onMouseLeave={(e) => {
        //                 if (!openVideosModal)
        //                     document.getElementById(
        //                         "videoControlVideosList"
        //                     ).style.background = "black";
        //             }}
        //             onClick={() => {
        //                 document.getElementById(
        //                     "videoControlVideosList"
        //                 ).style.background = "red";
        //             }}
        //         >
        //             <OndemandVideoIcon
        //                 // round
        //                 // fontSize={isSmallScreen ? "small" : "large"}
        //                 style={{ height: 20,background: "rgba(0, 0, 0, 0)" }}
        //                 htmlColor="white"                        
        //             />
        //         </IconButton>
        //     ),
        //     name: "videosList",
        // },
        {
            icon: (
                <IconButton
                    onClick={() => {
                        videoElement.currentTime -= 10;
                        setClick((click) => !click);
                    }}
                    id="videoControlForward10"
                    onMouseEnter={(e) =>
                        (document.getElementById(
                            "videoControlForward10"
                        ).style.background = "red")
                    }
                    onMouseLeave={(e) =>
                        (document.getElementById(
                            "videoControlForward10"
                        ).style.background = "black")
                    }
                    style={{
                        borderRadius: 0,
                        padding: "8px 12px",
                    }}
                    key="video_backward"
                >
                    <Replay10Icon
                        round
                        // fontSize={isSmallScreen ? "small" : "large"}
                        style={{ height: 25 }}
                        htmlColor="white"
                    />
                </IconButton>
            ),
            name: "backward",
        },
        {
            icon: (
                <IconButton
                    onClick={() => {
                        videoElement.currentTime += 10;
                        setClick((click) => !click);
                    }}
                    style={{
                        borderRadius: 0,
                        padding: "8px 12px",
                    }}
                    id="videoControlBackward10"
                    onMouseEnter={(e) =>
                        (document.getElementById(
                            "videoControlBackward10"
                        ).style.background = "red")
                    }
                    onMouseLeave={(e) =>
                        (document.getElementById(
                            "videoControlBackward10"
                        ).style.background = "black")
                    }
                    key="video_forward"
                >
                    <Forward10Icon
                        round
                        // fontSize={isSmallScreen ? "small" : "large"}
                        style={{ height: 25 }}
                        htmlColor="white"
                    />
                </IconButton>
            ),
            name: "forward",
        },
        // {
        //     icon: (
        //         <IconButton
        //             onClick={() => {
        //                 videoElement.muted = false;
        //                 setClick((click) => !click);
        //             }}
        //             style={{
        //                 borderRadius: 0,
        //                 padding: "8px 12px",
        //             }}
        //             id="videoControlMute"
        //             onMouseEnter={(e) =>
        //                 (document.getElementById(
        //                     "videoControlMute"
        //                 ).style.background = "red")
        //             }
        //             onMouseLeave={(e) =>
        //                 (document.getElementById(
        //                     "videoControlMute"
        //                 ).style.background = "black")
        //             }
        //             // style={{ "&:hover": { background: "white" } }}
        //         >
        //             <VolumeOffIcon
        //                 round
        //                 // fontSize={isSmallScreen ? "small" : "large"}
        //                 style={{ height: 25 }}
        //                 htmlColor="white"
        //             />
        //         </IconButton>
        //     ),
        //     name: "volumeOn",
        // },
        // {
        //     icon: (
        //         <IconButton
        //             onClick={() => {
        //                 videoElement.muted = true;
        //                 setClick((click) => !click);
        //             }}
        //             style={{
        //                 borderRadius: 0,
        //                 padding: "8px 12px",
        //             }}
        //             id="videoControlUnMute"
        //             onMouseEnter={(e) =>
        //                 (document.getElementById(
        //                     "videoControlUnMute"
        //                 ).style.background = "red")
        //             }
        //             onMouseLeave={(e) =>
        //                 (document.getElementById(
        //                     "videoControlUnMute"
        //                 ).style.background = "black")
        //             }
        //         >
        //             <VolumeUpIcon
        //                 round
        //                 // fontSize={isSmallScreen ? "small" : "large"}
        //                 style={{ height: 25 }}
        //                 htmlColor="white"
        //             />
        //         </IconButton>
        //     ),
        //     name: "volumeOff",
        // },
        {
            icon: (
                <IconButton
                    onClick={() => {
                        videoElement?.pause();
                        setClick((click) => !click);
                        document.getElementById(
                            "videoControPause"
                        ).style.background = "red";
                    }}
                    style={{
                        borderRadius: 0,
                        padding: "8px 12px",
                        background: "red",
                    }}
                    id="videoControPause"
                    onMouseEnter={(e) =>
                        (document.getElementById(
                            "videoControPause"
                        ).style.background = "red")
                    }
                    // onMouseLeave={(e) =>
                    //     (document.getElementById(
                    //         "videoControPause"
                    //     ).style.background = "black")
                    // }
                    key="video_pause"
                >
                    <PauseCircleOutlineIcon
                        // fontSize={isSmallScreen ? "small" : "large"}
                        style={{ height: 25 }}
                        htmlColor="white"
                    />
                </IconButton>
            ),
            name: "pause",
        },
        {
            icon: (
                <IconButton
                    onClick={() => {
                       if(videoElement.error == null){
                        videoElement?.play();
                        setClick((click) => !click);
                        document.getElementById(
                            "videoControPlay"
                        ).style.background = "red";
                       }
                    }}
                    style={{
                        borderRadius: 0,
                        padding: "8px 12px",
                        background: "red",
                    }}
                    id="videoControPlay"
                    onMouseEnter={(e) =>
                        (document.getElementById(
                            "videoControPlay"
                        ).style.background = "red")
                    }
                    // onMouseLeave={(e) =>
                    //     (document.getElementById(
                    //         "videoControPlay"
                    //     ).style.background = "black")
                    // }
                    key="video_play"
                >
                    <PlayCircleOutlineIcon
                        style={{ height: 25 }}
                        htmlColor="white"
                    />
                </IconButton>
            ),
            name: "play",
        },
    ];

    const handleClose = () => {
        setOpenVideosModal(false);
        setAnchorEl(null);
    };
    useEffect(() => {
                  
           // setAnchorEl(buttonRef?.current);
       
            setAnchorEl(null);
        

        //console.log("videoDtaadfaa", focussedVideosStartIndex);
        return () => {
            //console.log("component unmount");
        };
    }, []);

    return (
        <div
            className="video-overalls"
        >
            <div className={classes.speedDial}>
                <Button
                    id="videoRemote"
                    ref={buttonRef}
                    OpenIcon={SettingsRemoteIcon}
                    handleClick={(event) => {
                        setAnchorEl(document?.getElementById("videoRemote"));
                        // setAnchorEl(event.target);
                    }}
                    isSquare={true}
                ></Button>
                <Menu 
                    className={classes.menuContainer}
                    id="simple-menu"
                    classes={{
                        paper: classes.menuContainerPaper,
                        list: classes.menuList,
                    }}
                    anchorEl={anchorEl}
                    keepMounted
                    anchorOrigin={{ horizontal: "right",vertical:"bottom" }}
                    transformOrigin={{
                        horizontal: "left",
                        vertical:"top"
                    }}
                    elevation={0}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}                    
                >
                    {actions.map((action, index) => {
                        if (action.name == "play" && videoElement?.paused) {
                            return (
                                <MenuItem
                                    style={{
                                        backgroundColor: "#000",
                                        "&:hover": {
                                            backgroundColor: "#fff",
                                        },
                                        padding: 0,
                                    }}
                                    key={"videocontrol_"+index}
                                >
                                    {action.icon}
                                </MenuItem>
                            );
                        }  else if (
                            action.name == "pause" &&
                            !videoElement?.paused
                        ) {
                            return (
                                <>
                                    <MenuItem
                                        style={{
                                            backgroundColor: "#000",
                                            // "&:hover": { background: "red" },
                                            padding: 0,
                                        }}
                                        key={"videocontrol_"+index}
                                    >
                                        {action.icon}
                                    </MenuItem>
                                </>
                            );
                        }  else if (
                            ["play", "pause"].indexOf(
                                action.name
                            ) == -1
                        ) {
                            return (
                                <MenuItem
                                    style={{
                                        backgroundColor: "#000",
                                        "&:hover": { background: "none" },
                                        padding: 0,
                                    }}
                                    key={"videocontrol_"+index}
                                >
                                    {action.icon}
                                </MenuItem>
                            );
                        }
                    })}
                </Menu>
                {openVideosModal && (
                    <Modal
                        handleModalClose={() => setOpenVideosModal(false)}
                        show={openVideosModal}
                        close={() => setOpenVideosModal(false)}
                        title={exhibitorInfo["exhibitor_company_name"]}
                        videoData={boothVideos}
                        url={exhibitorInfo["exhibitor_company_url"]}
                        type="video"
                        showVideoPlayer={false}
                        setVideoSrc={(path, index) => {
                            setActiveVideo(index);
                           // console.log("played");
                            videoElement.src = path;
                            videoElement.play();
                            setOpenVideosModal(false);
                            setAnchorEl(null);
                        }}
                        customPosition={true}
                        activeVideo={activeVideo}
                        focussedVideosStartIndex={focussedVideosStartIndex}
                        setFocussedVideosStartIndex={
                            setFocussedVideosStartIndex
                        }
                    />
                )}
            </div>
        </div>
    );
}
