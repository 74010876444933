import React, { useEffect, useState } from "react";
import Rotateicon from "../../../assets/rotateico.png";
import { useStoreActions, useStoreState } from "easy-peasy";
import '../../audiovideorotate.css';

const RotateObject = () => {
   
    
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    let { rotateDirection } = useStoreState((state) => state.boothLiveState); 

    const rotateFn = () =>{
        if(typeof(rotateDirection)=="undefined") 
        {
            updateState({ rotateDirection: 0 });
        }  
        else
        {
            if(rotateDirection==3)
            {
                rotateDirection=-1;
            }
            rotateDirection = rotateDirection+1;
            updateState({ rotateDirection: rotateDirection });            
        }           
    }

    return (
        <>
        <img 
       id="rotate" 
       src={Rotateicon} 
       style={{width:"2.6em",height :"2.6em",cursor:" pointer"}}
       className="rotate-overalls"
       onClick={rotateFn}    
       />
        </>
    
    );
};

export default RotateObject;
