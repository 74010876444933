import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import Grid from "@material-ui/core/Grid";

const Appointment = () => {
    const { boothDetails, boardsEnabled } = useStoreState(
        (state) => state.boothLiveState
    );
    const { eventConfig } = useStoreState((state) => state.state);
    const eventId = eventConfig?.data?.[0]?.event_id;
    const [url, setUrl] = useState(undefined);
    useEffect(() => {
        setUrl(boothDetails?.booth?.[0]?.appointment_booking_url);
    }, [boothDetails]);
    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );

    useEffect(() => {
        if (isEmpty(boothDetails) && boothId && eventId) {
            getBoothDetails({
                booth_id: boothId,
                // booth_url: "https://dev-app-lite.hexafair.com/#/supernatural",
                event_id: eventId,
                //booth_url: "https://www.booth.live/#/it-company-demo-booth",
            });
        }
    }, [boothId]);
    return (
        <>
            {boardsEnabled?.hybrid_format_enabled == "No" ? (
                <Forbidden />                
            ) : (
                <>
                {(typeof url=="undefined")?(
                    <Grid
                        container
                        item
                        justify="center"
                        style={{
                            margin: 5,
                        }}                
                        direction="column"
                    >
                        <Grid item className="h4 text-center p-2">
                        No Data Found
                        </Grid>
                    </Grid>       
                ):(
                <iframe
                    id="appointmentIframe"
                    style={{ border: "none", width: "100%", height: "100%" }}
                    // className={props.classes.iframe}
                    src={url ? url : ""}
                ></iframe>
                )}
                </>
            )}
        </>
    );
};

export default Appointment;
