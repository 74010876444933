import React, {
    useRef,
    useCallback,
    useMemo,
    useState,
    useEffect,
    useLayoutEffect,
} from "react";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import { useStoreActions, useStoreState } from "easy-peasy";
import { useLoader, useThree, useFrame } from "@react-three/fiber";
// import booth from "../assets/a22.glb";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import imgD from "../assets/time.png";
import imgD1 from "../assets/mg.jpg";
import { Html } from "@react-three/drei";
import ReactPlayer from "react-player";
import Modal from "./Modal";
import { StoreProvider } from "easy-peasy";
import videoD from "../assets/videoD.mp4";
import store from "../../../../src/store/index";
import MuxPlayer from "./muxLivestream";
import { isEmpty } from "lodash";
import Vimeo from "@u-wave/react-vimeo";
import StreamControls from "./StreamControls";
import nx from "../texture/nx.jpg";
import ny from "../texture/ny.jpg";
import nz from "../texture/nz.jpg";
import px from "../texture/px.jpg";
import py from "../texture/py.jpg";
import pz from "../texture/pz.jpg";
import drag_img from '../assets/foot.png';
import * as TWEEN from "tween.js";
import VimeoPlayer from "../../../components/Livestreaming/Vimeo/vimeolivestream";
import YoutubePlayer from "../../../components/Livestreaming/Youtube/Youtube";
import DacastPLayer from "../../../components/Livestreaming/Dacast/Dacast";


export const ProductContext = React.createContext({});
//floor reflection config start
const path = "../texture/";
const format = ".jpg";
const urls = [px, nx, py, ny, pz, nz];
const reflectionCube = new THREE.CubeTextureLoader().load(urls);
const refractionCube = new THREE.CubeTextureLoader().load(urls);
refractionCube.mapping = THREE.CubeRefractionMapping;
const mat1 = new THREE.MeshLambertMaterial({
    color: 0xf5acbc,
    envMap: reflectionCube,
    reflectivity: 0.15,
});
const mat2 = new THREE.MeshLambertMaterial({
    color: 0xb27452,
    envMap: reflectionCube,
    reflectivity: 0.099,
});
const cubeRenderTarget = new THREE.WebGLCubeRenderTarget(256, {
    format: THREE.RGBFormat,
    generateMipmaps: true,
    minFilter: THREE.LinearMipmapLinearFilter,
    encoding: THREE.sRGBEncoding,
});
const cubeCamera = new THREE.CubeCamera(1, 1000, cubeRenderTarget);

function Cubecam() {
    const { scene, gl } = useThree();
    cubeCamera.position.set(0, 0, 0);
    scene.add(cubeCamera);
    useFrame(() => cubeCamera.update(gl, scene));
    return null;
}
//floor reflection config end

const Booth = (props) => {

    //booth navigation declaration
    let min_x_val=0,min_z_val=0,max_x_val=0,max_z_val=0; 
     
    const { camera, gl, scene } = useThree();
    var raycaster = new THREE.Raycaster();
    var mouse = new THREE.Vector2();
    let intersects;
    let windowHalf = new THREE.Vector2( window.innerWidth / 2, window.innerHeight / 2 );       
    let target = new THREE.Vector2();
    let dragging = false;
    const tar = React.useRef();
    const drag = React.useRef();
    const drag_texture = useLoader(THREE.TextureLoader, drag_img);
    let collideMeshList=[];



    //presentation video config
    var video = document.createElement("video");
    video.loop = true;
    video.crossOrigin = "anonymous";    
    video.load();
    //video.src = "https://player.vimeo.com/external/617614030.sd.mp4?s=b70c32d49f385e1f6d389065e38f425c44248aae&profile_id=164";
    video.play();
    video.autoplay = true;

    const { eventConfig } = useStoreState((state) => state.state);
    let { rotateDirection } = useStoreState((state) => state.boothLiveState);
    const [rot_state, rotState] = useState(false);
    const playerRef = useRef();
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    
    
    const box = new THREE.Box3();
    const {
        boothPosters,
        boothLogos,
        productsDetails,
        productsData,
        boothDetails,
        sessionsList,
        clickedMeshItem,
        streamUrl,
        isMux,
        videoStarted,
        stream_playing,
        stream_volume,
        stream_muted,
        isPresentationVisible,
        placeholdersMesh,
        livestreaming_method,
        streamProvide        
    } = useStoreState((state) => state.boothLiveState);
    let event = boothDetails?.event?.[0]?.event_id;
    let {model_360 }= useStoreState((state) => state.boothLiveState);
    const{getProductsDetails}=useStoreActions((state)=> state.boothLiveState)
   // console.log("boothLive posters", boothPosters);
   // console.log("streamurl", streamUrl);
    useEffect(() => {
        updateState({ stream_playing: false, cameraState:camera });
    }, []);
    const url = boothDetails?.booth?.[0]?.booth_model_asset; 
    //console.log(boothDetails);
    const placeholders = {};
    const [firstClick, setFirstClick] = useState(false);
    const [streamShow, setStreamShow] = useState(true);
    const [videoElement, setVideoElement] = useState(props.video);
//    console.log("propsbb", props )
    const { setLoading } = props;
   
    
    // let streamProvide ="Mux.com";
    // if(typeof sessionsList!="undefined")
    // {
    //     console.log("session---",sessionsList)
    //     if(typeof sessionsList[0].streaming_list!="undefined")
    //     {
    //         streamProvide =sessionsList[0].streaming_list[0].livestreaming_method;            
    //     }
    // }
    
    //   console.log("Method" , streamProvide);
  
//Camera height value for booth --------------------------
  const height_value_booth = boothDetails?.booth[0]?.cameraLookAt[0].split(",")?.[1]
   
  // console.log(height_value_booth);



    function pad(d) {
        return d < 10 ? "0" + d.toString() : d.toString();
    }

    function onDocumentMouseDown1(event) {
        if (!firstClick) {
            setFirstClick(true);    
            if(typeof videoElement !== 'undefined' ){
                if(videoElement.error == null){
                    videoElement?.play() 
                    updateState({ videoStarted: true });
            
                }
                     
                
            }       
        } else if (!videoElement?.paused && firstClick) {
            setFirstClick(true);
        }
        event.preventDefault();
        mouse.x = (event.clientX / gl.domElement.clientWidth) * 2 - 1;
        mouse.y = -(event.clientY / gl.domElement.clientHeight) * 2 + 1;
    }
    function onDocumentMouseDown(event) {
       /* event.preventDefault();
        mouse.x = (event.clientX / gl.domElement.clientWidth) * 2 - 1;
        mouse.y = -(event.clientY / gl.domElement.clientHeight) * 2 + 1;

        raycaster.setFromCamera(mouse, camera);

        var intersects = raycaster?.intersectObjects(scene.children, true);
        // console.log(intersects);
        if (
            intersects?.length > 0 &&
            intersects[0] &&
            intersects[0].object &&
            intersects[0].object.callback
        ) {
            intersects?.[0]?.object?.callback();
        } else if (
            intersects?.length > 0 &&
            intersects[0] &&
            intersects[0].object &&
            intersects[0].object.parent &&
            intersects[0].object.parent.callback
        ) {
            {
                console.log(intersects?.[0]?.object?.parent);
                intersects?.[0]?.object?.parent?.callback();
            }
        } else if (
            intersects?.length > 0 &&
            intersects[0] &&
            intersects[0].object &&
            intersects[0].object.parent.parent &&
            intersects[0].object.parent.parent.callback
        ) {
            console.log(intersects?.[0]?.object?.parent?.parent);
            intersects?.[0]?.object?.parent?.parent?.callback();
        }*/
    }

    var map = new THREE.TextureLoader().load(imgD1);
    map.wrapS = map.wrapT = THREE.RepeatWrapping;
    map.anisotropy = 16;
    map.flipY = false;
    var imgmaterial = new THREE.MeshBasicMaterial({ map: map });

    const [modalOpen, setModalOpen] = useState(false);
    const [productAll , setProductAll] = useState([]);
    const [sessionsVideoData, setSessionsVideoData] = useState([]);
    const [modalIndex, setModalIndex] = useState(null);
    
    const handleModalClose = () => {
        setModalOpen(false);
        updateState({ clickedMeshItem: "" });
    };

    const openProductModal = (i) => {
        // Object.keys(productsDetails)?.map((k, i) => {
        //     if (
        //         "product_" + productsDetails[k]?.product?.[0]?.product_sku ==
        //         product_name
        //     ) {
        //         setModalIndex(i);
        //         setModalOpen("Products");
        //     }
        // });
        // console.log("openproduct")
                setModalIndex(i);
                 setModalOpen("Products");
    };

    const showProductModel = () =>{

    }

    const openVideosModal = (model_mesh_name) => {
        if (!model_mesh_name.includes("Presentation_wall")) return;
        let tempVideos = [];
        sessionsList?.[0]?.streaming_list?.map((session) => {
            tempVideos.push({
                video_title: session?.session_title,
                video_path: session?.on_demand_video_url,
            });
        });
        setSessionsVideoData(tempVideos);
        setModalOpen("Session Videos");
    };

    const react_player_ref = useRef();
    useEffect(() => {
        window.addEventListener("click", onDocumentMouseDown1, false);
        return () => {
            window.removeEventListener("click", onDocumentMouseDown1, false);
        };
    }, [videoElement, firstClick]);

    useEffect(() => {
        //window.addEventListener("click", onDocumentMouseDown, false);
      
        return () => {
            //window.removeEventListener("click", onDocumentMouseDown, false);
            //hacky way to stop the video
            videoElement.currentTime = 0;
            videoElement?.pause();
        };
    }, []);
      
    const meshClickFunc = (name,product_name) => { // Getting product mesh details while clicking on it
        //event.preventDefault();  
        openfunc(name); //first event for product popup while clicking on product mesh
        setProductAll(product_name);
        updateState({ clickedMeshItem: name , model_360:product_name  });
       // openProductModal(name);
        openVideosModal(name);
    };
    
    const openfunc =(name)=>{   //Second event for product popup while clicking on product mesh
        // console.log("openfunc")
        if(productsData?.length > 0){
        for(let i=0 ;i<productsData.length ;i++){
            if("product_"+ productsData[i]?.product_sku == name){
                let prodId = productsData[i]?.id
              getProductsDetails({ event_id: event, product_id: prodId }).then((response) => {
                openProductModal(i)
                // console.log(prodId)
              })
            }
          }
       }
    };

    const stall_ref = useCallback(
        (node) => {
            if (node !== null) {
                // console.log(node);
                let b = box.setFromObject(node);
                let arr = [];
                let posterTextureIndex = 0;
                let i = 1;
               // console.log("insiude", boothPosters);
                let posterTextures = boothPosters.reduce((acc, posterImg) => {
                    let texture = new THREE.TextureLoader().load(
                        posterImg?.poster_path
                    );
                    texture.encoding = THREE.sRGBEncoding;
                    texture.name = "poster__00" + i;
                    i++;
                    acc.push(texture);
                    return acc;
                }, []);

                ///
                let j = 1;
                let logoTextureIndex = 0;
                let logoTextures = boothLogos.reduce((acc, logoImg) => {
                    let texture = new THREE.TextureLoader().load(
                        logoImg?.logo_path
                    );
                    texture.encoding = THREE.sRGBEncoding;
                    texture.name = "logo__00" + j;
                    j++;
                    acc.push(texture);
                    return acc;
                }, []);

                let posterTexture;
                let logoTexture;

                node.parent.children.map((i, index) => {
                    // i.onClick(console.log("Clicked" + i.name));
                    i.callback = function () {
                        //console.log("Clicked " + i.name);
                        meshClickFunc(i.name);
                    };
                });
                node.children.map((i, index) => {
                   // console.log(i.name);
                    // i.onClick(console.log("Clicked" + i.name));
                    i.callback = function () {
                       // console.log("Clicked " + i.name);
                        meshClickFunc(i.name);
                    };

                    posterTexture = posterTextures.find(
                        (texture) => texture.name == i.name
                    );
                    if (posterTexture && i.material) {
                        // console.log("posterTexture", posterTexture, i.name);
                        i.material.map = posterTexture;
                        i.material.map && (i.material.map.flipY = false);
                        // posterTextureIndex += 1;
                    }

                    logoTexture = logoTextures.find(
                        (texture) => texture.name == i.name
                    );
                    if (logoTexture && i.material) {
                        // console.log("logoTexture", logoTexture, i.name);
                        i.material.map = logoTexture;
                        i.material.map && (i.material.map.flipY = false);
                        // logoTextureIndex += 1;
                    }

                    // if (
                    //     i.name.indexOf("logo__0") > -1 &&
                    //     i.children.length == 0
                    // ) {
                    //     i.material.map = logoTextures?.[logoTextureIndex];
                    //     i.material.map && (i.material.map.flipY = false);
                    //     logoTextureIndex += 1;
                    // }
                    let box1 = new THREE.Box3();
                    let b1 = box1.setFromObject(i);
                    if (i.name === "videozone" && typeof videoElement !== "undefined") {
                        var texture = new THREE.VideoTexture(videoElement);
                        texture.minFilter = THREE.NearestFilter;
                        texture.magFilter = THREE.LinearFilter;
                        texture.format = THREE.RGBFormat;
                        var material = new THREE.MeshBasicMaterial({
                            map: texture,
                        });
                        i.material = material;
                        i.material.map.flipY = false;                             
                        if(videoElement.error == null){
                        videoElement?.play(); 
                        }
                        //videoElement?.play();                      
                    }
                    if (typeof i.material != "undefined") {
                        if (i.material.roughness < 0.5) {
                            i.material.roughness = 0;
                            i.material.envMap = cubeRenderTarget.texture;
                            i.material.envMap.encoding = THREE.sRGBEncoding;
                            i.material.envMap.mapping =
                                THREE.ACESFilmicToneMapping;
                            i.add(cubeCamera);
                            // console.log(i.material.envMap);
                        }
                    }

                    b1.name = i.name;
                    arr.push(b1);
                });

                node.children.map((i, index) => {
                    if (i.isMesh) {
                        i.castShadow = true;
                        i.receiveShadow = true;
                        if (i.material.map) i.material.map.anisotropy = 16;
                    }
                });

                node.children.map((i, index) => {
                    if (!i.name.indexOf("Empty")) {
                        // console.log("postiton ", i.position, i.name);
                        placeholders[i.name.slice(-2)] = i.position;
                    }
                });

                let speed = Math.abs(b?.min?.z) + b?.max?.z;
                updateState({
                    stallBoundingBox: b,
                    speed: speed,
                    boundings: arr,
                });
            }
        },
        [
            react_player_ref?.current,
            videoElement,
            boothPosters,
            boothDetails,
            boothLogos,
            productsData,
            productsDetails,
        ]
    );

    const LoadingSpinner = (position, index) => {
        var geometry = new THREE.SphereGeometry(0.2, 9, 5);
        var material = new THREE.MeshBasicMaterial({
            color: 0xffffff,
            opacity: 0.5,
            transparent: true,
            // wireframe: true,
        });
        var cube = new THREE.Mesh(geometry, material);
        scene.add(cube);
        var geo = new THREE.EdgesGeometry(cube.geometry); // or WireframeGeometry
        var mat = new THREE.LineBasicMaterial({
            color: 0x000000,
            linewidth: 0.1,
        });
        var wireframe = new THREE.LineSegments(geo, mat);
        cube.add(wireframe);
        let animate = () => {
            requestAnimationFrame(animate);
            cube.rotation.x += 0.1;
            cube.rotation.y += 0.1;
        };
        const name = "cube_" + pad(index);
        cube.position.set(position?.x, position?.y + 0.5, position?.z);
        cube.name = "cube_" + index;
        // console.log(cube);
        animate();
    };

    const glb = useLoader(
        GLTFLoader,
        url,
        (onprogress = () => {
            //console.log("loadinggggg...")
        })
    );

    useEffect(() => {
        if (!isEmpty(glb.scene)) {
            setLoading(false);

            let loader = new GLTFLoader();

            productsData?.forEach((product, index) => {
                // console.log(product, product.product_3d_model, "products");
                if (product?.featured_product == "yes") {
                    if (product?.product_image_in_3d == "yes") {
                        // console.log("index", placeholder_index);
                        // console.log(
                        //     product,
                        //     product.product_3d_model,
                        //     "products",
                        //     index
                        // );
                        let placeholder_position_ref = placeholders[pad(product?.placeholder)];
                        if(product?.placeholder!="" && product?.placeholder!=null && typeof(placeholder_position_ref)!="undefined")
                        {                            
                        loader.load(
                            product?.product_3d_model,
                            function (gltf) {
                                gltf.scene.traverse( function ( child ) {                                  
                                    if ( child.isMesh ) {
                                        child.parent.name="product_" + product?.product_sku;
                                        child.parent.objectname=product?.product_3d_model;
                                        collideMeshList.push(child);                                                       
                                    }
                                });
                               
                                let placeholder_position =
                                    placeholders[pad(product?.placeholder)];                                    
                                scene.add(gltf.scene);

                                //console.log("placeholder_position: ",placeholder_position);
                                
                                gltf.scene.name =
                                    "product_" + product?.product_sku;
                                gltf.scene.objectname=product?.product_3d_model;
                                    
                                    collideMeshList.push(gltf.scene); 
                                gltf.scene.position.set(
                                    placeholder_position.x,
                                    placeholder_position.y,
                                    placeholder_position.z
                                );
                                gltf.scene.callback = function () {
                                    // console.log("Clicked" + i.name);
                                   // meshClickFunc(gltf.scene.name);
                                };
                                const object_name =
                                    "cube_" + product?.product_sku;
                                const object =
                                    scene.getObjectByName(object_name);
                                // console.log(object, "obje");
                                object?.geometry?.dispose();
                                object?.material?.dispose();
                                scene.remove(object);
                            },
                            LoadingSpinner(
                                placeholders[pad(product?.position)],
                                product?.product_sku
                            ),
                            function (error) {
                               // console.error(error);
                            }
                        );
                        }
                    } else {
                        let mesh_link = "";
                       
                        // console.log(
                        //     placeholders[pad(product.placeholder)],
                        //     placeholders,
                        //     placeholdersMesh
                        // );
                   
                        placeholdersMesh?.map((i) => {
                            if (
                                pad(i?.placeholder) == pad(product.placeholder)
                            ) {
                                mesh_link = i?.placeholder_2d_mesh_name;
                               // console.log("mesh link", mesh_link);
                            }
                        });
                        loader.load(
                            mesh_link,
                            function (gltf) { 
                                gltf.scene.traverse( function ( child ) {                                  
                                    if ( child.isMesh ) {
                                        child.parent.name="product_" + product?.product_sku;
                                        collideMeshList.push(child);                                                       
                                    }
                                });
                                let placeholder_position =
                                    placeholders[pad(product?.placeholder)]; 
                                    
                                        
                                gltf.scene.position.set(
                                    placeholder_position.x,
                                    placeholder_position.y,
                                    placeholder_position.z
                                );
                                scene.add(gltf.scene);
                                gltf.scene.name =
                                    "product_" + product?.product_sku;
                                    collideMeshList.push(gltf.scene);
                                   // console.log("product_" + product?.product_sku);
                                gltf.scene.callback = function () {
                                    // console.log("Clicked" + i.name);
                                   // meshClickFunc(gltf.scene.name);
                                };
                                //console.log(product?.product_2d_model);
                                let texture = new THREE.TextureLoader().load(
                                    product?.product_2d_model
                                );
                                texture.encoding = THREE.sRGBEncoding;

                                gltf.scene.children[0].material.map = texture;

                                gltf.scene.children[0].material.map.flipY = false;
                                const object_name =
                                    "cube_" + product?.product_sku;
                                const object =
                                    scene.getObjectByName(object_name);
                                // console.log(object, "obje");
                                object?.geometry?.dispose();
                                object?.material?.dispose();
                                scene.remove(object);
                            },
                            LoadingSpinner(
                                placeholders[pad(product?.placeholder)],
                                product?.product_sku
                            ),
                            function (error) {
                               // console.error(error);
                            }
                        );
                    }
                }
            });
        }
    }, [glb]);



    //navigation work
    const Drag_plane = () => {
        return (
            <>
                <mesh
                    ref={drag}
                    rotation-x={Math.PI * -0.5}
                    position-z={20}
                    position-y={0.2}
                    position-x={0}
                    scale-x={3} 
                    scale-y={3} 
                    scale-z={3}
                    name={'dragplane'}
                >
                    <planeBufferGeometry args={[2, 2]} />
                    <meshStandardMaterial
                        map={drag_texture}
                        transparent={true}
                        color={'red'}
                    />
                </mesh>
                <mesh ref={tar} name={'target'} position-y={1}>
                    <boxBufferGeometry args={[500, 0.1, 500]} />
                    <meshStandardMaterial color={'red'} visible={false} />
                </mesh>
            </>
        )
    }

    const Event_listener = () => {
        setUpMouseHander(gl.domElement, doMouseMove, doMouseMove)
        document.addEventListener('mousemove', HandleMouseMove); 
        document.getElementById("rotate").addEventListener('click', rotate_firstclick);
        return null
    }
    const rotate_firstclick = () =>{      
        rotState(true); 
    }
    useEffect(() => {       
        if(rot_state==false)
        {
            rotateObjectfn(0); 
            updateState({ rotateDirection: 1 });                                   
        }
        else
        {            
            rotateObjectfn(rotateDirection);
        }           
    },[rot_state,rotateDirection]);    

    const rotateObjectfn = (rotateDir) =>{
       
        if(rotateDir==0)
        {
            camera.rotation.y = 0;
        }
        else if(rotateDir==1)
        {
            camera.rotation.y = 1.5707963267948966;
        }
        else if(rotateDir==2)
        {
            camera.rotation.y = 3.141592653589793;            
        }
        else if(rotateDir==3)
        {
            camera.rotation.y = -1.5707963267948966;
        }             
    };
   /* const rotate_firstclick = () =>{      
        rotState(true); 
    }
    useEffect(() => {
        if(rot_state==false)
        {
            rotateObjectfn(0);             
        }
        else
        {
            rotateObjectfn(rotateDirection);
        }  
    },[rot_state,rotateDirection]);    

    const rotateObjectfn = (rotateDir) =>{
       
        if(rotateDir==0)
        {
            camera.rotation.y = 0;
        }
        else if(rotateDir==1)
        {
            camera.rotation.y = 1.5707963267948966;
        }
        else if(rotateDir==2)
        {
            camera.rotation.y = 3.141592653589793;            
        }
        else if(rotateDir==3)
        {
            camera.rotation.y = -1.5707963267948966;
        }             
    };*/
    const HandleMouseMove = (event) => {
        if (event.which == 3) {
            mouse.x = event.clientX - windowHalf.x
            mouse.y = event.clientY - windowHalf.x
            target.x = (1 - mouse.x) * 0.01
            camera.rotation.y += 0.05 * (target.x - camera.rotation.y)
        }
    }
    function doMouseMove(x, y, evt, prevX, prevY) {
        var a = (2 * x) / gl.domElement.width - 1
        var b = 1 - (2 * y) / gl.domElement.height
        raycaster.setFromCamera(new THREE.Vector2(a, b), camera)
        intersects = raycaster.intersectObjects(scene.children, true)

        if (intersects.length == 0) {
            return
        } else {
            if (intersects[0].object.name.localeCompare('target') == 0) {
                var locationX = intersects[0].point.x
                var locationZ = intersects[0].point.z
                var coords = new THREE.Vector3(locationX, 0, locationZ)
                a = Math.min(max_x_val,Math.max(min_x_val,coords.x)); 
				b = Math.min(max_z_val,Math.max(min_z_val,coords.z));
                if (typeof drag.current != 'undefined') {
                    drag.current.position.set(a, 0.2, b)
                }
            }
        }
    }
    //desktop mouse event
    function setUpMouseHander(
        element,
        mouseDownFunc,
        mouseDragFunc,
        mouseUpFunc
    ) {
        if (
            !element ||
            !mouseDownFunc ||
            !(typeof mouseDownFunc == 'function')
        ) {
            throw 'Illegal arguments in setUpMouseHander'
        }
        if (typeof element == 'string') {
            element = document.getElementById(element)
        }
        if (!element || !element.addEventListener) {
            throw 'first argument in setUpMouseHander is not a valid element'
        }
        //var dragging = false;
        var startX, startY
        var prevX, prevY

        function doMouseDown(evt) {
            if (evt.which == 1) {
                mouse.x = (evt.offsetX / gl.domElement.clientWidth) * 2 - 1;
                mouse.y = -(evt.offsetY / gl.domElement.clientHeight) * 2 + 1;              
                raycaster.setFromCamera(mouse, camera);
                const intersects = raycaster.intersectObjects(collideMeshList,true);
                       

                if (intersects.length > 0) {       
                       // console.log(intersects[0].object.parent.name);
                        meshClickFunc(intersects[0].object.parent.name,intersects[0].object.parent.objectname);                        

                }

                const intersects1 = raycaster.intersectObjects(
                    scene.children,
                    true
                );

                if (typeof intersects1[0] != "undefined")
                { 
                    if(intersects1[0].object.name.localeCompare('target')==0)
                    {
                        if (typeof drag.current != 'undefined') {
                        //camera rotate value settings
                            if(parseFloat(camera.rotation.y)==0)
                            {							
                                    
                                    camera.position.copy(drag.current.position);
                                    camera.position.y=`${height_value_booth}`;						
                                    camera.position.z=parseFloat(camera.position.z)+(40);
                                    
                                    var t=new THREE.Vector3( drag.current.position.x,`${height_value_booth}`, (parseFloat(drag.current.position.z)+38) );	
                                    tween_zoom(t);					
                                                    
                            }
                            else if(parseFloat(camera.rotation.y)==1.5707963267948966)
                            {				
                                
                                    camera.position.copy(drag.current.position);
                                    camera.position.y=`${height_value_booth}`;
                                    camera.position.x=parseFloat(camera.position.x)+(40);

                                    var t=new THREE.Vector3( (parseFloat(drag.current.position.x)+38),`${height_value_booth}`, drag.current.position.z );	
                                    tween_zoom(t);					
                                                    
                            }
                            else if(parseFloat(camera.rotation.y)==-1.5707963267948966)
                            {				
                                
                                    camera.position.copy(drag.current.position);
                                    camera.position.y=`${height_value_booth}`;
                                    camera.position.x=parseFloat(camera.position.x)-(40);

                                    var t=new THREE.Vector3( (parseFloat(drag.current.position.x)-38), `${height_value_booth}`, drag.current.position.z );	
                                    tween_zoom(t);					
                                                                
                            }
                            else if(parseFloat(camera.rotation.y)==3.141592653589793)
                            {			
                                    
                                    camera.position.copy(drag.current.position);
                                    camera.position.y=`${height_value_booth}`;						
                                    camera.position.z=parseFloat(camera.position.z)-(40);

                                    var t=new THREE.Vector3( drag.current.position.x,`${height_value_booth}`, (parseFloat(drag.current.position.z)-38) );	
                                    tween_zoom(t);					
                                                                            
                            }	
                            camera.updateProjectionMatrix();
                        }
                    }
                }


            }

            var r = element.getBoundingClientRect()
            var x = evt.clientX - r.left
            var y = evt.clientY - r.top
            prevX = startX = x
            prevY = startY = y
            document.addEventListener('mousemove', doMouseMove)
            document.addEventListener('mouseup', doMouseUp)
        }

        function doMouseMove(evt) {
            var r = element.getBoundingClientRect()
            var x = evt.clientX - r.left
            var y = evt.clientY - r.top
            mouseDragFunc(x, y, evt, prevX, prevY, startX, startY)
        }

        function doMouseUp(evt) {
            //camera settings
            if (
                (parseFloat(camera.rotation.y) < 0.7 &&
                    parseFloat(camera.rotation.y) >= 0) ||
                (parseFloat(camera.rotation.y) > -0.7 &&
                    parseFloat(camera.rotation.y) <= 0)
            ) {
                camera.rotation.y = 0
            } else if (
                parseFloat(camera.rotation.y) > 0.7 &&
                parseFloat(camera.rotation.y) > 0 &&
                parseFloat(camera.rotation.y) < 2.1
            ) {
                camera.rotation.y = 1.5707963267948966
            } else if (
                parseFloat(camera.rotation.y) < -0.7 &&
                parseFloat(camera.rotation.y) < 0 &&
                parseFloat(camera.rotation.y) > -2.1
            ) {
                camera.rotation.y = -1.5707963267948966
            } else if (
                (parseFloat(camera.rotation.y) > 2.1 &&
                    parseFloat(camera.rotation.y) > 0) ||
                (parseFloat(camera.rotation.y) < -2.1 &&
                    parseFloat(camera.rotation.y) < 0)
            ) {
                camera.rotation.y = 3.141592653589793
            }
            camera.updateProjectionMatrix();
            if (typeof drag.current != 'undefined' && drag.current !=null) {
            drag.current.rotation.set(-Math.PI * 0.5,0,camera.rotation.y);
            }
        }
        element.addEventListener('mousedown', doMouseDown)
    }
    function tween_zoom(to)
    {	
        var f=camera.position;
        var tween = new TWEEN.Tween(f).to(to, 600); // duration of tweening is 0.5 second
        tween.onUpdate(function() {});
        tween.start();
    }
    
    		
	useFrame(() => {
	  TWEEN.update();
	});
    //navigation work







    return (
        <>
         
            <Cubecam />
            <Drag_plane />            
            <Event_listener />
           
            <primitive ref={stall_ref} object={glb.scene}>
                {glb.scene.children.map((i, index) => {
                    // console.log(i.name, i);
                    // if (i.name == "videozone") {
                    //     return (
                    //         <mesh
                    //             geometry={i.geometry}
                    //             position={i.position}
                    //             matrix={i.matrix}
                    //             rotation={i.rotation}
                    //             scale={i.scale}
                    //             quaternion={i.quaternion}
                    //             parent={i.parent}
                    //             matrixWorld={i.matrixWorld}
                    //             layers={i.layers}
                    //             up={i.up}
                    //             matrixAutoUpdate={true}
                    //             matrixWorldNeedsUpdate={false}
                    //         >
                    //             <meshStandardMaterial color="blue" />
                    //         </mesh>
                    //     );
                    // }
                   
                    //navigation 
                    if(i.name=="ground")
					{
						let box_hall=new THREE.Box3().setFromObject(i);						
						min_x_val=-((box_hall.getSize().x/2)-26);
						max_x_val=((box_hall.getSize().x/2)-26);
						
						min_z_val=-((box_hall.getSize().z/2)-43);
						max_z_val=((box_hall.getSize().z/2)-43);                      
						
						//console.log(min_x_val+" "+max_x_val);
						///console.log(min_z_val+" "+max_z_val);
						//console.log(box_hall.getSize());
                    }

                    if(i.name=="bridge")
					{                        
						let box_hall=new THREE.Box3().setFromObject(i);						
						min_x_val=-((box_hall.getSize().x/2)-26);
						max_x_val=((box_hall.getSize().x/2)-26);
						
						min_z_val=-((box_hall.getSize().z/2)-43);
						max_z_val=((box_hall.getSize().z/2)-43);
						
						//console.log(min_x_val+" "+max_x_val);
						//console.log(min_z_val+" "+max_z_val);
						//console.log(box_hall.getSize());
					}

                    if (i.name == "presentation_wall_01") {
                        i.material.visible = false;                        
                        return (
                            <mesh
                                geometry={i.geometry}
                                position={i.position}
                                // matrix={i.matrix}
                                rotation={i.rotation}
                                scale={i.scale}
                                quaternion={i.quaternion}
                                parent={i.parent}
                                matrixWorld={i.matrixWorld}
                                layers={i.layers}
                                up={i.up}
                                matrixAutoUpdate={true}
                                matrixWorldNeedsUpdate={false}
                            >
                                {/* <meshBasicMaterial>
                                        <videoTexture
                                            attach="map"
                                            args={[videoElement]}
                                        />
                                    </meshBasicMaterial> */}
                                <meshStandardMaterial color="black" />
                                <Html
                                    transform
                                    distanceFactor={1}
                                    zIndexRange={[0, 0]}
                                >
                                    <StoreProvider store={store}>
                                        {/* <MuxPlayer /> */}
                                        {/* <VimeoPlayer streamUrl={streamUrl} /> */}
                                        {/* <DacastPLayer streamUrl={streamUrl} /> */}
                                        {/* <YoutubePlayer streamUrl={streamUrl}/> */}

                                        {streamProvide == "Mux.com" ? (
                                            <MuxPlayer />
                                        ) : null}

                                        {streamProvide == "Vimeo" ? (
                                            <VimeoPlayer
                                                streamUrl={streamUrl}
                                            />
                                        ) : null}

                                        {streamProvide == "Youtube" ? (
                                            <YoutubePlayer
                                                streamUrl={streamUrl}
                                            />
                                        ) : null}
                                        {streamProvide == "Dacast" ? (
                                            <DacastPLayer
                                                streamUrl={streamUrl}
                                            />
                                        ) : null}
                                    </StoreProvider>
                                </Html>
                            </mesh>
                        );
                    }
                })}
            </primitive>
            <Html>
                <StoreProvider store={store}>
                    {modalOpen ? (
                        <>
                        <ProductContext.Provider value={{productAll}}>
                            <Modal
                                handleModalClose={handleModalClose}
                                show={modalOpen == "Products"}
                                title="Products"
                                // close={modalProductsCloseHandler}
                                productsData={productsData}
                               
                                initialIndex={modalIndex}
                                initialDisplayMode="carousel"
                            />
                             </ProductContext.Provider>
                            <Modal
                                handleModalClose={handleModalClose}
                                show={modalOpen == "Session Videos"}
                                title="Videos"
                                videoData={sessionsVideoData}
                                type="video"
                                showVideoPlayer={true}
                            />
                           
                        </>
                    ) : null}
                </StoreProvider>
               
            </Html>
        </>
    );
};

export default Booth;
