import React, { useState } from "react";
import RotateImg from "./assets/rotate.png";
const Orientation = () => {

    /*------------Qrcode URL validations-------------------*/ 
    let qrcode_url_list=[
        "appointment",
        "login",
        "businessCard",      
        "videos",
        "documents",
        "jobs",
        "products",
        "courses",
        "booth-conferences",
        "event-conferences",
        "coursedetails",
        "jobdetails",
        "productdetails",
        "event-conferences-details",
        "booth-conferences-details",
    ];
    let qrcode_status=-1;
    
    if(typeof window.location.hash !="undefined")
    {
        if(typeof window.location.hash.split("/")!="undefined")
        {
            let loc_arr=window.location.hash.split("/")[1];
            if(typeof loc_arr!="undefined")
            {
                if(typeof loc_arr.split("?")!="undefined")
                {
                   // qrcode_status=qrcode_url_list.indexOf(window.location.hash.split("/")[1].split("?")[0]);
                   qrcode_status=qrcode_url_list.indexOf(loc_arr.split("?")[0]);
                }
            }            
        }
    }
    /*------------Qrcode URL validations-------------------*/ 

    const [orientState, setOrientState] = useState(window.screen.orientation.type);   

    const updateDimensions = () =>{    
        setOrientState(window.screen.orientation.type)     
    };

    const Event_listener = () => {       
        window.addEventListener('resize', updateDimensions);
        return null;
    };     
    return ( 
        <>      
            {(qrcode_status==-1)?( 
            <>          
            <Event_listener/>

            <div style={{ height: "100%",width: "100%", position:"absolute",zIndex:999999, display:
                    orientState == "landscape-primary" ? "none" : "block" }}> 
                <img
                src={RotateImg}
                style={{
                    height: "100%",
                    width: "100%",                    
                }}
                /> 
            </div>  
            </>
            ):(null)}   
        </>
    )
}

export default Orientation;