import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    container: {
        background: "#eee",
    },
    registerModal: {
        // boxSizing: "content-box",
        background: "white",
        width: "auto",
        margin: "auto",
    },
    root: {
        height: "100%",
        background: "rgba(0, 0, 0, 0.1)",
        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
            borderColor: "#ccc",
            color: "#ddd",
        },
        "& .MuiInputLabel-outlined": {
            color: "rgba(0, 0, 0, 0.87)",
        },
    },
    input: {
        padding: "5px 10px",
        border: "1px solid black",
        "&:focus": {
            outline: "none",
        },
        height: 30,
        fontSize: 12,
        marginBottom: 5,
        width: "100%",
    },
    ".MuiTextField-root": {
        fontFamily: "Montserrat",
    },
    image: {
        // backgroundImage:
        //     "url(https://blog-assets.freshworks.com/freshdesk/wp-content/uploads/2020/04/14203454/Blog_Banner_v1-011.jpg)",
        backgroundRepeat: "no-repeat",
        // backgroundColor: "rgb(27, 9, 57)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        background: "rgba(227, 230, 240, 1)",
    },
    paper: {
        margin: theme.spacing(4, 4),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        background: "rgb(29, 117, 213)",
        color: "white",
        "&:hover": {
            background: "rgb(171, 0, 105)",
            opacity: "0.8",
        },
        fontFamily: "Montserrat",
    },
    // input: {
    //     fontFamily: "Montserrat",
    //     margin: "0.1rem",
    //     // padding: "0.5rem",
    //     // height: "1rem",
    //     fontSize: "0.9rem",
    //     "&:focus": {
    //         // color: "rgb(171, 0, 105)",
    //         border: "none",
    //         boxShadow: "none",
    //     },
    // },
}));

export default useStyles;
