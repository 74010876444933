import axiosConnector from "../../axiosConnector";

export const serverApi = {
    getAllBoothData: (event_id, mode, hall) => {
        return axiosConnector.get(
            `/all_booths_data.php?event_id=${event_id}&mode=${mode}&hall=${hall}`,
            {
                headers: { "Content-Type": "application/json" },
            }
        );
    },
    getConversionById: (room_id, user_id, event_id) => {
        return axiosConnector.post(
            `/conversation/get_conversation_by_room_id.php`,
            {
                room_id: room_id,
                user_id: user_id,
                event_id: event_id,
            },
            {
                headers: { "Content-Type": "application/json" },
            }
        );
    },

    addNewMemberToConversion: () => {
        return axiosConnector.get(`/add_new_member_to_conversation.php`, {
            headers: { "Content-Type": "application/json" },
        });
    },

    removeMemberFromConversation: () => {
        return axiosConnector.get(`/remove_member_from_conversation`, {
            headers: { "Content-Type": "application/json" },
        });
    },

    getChatMembersDetailsById : (payload) => {
       
        return axiosConnector.post( `/hybrid/conversation/user-details-by-id.php`, payload ,  {
            headers: { "Content-Type": "application/json" },
        });
    }
};
