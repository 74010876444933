import React, { useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
// import "../../../index.css";
import "typeface-montserrat";
import { Link } from "react-router-dom";
import useStyles from "./Register.style";
import { useStoreActions, useStoreState } from "easy-peasy";
import LoginRegisterSidebar from "../loginRegisterSiderbar/LoginRegisterSidebar";
import { Checkbox } from "@material-ui/core";
import './registerstyle.css';
import { divide } from "lodash";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {"Copyright © "}
            <a color="inherit" href="https://www.hexafair.com/">
                HEXAFAIR
            </a>{" "}
            {new Date().getFullYear()}
        </Typography>
    );
}

export default function Register(props) {
    
    const [errors, setErrors] = useState(false);
    const [success, setSuccess] = useState(false);
    const [validateState, setValidate] = useState(false);
    const [readTAndC, setReadTAndC] = useState(false);
    const { updateState } = useStoreActions((state) => state.state);
    const { register, getCountryCodes } = useStoreActions(
        (state) => state.state
    );
    const regexName = /^[a-z ,.'-]+$/i;
    const emailPattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const regexPhone = /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g;
    const regexEvent = /\b(0?[1-9]|1[0-9]|2[0-5])\b/g;
    const { countryCodes, boothDetails } = useStoreState(
        (state) => state.state
    );
    // //console.log("test", regexPhone.test(stat));
    const classes = useStyles();
    const [state, setState] = useState({});
    const { isLoggedIn, eventConfig } = useStoreState((state) => state.state);

    useEffect(() => {
        if (isLoggedIn) {
            props.history.push("/");
        }
    }, [isLoggedIn]);

    useEffect(() => {
        const listener = event => {
          if (event.code === "Enter" || event.code === "NumpadEnter") {
            //console.log("Enter key was pressed. Run your function.");
            event.preventDefault();
            finalclick()
             
          }
        };
        document.addEventListener("keydown", listener);
        return () => {
          document.removeEventListener("keydown", listener);
        };
      });

    useEffect(() => {
        getCountryCodes();
        setState({
            username: "",
            password: "",
            first_name: "",
            last_name: "",
            //phone_code: countryCodes[0]?.phone_country_code?.split("(")?.[0]
                // ? countryCodes[0]?.phone_country_code?.split("(")?.[0]
                // : "",
            phone_code:"",
            phone_number: "",
            password: "",
            event_id: boothDetails?.event?.[0]?.event_id,
            company: "",
            industry: "",
            agree_tandc:"",
        });
    }, []);

    function handleChange(evt) {
        let temp = { ...errors };
        const value = evt.target.value;   
        setState({
            ...state,
            [evt.target.name]: value,
        });
    }
    function finalclick(){
        if(readTAndC == false){
            return validate();
        }else{
            return submitHandler();
        }
    }
    

    function validate(responseD) {
        //console.log("claed", responseD);
        let temp = { ...errors };
        temp.username =
            state.username.length == 0
                ? "This field is required"
                : emailPattern.test(state.username) === false
                ? "Invalid Email"
                : null;
        temp.password =
            state.password.length == 0
                ? "This field is required"
                : state.password.length < 6
                ? "Password length should be mimimum 6"
                : null;
        temp.first_name =
            state.first_name.length == 0
                ? "This field is required"
                : regexName.test(state.first_name) === false
                ? "Invalid Name"
                : null;
        temp.last_name =
            state.last_name.length == 0
                ? "This field is required"
                : regexName.test(state.last_name) === false
                ? "Invalid Name"
                : null;
        temp.phone_code =
            state.phone_code.length == 0
                ? "Please select Country/Region"
                : null;
        temp.agree_tandc =
            readTAndC == false
                ? "Agree to the terms and conditions"
                : null;

        temp.phone_number =
            state.phone_number.length == 0
                ? "This field is required"
                : (state?.phone_number?.match(/\d/g).length === 10) === false
                ? "Invalid Phone Number"
                : null;
        // temp.event_id =
        //     state.event_id.length == 0
        //         ? "This field is required"
        //         : regexEvent.test(state.event_id) === false
        //         ? "Invalid Code"
        //         : null;
        setErrors({ ...temp });
        // Object.values(temp).every((x) => {
        //     if (x !== null) {
        //         return false;
        //     } else return true;
        // });
        // //console.log(
        //     Object.values(temp).every((x) => {
        //         if (x !== null) {
        //             return false;
        //         }
        //         if (x == null) {
        //             //console.log("null");
        //             return true;
        //         }
        //     })
        // );

        //console.log(state);
        if (
            temp.username != null ||
            temp.password != null ||
            temp.phone_code != null ||
            temp.phone_number != null ||
            temp.first_name != null ||
            temp.last_name != null
        ) {
            return false;
        } else if (
            temp.username === null &&
            temp.password === null &&
            temp.phone_number === null &&
            temp.first_name === null &&
            temp.last_name === null
        ) {
            return true;
        }
    }



    const submitHandler = (e) => {       
        if (validate()) {
           // console.log("called here");
            register({
                first_name: state.first_name,
                last_name: state.last_name,
                password: state.password,
                email: state.username,
                phone_code: state.phone_code,
                phone_number: state.phone_number,
                event_id: eventConfig?.data?.[0]?.event_id,
                
            }).then((response) => {
               // console.log("response LOFIN.JS", response);
					
                if (response.Status_code == "2001"  ) {
                    if(readTAndC == true ){
                    setSuccess(true);}else{ return null}
                   
                    
                } else {
                    alert(response.message);
                }
            });
        } else {
            //console.log("failed validation");
        }
    };
    return (
    
        <>
        <div className="register-overall">
            <div className="register-eventinfo">
            
                <div style={{ backgroundColor: "#1500fa", padding: "35% 15px",}}>
                    <LoginRegisterSidebar />
                </div>
            </div>
            <div className="register-content">

                    <div className="register-content-logo">
                            <img  width="100px" height="50px"src={eventConfig?.data?.[0]?.event_logo}/>
                    </div>
                    <div className="register-content-main">
                        <div className="register-content-sub">
                       
                          <div className="register-content-maintext-title">
                            <div className="register-content-maintext-titletext">
                               Register
                            </div>
                          </div>
                        <div className="register-content-maintext-desc">
                                <div className="register-content-maintext-desctext" >
                                Let's get you all setup so you can verify your
                                personal account and begin setting up your
                                profile
                                </div>
                        </div>
                        
                        <div className="register-content-maincontent">
                            
                            <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname">
                                <span style={{fontWeight:"400"}}>
                                    First Name *
                                </span>
                            </div>
                            
                            <input
                                type="text"
                                className="register-content-inputbox"
                                placeholder="Enter First Name"
                                id="registerFirstName"
                                onChange={handleChange}
                                name="first_name"
                                required
                            />
                            <div className="register-content-errors">
                            {errors?.first_name != "" ? (
                                <div
                                    
                                    style={{
                                        color: "red",
                                        fontSize:"11px",
                                        justifyContent: "left",
                                    }}
                                >
                                    {errors?.first_name}
                                </div>
                            ) : null}</div>
                            </div>
                            <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname">
                                <span style={{fontWeight:"400"}}>
                                    Last Name *
                                </span>
                            </div>
                            
                            <input
                                type="text"
                                className="register-content-inputbox"
                                id="registerPassword"
                                required
                                placeholder="Enter Last Name"
                                name="last_name"
                                onChange={handleChange}
                            />
                            <div className="register-content-errors">
                            {errors?.last_name != "" ? (
                                <div
                                    
                                    style={{
                                        color: "red",
                                        fontSize:"11px",
                                        textAlign: "left",
                                    }}
                                >
                                    {errors?.last_name}
                                </div>
                            ) : null}</div>
                        </div>
                        <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname">
                                <span style={{fontWeight:"400"}}>
                                    Country / Region *
                                </span>
                            </div>

                            
                                <select 
                                 className="register-content-inputbox"
                                 id="registerCountry" 
                                name="phone_code" onChange={handleChange}>
                                    {countryCodes?.map((country) => {
                                        return (
                                            <>
                                            <option value="" selected disabled hidden> Select a country</option>
                                                <option
                                                    
                                                    key="country"
                                                    /*style={{
                                                        maxWidth: "80%",
                                                        overflow: "hidden",
                                                        width: "50px",
                                                    }}*/
                                                    onChange={handleChange}
                                                    value={country?.phone_country_code.split('(')[0]}
                                                >
                                                    {
                                                        country?.phone_country_code
                                                    }
                                                </option>
                                            </>
                                        );
                                    })}
                                </select>
                            
                            <div className="register-content-errors">
                            {errors?.phone_code != "" ? (
                                <div
                                    
                                    style={{
                                        color: "red",
                                        fontSize:"11px",
                                        textAlign: "left",
                                    }}
                                >
                                    {errors?.phone_code}
                                </div>
                            ) : null}
                            </div>
                        </div>
                        <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname">
                                <span style={{fontWeight:"400"}}>
                                    {" "}
                                    Phone number *
                                </span>
                            </div>

                            
                                <input
                                    className="register-content-inputbox"
                                    id="registerPhone"
                                    name="phone_number"
                                    placeholder="Enter Phone Number"
                                    type="tel"
                                    pattern="[0-9]{10}"
                                    onChange={handleChange}
                                />
                            
                            <div className="register-content-errors">
                            {errors?.phone_number != "" ? (
                                <div
                                    
                                    style={{
                                        color: "red",
                                        fontSize:"11px",
                                        textAlign: "left",
                                    }}
                                >
                                    {errors?.phone_number}
                                </div>
                            ) : null}</div>
                        </div>
                        <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname"> 
                                <span style={{fontWeight:"400"}}>
                                    Email address*
                                </span>
                            </div>
                            
                                <input
                                    className="register-content-inputbox"
                                    id="registerEmail"
                                    name="username"
                                    placeholder="Enter Email Address"
                                    type="email"
                                    onChange={handleChange}
                                />
                            
                            <div className="register-content-errors">
                            {errors?.username != "" ? (
                                <div
                                    
                                    style={{
                                        color: "red",
                                        fontSize:"11px",
                                        textAlign: "left",
                                    }}
                                >
                                    {errors?.username}
                                </div>
                            ) : null}</div>
                        </div>
                        <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname">
                                <span style={{fontWeight:"400"}}>
                                    Password *
                                </span>
                            </div>

                            
                                <input
                                    className="register-content-inputbox"
                                    id="registerPassword"
                                    placeholder="Enter Password"
                                    name="password"
                                    type="password"
                                    onChange={handleChange}
                                />
                            
                            <div className="register-content-errors">
                            {errors?.password != "" ? (
                                <div
                                   
                                    style={{
                                        color: "red",
                                        fontSize:"11px",
                                        textAlign: "left",
                                    }}
                                >
                                    {errors?.password}
                                </div>
                            ) : null}</div>
                        </div>
                        <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname">
                                <span style={{fontWeight:"400"}}>
                                    Company
                                </span>
                            </div>

                            
                                <input
                                    className="register-content-inputbox"
                                    id="registerCompany"
                                    placeholder="Enter Company"
                                    name="company"
                                    type="text"
                                    onChange={handleChange}
                                />
                            <div className="register-content-errors"></div>
                        </div>
                        <div className="register-content-maincontent-wrapper">
                            <div className="register-content-inputname">
                                <span style={{fontWeight:"400"}}>
                                    Industry
                                </span>
                            </div>

                            
                                <input
                                    className="register-content-inputbox"
                                    id="registerIndustry"
                                    placeholder="Enter Industry"
                                    name="industry"
                                    type="text"
                                    onChange={handleChange}
                                />
                            <div className="register-content-errors"></div>
                        </div>
                       </div>
                      <div className="register-content-bottomtext">
                      <div className="register-content-bottomtext-terms">
                            
                            <input
                                 className="register-content-bottomtext-terms-checkbox"
                                 name="agree_tandc"
                                 type="checkbox"
                                checked={readTAndC}
                                onClick={(e) => {
                                    //console.log(e.target);
                                    setReadTAndC((val) => !val);
                                }}
                            /> &nbsp;
                        
                            <span className="register-content-bottomtext-terms-words">
                                Yes, I agree to all Terms and Conditions
                            </span>
                            </div>
                            <div className="register-content-errors">
                        {errors?.agree_tandc != "" ? (
                                <div
                                    
                                    
                                    style={{
                                        color: "red",
                                        textAlign: "left",
                                        fontSize:"11px",
                                        
                                    }}
                                >
                                    {errors?.agree_tandc}
                                </div>
                            ) : null}</div>
                            
                    
                            <div className="register-content-bottomtext-button"  onClick={(event)=>[finalclick()]}>
                            <div className="register-content-bottomtext-button-wrapper">
                            <span
                                style={{color:"white"  , textAlign:"center" , justifyContent:"center"  }}
                               >
                               
                                Register
                            </span>
                            </div>
                            </div>
                            {success ? (
                                <div
                                    
                                    //className="small"
                                    style={{
                                        color: "green",
                                        marginTop: 10,
                                    }}
                                >
                                    Registered successfully!
                                </div>
                            ) : null}
                            <div className="register-pageswitch-wrapper">
                            <span style={{justifyContent:"center" , alignItems:"center"}}>
                                Already have an account? &nbsp;
                            </span>
                            <span style={{ color: "#1500fa", cursor: "pointer" , justifyContent:"center" , alignItems:"center" }}
                                onClick={() => { props.history.push("/login");}}>
                                {`Log in`}
                            </span>
                        </div>
                      </div>
                      </div>
                </div>
            </div>
        </div>
        </>
    );
}
