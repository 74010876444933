import { withStyles, Grid } from "@material-ui/core";
import LookIcon from "../../assets/look.png";
import MoveIcon from "../../assets/move.png";
import ZoomIcon from "../../assets/zoom.png";
import InteractIcon from "../../assets/interact.png";
import RotateIcon from "../../assets/rotate-instruction.png";


const styles = {
    modal: {
        // background: "#1d1c1f",
        // display: "flex",
        padding: "0px 10px",
        textAlign: "center",
    },
    instruction: {
        padding: 7,
        margin: 20,
    },
};

const instructions = [
    {
        title: "Move",
        icon: <img src={MoveIcon} />,
        content: (
            <>
                Click on the circle location  
                <br />
                markers to move around
            </>
        ),
    },
    {
        title: "Rotation",
        icon: <img src={RotateIcon} />,
        content: (
            <>
                Click 90<sup>0</sup> icon to see around 
                <br />
                different views
            </>
        ),
    },
    // {
    //     title: "Zoom",
    //     icon: <img src={ZoomIcon} />,
    //     content: (
    //         <>
    //             Use the scroll wheel
    //             <br />
    //             to zoom in and out
    //         </>
    //     ),
    // },
    {
        title: "Interact",
        icon: <img src={InteractIcon} />,
        content: (
            <>
                Click icons and highlighted
                <br />
                areas to open interactions
            </>
        ),
    },
];

const InstructionsModal = ({ classes }) => {
    return (
        <Grid className={classes.modal} container justify="center">
            {instructions.map((instruction) => {
                return (
                    <Grid
                        item
                        xs={6}
                        sm={3}
                        className={classes.instruction}
                        container
                        direction="column"
                    >
                        <Grid item style={{ textAlign: "center" }}>                           
                            <b>{instruction.title}</b>
                        </Grid>
                        <Grid
                            item
                            style={{ padding: 10, height: 80, width: "auto" }}
                        >
                            {instruction.icon}
                        </Grid>
                        <Grid
                            item
                            className="small"
                            style={{ textAlign: "center" }}
                        >
                            {instruction.content}
                        </Grid>
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default withStyles(styles)(InstructionsModal);
