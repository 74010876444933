import React, { useEffect, useState,useRef } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { baseUrl,profile_img_baseUrl } from "../../store/restApi/api";

import Button from "../common/Button";
import "./editProfile.css";
import pencilIcon from "../../assets/pencil.svg";
import { useStoreState,useStoreActions } from "easy-peasy";
import {createUsersV2, getUsersAllDetailsV2} from '../../pubNub/utils/PubNubUtilty';
import { editProfile as editProfileApi } from "../../store/restApi/api";
import { forget_password as forget_passwordApi } from "../../store/restApi/api";

const ProfileSection = ({ user_id, event_id, onClose }) => {
    const [inputData, setInputData] = useState({
        event_id: event_id,
        user_id: user_id,
        method: "update_details"
    });
    const { updateState } = useStoreActions((state) => state.state);
    const { userDetails } = useStoreState((state) => state.state);
    const { pubnubstate } = useStoreState((state) => state.pubNub);

    const [validationMsgs, setvalidationMsgs] = useState({});
    const actions = useStoreActions((state) => state.pubNub);
    const tarasfasf = React.useRef();

    useEffect(() => {
        // let obj = {
        //     event_id,
        //     user_id,
        //     method: "display_details"
        // }
        // fetch(
        //     `${baseUrl}/edit_attendee_profile_details.php`,
        //     {
        //         method: 'post',
        //         headers: { 'Content-Type': 'application/json' },
        //         body: JSON.stringify(obj)
        //     }
        // )
        //     .then((res) => {
        //         return res.json();
        //     })
        //     .then(res => {
        //         let obj = res.Attendee_profile_details[0];
                
        //         //console.log(obj);
        //         let combineObj = { ...inputData, ...obj }
        //         setInputData(combineObj);
        //     })
        //     .catch((err) => {
        //         //console.log(err);
        //     });

         let payload_data = {
            event_id:event_id,
            user_id:user_id,
            method: "display_details"
        };
        
        editProfileApi(payload_data)
            .then(res => {
                let obj = res.Attendee_profile_details[0];            
                let combineObj = { ...inputData, ...obj }
                setInputData(combineObj);
            })
            .catch((err) => {
                //console.log(err);
            });
  }, [])

    const onChangeHandler = (propName, value) => {
        setInputData({
            ...inputData,
            [propName]: value,
        });
    };

    const validationChekcedTrue = () => {
        if (!inputData.attendee_first_name) {
            setvalidationMsgs({
                attendee_first_name: "First name is required"
            });
            return false;
        }
        if (!inputData.attendee_last_name) {
            setvalidationMsgs({
                attendee_last_name: "Last name is required"
            });
            return false;
        }
        if (!inputData.company) {
            setvalidationMsgs({
                company: "Company name is required"
            });
            return false;
        }
        if (!inputData.attendee_designation) {
            setvalidationMsgs({
                attendee_designation: "Title is required"
            });
            return false;
        }
        if (!inputData.attendee_phone_num) {
            setvalidationMsgs({
                attendee_phone_num: "Phone number is required"
            });
            return false;
        }
        else {
            setvalidationMsgs({});
            return true;
        }
    }

    const submitProfileDetails = async () => {
        if (validationChekcedTrue()) {
            userDetails.username=inputData.attendee_first_name+" "+inputData.attendee_last_name;
            inputData.attendee_company = inputData.company;
            // await fetch(
            //     `${baseUrl}/edit_attendee_profile_details.php`,
            //     {
            //         method: 'post',
            //         headers: { 'Content-Type': 'application/json' },
            //         body: JSON.stringify(inputData)
            //     }
            // )
            //     .then((res) => {
            //         return res.json();
            //     })
            //     .then(res => {                     
            //         updateState({ userDetails:userDetails });
            //         onClose();
            //     })
            //     .catch((err) => {
            //         //console.log(err);
            //     });

            editProfileApi(inputData)     
            .then(res => {                     
                updateState({ userDetails:userDetails });
                onClose();
            })
            .catch((err) => {
                //console.log(err);
            });
        }
    }

    const updateProfilePic = async (imageName, img) => {        
            
       
        let obj = {
            event_id: event_id,
            attendee_user_id: user_id,
            attendee_profile_image: imageName,
            attendee_image_encode: img
        }
        await fetch(
            `${baseUrl}/profile_image_upload.php`,
            {
                method: 'post',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(obj)
            }
        )
            .then((res) => {
               
                return res.text();
            })
            .then(res => {
                res=JSON.parse(res);              
                if(res.Status.localeCompare("Success")==0)
                {                                
                    userDetails.profile_image=res.attendee_profile_image;
                    updateState({ userDetails:userDetails }); 
                    createUsersV2(userDetails,pubnubstate)  
                    getUsersAllDetailsV2(pubnubstate, actions.updateState);          
                }
                onClose();
            })
            .catch((err) => {
                //console.log(err);
            });
    }

    function getBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

  
  
 


    return(
        <>
       
     <div className="row m-0 edit_form"  onClick={e => e.stopPropagation()}>
        <div className="col-1 p-0"></div>
        <div className="col-10 p-0">
            <div className="row m-0 align-items-center">
                <div className="col-4 border_line p-0"></div>
                <div className="col-4 text-center p-0">Personal Information</div>
                <div className="col-4 border_line p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0 d-flex justify-content-center">               

                    <input onChange={(e) => { 
                   e.stopPropagation()                    
                        getBase64(e.target.files[0]).then(
                            data => {
                                var strImage = data.replace(/^data:image\/[a-z]+;base64,/, "");
                                updateProfilePic(e.target.files[0].name, strImage)
                                //console.log(strImage, "DATA")
                            }
                        );
                    }} id="ppt" type="file" className="d-none" name="ppt" multiple  data-inline="true" data-mini="true" data-corners="false" /> 

                    <label for="ppt" data-role="button" data-inline="true" data-mini="true" data-corners="false">
                        <div className="edit_profile_div" style={{backgroundSize: 'cover', backgroundImage: `url(${inputData.profile_image})`}}>
                                <div className="edit_profile_icon d-flex justify-content-center align-items-center">
                                        <img src={pencilIcon} width="16" height="16" alt="" />
                                </div>
                        </div>
                    </label>
       
                </div>
                <div className="col-2 p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-3 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">First Name*</p>
                        </div>
                        <div className="col-9 w-100 p-0">
                            <input type="text" value={inputData.attendee_first_name} onChange={(e) => {
                                onChangeHandler("attendee_first_name", e.target.value)
                            }} />
                            <p className="validation_msg">{validationMsgs.attendee_first_name}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-3 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">Last Name*</p>
                        </div>
                        <div className="col-9 w-100 p-0">
                            <input type="text" value={inputData.attendee_last_name} onChange={(e) => {
                                onChangeHandler("attendee_last_name", e.target.value)
                            }} />
                            <p className="validation_msg">{validationMsgs.attendee_last_name}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-1 p-0"></div>
                <div className="col-9 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-4 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">Company Name*</p>
                        </div>
                        <div className="col-8 w-100 p-0">
                            <input type="text" value={inputData.company} onChange={(e) => {
                                onChangeHandler("company", e.target.value)
                            }} />
                            <p className="validation_msg">{validationMsgs.company}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>

            <div className="row m-0 mt-4 align-items-center">
                <div className="col-1 p-0"></div>
                <div className="col-9 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-4 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">Phone Number*</p>
                        </div>
                        <div className="col-8 w-100 p-0">
                            <input type="number" value={inputData.attendee_phone_num} onChange={(e) => {
                                onChangeHandler("attendee_phone_num", e.target.value)
                            }} />
                            <p className="validation_msg">{validationMsgs.attendee_phone_num}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>

            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-3 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">Title*</p>
                        </div>
                        <div className="col-9 w-100 p-0">
                            <input type="text" value={inputData.attendee_designation} onChange={(e) => {
                                onChangeHandler("attendee_designation", e.target.value)
                            }} />
                            <p className="validation_msg">{validationMsgs.attendee_designation}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-4 border_line p-0"></div>
                <div className="col-4 text-center p-0">Social Media Profiles</div>
                <div className="col-4 border_line p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-3 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">Linked In</p>
                        </div>
                        <div className="col-9 w-100 p-0">
                            <input type="text" value={inputData.profile_link_linkedin} onChange={(e) => {
                                onChangeHandler("profile_link_linkedin", e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-3 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">Twitter</p>
                        </div>
                        <div className="col-9 w-100 p-0">
                            <input type="text" value={inputData.profile_link_twitter} onChange={(e) => {
                                onChangeHandler("profile_link_twitter", e.target.value)
                            }} />
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center justify-content-center m-0">
                        <button className="submit_profile_btn" onClick={submitProfileDetails}>
                            Submit
                        </button>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
        </div>
        <div className="col-1 p-0"></div>
    </div>
    </>)
    
};

const PasswordTab = ({ user_id, event_id, onClose }) => {

    const { userDetails } = useStoreState((state) => state.state);

    //console.log(userDetails);

    const [passwordData, setPasswordData] = useState(
        {
            event_id: event_id,
            user_id: user_id,
            method: "2",
            email_id: userDetails.email
        }
    );

    const [validationMsgs, setvalidationMsgs] = useState({});

    const validationChekcedTrue = () => {
        if (!passwordData.password) {
            setvalidationMsgs({
                password: "Password is required"
            });
            return false;
        }
        if (!passwordData.new_password) {
            setvalidationMsgs({
                new_password: "Confirm password is required"
            });
            return false;
        }
        if(passwordData.password !== passwordData.new_password){
            setvalidationMsgs({
                new_password: "Password & confirm password should be same"
            });
            return false;
        }
        else {
            setvalidationMsgs({});
            return true;
        }
    }

    const onChangeHandler = (propName, value) => {
        setPasswordData({
            ...passwordData,
            [propName]: value,
        });
    };


    const submitPasswordDetails = async () => {
        if (validationChekcedTrue()) {
            // await fetch(
            //     `${baseUrl}/get_password_to_reset.php`,
            //     {
            //         method: 'post',
            //         headers: { 'Content-Type': 'application/json' },
            //         body: JSON.stringify(passwordData)
            //     }
            // )
            //     .then((res) => {
            //         return res.json();
            //     })
            //     .then(res => {
            //         onClose();
            //     })
            //     .catch((err) => {
            //        // console.log(err);
            //     });


           
            forget_passwordApi(passwordData)
                .then(res => {
                    onClose();
                })
                .catch((err) => {
                   // console.log(err);
                });
        }
    }

    return <div className="row m-0 edit_form">
        <div className="col-1 p-0"></div>
        <div className="col-10 p-0">
            <div className="row m-0 align-items-center">
                <div className="col-4 border_line p-0"></div>
                <div className="col-4 text-center p-0">Change Password</div>
                <div className="col-4 border_line p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-3 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">New Password*</p>
                        </div>
                        <div className="col-9 w-100 p-0">
                            <input type="password" value={passwordData.password} onChange={(e) => {
                                onChangeHandler("password", e.target.value)
                            }} />
                            <p className="validation_msg">{validationMsgs.password}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center m-0">
                        <div className="col-3 pr-4 d-flex justify-content-end p-0">
                            <p className="mb-0 text-end">Confirm Password*</p>
                        </div>
                        <div className="col-9 w-100 p-0">
                            <input type="password" value={passwordData.new_password} onChange={(e) => {
                                onChangeHandler("new_password", e.target.value)
                            }} />
                            <p className="validation_msg">{validationMsgs.new_password}</p>
                        </div>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>

            <div className="row m-0 mt-4 align-items-center">
                <div className="col-2 p-0"></div>
                <div className="col-8 p-0">
                    <div className="row align-items-center justify-content-center m-0">
                        <button className="submit_profile_btn" onClick={submitPasswordDetails}>
                            Submit
                        </button>
                    </div>
                </div>
                <div className="col-2 p-0"></div>
            </div>
        </div>
        <div className="col-1 p-0"></div>
    </div>
}


const EditProfile = ({ user_id, event_id, onClose }) => {

    const [key, setKey] = useState('profile');

    return (
        <div className="edit_profile_container">
            <p className="mb-0 edit_profile_heading">Edit Profile</p>

            <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-4"
            >
                <Tab eventKey="profile" title="PROFILE">
                    <ProfileSection
                        user_id={user_id}
                        event_id={event_id}
                        onClose={onClose}
                    />
                </Tab>
                <Tab eventKey="settings" title="CHANGE PASSWORD">
                    <PasswordTab
                        user_id={user_id}
                        event_id={event_id}
                        onClose={onClose}
                    />
                </Tab>

            </Tabs>


        </div>
    )
}

export default EditProfile;