import React, { useEffect, useState } from "react";
import { Rating } from "react-simple-star-rating";
import { baseUrl } from '../../store/restApi/api'
import { Badge } from "reactstrap";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import Button from "../common/Button";
import { useStoreState, useStoreActions } from "easy-peasy";
const SessionList = ({
  title,
  desc,
  startDate,
  startTime,
  endDate,
  endTime,
  Status,
  startZone,
  endZone,
  session_id,
  event_id,
  attendee_id,
  channel_id,
  streaming_provider,
  session_rating,
}) => {
  const [rating, setRating] = useState(0);
  const { updateState } = useStoreActions((state) => state.state);



  useEffect(() => {
    setRating(() => session_rating)
   
    return () => {
      setRating(() => 0)
    }
  }, [session_rating])
  const handleRating = (count) => {
    if(session_rating == ""){
      setRating(count);
       rateSession(count);
    }
  };


  async function rateSession(count) {
    const response = await fetch(
      `${baseUrl}/session_like_dislike.php?
          event_id=${event_id}&attendee_id=${attendee_id}&rating=${count}&session_id=${session_id}`
    )
      .then((res) => res.json())
      .catch((err) => {
        //console.log(err);
      });
    //console.log(response)
  }
  const stateUpdate = (channel_id,streaming_provider) => {    
    let isMux = false;
    // revertToScreen();
    // setVideoUrl(
    //     channel_id
    // // );
    // if (
    //    streaming_provider ==
    //     "Mux.com"
    // ) {
      
    updateState({
      isMux: true,
      streamUrl: channel_id,
      streamProvide:streaming_provider,
      isAuditoriumConferenceModalVisible : false
    });

    
    // }
    // Below code if other provider links will be added later on
    //  else {
    //     updateState({
    //         isMux: false,
    //         streamUrl:
    //             channel_id,
    //     });
    // }
  }

  return (
    <>
      {title && desc ? (
        <tr className="agenda_body_tr tr">
          <td className="agenda_time td">
            <div>
              <span>
                {startDate} - {endDate}
              </span>
              <br />
              <span>
                {startTime} - {endTime}
              </span>
            </div>
          </td>
          <td className="agenda_content_td td">
            <div className="agenda_content">
              <strong>{title}</strong>
            </div>
            <span className="agenda_desc">{desc}</span>
          </td>
          <td className="agenda_content_watch td"
          // onClick={() => {
          //     setVideoUrl(
          //         session?.on_demand_video_url
          //     );
          //     setPlaySesisonVideo(true);
          // }}
          ><div style={{ padding: 5, height: 20 }} onClick={() => {stateUpdate(channel_id , streaming_provider)}}>

              {Status == "Live" ? (
                <Badge>Live</Badge>
              ) : null}

              {Status == "Over" ? (
                <Badge>Over</Badge>
              ) : null}
              {Status == "Scheduled" ? (
                <Badge>Scheduled</Badge>
              ) : null}
            </div>
            {Status != "Scheduled" ? (
              <div style={{ cursor: "pointer" }} onClick={() => {stateUpdate(channel_id , streaming_provider)}}>
                <Button
                  OpenIcon={ChevronRightIcon}
                  handleClick={() => {
                    //  let isMux = false;
                    // // revertToScreen();
                    // // setVideoUrl(
                    // //     channel_id
                    // // );
                      stateUpdate(channel_id , streaming_provider)
                  }}

                  small={true}
                  content={
                    Status == "Live" ?
                      <b>Join Livestreaming</b> : Status == "Over" ? <b>Watch Recording</b> : ""
                  }
                />
              </div>) : null}</td>
          {/* <td className="agenda_content_watch td">{Status}</td> */}
          <td className="agenda_content_rating td">
            <Rating
              onClick={handleRating}
              ratingValue={rating}
              fillColor="#1029f7"
              className={`${rating == 0 ? '' : 'disabled'}`}
            />
          </td>
        </tr>
      ) : (<tr></tr>)}
    </>
  );
};

export default SessionList;
