import React, { useEffect, useState } from "react";
import './user.css';
import textchat from "../../../assets/textchat.png";
import videochat from "../../../assets/videochat.png";
import { useStoreActions, useStoreState } from "easy-peasy";
import { videoCallURL } from "../../../pubNub/constants/PubNubConstant";
import './userList.css';
//-------------------------Chat popup-------------------------
import Modal1 from "../../modal/Modal";
import EventChat from "../chat/Chat";
import "../../../components/pages/chat/simple-chat.scss";
import { serverApi } from "../../../store/restApi/serverApi";
import fb_logo from "../../../assets/fb-logo.png";
import li_logo from "../../../assets/linkedin-icon-512x512-vkm0drb1.png"
import twitter_log from '../../../assets/twitter.avif';
import skype from "../../../assets/skype.png"
import call from "../../../assets/call-icon.png";
import email from "../../../assets/email.png";
import useWindowSize from "./useWindowSize";

const Chat = () => {
    return (
        <EventChat />
    );
};
const User1 = ({ data }) => {
    const [profile, setProfile] = useState(data?.profile);
    const [showChatModal, setShowChatModal] = useState(false);
    const [bussinessCard, setBussinessCard] = useState('');
    const [open, setOpen] = useState(false)
    const { sendMessage, videoCallAction, updateState } = useStoreActions((state) => state.pubNub);
    const { upto } = useStoreActions((state) => state.state);
    const { userDetails, eventConfig, chat_modal } = useStoreState((state) => state.state);
    const [openBussinessCard, setOpenBussinessCard] = useState(false)
    const [currentProfile, setCurrentProfile] = useState("")
    const pubAction = useStoreActions((state) => state.pubNub);
    const width = useWindowSize()

    useEffect(() => {
        setProfile((val) => data?.profile);
        // updateState({ profile_det: data?.profile});

        setBussinessCard({ b_card: data?.businesscard, profile_det: data?.profile });
        return () => {
            setProfile((val) => '');
            setBussinessCard((val) => '');
        };
    }, [data]);

    const triggerVideoCall = () => {
        let payLoad = {
            channel: profile?.user_id,
            message: userDetails?.displayname,
            meta: {
                ToUUID: profile?.user_id,
                ToUserName: profile?.first_name,
                FromUUID: userDetails?.id,
                fromUser: userDetails?.displayname,
                chatType: "VIDEO",
                chatURL: videoCallURL + userDetails?.id
            },
        };
        updateState({ profile_det: data?.profile });
        // console.log(profile?.user_id)
        videoCallAction({})
        videoCallAction(payLoad)
        sendMessage(payLoad);
    }
    const handleChat = (profile_data) => {
        let payload = {
            event_id: eventConfig?.data?.[0]?.event_id,
            user_id: profile_data?.user_id,
        };
        serverApi
            .getChatMembersDetailsById(payload)
            .then(({ data }) => {
                let chatUserObj = {
                    displayName: data[0]?.name,
                    user_id: data[0]?.id,
                    email_id: data[0]?.email,
                    type: 'NORMAL'
                };
                updateState({ currentChatUserDetails: chatUserObj });
                pubAction.updateState({ chatType: "" });
                setShowChatModal(true);
                setOpenBussinessCard(false)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    const { boothDetails, exhibitorInfo } = useStoreState(
        (state) => state?.boothLiveState
    );


    return (
       
           <>
                        <div class="col-sm-12 col-md-6 col-lg-4">
                            <div class="widget-box">
                                <div class="img-box"><div class="count">
                                    {profile?.first_name?.charAt(0)}{profile?.last_name?.charAt(0)}
                                </div></div>
                                <h3>{profile.first_name + " " + profile.last_name}</h3>
                                {profile.job_title !== "" && typeof profile.job_title !== "undefined" ?(
                                    <p>{profile.job_title}</p>
                                ):(null) }
                                {profile.company !== "" && typeof profile.company !== "undefined" ?(
                                    <p>{profile.company}</p>
                                ):(null) }
                                         <div className="box-img" style={{background:'#f7f7f7'}}>
                                         <img src={videochat} onClick={triggerVideoCall} />
                                            <img src={textchat} onClick={() => handleChat(profile)} /> 
                                        </div>                      
                                                                   
                              
                                
                                {data?.businesscard?.businesscard_email !== "" && typeof data.businesscard !== "undefined" ?(
                                    <div class="box-img no-left">
                                    <img src={email} /> <p>{data?.businesscard?.businesscard_email}</p>
                                </div>
                                ):(
                                
                                null
                                )}

                                
                                {data?.businesscard?.businesscard_phone_no !== "" && typeof data?.businesscard !== "undefined" ? (
                                    <div class="box-img no-left">
                                        <img src={call} /> <p>{data?.businesscard?.businesscard_phone_no}</p>
                                    </div>
                                ) : (
                                   
                                    null
                                )}
                               
                               
 
                              
                                <div class="box-img">
                                    { data?.businesscard?.businesscard_profile_link_facebook !== null &&data?.businesscard?.businesscard_profile_link_facebook !== "" && typeof data?.businesscard?.businesscard_profile_link_facebook !== "undefined" ?(
                                        <a href={data?.businesscard?.businesscard_profile_link_facebook}target="_blank"> <img src={fb_logo} style={{width:'40px'}} /></a>
                                    ):null }
                                { data?.businesscard?.businesscard_profile_link_linkedin !== null &&data?.businesscard?.businesscard_profile_link_linkedin !== "" && typeof data?.businesscard?.businesscard_profile_link_linkedin !== "undefined"?(
                                    <a href={data?.businesscard?.businesscard_profile_link_linkedin} target="_blank"><img src={li_logo} /> </a>
                                ):null}
                                 { data?.businesscard?.businesscard_profile_link_twitter !== null &&data?.businesscard?.businesscard_profile_link_twitter !== "" && typeof data?.businesscard?.businesscard_profile_link_twitter !== "undefined"?(
                                    <a href={data?.businesscard?.businesscard_profile_link_twitter} target="_blank"><img src={twitter_log} /> </a>
                                ):null}
                                {/* businesscard_profile_link_twitter */}
                                {data?.businesscard?.businesscard_skype_username !==null && data?.businesscard?.businesscard_skype_username !=="" &&typeof data?.businesscard?.businesscard_skype_username!=="undefined" ?(
                                            <a href={`'skype:'${data?.businesscard?.businesscard_skype_username}`} target="_blank"><img src={skype} style={{marginLeft:'5px'}}/> </a>
                                 ):null}
                                
                                </div>
                                <div class="box-img">
                                {profile.interests !== "" && typeof profile.interests !== "undefined"?(
                                    <p>{profile.interests}</p>
                                ):(null) }
                                </div>
                                
                            </div>
                        </div>
                   
            <Modal1
                open={showChatModal}
                onClose={() => setShowChatModal((value) => false)}

                widith="70%"
                height="80%"
            >
                <Chat />
            </Modal1>
            </>
        
    )
}

export default User1;

