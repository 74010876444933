import React, { useEffect, useState } from "react";
import "./questionAndAnswer.css";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { baseUrl } from '../../store/restApi/api'
import QuestionComponent from "./QuestionComponent";
import { 
  attendeePostQuestion as attendeePostQuestionApi,
  attendeePostedQuestion as attendeePostedQuestionApi,
  questionsUpvote as questionsUpvoteApi
 } from "../../store/restApi/api";


const QuestionAndAnswer = ({ event_id, hall_id, attendee_id }) => {
  const [attendeeQuestions, setattendeeQuestions] = useState([]);
  const [questionCount, setQuestionCount] = useState(0);
  const [newQuestion, setNewQuestion] = useState("");
  const [keyPressed, setKeyPressed] = useState(false);
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [isPopular, setIsPopular] = useState(false);
  const [isRecent, setIsRecent] = useState(true);

  useEffect(() => {
    async function fetchQuestionCount() {
      // const questioncounts = await fetch(
      //   `${baseUrl}/attendee_post_question.php?
      //     &event_id=${event_id}&hall_id=${hall_id}`
      // )
      //   .then((res) => res.json())
      //   .catch((err) => {
      //    // console.log(err);
      //   });

          let payload_data={
            event_id: event_id,
            hall_id: hall_id
          };
          const questioncounts = attendeePostQuestionApi(payload_data)
          .then((res) => {  
                  
            setQuestionCount(res?.question_count);
          })
          .catch((err) => {
           // console.log(err);
          });
      
    }

    fetchQuestionCount();
    fetchQuestion(`${isRecent ? "recent" : "popular"}`);

    return () => {
      setQuestionCount(0)

    };
  }, [keyPressed, isRecent]);

  useEffect(() => {
    let time = setTimeout(() => {
      setKeyPressed(!keyPressed);
    }, 10000);
    return () => {
      clearTimeout(time);
    };
  });

  const askQuestion = (e) => {
    if (e.key === "Enter" && newQuestion.trim() !== "") {
      e.preventDefault();

      submitQuestion();
      setKeyPressed(!keyPressed);
      setNewQuestion("");
    }
  };

  async function fetchQuestion(questionType) {

    // const attendeeQuestion = await fetch(
    //   `${baseUrl}/attendee_posted_questions.php?
    //      &event_id=${event_id}&hall_id=${hall_id}&option=${questionType}`
    // )
    //   .then((res) => res.json())
    //   .catch((err) => {
    //     //console.log(err);
    //   });
   
    let payload_data={
      event_id: event_id,
      hall_id: hall_id,
      option: questionType
    };
    const attendeeQuestion = attendeePostedQuestionApi(payload_data)
          .then((res) =>{
           setattendeeQuestions(res);
          })
          .catch((err) => {
           // console.log(err);
          });    
  }

  async function submitQuestion() {
    // const response = await fetch(
    //   `${baseUrl}/attendee_post_question.php?
    //   attendee_id=${attendee_id}&question=${newQuestion.trim()}&event_id=${event_id}&hall_id=${hall_id}&user_type=${isAnonymous ? 2 : 1
    //   }`
    // )
    //   .then((res) => res.json())
    //   .catch((err) => {
    //     //console.log(err);
    //   });

          let payload_data={
            attendee_id:attendee_id,
            question:newQuestion.trim(),
            event_id:event_id,
            hall_id:hall_id,
            user_type:(isAnonymous ? 2 : 1)
          };
          attendeePostQuestionApi(payload_data)
          .then((res) =>{            
          })
          .catch((err) => {
           // console.log(err);
          });
  }

  const handleAskQuestion = () => {
    if (newQuestion.trim() !== "") {
      submitQuestion();
      setKeyPressed(!keyPressed);
      setNewQuestion("");
    }
  };

  const updatevote = (question_id, flag) => {
     async function updateVote() {
    //   const response = await fetch(
    //     `${baseUrl}/update_questions_upvote.php?
    //       event_id=${event_id}&attendee_id=${attendee_id}&hall_id=${hall_id}&question_id=${question_id}&upvote_flag=${flag}`
    //   )
    //     .then((res) => res.status)
    //     .catch((err) => {
    //       //console.log(err);
    //     });


        let payload_data={
          event_id:event_id,
          attendee_id:attendee_id,
          hall_id:hall_id,
          question_id:question_id,
          upvote_flag:flag
        };
        questionsUpvoteApi(payload_data).then((res) => res.status)
        .catch((err) => {
          //console.log(err);
        });
     }

    updateVote();
    setKeyPressed(!keyPressed);
  };

  const deleteQuestion = (question_id) => {
    async function deleteQA() {
      // const response = await fetch(
      //   `${baseUrl}/attendee_posted_questions.php?
      //     &event_id=${event_id}&hall_id=${hall_id}&question_id=${question_id}&option=delete`
      // )
      //   .then((res) => res.json())
      //   .catch((err) => {
      //     //console.log(err);
      //   });
      let payload_data={
        event_id:event_id,
        hall_id:hall_id,
        question_id:question_id,
        option:'delete'
      };
      attendeePostedQuestionApi(payload_data)
      .then((res) => res.json())
      .catch((err) => {
        //console.log(err);
      });
    }

    deleteQA();
    setKeyPressed(!keyPressed);
  };

  const editQuestion = (updateQuestion, question_id) => {
    async function editQA() {
      // const response = await fetch(
      //   `${baseUrl}/attendee_posted_questions.php?
      //       &event_id=${event_id}&hall_id=${hall_id}&question_text=${updateQuestion}
      //       &question_id=${question_id}&option=edit`
      // )
      //   .then((res) => res.json())
      //   .catch((err) => {
      //     //console.log(err);
      //   });
      let payload_data={
        event_id:event_id,
        hall_id:hall_id,
        question_text:updateQuestion,
        question_id:question_id,
        option:'edit'
      };
      attendeePostedQuestionApi(payload_data)    
      .then((res) => res.json())
      .catch((err) => {
        //console.log(err);
      });
    }
    editQA();
    setKeyPressed(!keyPressed);
  };

  const handleSwitchQuestion = (type) => {
    if (type === "popular") {
      setIsPopular(true);
      setIsRecent(false);
    } else {
      setIsPopular(false);
      setIsRecent(true);
    }

    fetchQuestion(type);
    setKeyPressed(!keyPressed);
  };

  return (
    <div className="questionAndAnswer_container">
      <div className="qa_question" onKeyDown={askQuestion}>
        <AccountCircleRoundedIcon className="qa_user_icon" />
        <textarea
          placeholder="Type your question"
          style={{ margin: "0px 0px 0px 16px", height: "24px" }}
          onChange={(e) => setNewQuestion(e.target.value)}
          value={newQuestion}
        ></textarea>
      </div>
      <div className="qa_anaynoms_options">
        <div className="qa_question_type">
          <input
            type="checkbox"
            name="Anonymous"
            checked={isAnonymous}
            onChange={() => setIsAnonymous(!isAnonymous)}
          />
          <span>Anonymous</span>
        </div>
        <div className="send_button" onClick={handleAskQuestion}>
          <SendRoundedIcon style={{ fontSize: "1.2rem", cursor: "pointer" }} />
        </div>
      </div>
      <div className="qa_options">
        <div className="qa_question_type">
          <span
            style={{ borderBottom: `${isPopular ? "3px solid #1029f7" : ""}` }}
            onClick={() => handleSwitchQuestion("popular")}
          >
            Popular
          </span>
          <span
            style={{ borderBottom: `${isRecent ? "3px solid #1029f7" : ""}` }}
            onClick={() => handleSwitchQuestion("recent")}
          >
            Recent
          </span>
        </div>
        <div className="qa_question_count">
          <span>{questionCount} questions</span>
        </div>
      </div>
      <div className="qa_topic_container">
        {attendeeQuestions.map(
          ({
            Question,
            Question_id,
            created_by_participant_name,
            upvote_count,
            created_time,
            reply_message,
            attendee_upvote_list,
            created_by_participant_id,
          }) => (
            <QuestionComponent
              question={Question}
              Question_id={Question_id}
              name={created_by_participant_name}
              upvote={upvote_count}
              time={created_time}
              updateUpvote={updatevote}
              key={Question_id}
              deleteQA={deleteQuestion}
              edit={editQuestion}
              replay={reply_message}
              selfUpvote={attendee_upvote_list
                .split(",")
                .filter(
                  (vote) =>
                    parseInt(vote) === parseInt(created_by_participant_id)
                )}
              created_by={created_by_participant_id}
            />
          )
        )}
      </div>
    </div>
  );
};

export default QuestionAndAnswer;
