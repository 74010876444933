import React, { useEffect, useState } from "react";
import "./feedback.css";
import { baseUrl } from '../../store/restApi/api'
import { useStoreState,useStoreActions } from "easy-peasy";
import { feedbacQuestion as feedbacQuestionApi } from "../../store/restApi/api";
import { feedbackResponse as feedbackResponseApi } from "../../store/restApi/api";



const FeedBack = ({user_id, event_id, logout, logutflag}) => {
  const [allQuestion, setAllQuestion] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [showResponse, setShowResponse] = useState('')
  const { updateState } = useStoreActions((state) => state.state);
  const { userDetails } = useStoreState((state) => state.state);

  useEffect(() => {



    async function fetchQuestion() {
      // const questions = await fetch(
    //     `${baseUrl}/event_feedback_form_questions.php`,
    //     {
    //       method: 'post',
    //       headers: {'Content-Type':'application/json'},
    //       body: JSON.stringify({
    //        "event_id": event_id
    //       })
    //     }
    //   )
    //     .then((res) => res.json())
    //     .catch((err) => {
    //       //console.log(err);
    //     });

    //   if(questions.length){
    //     setAllQuestion(questions);      
    //   }
     
    // }

        let payload = { event_id: event_id}
        feedbacQuestionApi(payload)
        .then((res) =>{          
         setAllQuestion(res)
        })
        .catch((err) => {
          //console.log(err);
        });

       
     
    }
    setShowResponse('');

    fetchQuestion();
    return () => {
      //console.log("clean up");
    };
  }, []);

  function handleQuestionType(question_type,question_id) {
    return question_type === "text" ? (
      <input
        type={question_type}
        name={question_id}
        onChange={handleInputChange}
      />
    ) : (
      <textarea name={question_id} onChange={handleInputChange}></textarea>
    );
  }

  const handleInputChange = (e) => {  
 
    let question_id = e?.nativeEvent.srcElement.name;
    let value = e.target.value;
  

    let index = allQuestion.findIndex((x) => x.question_id === question_id);
    let question = allQuestion[index];
  

    if(typeof question!="undefined")
    { 
      if(question.question_type === "checkbox"){
        let checked = handleMultipleAnswerQuestion(value, e.target.checked , question["answer"])
        question["answer"] = checked;
      }else {        
        question["answer"] = value;
      }
    }

    allQuestion[index] = question;

    setAllQuestion(allQuestion);
  };

  const handleMultipleAnswerQuestion = (currentAnswer , checked , oldAnswer) => {
    let answers = []

    if(oldAnswer){
      oldAnswer = oldAnswer.split(",")
      if(checked){
        answers =  [...oldAnswer, currentAnswer]
      }else {
        answers = oldAnswer.filter(data => data !== currentAnswer )
      }
    }else{
      answers.push(currentAnswer)
    }
    return answers.join();
  }

  const handleFeedbackSubmit = () => {
 
    let unAnswered = allQuestion.filter(
      (question) => question.form_validation === "required" && !question.answer
    );
  
    if(unAnswered.length !== 0 ){
      setErrorMessage(true)
    }else{
      setErrorMessage(false)
      submitFeedBack()
    }
  };


  const submitFeedBack = async () => {
    /*let queryParam = "";

    queryParam += `user_id=${user_id}&event_id=${event_id}&user_type=${allQuestion[0].user_type}`;

    allQuestion.forEach(({ answer, question_id }, index) => {
      queryParam += `&question_id_${index + 1}=${question_id}&response_value_${index + 1}=${answer}`;
    });
    const response = await fetch(
      `${baseUrl}/event_feedback_form_response.php?${queryParam}`
    )
      .then((res) => res.json())
      .catch((err) => {
       // console.log(err);
      });

    if(response?.Status_code === "2001"){
      setShowResponse(response.message)
     let userDetail = {
        ...userDetails,
        event_feedback_given : 1
      }
      updateState({
        userDetails: userDetail,
    });
      if(logutflag) logout();
    }else if(response?.Status_code === "2003"){
      setShowResponse(response.message)
      let userDetail = {
        ...userDetails,
        event_feedback_given : 1
      }
      updateState({
        userDetails: userDetail,
    });
      if(logutflag) logout();
    }else{
      setShowResponse("Something went wrong !!!")
    }*/


    let queryParam = {};

    queryParam['user_id']=user_id;
    queryParam['event_id']=event_id;
    queryParam['user_type']=allQuestion[0].user_type;

    allQuestion.forEach(({ answer, question_id }, index) => {     
      queryParam[('question_id_'+(index + 1))]=question_id;
      queryParam[('response_value_'+(index + 1))]=answer;      
    });

      feedbackResponseApi(queryParam)
      .then((response) =>{
        if(response?.Status_code === "2001"){
          setShowResponse(response.message)
         let userDetail = {
            ...userDetails,
            event_feedback_given : 1
          }
          updateState({
            userDetails: userDetail,
        });
          if(logutflag) logout();
        }else if(response?.Status_code === "2003"){
          setShowResponse(response.message)
          let userDetail = {
            ...userDetails,
            event_feedback_given : 1
          }
          updateState({
            userDetails: userDetail,
        });
          if(logutflag) logout();
        }else{
          setShowResponse("Something went wrong !!!")
        }
      })
      .catch((err) => {
       // console.log(err);
      });
  };


  return (
    <>
    {errorMessage && (
      <div className="feedback_error_message" style={{lineHeight : '-1px'}}>
        <span>Please fill the required field (*)</span>
      </div>
    )}
      
    <div className="feedBack_container" onClick={e => e.stopPropagation()}>  
      <div className="feedBack_header">
        <span>Event Survey/Feedback:</span>
      </div>
      <div className="feedBack_question_container">        
        {allQuestion?.map(
          ({
            question_id,
            question_type,
            questions,
            value,
            form_validation,
            order_by
          }) => {
            return (              
              <div key={question_id} id={question_id}>
                <div className="feedback_question">
                  <span>{order_by})  </span>                  
                  <span>
                    {questions}
                    <span style={{ color: "red", fontWeight: "bold" }}>
                      {form_validation === "required" ? "*" : ""}
                    </span>
                  </span>
                </div>
                <div className="feedback_answer">
                  {value.length !== 0 ? (
                    value?.split(",").map((option, index) => {
                      return (
                        <div className="feedback_option" key={index}>
                          <input
                            type={question_type}
                            name={question_id}
                            value={option}
                            onChange={handleInputChange}                             
                          />
                           <span>{option}</span>
                        </div>
                      );
                    })
                  ) : (
                    <div className="feedback_option">
                      {handleQuestionType(question_type,question_id)}
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
      {showResponse === "" ? (
        <div className="feedback_submit" onClick={handleFeedbackSubmit}>
          <span>Submit</span>
        </div>
      ) : (
        <div className="feedback_response">**{showResponse}**</div>
      )}

     
    </div>
    </>
  );
};

export default FeedBack;
