import React from "react";
import "./modal.css";
import ReactDOM from "react-dom";
import CloseIcon from "@material-ui/icons/Close";
import Button from "../common/Button";


const Modal = ({ open, children, onClose, width, height }) => {
  if (!open) return null;

  

 
  return ReactDOM.createPortal(
    <>
      <div className="overlay"  />
      <div className="modal_container"  style={{width : width, height : height} }>
        <Button
         customIconContainer='close'
          OpenIcon={CloseIcon}
          handleClick={(event) => {
            onClose();
          }}
          id="close"
          style={{marginRight: '-3rem', marginTop:'-1%'}}
          // style={{marginRight: "-50px",marginTop: "-1rem"}}
        ></Button>
        {children}
      </div>
    </>,

    document.getElementById("portal")
  );
};

export default Modal;
