import axios from "axios";
import qs from "qs";
import { Api_Key } from "../../App";
var CryptoJS = require("crypto-js");

//-------------------------------- Encrypt function ------------------------------------------>
const Encrypt_function = (payload, secret_key) => {
    let Encryption_payload = CryptoJS.AES.encrypt(
        JSON.stringify(payload),
        secret_key
    );
    let Encrypted_json = {
        iv: Encryption_payload.iv.toString(),
        s: Encryption_payload.salt.toString(),
        ct: Encryption_payload.ciphertext.toString(CryptoJS.enc.Base64),
    };

    let Encrypted_json_string = {
        encrypt_payload_string: JSON.stringify(Encrypted_json),
    };
    return Encrypted_json_string;
};

/*-------------------------------- Decrypt function ------------------------------------------*/
const Decrypt_function = (response, secret_key) => {
    let decrypt_data = {};
    var salt = response?.data?.salt;
    var iv = response?.data?.iv;
    var iterations = response?.data?.iterations;
    var key = CryptoJS.PBKDF2(secret_key, salt, {
        hasher: CryptoJS.algo.SHA256,
        keySize: 64 / 8,
        iterations: iterations,
    });

    var decrypted = CryptoJS.AES.decrypt(response?.data?.data, key, {
        iv: CryptoJS.enc.Utf8.parse(iv),
    });
    if (decrypted.toString(CryptoJS.enc.Utf8)) {
        decrypt_data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
        return decrypt_data;
    } else {
        return null;
    }
};
/*-------------------------------- Decrypt function ------------------------------------------*/

const getConfig = (url, method, contentType, data, sendHeader = true) => {
    if (sendHeader)
        return {
            url: url,
            method: method,
            headers: {
                "Content-Type": contentType,
            },
            data: data,
        };
    else {
        return {
            url: url,
            method: method,
            data: data,
        };
    }
};
const config = {
    headers: {
        "Content-Type": "multipart/form-data",
    },
};

//--------------------------dev-app-2------------------------------------------
// const devBaseUrl = "https://dev-app-2.hexafair.com/platform/resources/api/v2/";
// const baseUrl = "https://dev-app-2.hexafair.com/platform/resources/api/v2";
// const QRCodeUrl = "https://hf-hybrid-dev.hexafair.com/demosite/#/";
//const QRCodeUrl = "http://localhost:3000/#/";
//--------------------------dev-app-2------------------------------------------

//--------------------------Live-----------------------------------------
const devBaseUrl = "https://app.hexafair.com/platform/resources/api/v2";
const baseUrl = "https://app.hexafair.com/platform/resources/api/v2";
const QRCodeUrl = "https://events.hexafair.com/onlive/#/";
//--------------------------Live-----------------------------------------

//Tracking APIs
export const product_buynowurl_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/product_buynowurl_viewed.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const document_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/add_log_documents_downloaded.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const video_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/add_log_videos_viewed.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const job_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/add_log_jobs_viewed_in_json.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const course_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/add_log_courses_viewed_in_json.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const product_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/product_viewed_in_json.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const booth_visitor_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/add_log_visitor_time_areawise.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const businesscard_log = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/add_log_attendee_who_viewed_exhibitor_businesscard.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const login = async (payload) => {
    // var axios = require("axios");
    // let data = JSON.stringify(payload);
    // alert("11122")
    const response = await axios(
        getConfig(
            baseUrl + "/login.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    );
    // .then((response) => {
    //     //console.log("loginResponse", response);
    //     return response?.data;
    // })
    // .catch((error) => {
    //     //console.log(error);
    //     return error;
    // });

    return response?.data;
};

export const register = async (payload) => {
    let data = JSON.stringify({
        payload,
    });
    const response = await axios(
        getConfig(
            baseUrl + "/attendee_registration.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    );
    // .then((response) => {
    //     //console.log("loginResponse", response);
    //     return response?.data;
    // })
    // .catch((error) => {
    //     //console.log(error);
    //     return error;
    // });

    return response?.data;
};

export const getExhibitorInfo = async (payload) => {
    const res = await axios.post(
        "https://dev-app-1.hexafair.com/platform/resources/api/HMB/booth-exhibitor-profile-information-in-json.php",
        {
            ...payload,
        },
        { ...config }
    );
    return res?.data;
};

export const getBoothData = async (payload) => {
    const res = await axios.post(
        baseUrl + "/booth_data_new.php",
        {
            ...payload,
        },
        { ...config }
    );
    return res?.data;
};

export const sendEmail = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const res = await axios
        .post(
            baseUrl + "/booth-send-email-to-exhibitor.php",
            {
                ...encrypt_payload,
            },
            { ...config }
        )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return res?.data;
};

// export const sendProductEnquiryEmail = async (payload) => {
//     let response = await axios(
//         getConfig(
//             baseUrl + "/product_enquiry_send-email-to-exhibitor.php",
//             "post",
//             "application/json",
//             JSON.stringify(payload)
//         )
//     ).then((response) => {
//             console.log("enquiryEmailData", response?.data);
//             return response?.data;
//         })
//         .catch((error) => {
//             console.log(error);
//         });
//     return response;
// };

export const sendProductEnquiryEmail = async (payload) => {
    /*----------------Encrypt-------------*/

    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);

    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/product_enquiry_send-email-to-exhibitor.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const getJobs = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
    let config = {
        method: "post",
        url: baseUrl + "/job_list.php",
        data: encrypt_payload,
    };

    const response = await axios(config)
        .then(function (response) {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            console.log(decrypt_data)
            /*--------------Decrypt-------------*/

            return decrypt_data;
        })
        .catch(function (error) {
            //console.log("error", error);
        });
    return response;
};

export const getProducts = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/products.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    );
    if (response?.data?.hasOwnProperty("salt")) {
        /*--------------Decrypt-------------*/
        let decrypt_response = Decrypt_function(response, unique_key);
        console.log("products", decrypt_response);

        return decrypt_response?.data;
        /*--------------Decrypt-------------*/
    } else {
        return response?.data;
    }
};

export const getPlaceHolderMesh = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/PlaceHolders.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            // console.log("response", response?.data);
            // return response?.data;
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);

            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
            return error;
        });

    return response;
};

export const productViewed = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            "https://dev-app-1.hexafair.com/platform/resources/api/productin_json.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);

            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response?.data;
};

export const applyCourse = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/reqadmission.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);

            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const applyJob = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/applyjob.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);

            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const getCountryCodes = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    //  let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/get_phone_number_country_code.php",
            "post",
            "application.json"
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            console.log("countryCodes error", error);
        });
    return response;
};

export const getSessionsList = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
console.log(payload)
    const response = await axios(
        getConfig(
            baseUrl + "/session_list_booth.php",
            "post",
            "application.json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            console.log(decrypt_data, "session_list_booth");

            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            console.log("sessionsList error", error);
        });
    return response;
};

export const getSessionsListAuditorium = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/session_list_auditorium.php",
            "post",
            "application.json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            console.log(decrypt_data);
            return decrypt_data;
        })
        .catch((error) => {
            console.log("sessionsList error", error);
        });
    return response;
};

//------------------------------------------------------------------------------
export const contactUs = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/booth-send-email-to-exhibitor.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/

            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const productBuyNowUrlViewed = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload?.eventId, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            "https://dev-app-1.hexafair.com/platform/resources/api/product_buynowurlin_json.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response?.data;
};

// HEXAFAIRCOURSESLIST

export const getCourses = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);

    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/course_list.php",
            "post",
            "application/json",
            encrypt_payload
        )
    )
        .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);

            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            //console.log(error);
            return error;
        });

    return response;
};

export const getBoothDetails = async (payload) => {
    let response = await axios(
        getConfig(
            baseUrl + "/booth_data_new.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            //console.log("boothDetails", response?.data);
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    // response = temp;
    return response;
};

export const sendResourcesEmail = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);

    /*----------------Encrypt-------------*/
    //baseUrl +  /enquiry_on_email_new.php
    let response = await axios(
        getConfig(
            baseUrl + "/resources_email_request_by_visitor.php",
            "post",
            "",
            JSON.stringify(encrypt_payload),
            false
        )
    )
        .then((response) => {
            // console.log("resourcesEmailData", response?.data);
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);

            /*--------------Decrypt-------------*/
            return decrypt_data;
        })
        .catch((error) => {
            console.log(error);
        });
    // response = temp;
    return response;
};

export const logout = async (payload) => {
    await fetch(baseUrl + `/logout.php?`, {
        method: "post",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payload),
    })
        .then((res) => {
            return res.status;
        })
        .catch((err) => {
            console.log(err);
        });
};

/*--------------------------My Favourite section API----------------------------*/
// export const myFavouriteAdd = async (payload) => {
//     const response = await axios(
//         getConfig(
//             baseUrl + "/add_to_favourite.php",
//             "post",
//             "application/json",
//             JSON.stringify(payload)
//         )
//     )
//         .then((response) => {
//             return response?.data;
//         })
//         .catch((error) => {
//             //console.log(error);
//         });
//     return response;
// };

// export const myFavouriteRemove = async (payload) => {
//     const response = await axios(
//         getConfig(
//             baseUrl + "/remove_from_favourite.php",
//             "post",
//             "application/json",
//             JSON.stringify(payload)
//         )
//     )
//         .then((response) => {
//             return response?.data;
//         })
//         .catch((error) => {
//             //console.log(error);
//         });
//     return response;
// };

// export const myFavouriteStatus = async (payload) => {
//     const response = await axios(
//         getConfig(
//             baseUrl + "/get-bookmark-data-by-id.php",
//             "post",
//             "application/json",
//             JSON.stringify(payload)
//         )
//     )
//         .then((response) => {
//             return response?.data;
//         })
//         .catch((error) => {
//             //console.log(error);
//         });
//     return response;
// };
/*--------------------------My Favourite section API----------------------------*/

//---------------------------Qr code API -------------------------

export const getJobDetails = async (payload) => {
    // let data = qs.stringify({
    //     exhibitor_id: payload["exhibitor_id"],
    //     event_id: payload["event_id"],
    //     booth_id: payload["booth_id"],
    //     job_id: payload["job_id"],
    // });

    //  /*----------------Encrypt-------------*/
    //  let unique_key = Api_Key;
    //  let encrypt_payload = Encrypt_function(payload, unique_key);
    //  /*----------------Encrypt-------------*/
    let config = {
        method: "post",
        url: baseUrl + "/job_detail.php",
        data: payload,
    };

    const response = await axios(config)
        .then(function (response) {
            /*--------------Decrypt-------------*/
            // let decrypt_data = Decrypt_function(response, unique_key);

            /*--------------Decrypt-------------*/

            // return decrypt_data;
            return response?.data;
        })
        .catch(function (error) {
            //console.log("error", error);
        });
    return response?.data;
};

export const getProductsDetails = async (payload) => {
    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
    let encrypt_payload = Encrypt_function(payload, unique_key);
    /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/product_detail.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            // console.log("responseProductDetails", response);
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            return decrypt_data;
            /*--------------Decrypt-------------*/
            //    return response?.data;
        })
        .catch((error) => {
            //console.log(error);
            return error;
        });

    return response?.data;
};

export const getSessionsDetailsForAuditorium = async (payload) => {
    /*----------------Encrypt-------------*/
    // let unique_key = Api_Key;
    // let encrypt_payload = Encrypt_function(
    //     payload,
    //    unique_key
    // );
    /*----------------Encrypt-------------*/

    //console.log("fetching sessions list", payload);
    const response = await axios(
        getConfig(
            baseUrl + "/session_detail_auditorium.php",
            "post",
            "application.json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            // /*--------------Decrypt-------------*/
            // let decrypt_data = Decrypt_function(
            //     response,
            //    unique_key
            // );
            // /*--------------Decrypt-------------*/
            // return decrypt_data;

            return response?.data;
        })
        .catch((error) => {
            console.log("sessionsList error", error);
        });
    return response;
};

export const getSessionsDetailsForBooth = async (payload) => {
    //console.log("fetching sessions list", payload);

    // /*----------------Encrypt-------------*/
    // let unique_key = Api_Key;
    // let encrypt_payload = Encrypt_function(
    //     payload?.eventId,
    //     unique_key
    // );
    // /*----------------Encrypt-------------*/

    const response = await axios(
        getConfig(
            baseUrl + "/session_detail_booth.php",
            "post",
            "application.json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            // /*--------------Decrypt-------------*/
            // let decrypt_data = Decrypt_function(
            //     response,
            //     unique_key
            // );
            // /*--------------Decrypt-------------*/
            // return decrypt_data;
            return response?.data;
        })
        .catch((error) => {
            console.log("sessionsList error", error);
        });
    return response?.data;
};

export const getCourseDetails = async (payload) => {
    // const data = new FormData();
    // for (let key in payload) data.append(key, payload[key]);
    //  /*----------------Encrypt-------------*/
    //  let unique_key = Api_Key;
    //  let encrypt_payload = Encrypt_function(payload, unique_key);

    //  /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/course_detail.php",
            "post",
            "application/json",
            payload
        )
    )
        .then((response) => {
            //console.log("responseCourses", response);
            /*--------------Decrypt-------------*/
            //  let decrypt_data = Decrypt_function(
            //     response,
            //     unique_key
            // );

            /*--------------Decrypt-------------*/
            // return decrypt_data;

            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
            return error;
        });

    return response?.data;
};

/*--------------------------My Favourite section API----------------------------*/
export const myFavouriteAdd = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/add_to_favourite.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const myFavouriteRemove = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/remove_from_favourite.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const myFavouriteStatus = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/get-bookmark-data-by-id.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const swagBagCourse = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/add_log_courses_viewed_in_json.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const swagBagDocuments = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/add_log_documents_downloaded.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const swagBagJobs = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/add_log_jobs_viewed_in_json.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const swagBagBusinessCard = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/add_log_attendee_who_viewed_exhibitor_businesscard.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

export const swagBagProducts = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/product_viewed_in_json.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};

// export const video_log = async (payload) => {

//     const response = await axios(
//         getConfig(
//             baseUrl + "/add_log_videos_viewed.php",
//             "post",
//             "application/json",
//             JSON.stringify(payload)
//         )
//     )
//         .then((response) => {
//           return response?.data;
//         })
//         .catch((error) => {
//             //console.log(error);
//         });
//     return response;

// };
/*--------------------------My Favourite section API----------------------------*/

/*--------------------------Swag Bag API----------------------------*/

export const SwagBag = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/add_to_swag_bags.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};
/*--------------------------Swag Bag API----------------------------*/

/*--------------------------Swag Bag List API----------------------------*/

export const SwagList = async (payload) => {
    const response = await axios(
        getConfig(
            baseUrl + "/swag_bag_lists.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};
/*--------------------------Swag Bag List API----------------------------*/
