import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import VideocamIcon from "@material-ui/icons/Videocam";
import { useStoreState, useStoreActions } from "easy-peasy";
import "emoji-mart/css/emoji-mart.css";
import React from "react";
import "./simple-chat.scss";
import Avatar from "react-avatar";
import Button from "../../common/Button";
import ChatIcon from "@material-ui/icons/Chat";
import { videocall as videocallAPI } from "../../../boothLive/src/utils/pubnub";

const ChatRightContainer = ({
	chatType,
	currentChannel,
	setChannel,
	currentuser,
}) => {
	const { boothRoomDetails } = useStoreState((state) => state.boothLiveState);
	const { userDetails, eventConfig } = useStoreState((state) => state.state);
	const { updateState, sendMessage, videoCallAction } = useStoreActions(
		(state) => state.pubNub
	);

	const showChatFn = (displayName, userId, emailId) => {
		let chatUserObj = {
			displayName: displayName,
			user_id: userId,
			email_id: emailId,
			type : 'NORMAL'
		};
		updateState({ chatType: "NORMAL" });
		updateState({ currentChatUserDetails: { ...chatUserObj } });
		setChannel(chatUserObj)
	};

	const handleVideoCall = (displayName, userId, emailId) => {
		let toUser = { id: userId, displayname: displayName };
		let fromUser = { id: userDetails?.id, displayname: userDetails?.displayname };

		videocallAPI(toUser, fromUser, videoCallAction, sendMessage);
		
	};

	return (
		<>
			{chatType == "GROUP" ? (
				<div className="right-end-wrapper">
					<div className="right-end-top-wrapper">
						<div className="right-end-top-title">
							<span>Hosts ({boothRoomDetails?.host_details?.length})</span>
						</div>
                         <div className="right-end-top-content-overall">
						{boothRoomDetails?.host_details?.map(
							(
								{ user_id, profile_image, video_call_url, full_name, designation },
								index
							) => {
								return (
									<div
										className="right-end-top-content"
										key={user_id}
										//   onClick={() => choose_staff(data)}
									>
										<div className="right-end-top-common">
											<div className="right-end-top-common-image-container">
												{profile_image != "" ? (
													<img src={profile_image} alt="full_name" className="right-end-profile-image" />
												) : (
													<Avatar name={full_name} round={true}  className="right-end-profile-image"/>
												)}
											</div>
											<div className="right-end-top-common-info">
												<div className="right-end-top-common-info-name">
													<span>{full_name}</span>
												</div>
												<div className="right-end-top-common-info-role">
													<span>{designation}</span>
												</div>
												<div className="right-end-top-common-info-actions">
													<div className="actions-common-holder">
														<div className="actions-common-button-outer"
															
														>
															<div className="actions-common-button-inner" onClick={(e) => {
																handleVideoCall(full_name, user_id, "");
															}}
															isSquare={true}
															OpenIcon={VideocamIcon}>
                                                               <VideocamIcon className="chat-video-icon" />
															</div>
														</div>
													</div>
													<div className="actions-common-holder">
                                                    <div className="actions-common-button-outer"
													    
													>
														<div className="actions-common-button-inner" onClick={(e) => {
															showChatFn(full_name, user_id, "");
														}}
														isSquare={true}>
														<ChatIcon className="chat-video-icon" />

														</div>
													</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								);
							}
						)}
						</div>
					</div>
				</div>
			) : (
				<div className="right-end-wrapper">
					<div className="right-end-top-wrapper">
						<div className="right-end-top-content">
							<div className="right-end-top-common">
								<div className="right-end-top-common-image-container">
									{currentuser?.profileUrl != null ? (
										<img
											src={currentuser?.profileUrl}
											alt="full_name"
											// className="img-profile"
											className="right-end-profile-image"
										/>
									) : (
										<Avatar name={currentuser?.name} round={true} className="right-end-profile-image"/>
									)}
								</div>
								<div className="right-end-top-common-info-name">
									<span>{currentuser?.name}</span>
								</div>
								<div className="right-end-top-common-info-actions">
									<div className="actions-common-holder">
										<div className="actions-common-button-outer"
											onClick={(e) => {
												handleVideoCall(
													currentuser?.name,
													currentuser?.id,
													currentuser?.email
												);
											}}
											isSquare={true}
										
										>
											<div className="actions-common-button-inner">
												<VideocamIcon  className="chat-video-icon" />
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default ChatRightContainer;