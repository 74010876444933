        import { useStoreState, useStoreActions } from "easy-peasy";
        import isEmpty from "lodash";
        import SearchPopup from '../Filter/SearchBar';
        import React, { useEffect, useState, useMount } from "react";
        import { useHistory } from "react-router-dom";
        import conference_ico from "../../assets/conference-ico.png";
        import exhibitors_ico from "../../assets/exhibitors-ico.png";
        import helpdesk_ico from "../../assets/helpdesk-ico.png";
        import lobby_ico from "../../assets/lobby-ico.png";
        import social_ico from "../../assets/social-normal.png";
        import chat_ico from "../../assets/chat-ico.png";
        import network_ico from "../../assets/network lounge-ico.png";
        import leaderboard_ico from "../../assets/leaderboard.png";
        import profile_ico from "../../assets/profile.png";
        import mybox from "../../assets/mybox.png";
        import { getBoothsList as getBoothsListAPI } from "../../store/restApi/api";
        // import Modal from "../Modal";
        import "./floatingMenu.css";
        /*---------------------Leaderboard imports----------------------*/
        import Modal1 from "../modal/Modal";
        import leaderBoard from "../../components/leaderboard/LeaderBoard";
        import "../../components/leaderboard/leaderBoard.css";
        import EventChat from "../../components/pages/chat/Chat";
        import "../../components/pages/chat/simple-chat.scss";
        import leaderboard from "../../assets/leaderBoard -1.png";
        import ReactHtmlParser from "react-html-parser";
        import { baseUrl } from "../../store/restApi/api";
        import Logout from "../logout/Logout";
        import { booth_visitor_log as boothVisitorLogApi } from "../../store/restApi/boothLiveApi";
        import { leaderBoard as leaderBoardApi } from "../../store/restApi/api";
        import Menu from '../../assets/Hamburger_icon.svg.png'
        import Modal from 'react-bootstrap/Modal'
        import search from '../../assets/search.png'
        /*---------------------Leaderboard imports----------------------*/

        import {Api_Key} from '../../App';
        import useWindowSize from "../pages/usersList/useWindowSize";
        import zIndex from "@material-ui/core/styles/zIndex";

        var CryptoJS = require("crypto-js");

        /*------------Chat functionality - display on popup---------------------*/
        const Chat = () =>{
            return (      
                <EventChat/>
            );
        };
        /*------------Chat functionality - display on popup---------------------*/

        // import TagIcon from '@material-ui/icons/Tag';
        const LeadeBoardUI = ({ data }) => {
        // console.log(data);
            return (
                <div className="leaderBoard-container">
                    <div className="leaderBoard-title">
                        <span>Leader Board</span>
                    </div>
                    <div className="leaderboard-body">
                        <div className="leaderboard-left">
                            {typeof data != "undefined" && data != ""
                                ? data?.map(
                                    (
                                        {
                                            attendee_first_name,
                                            attendee_last_name,
                                            total_activity_score,
                                            attendee_id,
                                        },
                                        i
                                    ) => (
                                        <div
                                            className="leaderboard-rankings-list"
                                            key={attendee_id}
                                        >
                                            <div className="leaderboard-rankings">
                                                <span>{i + 1}</span>
                                            </div>
                                            <div className="leaderboard-rankings-name">
                                                <div>
                                                    <span>{`${attendee_first_name} ${attendee_last_name}`}</span>
                                                </div>
                                                <div>
                                                    <span className="leaderboard-points">
                                                        {total_activity_score} points
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )
                                : null}
                        </div>
                        <div className="leaderboard-right">
                            <div>
                                {" "}
                                {ReactHtmlParser(
                                    `${
                                        data.length > 0
                                            ? data[0].leaderboard_instructions
                                            : ""
                                    }`
                                )}{" "}
                            </div>
                        </div>
                    </div>
                </div>
            );
        };

        const FloatingMenuBar = ({ classes, videoCallClose }) => {
            const pubAction = useStoreActions((state) => state.pubNub);
            const [showModal, setShowModal] = useState(false);  
            const [exhibitorsList, setExhibitorsList] = useState([]);
            const [ show, setShow ] = useState( false );
            const history = useHistory();
            const { eventConfig, userDetails  } = useStoreState((state) => state.state);
            const { chatModal } = useStoreState((state) => state.pubNub);
            let conference_enabled = eventConfig?.data?.[0]?.live_streaming_is_enabled;
            let network_enabled = eventConfig?.data?.[0]?.connect_feature_is_enabled;
            let socialwall_enabled = eventConfig?.data?.[0]?.social_wall_streaming_url;
            let leaderboard_enabled = eventConfig?.data?.[0]?.leader_board_setting_enabled;
            let { auditoriumVisitStatus } = useStoreState((state) => state.state);
            const { updateState } = useStoreActions((state) => state.state);

            const [openLeaderBoard, setOpenLeaderBoard] = useState(false);
            const [leaderBoardData, setLeaderBoardData] = useState("");
            const [showPopup, setShowPopup] = useState(false);
            const openLeaderBoardPopup = () => {

                async function fetchLeaderBoard() {
                let payload = {event_id:eventConfig?.data?.[0]?.event_id};
                
                        leaderBoardApi(payload)               
                        .then(
                            (res) => {              
                            setLeaderBoardData(() => res.data);
                            setOpenLeaderBoard(true);
                            })
                        .catch((err) => {
                        // console.log(err);
                        });
                }
                fetchLeaderBoard();
            };


        

            const showUserList = () => {
                if(typeof auditoriumVisitStatus!="undefined")
                {
                    if(auditoriumVisitStatus)
                    {
                        //visitor update
                        const payload_update = {
                            visitor_user_id_type: userDetails?.user_type,
                            visitor_user_id: userDetails?.id,
                            event_id: eventConfig?.data[0]?.event_id,
                            booth_id: '', 
                            location: "5",  
                            action: "2",            
                        };

                        boothVisitorLogApi(payload_update).then((response) => {
                            updateState({ auditoriumVisitStatus: false });
                            history.push(
                                `/userlist/${userDetails?.id}/${eventConfig?.data?.[0]?.event_id}`
                            );               
                        });
                    }
                    else
                    {
                        history.push(
                            `/userlist/${userDetails?.id}/${eventConfig?.data?.[0]?.event_id}`
                        );
                    }
                }
                else
                {
                    history.push(
                        `/userlist/${userDetails?.id}/${eventConfig?.data?.[0]?.event_id}`
                    );
                } 
                
            };
            const socialWall = () => {
                if(typeof auditoriumVisitStatus!="undefined")
                {
                    if(auditoriumVisitStatus)
                    {
                        //visitor update
                        const payload_update = {
                            visitor_user_id_type: userDetails?.user_type,
                            visitor_user_id: userDetails?.id,
                            event_id: eventConfig?.data[0]?.event_id,
                            booth_id: '', 
                            location: "5",  
                            action: "2",            
                        };

                        boothVisitorLogApi(payload_update).then((response) => {
                            updateState({ auditoriumVisitStatus: false });
                            history.push("/socialwall");               
                        });
                    }
                    else
                    {
                        history.push("/socialwall");
                    }
                }
                else
                {
                    history.push("/socialwall");
                } 
            
            };
            

            const showMyBox = () => {
                if(typeof auditoriumVisitStatus!="undefined")
                {
                    if(auditoriumVisitStatus)
                    {
                        //visitor update
                        const payload_update = {
                            visitor_user_id_type: userDetails?.user_type,
                            visitor_user_id: userDetails?.id,
                            event_id: eventConfig?.data[0]?.event_id,
                            booth_id: '', 
                            location: "5",  
                            action: "2",            
                        };

                        boothVisitorLogApi(payload_update).then((response) => {
                            updateState({ auditoriumVisitStatus: false }); 
                            history.push(`/mybox`);              
                        });
                    }
                    else
                    {
                        history.push(`/mybox`);
                    }
                }
                else
                {
                    history.push(`/mybox`);
                } 
                
            };

            const showMyChat = () => {
                if(typeof auditoriumVisitStatus!="undefined")
                {
                    if(auditoriumVisitStatus)
                    {
                        //visitor update
                        const payload_update = {
                            visitor_user_id_type: userDetails?.user_type,
                            visitor_user_id: userDetails?.id,
                            event_id: eventConfig?.data[0]?.event_id,
                            booth_id: '', 
                            location: "5",  
                            action: "2",            
                        };

                        boothVisitorLogApi(payload_update).then((response) => {
                            updateState({ auditoriumVisitStatus: false });
                            history.push(`/chat`);               
                        });
                    }
                    else
                    {
                        history.push(`/chat`);
                    }
                }
                else
                {
                    history.push(`/chat`);
                } 
            
            };

            const showAuditorium = () => {     
                history.push("/auditorium");     
            };


            const showLobby = () => {
                
                if(typeof auditoriumVisitStatus!="undefined")
                {
                    if(auditoriumVisitStatus)
                    {
                        //visitor update
                        const payload_update = {
                            visitor_user_id_type: userDetails?.user_type,
                            visitor_user_id: userDetails?.id,
                            event_id: eventConfig?.data[0]?.event_id,
                            booth_id: '', 
                            location: "5",  
                            action: "2",            
                        };

                        boothVisitorLogApi(payload_update).then((response) => {
                            updateState({ auditoriumVisitStatus: false });
                            history.push("/");               
                        });                
                    }
                    else
                    {
                        history.push("/");
                    }
                }
                else
                {
                    history.push("/");
                }
            };

            const setShowHelpDesk = () => {
                if(typeof auditoriumVisitStatus!="undefined")
                {
                    if(auditoriumVisitStatus)
                    {
                        //visitor update
                        const payload_update = {
                            visitor_user_id_type: userDetails?.user_type,
                            visitor_user_id: userDetails?.id,
                            event_id: eventConfig?.data[0]?.event_id,
                            booth_id: '', 
                            location: "5",  
                            action: "2",            
                        };

                        boothVisitorLogApi(payload_update).then((response) => {
                            updateState({ auditoriumVisitStatus: false });
                            history.push("/?helpdesk=1");               
                        });
                    }
                    else
                    {
                        history.push("/?helpdesk=1");
                    }
                }
                else
                {
                    history.push("/?helpdesk=1");
                }             
            };
            const showExhibitorList = () => {
                if(typeof auditoriumVisitStatus!="undefined")
                {
                    if(auditoriumVisitStatus)
                    {
                        //visitor update
                        const payload_update = {
                            visitor_user_id_type: userDetails?.user_type,
                            visitor_user_id: userDetails?.id,
                            event_id: eventConfig?.data[0]?.event_id,
                            booth_id: '', 
                            location: "5",  
                            action: "2",            
                        };

                        boothVisitorLogApi(payload_update).then((response) => {
                            updateState({ auditoriumVisitStatus: false });
                            history.push("/exhibitorlist");               
                        });
                    }
                    else
                    {
                        history.push("/exhibitorlist");
                    }
                }
                else
                {
                    history.push("/exhibitorlist");
                } 
            };

            useEffect(() => {
                if (isEmpty(exhibitorsList)) {
                    getBoothsListAPI({
                        eventId: eventConfig?.data?.[0]?.event_id,
                    }).then((res) => {
                        //console.log(res);
                        setExhibitorsList(res);
                    });
                }
            }, []);
            const handleClose = () => {
                
                setShow( false );
            }

            const handleShow = () => {

                setShow( true );
            }

            const width = useWindowSize()
            return (
                <>
                {width.width <= 1000 ? (

                    //------------------Mobile display-----------------------//
    <>
                    {showPopup && (
                    <SearchPopup
                    show={showPopup}
                    onClose={() => setShowPopup(false)}
                    />
                )}
                    <Logout />

        <Modal show={show} onHide={handleClose} animation={false}  className="modalcontainer" >
            
        <Modal.displayName style={{
            background: 'blue',
            display: 'flex'
            
        }}   >
        <img
            alt="lobby_ico"
            src={lobby_ico}
            // width="70"
            // height="50"
            id="lobby"
            style={{ margin: "3px" }}
            onClick={() => {
                if (videoCallClose) {
                    videoCallClose();
                }
                showLobby();
            }}
            className={classes?.img}
        />
        {conference_enabled == "Yes" ? (
        <div
        //  style={{ margin: "0px 5px", padding: "1px" }}
        className="mob-div"
        >
            <img
                alt="conference_ico"
                src={conference_ico}
                width="70"
                style={{ margin: "3px" }}
                height="50"
                id="auditorium"
                onClick={() => {
                    if (videoCallClose) {
                        videoCallClose();
                    }
                    showAuditorium();
                }}
                className={classes?.img}
            />
        </div>
        ) : null}
        {network_enabled == "Yes" ? (
        <div 
        // style={{ margin: "0px 5px", padding: "1px" }}
        className="mob-div"
        >
            <img
                alt="network_ico"
                src={network_ico}
                width="70"
                style={{ margin: "3px" }}
                height="50"
                onClick={() => {
                    if (videoCallClose) {
                        videoCallClose();
                    }
                    showUserList();
                }}
                className={classes?.img}
            />
        </div>
        ) : null}
        <div 
        // style={{ margin: "0px 5px", padding: "1px" }}
         className="mob-div"
        >
        <img
            alt="exhibitors_ico"
            src={exhibitors_ico}
            width="70"
            height="50"
            id="exhibitors"
            style={{ margin: "3px" }}
            onClick={() => {
                if (videoCallClose) {
                    videoCallClose();
                }
                showExhibitorList();
            }}
            className={classes?.img}
        />
        </div>
        <div 
        // style={{ margin: "0px 10px", padding: "1px" }}
        className="mob-div"
        >
                        <img
                            alt="search"
                            src={search}
                            width="70"
                            height="50"
                            id="exhibitors"
                            style={{ margin: "3px" }}
                            className={classes?.img}
                            onClick={() => setShowPopup(true)}
                        />
                        </div>
        { socialwall_enabled !== "" ? (
        <div 
        className="mob-div"
        // style={{ margin: "0px 5px", padding: "1px" }}
        >
        <img
            alt="social-ico"
            src={social_ico}
            width="70"
            style={{ margin: "3px" }}
            height="50"
            onClick={() => {
                if (videoCallClose) {
                    videoCallClose();
                }
                socialWall();
            }}
            className={classes?.img}
        />
        </div>) : null}
        <div 
         className="mob-div"
        // style={{ margin: "0px 5px", padding: "1px" }}
        >
        <img
            alt="chat_ico"
            src={chat_ico}
            width="70"
            height="50"
            id="chat"
            style={{ margin: "3px" }}
            onClick={() => {
                if (videoCallClose) {
                    videoCallClose();
                }
                // pubAction.updateState({ chatType: 'NORMAL' });
                // setShowChatModal(true);
                pubAction.updateState({ chatType: 'NORMAL'  , chatModal: true });
            }}
            className={classes?.img}
        />
        </div>
        <div 
        // style={{ margin: "0px 5px", padding: "1px" }}
        className="mob-div"
        >
        <img
            alt="mybox"
            src={mybox}
            width="70"
            height="50"
            id="exhibitors"
            style={{ margin: "3px" }}
            onClick={() => {
                if (videoCallClose) {
                    videoCallClose();
                }
                showMyBox();
            }}
            className={classes?.img}
        />
        </div>

        {leaderboard_enabled == "Yes" ? (
        <div 
        // style={{ margin: "0px 5px", padding: "1px" }}
        className="mob-div"
        >
        <img
            alt="leaderboard_ico"
            src={leaderboard_ico}
            width="70"
            height="50"
            id="leaderboard"
            style={{ margin: "3px" }}
            onClick={() => {
                if (videoCallClose) {
                    videoCallClose();
                }
                openLeaderBoardPopup();
            }}
            className={classes?.img}
        />
        </div>) : null}

        </Modal.displayName>
        </Modal>

        <div style={{
            position:'absolute',
            top:'5px',
            backgroundColor:'#0739ff',
            zIndex:1
        }}>
        {/* <Button variant="primary" onClick={handleShow}>

        </Button> */}
        <img src={Menu} alt="Menu" style={{height:'25px'}} onClick={handleShow}/>
        </div>
    </>
    //------------------Mobile display-----------------------//
                ): (
    //-----------------------Desktop Display--------------------------//
                <>
                
                {showPopup && (
                    <SearchPopup
                    show={showPopup}
                    onClose={() => setShowPopup(false)}
                    />
                )}
                <div className= {`floating-menu-container ${classes?.mt}`}>
                        <div style={{ margin: "0px 10px", padding: "1px" }}>
                            <img
                                alt="lobby_ico"
                                src={lobby_ico}
                                width="70"
                                height="50"
                                id="lobby"
                                style={{ margin: "3px" }}
                                onClick={() => {
                                    if (videoCallClose) {
                                        videoCallClose();
                                    }
                                    showLobby();
                                }}
                                className={classes?.img}
                            />
                        </div>
                        {conference_enabled == "Yes" ? (
                            <div style={{ margin: "0px 10px", padding: "1px" }}>
                                <img
                                    alt="conference_ico"
                                    src={conference_ico}
                                    width="70"
                                    style={{ margin: "3px" }}
                                    height="50"
                                    id="auditorium"
                                    onClick={() => {
                                        if (videoCallClose) {
                                            videoCallClose();
                                        }
                                        showAuditorium();
                                    }}
                                    className={classes?.img}
                                />
                            </div>
                        ) : null}
                        {network_enabled == "Yes" ? (
                            <div style={{ margin: "0px 10px", padding: "1px" }}>
                                <img
                                    alt="network_ico"
                                    src={network_ico}
                                    width="70"
                                    style={{ margin: "3px" }}
                                    height="50"
                                    onClick={() => {
                                        if (videoCallClose) {
                                            videoCallClose();
                                        }
                                        showUserList();
                                    }}
                                    id="users"
                                    className={classes?.img}
                                />
                            </div>
                        ) : null}
                        <div style={{ margin: "0px 10px", padding: "1px" }}>
                            <img
                                alt="exhibitors_ico"
                                src={exhibitors_ico}
                                width="70"
                                height="50"
                                id="exhibitors"
                                style={{ margin: "3px" }}
                                onClick={() => {
                                    if (videoCallClose) {
                                        videoCallClose();
                                    }
                                    showExhibitorList();
                                }}
                                className={classes?.img}
                            />
                        </div>
                        { socialwall_enabled !== "" ? (<div style={{ margin: "0px 10px", padding: "1px" }}>
                            <img
                                alt="social-ico"
                                src={social_ico}
                                width="70"
                                style={{ margin: "3px" }}
                                height="50"
                                onClick={() => {
                                    if (videoCallClose) {
                                        videoCallClose();
                                    }
                                    socialWall();
                                }}
                                className={classes?.img}
                            />
                        </div>) : null}
                        <div style={{ margin: "0px 10px", padding: "1px" }}>
                            <img
                                alt="chat_ico"
                                src={chat_ico}
                                width="70"
                                height="50"
                                id="chat"
                                style={{ margin: "3px" }}
                                onClick={() => {
                                    if (videoCallClose) {
                                        videoCallClose();
                                    }
                                    // pubAction.updateState({ chatType: 'NORMAL' });
                                    // setShowChatModal(true);
                                    pubAction.updateState({ chatType: 'NORMAL'  , chatModal: true });
                                }}
                                className={classes?.img}
                            />
                        </div>
                        <div style={{ margin: "0px 10px", padding: "1px" }}>
                        <img
                            alt="search"
                            src={search}
                           
                            id="exhibitors"
                            style={{ margin: "2px", width:"55px", height:'50px' }}
                            className={classes?.img}
                            onClick={() => setShowPopup(true)}
                        />
                        </div>
                        <div style={{ margin: "0px 10px", padding: "1px" }}>
                            <img
                                alt="mybox"
                                src={mybox}
                                width="70"
                                height="50"
                                id="exhibitors"
                                style={{ margin: "3px" }}
                                onClick={() => {
                                    if (videoCallClose) {
                                        videoCallClose();
                                    }
                                    showMyBox();
                                }}
                                className={classes?.img}
                            />
                        </div>
                        {/* <div style={{ margin: "0px 1px", padding: "1px" }}>
                            <img
                                alt="helpdesk_ico"
                                src={helpdesk_ico}
                                width="70"
                                height="50"
                                id="helpdesk"
                                style={{ margin: "3px" }}
                                onClick={() => {
                                    if (videoCallClose) {
                                        videoCallClose();
                                    }
                                    setShowHelpDesk();
                                }}
                                className={classes?.img}
                            />
                        </div> */}
                        {leaderboard_enabled == "Yes" ? (<div style={{ margin: "0px 10px", padding: "1px" }}>
                            <img
                                alt="leaderboard_ico"
                                src={leaderboard_ico}
                                width="70"
                                height="50"
                                id="leaderboard"
                                style={{ margin: "3px" }}
                                onClick={() => {
                                    if (videoCallClose) {
                                        videoCallClose();
                                    }
                                    openLeaderBoardPopup();
                                }}
                                className={classes?.img}
                            />
                        </div>) : null}
                        <div style={{ margin: "0px 1px", padding: "1px" }}>
                            <Logout />
                        </div>

                    </div>
    {/* //-----------------------Desktop Display--------------------------// */}
                    
        </>)}
                
                
                    <Modal1 
                        // open={showChatModal}
                        // onClose={() => setShowChatModal((value) => false)}        
                        open={chatModal}
                        onClose={() => pubAction.updateState({ chatModal: false })}   
                                    
                    
                        widith="70%"
                        height="80%"
                    >
                    <Chat/>  
                    </Modal1>

                    <Modal1
                        open={openLeaderBoard}
                        onClose={() => setOpenLeaderBoard((value) => false)}
                        widith="70%"
                        height="80%"
                    >
                        <LeadeBoardUI data={leaderBoardData} />
                    </Modal1>
                    
                </>
            );
        };

        export default FloatingMenuBar;
