import CloudDownloadRounded from "@material-ui/icons/CloudDownloadRounded";
import SendIcon from "@material-ui/icons/Send";
import {
    ChannelList,
    Chat,
    MessageInput,
    MessageList,
    TypingIndicator
} from "@pubnub/react-chat-components";
import { useStoreActions, useStoreState } from "easy-peasy";
import { Picker } from "emoji-mart";
import "emoji-mart/css/emoji-mart.css";
import { memo, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { pubNubHandler } from "../../../pubNub/utils/PubNubResponseHandler";
import { pubnubUtilty } from "../../../pubNub/utils/PubNubUtilty";
import { serverApi } from "../../../store/restApi/serverApi";
import ImageComponant from "../../imageComponant/ImageComponant";
import Modal from "../../modal/Modal";
import ChatRightContainer from "./ChatRightContainer";
import "./simple-chat.scss";


const EventChat  = memo(() => {
    

const [showChannels, setShowChannels] = useState(true);
const [showFile, setShowFile] = useState(false);
const [filePayload, setFilePayload] = useState({});
const {
	currentChatUserDetails,
	pubnubstate,
	chatUsers,
	directChannels,
	channelMembers,
    chatType,
    loggedInUser
} = useStoreState((state) => state.pubNub);
const {
	eventConfig
} = useStoreState((state) => state.state);
const {
	boothRoomDetails,
} = useStoreState((state) => state.boothLiveState);
const {
	updateState,
	sendMessage,
	videoCallAction
} = useStoreActions(
	(state) => state.pubNub
);
const {
	updateState : boothUpdateState
} = useStoreActions(
	(state) => state.boothLiveState
);
const [currentChannel, setCurrentChannel] = useState(directChannels[0]);
const allChannelIds = [...directChannels].map((c) => c?.id);
const mounted = useRef(false);

const currentUser = chatUsers?.find((u) => u.id === pubnubstate.getUUID());
const channelMem = chatUsers?.find((u) => u.id !== pubnubstate.getUUID());


const createSharedSpaceHandler = (status , response ) => {
  
    if (status && !status?.error) {
        let custom =  response?.data?.custom;
        pubnubUtilty.addUserToSpaceV2(pubnubstate,response?.data?.id,[custom?.user1_id,custom?.user2_id],addUserHandler)
    } else {
        alert("Error: A space with that name already exists");
    }
};

const membersFromSpaceHandler = (status, response) => {
    if (status && !status?.error ) {
        let ids = response?.data?.map((data) => data?.uuid?.id);
        if (ids && ids.length > 0) {
            fetchChatMembers(ids)
            updateState({ channelMembers: response });
        }
    }

}; 

const fetchChatMembers = (ids) => {
    
    if (ids && ids.length > 0) {
        let payload = {
            event_id: eventConfig?.data?.[0]?.event_id,
            user_id: ids.toString(),
        };
        serverApi
            .getChatMembersDetailsById(payload)
            .then(({ data }) => {
                updateState({ chatUsers: data });
                let classname = document.getElementsByClassName("pn-msg-input__send");
                
                window.removeEventListener("pn-msg-input__send", onSendHandler);
                classname[0].addEventListener("click", () => onSendHandler(data),	true);
            })
            .catch((err) => {
                console.log(err);
            });
    }
}

const addUserHandler = (status , response ) => {
    if (status && !status?.error) {
        updateState({channelCreated: currentChatUserDetails?.user_id});
         pubnubUtilty.getMemberships(pubnubstate,loggedInUser,(status , response) => {
            if (status && !status?.error) {
                let directChannl = pubNubHandler.userMemberShipChannelHandler(response?.data, loggedInUser?.id);
                updateState({directChannels:directChannl});
                let currentChatChannel = directChannl?.filter((data) => data.name === (currentChatUserDetails?.displayName).trim());
                if(currentChatChannel.length === 0){
                    currentChatChannel = [directChannl[0]]
                }
                setCurrentChannel(() => currentChatChannel[0]); 
                    pubnubUtilty.getMembersFromSpaceV2(
                        currentChatChannel[0]?.id,
                        pubnubstate,
                        membersFromSpaceHandler
                    );
            }
        })
                
        } else {
            alert("Error: Check that space-id and user-id is correct");
        }
};


const channelMemberShipHandler = (status , firstRes , currentChatUserDetails) => {
    if (status && !status?.error && firstRes !== null ) {
        
        let channels = pubNubHandler.userMemberShipChannelHandler(firstRes?.data,loggedInUser?.id);
        let channelName = currentChatUserDetails?.displayName;
        let channel = pubNubHandler.filterChannelByChannelName(channels,channelName);

        if ((!channel || channel.length === 0) && mounted.current) {
            console.log("effect 1")
            if(currentChatUserDetails?.type === 'GROUP'){
                pubnubUtilty.addUserToSpaceV2(pubnubstate,currentChatUserDetails?.user_id,[loggedInUser?.id],addUserHandler)
            }else {
                let spaceId = pubnubUtilty.generateSpaceId();
                let user1 ={'id' : loggedInUser?.id , 'name' : loggedInUser?.name } 
                let user2 ={'id' : currentChatUserDetails?.user_id , 'name' : currentChatUserDetails?.displayName } 
                pubnubUtilty.createSharedSpaceV2(pubnubstate,user1,user2,spaceId,createSharedSpaceHandler)
            }
        }else if(mounted.current){
            console.log("effect 1")
            updateState({directChannels:channels});
            let currentChatChannel = pubNubHandler.filterChannelByChannelName(channels,channelName);
            if(currentChatChannel.length === 0){
                currentChatChannel = [channels[0]]
            }
            setCurrentChannel(() => currentChatChannel[0]);
            pubnubUtilty.getMembersFromSpaceV2(currentChatChannel[0]?.id,pubnubstate,membersFromSpaceHandler);
        }
    }
};

const getUserMemberShipSpacesV2Handler  =  (status, response) => {
    if (status && !status?.error && mounted.current) {
        let directChannl = pubNubHandler.userMemberShipChannelHandler(response?.data,loggedInUser?.id);
        let currentChatChannel = directChannl.filter((data) => data.id === currentChatUserDetails?.user_id);
        if(currentChatChannel.length === 0){
            currentChatChannel = [directChannl[0]]
        }
        setCurrentChannel(() => currentChatChannel[0]);
        updateState({ directChannels: directChannl });
        pubnubUtilty.getMembersFromSpaceV2(
            currentChatChannel[0]?.id,
            pubnubstate,
            membersFromSpaceHandler
        );
    }
};   

const fetchHostDetailsByRoomId = (roomId) => {
    
    serverApi
        .getConversionById(
            roomId,
            loggedInUser?.id,
            eventConfig?.data?.[0]?.event_id
        )
        .then(({ data }) => {
            boothUpdateState({ boothRoomDetails: data });
        })
        .catch((err) => {
            console.log(err);
        });

}

useEffect(() => {
 
    mounted.current = true; 
    function setupChat() {
        if (!(Object.keys(currentChatUserDetails).length === 0 &&  Object.getPrototypeOf(currentChatUserDetails) === Object.prototype )) {
            pubnubUtilty.getMemberships(pubnubstate, loggedInUser, (status,res) => channelMemberShipHandler(status,res,currentChatUserDetails));
        } else {
            pubnubUtilty.getUserMemberShipSpacesV2( loggedInUser?.id,pubnubstate,getUserMemberShipSpacesV2Handler);
        }
    }
    setupChat();
    return () => {
        window.removeEventListener("pn-msg-input__send", onSendHandler);
        mounted.current = false;
    }; 
}, []);

function setupChatHost(currentChatUserDetails) {
    if (!(Object.keys(currentChatUserDetails).length === 0 &&  Object.getPrototypeOf(currentChatUserDetails) === Object.prototype )) {
        pubnubUtilty.getMemberships(pubnubstate, loggedInUser, (status,res) => channelMemberShipHandler(status,res,currentChatUserDetails));
    } else {
        pubnubUtilty.getUserMemberShipSpacesV2( loggedInUser?.id,pubnubstate,getUserMemberShipSpacesV2Handler);
    }
}

useEffect(() => {
    mounted.current = true; 
    function setupChat1() {
        if(currentChannel){
            updateState({ chatType: currentChannel?.chatType });

            if(currentChannel?.chatType === 'GROUP'){
                fetchHostDetailsByRoomId(currentChannel?.id)
            }
            pubnubUtilty.getMembersFromSpaceV2(
                currentChannel?.id,
                pubnubstate,
                membersFromSpaceHandler
            );
    }
       
    }
    setupChat1();
    return () => {
        window.removeEventListener("pn-msg-input__send", onSendHandler);
        mounted.current = false;
    }; 
}, [currentChannel]);


const onSendHandler = (data) => {
    if (data && data.length > 0) {
      let roomId, roomTitle, membersId , hostUserId , chatUserId;

      if (data.length > 2) {
        
        let { room_id, room_title, host_details } = boothRoomDetails;

        if( host_details != null && host_details.length > 0){
           hostUserId = host_details.map((d) => d.user_id.toString());
           chatUserId = data.map((d) => d.id.toString());

           membersId = hostUserId.filter((e) => chatUserId.includes(e));

           membersId = membersId.filter((member) => member !== currentUser?.id);
        }
        roomId = room_id;
        roomTitle = room_title;

      } else {
        roomTitle = loggedInUser?.name;

        let memeber = data.filter(
          (member) => member?.id !== currentUser?.id
        );

        membersId = [memeber[0]?.id];
      }

      for (let index = 0; index < data.length; index++) {
        const element = data[index];

        if (membersId && membersId.includes(element.id)) {
          let payLoad = {
            channel: element.id,
            message: roomTitle,
            meta: {
              ToUUID: element?.id,
              ToUserName: element?.name,
              FromUUID: loggedInUser?.id,
              fromUser: loggedInUser?.name,
              chatType: "MESSAGE_NOTIFICATION",
            },
          };
          videoCallAction({});
          videoCallAction(payLoad);
          sendMessage(payLoad);
        }
      }
    }
  };

  const extraActions = (e) => {
    if (e?.messageType === 4) {
        let fileName = e?.message?.file?.name;
        fileName = fileName.split(".").pop();
        if (
            fileName == "gif" ||
            fileName == "png" ||
            fileName == "bmp" ||
            fileName == "jpeg" ||
            fileName == "jpg"
        ) {
            return (
                <CloudDownloadRounded
                    onClick={() => {
                        download(e.message.file.url);
                    }}
                    style={{
                        width: "100%",
                    }}
                />
            );
        }
    }
};


const download = (e) => {
	fetch(e, {
			method: "GET",
			headers: {},
		})
		.then((response) => {
			response.arrayBuffer().then(function(buffer) {
				const url = window.URL.createObjectURL(new Blob([buffer]));
				const link = document.createElement("a");
				link.href = url;
				link.setAttribute("download", "image.png"); //or any other extension
				document.body.appendChild(link);
				link.click();
			});
		})
		.catch((err) => {
			console.log(err);
		});
};

return (
    <div
        className={`app-simple light`}
        onClick={(e) => e.stopPropagation()}
    >
        <Modal
            open={showFile}
            width="500px"
            height="500px"
            onClose={() => setShowFile(false)}
        >
            <ImageComponant payload={filePayload} />
        </Modal>
        <Chat
            users={chatUsers}
            currentChannel={currentChannel?.id}
            channels={allChannelIds}
            theme="light"
        >
            <div className={`channels ${showChannels && "shown"}`}>
                <h4 className="chat-modal-title">Chats</h4>

                <ChannelList
                    channels={directChannels}
                    onChannelSwitched={(channel) =>
                        setCurrentChannel(channel)
                    }
                />

                <div className="bottom-portion"></div>
            </div>
            <div className="chat">
                <div className="info-overall">
                    <div className="info">
                        <span
                            className="hamburger"
                            onClick={() => setShowChannels(true)}
                        >
                            {/* ☰ */}
                        </span>
                        <h4>{currentChannel?.name}</h4>
                    </div>
                </div>
                <MessageList
                    fetchMessages={20}
                    enableReactions
                    extraActionsRenderer={extraActions}
                    style={{
                        marginLeft: '3rem',
                    }}
                >
                    <TypingIndicator showAsMessage />
                </MessageList>
                <MessageInput
                    typingIndicator={false}
                    fileUpload="all"
                    placeholder="Type your message"
                    emojiPicker={<Picker />}
                    sendButton={<SendIcon style={{backgroundColor:'#0739ff',width:'20px',height:'20px',borderRadius:'50%'}}/>}
                />
            </div>
            <ChatRightContainer chatType={chatType}   currentChannel={currentChannel}  setChannel={setupChatHost} currentuser={channelMem}/>
        </Chat>
    </div>
);
})

export default EventChat;
