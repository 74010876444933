import "reactstrap";
import "../../../index.css";
import "typeface-montserrat";
import { useStoreActions, useStoreState } from "easy-peasy";
import Grid from "@material-ui/core/Grid";
import { Typography } from "@material-ui/core";

const LoginRegisterSidebar = () => {
    const { eventConfig } = useStoreState((state) => state.state);
    return (
       
            <Grid container>
                <div>
                    <Typography variant="body2" style={{ color: "#FFF" , fontSize:"25px" , marginLeft:8, marginRight:8 , wordSpacing:"1px" , letterSpacing:"1px" , fontWeight:"700",fontFamily:"Roboto"}}>
                        {eventConfig?.data?.[0]?.event_title}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        style={{
                            color: "#FFF",
                            fontSize: "inherit",
                            marginLeft: 8,
                            marginRight:8 , 
                            marginTop: 20,
                            wordSpacing:"1px",
                            letterSpacing:"1px",
                            fontFamily:"Roboto"
                            
                        }}
                    >
                        {eventConfig?.data?.[0]?.event_start_date_time} -{" "}
                        {eventConfig?.data?.[0]?.event_end_date_time}
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        style={{
                            color: "#FFF",
                            fontSize: "inherit",
                            marginLeft: 8,
                            marginRight:8 , 
                            marginTop: 20,
                            wordSpacing: "1px",
                            letterSpacing:"1px",
                            fontFamily:"Roboto"
                            
                        }}
                    >
                        {eventConfig?.data?.[0]?.event_description}
                    </Typography>
                </div>
            </Grid>
    );
};

export default LoginRegisterSidebar;
