import { v4 as uuidv4 } from "uuid";

export  const pubnubUtilty = {
    createSharedSpaceV2: (pubnub, user1, user2, spaceId,callback) => {

        pubnubUtilty.removeChannelMemberShips(pubnub,spaceId)
 
        return pubnub.objects.setChannelMetadata({
            channel: spaceId,
            data: {
                name: user1?.name,
                description: ``,
                custom: {
                    user1_id : user1?.id,
                    user2_id : user2?.id,
                    user1_displayName : user1?.name,
                    user2_displayName: user2?.name,
                    user1_description : `Direct chat with ${user1?.name}`,
                    user2_description: `Direct chat with ${user2?.name}`,
                },
            },
        } , callback);
    },

    generateSpaceId: () => {
        let id = uuidv4();
        return `Space_${id}`;
    },
    addUserToSpaceV2: (pubnub, spaceId, usersId,callback) => {
        return pubnub.objects.setChannelMembers({
            channel: spaceId,
            uuids: usersId,
        },callback);
    },

    getUserMemberShipSpacesV2: (userId, pubnub,callback) => {
        return pubnub.objects.getMemberships({
            uuid: userId,
            include: {
                channelFields: true,
                customChannelFields: true,
            },
        },callback);
    },

    getSpacesV2: (userId, pubnub,callback) => {
        return pubnub.getMemberships({
            uuid: userId,
        },callback);
    },

    createUsersV2: (userdetails, pubnub,callback) => {

        pubnubUtilty.removeMemberShipsForUser(pubnub,userdetails?.id);

        return pubnub.objects.setUUIDMetadata({
            uuid: userdetails?.id,
            data: {
                name: userdetails?.name,
                profileUrl: userdetails?.profileUrl,
                email :userdetails?.email
            },
        },callback);

    },

    getMembersFromSpaceV2: (spaceId, pubnub, callback) => {
        return pubnub.objects.getChannelMembers({
            channel: spaceId,
        },callback);
    },

    getUsersDetailsV2: (id, pubnub,callback) => {
        return pubnub.objects.getUUIDMetadata({
            uuid: id,
        },callback);
    },

    getUsersAllDetailsV2: (pubnub,callback) => {
        return pubnub.objects.getAllUUIDMetadata({},(status, res) => {
            console.log(res)
            res?.data.forEach(element => {
                // if(element?.id != "4151" && element?.id != "6370")
                //    deleteUserV2(pubnub,element?.id )
                });
            
        });
        },

    deleteSpaceV2: async (pubnub, spaceId,callback) => {
        pubnub.objects.removeChannelMetadata({
            channel: spaceId,
        });

        return pubnub.objects.removeMemberships({
            channels: [spaceId],
        },callback);
    },

    deleteUserV2: (pubnub, userId,callback) => {
        return pubnub.objects.removeUUIDMetadata({
            uuid: userId,
        },callback);
    },

    fetchMessages: (pubnub, spaceId) => {
        return pubnub.fetchMessages({
            channels: [spaceId],
        });
    },
    getMemberships: (pubnub, userDetails,callback) => {
        return pubnub.objects.getMemberships({
            uuid: userDetails?.id,
            include: {
                channelFields: true,
                customChannelFields: true,
            },
        },callback);
    },
    getChannelMetaData: (pubnub, spaceId, callback) => {
        return pubnub.objects.getChannelMetadata({
            channel: spaceId,
            include: {
                channelFields: true,
                customChannelFields: true,
            },
        }, callback);
    },

    getAllChannelMetaData: (pubnub) => {
        return pubnub.objects.getAllChannelMetadata({}, (status, res) => {
            res?.data.forEach(element => {
                deleteSpaceV2(pubnub,element?.id)
              });
             console.log(res)
        })
    } , 
    removeMemberShipsForUser : (pubnub, userId) => {
        return  pubnubUtilty.getMemberships(pubnub,userId,(sta,ress)=> {
            console.log(ress)
            ress?.data.forEach(element => {
                pubnubUtilty.removeMemberships(pubnub,userId,element?.channel?.id,(st,ressa)=> {
                    console.log(ressa)
                })
            })
          })
    } ,

    removeMemberships : (pubnub,uuid, spaceId, callback) => {
        return  pubnub.objects.removeMemberships({
            uuid:uuid,
            channels: [spaceId]
        },callback)
    } ,

    removeChannelMemberShips : (pubnub,channel) => {
        pubnubUtilty.getMembersFromSpaceV2(channel,pubnub,(status, res ) => {

            res?.data.forEach(element => {
                return  pubnub.objects.removeMemberships({
                    uuid:element.uuid.id,
                    channels: [channel]
                },(st,ressa)=> {
                    console.log(ressa)
                })
            })
        })
      
    }
};

export const createSharedSpaceV2 = async (
    pubnub,
    name,
    fromUser,
    toUser,
    updateState
) => {
    let id = uuidv4();
    let spaceId = `Space_${id}`;
    pubnub.objects.setChannelMetadata(
        {
            channel: spaceId,
            data: {
                name: name,
                description: `Direct chat with ${name}`,
                custom: {
                    owner_name: fromUser?.username,
                    owner_description: `Direct chat with ${fromUser?.username}`,
                },
            },
        },
        function (status, response) {
            if (status.error) {
                alert("Error: A space with that name already exists");
                return;
            }
            addUserToSpaceV2(
                pubnub,
                spaceId,
                [fromUser?.id, toUser?.user_id],
                updateState
            );
            //console.log(response);
        }
    );

    // console.log(spaceId);
};

export const addUserToSpaceV2 = (pubnub, spaceId, usersId, updateState) => {
    pubnub.objects.setChannelMembers(
        {
            channel: spaceId,
            uuids: usersId,
        },
        function (status, response) {
            if (status.error) {
                alert("Error: Check that space-id and user-id is correct");
                return;
            }
            updateState({
                channelCreated: spaceId,
            });
            return response;
        }
    );
};

export const getSpacesV2 = (userId, pubnub, updateState, username) => {
    pubnub.getMemberships(
        {
            uuid: userId,
        },
        function (status, response) {
            // console.log(response);
            if (response?.data.length > 0) {
                let channels = response?.data.map(({ channel }) => {
                    if (channel?.name === username) {
                        return {
                            ...channel,
                            name: channel?.custom?.owner_name,
                            description: channel?.custom?.owner_description,
                        };
                    } else {
                        return channel;
                    }
                });
                updateState({
                    directChannels: channels,
                });
            } else {
                updateState({
                    directChannels: [],
                });
            }
        }
    );
};

export const createUsersV2 = (userdetails, pubnub) => {
    pubnub.objects.setUUIDMetadata(
        {
            uuid: userdetails?.id,
            data: {
                name: userdetails?.username,
                profileUrl: userdetails?.profile_image,
            },
        },
        (status, response) => {
            //console.log(response);
        }
    );
};

export const getMembersFromSpaceV2 = (spaceId, pubnub, updateState) => {
    pubnub.objects.getChannelMembers(
        {
            channel: spaceId,
        },
        function (status, response) {
            updateState({
                channelMembers: response?.data,
            });
        }
    );
};

export const getUsersDetailsV2 = (userdetails, pubnub) => {
    pubnub.objects.getUUIDMetadata(
        {
            uuid: userdetails?.id,
        },
        function (status, response) {
            // console.log(response)
            if (status?.error === true) {
                createUsersV2(userdetails, pubnub);
            }
        }
    );
};

export const getUsersAllDetailsV2 = (pubnub, updateState) => {
    pubnub.objects.getAllUUIDMetadata({}, function (status, response) {
        // console.log(response);
        // response?.data.forEach(element => {
        //   deleteUserV2(pubnub,element?.id)
        // });
        updateState({
            chatUsers: response?.data,
        });
    });
};

export const deleteSpaceV2 = async (pubnub, spaceId) => {
    await pubnub.objects.removeChannelMetadata(
        {
            channel: spaceId,
        },
        function (status, response) {
             console.log(response);
        }
    );

    pubnub.objects.removeMemberships({
        channels: [spaceId],
    }, function (status, response) {
        console.log(response);
   });
};

export const deleteUserV2 = (pubnub, userId) => {
    pubnub.objects.removeUUIDMetadata(
        {
            uuid: userId,
        },
        function (status, response) {
            //console.log(response);
        }
    );
};

export const fetchMessages = (pubnub, spaceId, updateState) => {
    pubnub.fetchMessages(
        {
            channels: [spaceId],
            // end: '15343325004275466',
            // count: 100
        },
        (status, response) => {
            // console.log(response);
            updateState({
                messages: response?.channels[spaceId],
            });
        }
    );

    // pubnub.deleteMessages(
    //   {
    //     channel: spaceId,
    //     // start: "15526611838554310",
    //     // end: "15526611838554309",
    //   },
    //   function (status, response) {
    //     console.log(status, response);
    //   }
    // );
};

export const checkandCreateChannel = async (
    userDetails,
    pubnub,
    currentChatUserDetails,
    updateState
) => {
    pubnub.objects.getMemberships(
        {
            uuid: userDetails?.id,
            include: {
                channelFields: true,
                customChannelFields: true,
            },
        },
        function (status, response) {
            // console.log(response);
            if (response?.data.length > 0) {
                let channels = response?.data.map(({ channel }) => {
                    if (channel?.name === userDetails?.username) {
                        return {
                            ...channel,
                            name: channel?.custom?.owner_name,
                            description: channel?.custom?.owner_description,
                        };
                    } else {
                        return channel;
                    }
                });

                let currentChanl = channels.filter(
                    (channel) =>
                        channel?.name ===
                        currentChatUserDetails?.first_name +
                            " " +
                            currentChatUserDetails?.last_name
                );

                if (!currentChanl || currentChanl.length === 0) {
                    createSharedSpaceV2(
                        pubnub,
                        currentChatUserDetails?.first_name +
                            " " +
                            currentChatUserDetails?.last_name,
                        userDetails,
                        currentChatUserDetails,
                        updateState
                    );
                }
            } else {
                createSharedSpaceV2(
                    pubnub,
                    currentChatUserDetails?.first_name +
                        " " +
                        currentChatUserDetails?.last_name,
                    userDetails,
                    currentChatUserDetails,
                    updateState
                );
            }
        }
    );
};
