import React, { useState, useEffect } from "react";
import "./polls.css";
import ArrowBackOutlinedIcon from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlinedIcon from "@material-ui/icons/ArrowForwardOutlined";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import { baseUrl } from '../../store/restApi/api'
import AssignmentTurnedInOutlinedIcon from '@material-ui/icons/AssignmentTurnedInOutlined';
import {pollsQuestion as pollsQuestionApi, pollsResponse as pollsResponseApi } from "../../store/restApi/api";
const Polls = ({ close, event_id, user_id }) => {

  const [allQuestion, setAllQuestion] = useState([]);
  const [eachQuestion, setEachQuestion] = useState({});
  const [questionIndex, setQuestionIndex] = useState(0);
  const [optionIndex, setOptionIndex] = useState("");
  const [showError, setshowError] = useState(false);

  useEffect(() => {
    async function fetchQuestion() {
      // const questions = await fetch(
      //   `${baseUrl}/polls_form_questions.php?event_id=${event_id}&user_id=${user_id}`
      // )
      //   .then((res) => res.json())
      //   .catch((err) => {
      //     //console.log(err);
      //   });
      //setAllQuestion(questions);

      let payload_data={
        event_id: event_id,
        user_id: user_id
      };
      pollsQuestionApi(payload_data)
        .then((res) => {
          console.log(res);
          setAllQuestion(res);
        })
        .catch((err) => {
          //console.log(err);
        });
      

    }

    fetchQuestion();
    return () => {
      //console.log("clean up");
    };
  }, []);

  useEffect(() => {
    if (allQuestion.length !== 0) {
      //console.log("change all question" + questionIndex);
      setEachQuestion(allQuestion[questionIndex]);
    }
    return () => {
      //console.log("clean up");
    };
  }, [allQuestion, questionIndex]);

  useEffect(() => {
    setEachQuestion(allQuestion[questionIndex]);
    setOptionIndex(0);

    return () => {
      //console.log("clean up");
    };
  }, [questionIndex, allQuestion]);

  const handleMultipleAnswerQuestion = (currentAnswer, checked, oldAnswer) => {
    let answers = [];

    if (oldAnswer) {
      oldAnswer = oldAnswer.split(",");
      if (checked) {
        answers = [...oldAnswer, currentAnswer];
      } else {
        answers = oldAnswer.filter((data) => data !== currentAnswer);
      }
    } else {
      answers.push(currentAnswer);
    }
    return answers.join();
  };

  const handleAnswerChange = (e) => {
    setshowError(false);

    if (eachQuestion.question_type === "checkbox") {
      let checked = handleMultipleAnswerQuestion(
        e.target.value,
        e.target.checked,
        eachQuestion["answer"]
      );
      eachQuestion["answer"] = checked;
      let checkedIndex = handleMultipleAnswerQuestion(
        e.target.getAttribute("data-key"),
        e.target.checked,
        optionIndex
      );
      setOptionIndex((data) => checkedIndex);
    } else {
      eachQuestion["answer"] = e.target.value;
      setOptionIndex(e.target.getAttribute("data-key"));
    }
  };

  const handleClose = () => {
    close();
  };

  const handleAnswerSubmit = async () => {
    if (
      (eachQuestion?.form_validation === "required" && eachQuestion?.answer) ||
      eachQuestion?.form_validation !== "required"
    ) {
      
      /*let api = `${baseUrl}/polls_form_response.php
                        ?user_id=${user_id}
                        &event_id=${event_id}
                        &user_type=attendee
                        &ques_id=${eachQuestion?.question_id}
                        &response_text=${eachQuestion.answer}
                        &response_option=${optionIndex}
                        &completed_status=${allQuestion.length === questionIndex + 1 ? 2 : 1
        }`;    

      let response = await fetch(api)
        .then((res) => res.json())
        .catch((err) => {
         // console.log(err);
        });   

      if (response?.Status === "Success") {
        let opt = {
          Option_1: response?.Option_1,
          Option_2: response?.Option_2,
          Option_3: response?.Option_3,
          Option_4: response?.Option_4,
        };

        setEachQuestion((data) => {
          eachQuestion.completed_status = 1;
          return { ...eachQuestion, ...opt };
        });
        allQuestion[questionIndex] = eachQuestion;
      }*/

      let payload_data={
        user_id:user_id,
        event_id:event_id,
        user_type:'attendee',
        ques_id:eachQuestion?.question_id,
        response_text:eachQuestion.answer,
        response_option:optionIndex,
        completed_status:((allQuestion.length === questionIndex + 1) ? 2 : 1)
      };
      pollsResponseApi(payload_data)
      .then((response) =>{
        alert(response);
        console.log(response)
        if (response?.Status === "Success") {
          let opt = {
            Option_1: response?.Option_1,
            Option_2: response?.Option_2,
            Option_3: response?.Option_3,
            Option_4: response?.Option_4,
          };
  
          setEachQuestion((data) => {
            eachQuestion.completed_status = 1;
            return { ...eachQuestion, ...opt };
          });
          allQuestion[questionIndex] = eachQuestion;
        }
      })
      .catch((err) => {
       // console.log(err);
      });




    } else {
      setshowError(true);
    }
  };

  const handleNext = () => {
    if (allQuestion.length !== questionIndex + 1) {
      setQuestionIndex(questionIndex + 1);
    } else {
      close();
    }
  };

  const handleBackButton = () => {
   // console.log("handleBackButton " + questionIndex);
    if (questionIndex !== 0) {
      setEachQuestion(allQuestion[questionIndex - 1]);
      setQuestionIndex(questionIndex - 1);
    }
  };

  return (
    <> 
    {/* {eachQuestion?.completed_status != 2 && ( */}
      <div className="question_top">
        <div className="back_container" onClick={handleBackButton}>
          <ArrowBackOutlinedIcon className="menu_icons question_icons" />
        </div>
      </div>
    {/* )} */}
      {/* {eachQuestion?.completed_status != 2 ? ( */}
        <div className="questions_container">

          <div className="question">
            <span>{eachQuestion?.questions}</span>
          </div>
          <div className="select_option">
            {eachQuestion?.value?.split(",").map((option, index) => {
              return (
                <div
                  style={{ borderBottom: "1px solid #EBEBEB", padding: "15px" }}
                  key={index}
                >
                  <div className="option" key={index}>
                    <div>
                      {eachQuestion?.completed_status == 0 && (
                        <input
                          type={eachQuestion?.question_type}
                          name="answer"
                          value={option}
                          onChange={handleAnswerChange}
                          checked={eachQuestion?.answer === option}
                          data-key={index}
                        />
                      )}
                      <span className="option_answer">{option}</span>
                    </div>
                    <div>
                      {eachQuestion?.completed_status != 0 && (
                        <span className="option_percentage">{`${eachQuestion[
                          "Option_" + (index + 1)
                        ].toFixed(2)}%`}</span>
                      )}
                    </div>
                  </div>
                  {eachQuestion?.completed_status != 0 && (
                    <div
                      className="progress"
                      style={{
                        "--w": `${eachQuestion["Option_" + (index + 1)]}`,
                      }}
                    ></div>
                  )}
                </div>
              );
            })}
          </div>
          {showError && (
            <div className="error_message">
              <span>**Please select any one of the option**</span>
            </div>
          )}

          {eachQuestion?.completed_status > 0 && (
            <div className="next_container" onClick={handleNext}>
              <span className="button_text">{`${allQuestion.length !== questionIndex + 1 ? "Next" : "Close"
                }`}</span>
              <ArrowForwardOutlinedIcon className="next_icon" />
            </div>
          )}

          {eachQuestion?.completed_status == 0 && (
            <div className="next_container" onClick={handleAnswerSubmit}>
              <span className="button_text">Submit</span>
            </div>
          )}
        </div>
       {/* ) : (
         <>
           <AssignmentTurnedInOutlinedIcon  className="assign-icon"/>
           <div style={{margin : '0 auto' , fontWeight : '600'}}>
             <span>Polls Completed !</span>
           </div>
         </>
       )} */}
    </>
  );
};

export default Polls;
