import React from "react";
import {
    Box,
    Container,
    Typography,
    Button,
    Grid,
    Avatar,
    makeStyles,
} from "@material-ui/core";
import { useStoreState, useStoreActions } from "easy-peasy";
import { Link } from "react-router-dom";
import LoginRegisterSidebar from "../loginRegisterSiderbar/LoginRegisterSidebar";

// import { ReactComponent as ReactLogo } from "../assets/error.svg"
const useStyles = makeStyles((theme) => ({
    container: {
        background: "#e7e6ff",
        height: "100%",
    },
    loginModal: {
        background: "white",
        minWidth: "40vw !important",
        // height: "100%",
        margin: "auto",
        position: "relative",
        top: "15%",
    },
    fonts: {
        fontSize: 80,
    },
    badge: {
        margin: "0 auto",
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginBottom: 30,
    },
}));

const EventNotLive = () => {
    const classes = useStyles();
    const { eventConfig } = useStoreState((state) => state.state);
    const eventStatus = eventConfig?.data?.[0]?.event_status;
    const isEventCancelled = eventStatus == "Cancelled";
    //const isEventScheduled = eventStatus == "scheduled";
    const isEventScheduled = eventStatus == "Scheduled";
    const isEventOver = eventStatus == "Over";
    const getEventNotLiveUI = () => {
        if (isEventCancelled)
            return (
                <div>
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        textAlign="center"
                        justifyContent="center"
                    >
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="h4"
                        >
                            Event is Cancelled
                        </Typography>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            <Box p={2}>
                                {eventConfig?.data?.[0]?.cancelled_message}
                            </Box>
                        </Typography>
                    </Box>
                </div>
            );
        else if (isEventScheduled)
            return (
                <div>
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        textAlign="center"
                        justifyContent="center"
                    >
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="h4"
                        >
                            Event is Scheduled
                        </Typography>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            <Box p={2}>
                                {eventConfig?.data?.[0]?.scheduled_message}
                            </Box>
                        </Typography>
                    </Box>
                </div>
            );
        else if (isEventOver)
            return (
                <div>
                    <Box
                        display="flex"
                        flexDirection="column"
                        height="100%"
                        textAlign="center"
                        justifyContent="center"
                    >
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="h4"
                        >
                            Event is Over
                        </Typography>
                        <Typography
                            align="center"
                            color="textPrimary"
                            variant="subtitle2"
                        >
                            <Box p={2}>
                                {eventConfig?.data?.[0]?.over_message}
                            </Box>
                        </Typography>
                    </Box>
                </div>
            );
    };
    return (
        <>
            {
                <div className={classes.container}>
                    <Grid
                        container
                        xs={10}
                        sm={7}
                        md={4}
                        lg={3}
                        className={classes.loginModal + " shadow"}
                    >
                        <Grid
                            container
                            xs={5}
                            style={{
                                backgroundColor: "#1500fa",
                                padding: "20% 15px",
                            }}
                        >
                            <LoginRegisterSidebar />
                        </Grid>
                        <Grid container xs={7} style={{ padding: "15px " }}>
                            {getEventNotLiveUI()}
                        </Grid>
                    </Grid>
                </div>
            }
        </>
    );
};

export default EventNotLive;
