import React, { useState } from "react";
import "./userDropDown.css";
import AccountCircleRoundedIcon from "@material-ui/icons/AccountCircleRounded";
import FeedbackOutlined from "@material-ui/icons/FeedbackOutlined";
import ExitToApp from "@material-ui/icons/ExitToApp";
import { useStoreState } from "easy-peasy";

const UserDropDown = ({ exit, feedBack, feedBackflag, updateProfile }) => {
    const [isOpen, setIsOpen] = useState(false);

    const { userDetails, eventConfig } = useStoreState((state) => state.state);

    //console.log(userDetails);
    return (
        <div className="User-area" onClick={() => setIsOpen((val) => !val)}>
            <div className="User-avtar">
                {/* <AccountCircleRoundedIcon className="user-icon" /> */}
                <img src={userDetails.profile_image} height="25" width="25" />
            </div>
            <ul className={`User-Dropdown ${isOpen ? " U-open" : ""}`}>
                <li onClick={exit}>
                    <a>Logout</a>
                    <ExitToApp style={{ float: "right", marginTop: "12px" }} />
                </li>
                <li onClick={updateProfile}>
                    <a>Update profile</a>
                </li>
                {/* <li>
                    <a>Do not distrub</a>
                </li> */}
              
                {userDetails?.event_feedback_not_interested === 0 &&
                    eventConfig.data[0]?.feeback_form_enabled == "Yes" &&
                    feedBackflag === 0 && (
                        <li onClick={feedBack}>
                            <a>Feedback</a>
                            <FeedbackOutlined
                                style={{ float: "right", marginTop: "12px" }}
                            />
                        </li>
                    )}

                <li>
                    <div className="user-profile">
                        <span>
                            <img
                                src={userDetails.profile_image}
                                height="25"
                                width="25"
                            />
                        </span>
                        <div className="user-pro-details">
                            <span style={{ lineHeight: "2rem" }}>
                                {userDetails?.username}
                            </span>
                            <br />
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default UserDropDown;
