import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import Button from "../common/Button";
import LanguageIcon from "@material-ui/icons/Language";

const styles = (theme) => ({
    modal: {},
});

const ExhibitorInfoModal = (props) => {
   // console.log("description", props);

    const { classes } = props;

    return (
        <Grid
            style={{ width: "100%", border: "none", padding: "15px" }}
            className=""
        >
            <Grid
                item
                xs={12}
                className="h2"
                style={{ fontWeight: "normal", marginBottom: 15 }}
            >
                {props.title}
            </Grid>
            <Grid item xs={12} style={{ marginBottom: 15 }} className="productDescription">
                {props.description}
            </Grid>
            <Grid item xs={12}>
                <a
                    target="_blank"
                    href={"//" + props.url}
                    style={{ color: "#1c1d1f" }}
                >
                    <Button OpenIcon={LanguageIcon} />
                    <h4
                        style={{ fontWeight: "normal", display: "inline-flex" }}
                    >
                        {props.url}
                    </h4>
                </a>
            </Grid>
        </Grid>
    );
};

export default withStyles(styles)(ExhibitorInfoModal);
