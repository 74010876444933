import React, { useEffect, useState } from "react";
import "../../pubNub/notification/videoCallWindow/videoCallWindow.css";
//import PinchZoomPan from "react-responsive-pinch-zoom-pan";
const ImageComponant = ({ payload }) => {
    const [src, setSrc] = useState("");
    //console.log(payload);
    useEffect(() => {
        setSrc(() => payload.message.file.url);
        return () => {
            setSrc(() => "");
        };
    }, [payload]);

    //return  <PinchZoomPan doubleTapBehavior='zoom'><img src={src} alt="error" className="upload-container"/></PinchZoomPan>;
    return <img src={src} alt="error" className="upload-container"/>
};

export default ImageComponant;
