import { Table } from "reactstrap";
import { withStyles } from "@material-ui/core/styles";
// import Button from "../common/Button";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { IconButton } from "@material-ui/core";
import Modal from "../Modal";
import { useEffect, useState } from "react";
import { Badge } from "reactstrap";
import { useStoreState, useStoreActions } from "easy-peasy";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import { manageNavigationButtonDisplay } from "../Clickable";
import { isUri } from "valid-url";
import Button from '@material-ui/core/Button';
import PlayArrowRoundedIcon from "@material-ui/icons/PlayArrow";

//------------------------My Favourite Function--------------------------//
import { myFavouriteAdd, myFavouriteRemove } from "../../../../store/restApi/boothLiveApi";
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
//------------------------My Favourite Function--------------------------//

const styles = {
    table: {
        margin: "10px auto",
        textAlign: "center",
        borderColor: "#1c1d1f",
        "& th": {
            borderColor: "#1c1d1f",
        },
        "& td": {
            borderColor: "#1c1d1f",
        },
        "& tr": {
            borderColor: "#1c1d1f",
        },
        "& thead": {
            borderColor: "#1c1d1f",
        },
    },
};

const SessionsModal = (props) => {
    const { sessionsList, classes } = props;
    const [videoUrl, setVideoUrl] = useState("");
    const [videoLinkTag, setVideoLinkTag] = useState("");
    const [playSessionVideo, setPlaySesisonVideo] = useState(false);
    const {
        streamUrl,
        cameraState,
        showBack,
        positions,
        isPresentationZone,
        no_of_coordinates,
        presentationZoneIndex,
    } = useStoreState((state) => state.boothLiveState);
    //console.log(positions);
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    const [camera] = useState(cameraState);
    const [activeSession, setActiveSession] = useState(0);
    const [sessionsCount, setSessionsCount] = useState(sessionsList?.length);
    const { eventConfig,userDetails } = useStoreState((state) => state.state);
    const { boothDetails, exhibitorInfo } = useStoreState(
        (state) => state?.boothLiveState
    );
    //console.log("cameraSta", cameraState);
    const [presentationCoords, setPresentationCoords] = useState(undefined);

    useEffect(() => {
        positions?.map((i, index) => {
            if (i.name == "presentation_zone") {
                setPresentationCoords(i);
                
                // console.log("preseng", i,JSON.parse(i.cameraPosition)[1] )
            } else {
                alert("This booth not contains presentation zone");
            }
        });
    }, [positions]);

    const manageNavButtonDisplay = (index) => {
        if (
            document.getElementById("navigateModelButton" + index).style
                .display == "block"
        )
            document.getElementById(
                "navigateModelButton" + index
            ).style.display = "none";
    };

    const revertToScreen = () => {
        // props.handleModalClose();

        if (cameraState && presentationCoords) {
            // camera.position.set(
            //     -5.7241034507751465 , 20 , 101.36194610595703
            // );
            // camera.lookAt(
            //     -5.765683780563588 , 20.00399998933334 , 102.36107326315977
            // );

            camera.position.set(
                JSON.parse(presentationCoords?.cameraPosition)[0],
                JSON.parse(presentationCoords?.cameraPosition)[1],
                JSON.parse(presentationCoords?.cameraPosition)[2]
            );
            camera.lookAt(
                JSON.parse(presentationCoords?.cameraLookAt)[0],
                JSON.parse(presentationCoords?.cameraLookAt)[1],
                JSON.parse(presentationCoords?.cameraLookAt)[2]
            );
            camera.updateProjectionMatrix();
            // updateState({
            //     stream_playing: true,
            //     showBack: true,
            //     isPresentationZone: true,
            // });

            // manageNavButtonDisplay(presentationZoneIndex);
        }
    };

    /*-------------------------------Favourite Functionality------------------------------------------------*/

    const [favourite, setfavourite] = useState(sessionsList?.[activeSession]?.streaming_list);//sessionsList?.[activeSession]?.streaming_list

   

   const setFavorite_fn=(status,idx,sessions_id)=>{       
       setfavourite(favValue => 
           [...favValue].map(el => 
               el.session_id === sessions_id? ({...el, flag_status:status}) : el)
           )      

            let session_dummy=sessionsList;          
            session_dummy[activeSession].streaming_list[idx].flag_status=status;        
            updateState({ sessionsList: session_dummy}); 
            
            let favourite_payload = {
                user_id: userDetails?.id,
                favourite_type: 'session',
                action_id: sessionsList?.[activeSession]?.streaming_list[0]?.session_id,
                event_id: eventConfig?.data?.[0]?.event_id,
                booth_id: boothDetails?.booth[0]?.booth_id
            };
            //    alert(status)
            if(status)
            {
                myFavouriteAdd(favourite_payload).then((response) => {
                    console.log(response);            
                })
                .catch((error) => {
                    //console.log(error);
                });
            }
            else
            {
                myFavouriteRemove(favourite_payload).then((response) => {
                    console.log(response);            
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            }  
     };

    /*-------------------------------Favourite Functionality------------------------------------------------*/

    return (
        <>
            <Table bordered className={classes?.table}>
                <thead
                    style={{
                        background: "#1c1d1f",
                        color: "white",
                        borderColor: "#1c1d1f",
                    }}
                >
                    <tr>
                        <th>
                            <IconButton
                                onClick={() => {
                                    setActiveSession(
                                        (activeSession + sessionsCount - 1) %
                                            sessionsCount
                                    );
                                }}
                            >
                                <ChevronLeftIcon htmlColor={"white"} />
                            </IconButton>
                        </th>
                        <th>
                            <span
                                style={{
                                    position: "relative",
                                    top: "50%",
                                    transform: "translateY(-50%)",
                                    msTransform: "translateY(-50%)",
                                }}
                            >
                                {sessionsList?.[activeSession]?.session_date}
                            </span>
                        </th>
                        <th>
                            <IconButton
                                onClick={() => {
                                    setActiveSession(
                                        (activeSession + 1) % sessionsCount
                                    );
                                }}
                            >
                                <ChevronRightIcon htmlColor={"white"} />
                            </IconButton>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {favourite.map(
                        (session, index) => {
                            let videoLinkTag = "";
                            if (session?.Status == "Live")
                                videoLinkTag = "Join Livestreaming";
                            else if (session?.Status == "Over") {
                                if (isUri(session?.on_demand_video_url))
                                    videoLinkTag = "Watch Recording";
                                else videoLinkTag = "";
                            } else videoLinkTag = "";
                            return (
                                <tr>
                                    <td>
                                        {session?.session_start_time +
                                            " - " +
                                            session?.session_end_time}
                                        <br />
                                        {session?.session_end_timezone}
                                    </td>
                                    <td style={{ textAlign: "left" }}>
                                        <b>{session?.session_title}</b>
                                        <br />
                                        {session?.session_description}
                                    </td>
                                    <td
                                        onClick={() => {
                                            setVideoUrl(
                                                session?.on_demand_video_url
                                            );
                                            setPlaySesisonVideo(true);
                                        }}
                                    >
                                        <div style={{ padding: 5 }}>
                                            {"     "}
                                            {session?.Status == "Live" ? (
                                                <Badge>Live</Badge>
                                            ) : null}
                                            {"     "}
                                            {session?.Status == "Over" ? (
                                                <Badge>Over</Badge>
                                            ) : null}
                                            {session?.Status == "Scheduled" ? (
                                                <Badge>Scheduled</Badge>
                                            ) : null}
                                        </div>
                                        {session?.Status != "Scheduled" ? (
                                            <div
                                                style={{
                                                    cursor: "pointer",
                                                    zIndex: 10000,
                                                }}
                                            >
                                                {/* <Button
                                                    OpenIcon={ChevronRightIcon}
                                                   
                                                    onClick={() => {
                                                        let isMux = false;
                                                        revertToScreen();
                                                        console.log("session")
                                                        setVideoUrl(
                                                            session?.on_demand_video_url
                                                        );
                                                        if (
                                                            session.livestreaming_method ==
                                                            "Mux.com"
                                                        ) {
                                                            updateState({
                                                                isMux: true,
                                                                streamUrl:
                                                                    session[
                                                                        "Channel_URL/ID"
                                                                    ],
                                                            });
                                                        } else {
                                                            updateState({
                                                                isMux: true,
                                                                streamUrl:
                                                                session[
                                                                    "Channel_URL/ID"
                                                                ],
                                                            });
                                                        }
                                                    }}
                                                    small={true}
                                                    content={
                                                        <b>{videoLinkTag}</b>
                                                    }
                                                /> */}
                                                <Button
                                                    startIcon={
                                                        <PlayArrowRoundedIcon />
                                                    }
                                                    onClick={() => {
                                                        let isMux = false;
                                                        revertToScreen();
                                                        //console.log("session")
                                                        setVideoUrl(
                                                            session?.on_demand_video_url
                                                        );
                                                        if (
                                                            session.livestreaming_method ==
                                                            "Mux.com"
                                                        ) {
                                                            updateState({
                                                                isMux: true,
                                                                streamUrl:
                                                                    session[
                                                                        "Channel_URL/ID"
                                                                    ],
                                                                streamProvide:
                                                                    session.livestreaming_method,
                                                            });
                                                        } else {
                                                            updateState({
                                                                isMux: true,
                                                                streamUrl:
                                                                    session[
                                                                        "Channel_URL/ID"
                                                                    ],
                                                                streamProvide:
                                                                    session.livestreaming_method,
                                                            });
                                                        }
                                                    }}
                                                >
                                                    Join Livestreaming
                                                </Button>

                                                <div
                                                    onClick={() =>
                                                        setFavorite_fn(true,index,session?.session_id)
                                                    }
                                                >
                                                    {(session?.flag_status) ? ( <FavoriteIcon /> ) : (<FavoriteBorderIcon />)}
                                                </div>
                                            </div>
                                        ) : null}
                                    </td>
                                </tr>
                            );
                        }
                    )}
                </tbody>
            </Table>
            {/* {videoUrl != "" ? (
                <Modal
                    sessionVideo={true}
                    videoUrl={streamUrl}
                    show={playSessionVideo}
                    handleModalClose={() => {
                        setPlaySesisonVideo(false);
                    }}
                />
            ) : null} */}
        </>
    );
};

export default withStyles(styles)(SessionsModal);
