//
// This example assumes you are importing mux-embed from npm
// View this code on codesandbox: https://codesandbox.io/s/mux-data-hls-js-react-ucvvh
//
import React, { useEffect, useRef } from "react";
import Hls from "hls.js";
import mux from "mux-embed";
import { useStoreState, useStoreActions } from "easy-peasy";

const VideoPlayer = () => {
    const {  streamUrl } = useStoreState((state) => state.state);
    const { updateState } = useStoreActions((state) => state.state);

    const videoRef = useRef(null);
    // const src = "https://stream.mux.com/vects02G5kyJanURkcK6oexmS7Ri32Yg7GgXabTafT3w.m3u8"
    const src =streamUrl 
       // console.log(streamUrl)
    useEffect(() => {
        // console.log("mux change link")
        updateState({ canPlayMuxLink: false });
        let hls;
        if (videoRef.current) {
            const video = videoRef.current;
            const initTime = Date.now();

            if (video.canPlayType("application/vnd.apple.mpegurl")) {
                // This will run in safari, where HLS is supported natively
                video.src = src;
            } else if (Hls.isSupported()) {
                // This will run in all other modern browsers
                hls = new Hls();
                hls.loadSource(src);
                hls.attachMedia(video);
            }

            mux.monitor(video, {
                debug: false,
                // pass in the 'hls' instance and the 'Hls' constructor
                hlsjs: hls,
                Hls,
                data: {
                    env_key: "tvaa9hlrf1kr2lv764trnp4en", // required
                    // Metadata fields
                    player_name: "Main Player", // any arbitrary string you want to use to identify this player
                    player_init_time: initTime,
                    // ...
                },
            });
        }

        return () => {
            if (hls) {
                hls.destroy();
            }
        };
    }, [videoRef, streamUrl]);

    return (
        <video
            id="muxplayerAuditorium"
            // controls
            ref={videoRef}
            style={{
                outline: "unset",
            }}
            //style={{ objectFit: "initial" }}
            width="790"
            height="770"
            autoPlay={true}
            onCanPlay={() => updateState({ canPlayMuxLink: true })}
            onPlay={() => {
                //console.log("Play");
                updateState({ mux_playing: true });
            }}
            onPause={() => {
                updateState({ mux_playing: false });
            }}
        />
    );
}

export default VideoPlayer;
