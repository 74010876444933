import React, { useEffect, useState } from "react";
import { baseUrl } from "../../../../store/restApi/api";
import Listing from "../util/Listing";
import Download from "../../../../assets/download-file.png";
import { SwagList as SwagListApi } from "../../../../store/restApi/boothLiveApi";


const Products = ({ event_id, user_id }) => {
    const header = [
        // {
        //     id: "SNO",
        //     displayName: "SNO",
        // },
        {
            id: "product_title",
            displayName: "Products",
        },
        {
            id: "product_description",
            displayName: "Description",
        },
        {
            id: "price",
            displayName: "PRICE",
        },
        {
            id: "product_code",
            displayName: "Code",
        },
        {
            id:"remove_favourite_product",
            displayName: "Remove",
        }
    ];
    const [child, setChild] = useState([]);

    // const remove_favourite_fn=()=>{

    // }
    
    const actionsUI = () => {
        return (
            <>
                {/* <div>
                  <button  onClick={() => remove_favourite_fn() }>Remove From FAVOURITES/SWAGBAG</button>  
                </div> */}
            </>
        );
    };

    useEffect(() => {
        async function fetchDocuments() {
            // await fetch(`${baseUrl}/mybox_product_viewed.php`, {
            //     method: "post",
            //     headers: { "Content-Type": "application/json" },
            //     body: JSON.stringify({
            //         event_id: event_id,
            //         user_id: user_id,
            //     }),
            // })

            let payload_data={
                event_id: event_id,
                user_id: user_id,
                swag_type : "product"
            };
            SwagListApi(payload_data)
                .then((res) => setChild(() =>res.products))
                .catch((err) => {
                    //console.log(err);
                });
        }
        fetchDocuments();
        return () => {
            setChild(() => []);
        };
    }, []);

    return (
        <Listing
            header={header}
            child={child}
            actions={actionsUI}
            actionHeader=""
        />
    );
};

export default Products;
