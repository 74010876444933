import React from 'react';
import YouTube from 'react-youtube';


class YoutubePlayer extends React.Component {
  constructor(props) {
    super(props);
  }
 
  render() {
    
    const opts = {
      height: '760',
      width: '800',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

    return <YouTube videoId={this.props.streamUrl} opts={opts} onReady={this._onReady} />;
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.playVideo();
  }
}
export default YoutubePlayer;