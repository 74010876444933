import React, { useEffect, useState } from "react";
import Iframe from "react-iframe";
import "./videoCallWindow.css";

const VideoCallWindow = ({payload}) => {
    const [src, setSrc] = useState(payload);

    useEffect(() => {
        setSrc(() => payload);
        return () => {
            setSrc(() => "");
        };
    }, [payload]);

    return (
        <div className="videoCallWindow-container">
            <Iframe url={src.toString()} style={{
                top:'7%', position:'relative'
            }} width="100%" height="100%" />
        </div>
    );
};

export default VideoCallWindow;
