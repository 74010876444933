import { createStore, reducer, persist } from "easy-peasy";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import stateModel from "./model/state";
import boothLiveStateModel from "./model/boothLiveState";
import pubNubStateModel from './model/pubNubState'

//console.log("statemode", stateModel)
const model = {
    state: stateModel,
    boothLiveState: boothLiveStateModel,
    pubNub : pubNubStateModel
};
const store = createStore(persist(model), {
    disableImmer: true,
    middleware: [thunk],
});

export default store;
