import { withStyles, Grid } from "@material-ui/core";

const styles = {
    modal: {
        // background: "#1d1c1f",
        // display: "flex",
        padding: "0px 10px",
        textAlign: "center",
        height: "60vh",
        width: "60vh",
       
    },
    instruction: {
        padding: 10,
    },
};

const TawkToChatModal = ({ classes, tawkToCode }) => {
    console.log("takwraslkfasklj", tawkToCode);
    return (
        <Grid className={classes.modal} container justify="center">
            <iframe
                id="tawkToChat"
                style={{
                    border: "none",
                    width: "100%",
                    height: "100%",
                }}
                src={tawkToCode}
            ></iframe>
        </Grid>
    );
};

export default withStyles(styles)(TawkToChatModal);
