import React from "react";


function DacastPLayer (props){
    
    return(
        
            <div>
                 <iframe width={800} height={760} src={props.streamUrl} allowFullScreen allow="autoplay"  ></iframe>
            </div>
        
    )
}
export default DacastPLayer;