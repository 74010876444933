import React, { useEffect, useState } from "react";
import Button from "./common/Button";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import { withStyles } from "@material-ui/core/styles";
import { useStoreState, useStoreActions } from "easy-peasy";
import Rotate90DegreesCcwIcon from "@material-ui/icons/Rotate90DegreesCcw";
import CloseIcon from "@material-ui/icons/Close";
import { manageNavigationButtonDisplay } from "./Clickable";
import * as THREE from "three";
// import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
// import { OrbitControls } from "@react-three/drei";
import { OrbitControls } from "../components/common/OrbitControls.js";

import ReactDOM from "react-dom";
const styles = (theme) => ({
    addIconContainerClass: {
        marginBottom: "10px",
        right: 30,
    },
    removeIconContainerClass: {
        right: 30,
        marginBottom: "10px",
    },

    rotateIconContainerClass: {
        right: 30,
    },
});
const ZoomButtons = (props) => {
    const [camera] = useState(props.zoomCamera);
    const [glScene] = useState(props.glScene);

    const { classes } = props;
    const { isSmallScreen, showBack } = useStoreState(
        (state) => state.boothLiveState
    );
    const {
        positions,
        isPresentationVisible,
        no_of_coordinates,
        isPresentationZone,
    } = useStoreState((state) => state.state);
   
    const { updateState } = useStoreActions((state) => state.boothLiveState);
    // if (glScene) {
    //     var controls = new OrbitControls(camera, glScene.domElement);
    //     // controls.maxDistance = 55;
    // }
    // function zoomModel(isZoomOut, scale) {
    //     if (isZoomOut) {
    //         controls.dollyOut();
    //     } else {
    //         controls.dollyIn();
    //     }
    // }

    const zoomInHandler = () => {
       // console.log(camera.fov);
        camera.fov *= 0.9;
        // camera.position.z -= 2;
        camera.updateProjectionMatrix();
    };

    const zoomOutHandler = () => {
        if (camera.fov <= 100) {
           // console.log(camera.fov);
            camera.fov *= 1.1;
            // camera.position.z += 2;
            camera.updateProjectionMatrix();
        }
    };

    const revertToOriginalPosition = () => {
        camera.fov = 75;
        camera.updateProjectionMatrix();
        // console.log(positions);
        camera.position.set(
            JSON.parse(positions[0].cameraPosition)[0],
            JSON.parse(positions[0].cameraPosition)[1],
            JSON.parse(positions[0].cameraPosition)[2]
        );
        camera.lookAt(
            JSON.parse(positions[0].cameraLookAt)[0],
            JSON.parse(positions[0].cameraLookAt)[1],
            JSON.parse(positions[0].cameraLookAt)[2]
        );

        updateState({
            showBack: false,
            isPresentationVisible: positions[0]?.isVisible?.presentation,
            isPresentationZone: false,
        });
        manageNavigationButtonDisplay(no_of_coordinates);
    };
    return <></>;
};

export default withStyles(styles)(ZoomButtons);
