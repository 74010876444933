import React, { Suspense, useEffect, useState } from "react";
import {
    getBoothsList as getBoothsListAPI,
    getBoothData as getBoothDataAPI,
} from "../../store/restApi/api";
import Modal from "../Modal";

import * as THREE from "three";
import { Canvas } from "@react-three/fiber";
import { Sky, PointerLockControls, extend, Html } from "@react-three/drei";
import CustomStall from "../Stall";
import { useStoreActions, useStoreState } from "easy-peasy";
import { withRouter } from "react-router-dom";
import demo from "../../assets/shoping_mall.glb";
import { StoreProvider } from "easy-peasy";
import store from "../../store/index";
import LogoSpinner from "../common/LogoSpinner";
import { Button, Snackbar, Slide } from "@material-ui/core";
import HomeHotspots from "../HomeHotspots";
import CancelRoundedIcon from "@material-ui/icons/CancelRounded";
import { manageNavigationButtonDisplay } from "../HomeHotspots";
import Rotate90DegreesCcwOutlinedIcon from "@material-ui/icons/Rotate90DegreesCcwOutlined";
import { useHistory } from "react-router-dom";
import VideoControls from "../LobbyVideoControls";
import AudioControls from "../LobbyAudioControls";
import lobby_ico from "../../assets/lobby-ico.png";
import conference_ico from "../../assets/conference-ico.png";
import network_ico from "../../assets/network lounge-ico.png";
import exhibitors_ico from "../../assets/exhibitors-ico.png";
import helpdesk_ico from "../../assets/helpdesk-ico.png";
import Logout from "../logout/Logout";
import isEmpty from "lodash/isEmpty";
import BoothLiveModal from "../../boothLive/src/components/Modal";

function Loading() {
    return (
        <div
            style={{
                position: "absolute",
                width: "100vw",
                height: "100vh",
                zIndex: 99999,
            }}
        >
            <LogoSpinner
                style={{ position: "absolute", top: "50vh", left: "50vw" }}
            />
        </div>
    );
}

const ExhibitorsListPage = (props) => {
    const URL_Params = new URLSearchParams(props.location.search);

    const { glb, setGlb } = props;

    const { updateState } = useStoreActions((state) => state.state);
    // const { showBack } = useStoreState((state) => state.state);
    const [pointerLock, setPointerLock] = useState(false);
    // const [snackbarConfig, updateSnackbarConfig] = useState(false);
    const [videoElement, setVideoElement] = useState(undefined);

    const [camera, setCameraState] = useState(undefined);
    const [glbModelLoading, setGlbModalLoading] = useState(isEmpty(glb));

    let { eventConfig,instructionPopupShow } = useStoreState((state) => state.state);
    const { userDetails } = useStoreState((state) => state.state);
    // const [isTourOpen, setIsTourOpen] = useState(
    //     localStorage.getItem("hexaShowTour") == "false" ? false : true
    // );

    const [isTourOpen, setIsTourOpen] = useState(instructionPopupShow);

    useEffect(() => {
        localStorage.setItem("hexaShowTour", instructionPopupShow); 
        updateState({ positions: undefined });       
               
    }, []);

    const setCloseTour = () => {
        localStorage.setItem("hexaShowTour", "false");
        setIsTourOpen(false);
        updateState({ instructionPopupShow:false });        
    };

    useEffect(() => {
        return () => {
            videoElement?.pause();
        };
    }, [videoElement]);
    //console.log("dikha", videoElement && videoElement?.src);
    // const enableRotate = () => {
    //     updateSnackbarConfig((updatedConfig) => !updatedConfig);

    //     setPointerLock((pointerLock) => !pointerLock);
    // };
    // const SlideTransition = (props) => {
    //     return <Slide {...props} direction="up" />;
    // };
    // const revertToOriginalPosition = () => {
    //     if (camera) {
    //         camera.position.set(0.36163169145584106, 25, 159.6537322998047);
    //         camera.lookAt(
    //             0.35363696017376345,
    //             24.964007775496132,
    //             158.65441220891938
    //         );
    //         camera.updateProjectionMatrix();
    //         manageNavigationButtonDisplay(2);
    //     }
    //     updateState({
    //         showBack: false,
    //     });
    // };


  
    return (
        <>
            <>
                <StoreProvider store={store}>
                    {glbModelLoading && Loading()}
                   
                    {videoElement?.src && (
                        <VideoControls
                            videoElement={videoElement}
                            setVideoElement={setVideoElement}
                        />
                    )}

                    {videoElement?.src && (
                        <AudioControls
                            videoElement={videoElement}
                            setVideoElement={setVideoElement}
                        />
                    )}
                   
                    <Canvas
                        gl={{ antialias: true }}
                        style={{
                            height: "100%",
                        }}
                        id="canvas"
                        concurrent
                        camera={{
                            far: 10000,
                            // position: JSON.parse(
                            //     positions?.[0]?.cameraPosition
                            // ),
                        }}
                        onCreated={
                            (({ camera, scene }) => {
                                //console.log("called s");
                                camera.position.set(
                                    10.833200454711914,
                                    15,
                                    32.499595642089844
                                );
                                camera.lookAt(
                                    10.833200454711914,
                                    15,
                                    31.499595642089844
                                );
                                camera.updateProjectionMatrix();
                                //console.log(camera.position);
                                setCameraState(camera);
                            },
                            ({ gl }) => {
                                gl.toneMapping = THREE.ACESFilmicToneMapping;
                            })
                        }
                    >
                        {/* <rectAreaLight
                        width={600}
                        height={300}
                        color="0xffffff"
                        intensity={2}
                        position={[
                        0,50,0
                        ]}
                        rotation={[
                            4.71238898038469, 0,0
                        ]}
                        lookAt={[
                            0,0,0
                        ]}
                        penumbra={1}
                        /> */}
                        <pointLight
                            castShadow
                            intensity={0.5}
                            position={[0, 100, 0]}
                        />
                        {/* <ambientLight intensity={0.6} /> 
                             <Sky
                                distance={450000}
                                sunPosition={[-1500, 700, 1500]}
                            /> */}

                        <Suspense fallback={<></>}>
                            <StoreProvider store={store}>
                                <CustomStall
                                    instructions={true}
                                    boothUrl={
                                        eventConfig?.data?.[0]
                                            ?.event_venue_3d_model_file
                                    }
                                    setLoading={setGlbModalLoading}
                                    eventId={eventConfig?.data?.[0]?.event_id}
                                    event_code={eventConfig?.eventCode}
                                    userDetails={userDetails}
                                    eventConfig={eventConfig}
                                    glb={glb}
                                    setGlb={setGlb}
                                    videoElement={videoElement}
                                    setVideoElement={setVideoElement}
                                    helpdesk={URL_Params.get("helpdesk")}
                                />
                                {glb && (
                                    <Html>
                                        {isTourOpen && (
                                            <>
                                                <BoothLiveModal
                                                    show={true}
                                                    instructions={true}
                                                    handleModalClose={() =>
                                                        setCloseTour()
                                                    }
                                                    dark={true}
                                                />
                                            </>
                                        )}
                                    </Html>
                                )}
                                <ambientLight intensity={1} />

                                {/* <HomeHotspots /> */}
                            </StoreProvider>
                        </Suspense>
                        <PointerLockControls
                            lock={() => console.log()}
                            isLocked={pointerLock}
                            enabled={false}
                        />
                    </Canvas>
                </StoreProvider>
            </>
        </>
    );
};

export default withRouter(ExhibitorsListPage);
