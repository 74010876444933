import React from "react";
import "./confirmation.css";

const ConfirmationPopup = ({ message, yesTrigger, noTrigger }) => {
  return (
    <div className="confirmation_container">
      <div className="confirmation_message">
        <span>{message}</span>
      </div>
      <div className="confirmation_button">
        <button style={{ marginRight: "2rem" }} onClick={yesTrigger}>
          Submit Feedback
        </button>
        <button onClick={noTrigger}>Not interested</button>
      </div>
    </div>
  );
};

export default ConfirmationPopup;
