import React from "react";
import {
    Box,
    Container,
    Typography,
    Button,
    Avatar,
    makeStyles,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import logo from "../../assets/forbidden.gif";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: "100vh",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
    fonts: {
        fontSize: 80,
    },
    badge: {
        margin: "0 auto",
        backgroundColor: theme.palette.primary.main,
        width: theme.spacing(7),
        height: theme.spacing(7),
        marginBottom: 30,
    },
}));

const Error = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Box
                display="flex"
                flexDirection="column"
                height="100%"
                textAlign="center"
                justifyContent="space-evenly"
            >
                <center>
                    <img
                        src={logo}
                        alt="loading..."
                        style={{ height: "50vh" }}
                    />
                </center>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="h1"
                    className={classes.fonts}
                >
                    Oops...
                </Typography>
                <Typography
                    align="center"
                    color="textPrimary"
                    variant="subtitle2"
                >
                    <Box p={2}>
                        We are sorry! This feature is not currently available in
                        your plan. If you are the owner, please contact{" "}
                        HexaFair support team
                    </Box>
                </Typography>
            </Box>
        </div>
    );
};

export default Error;
