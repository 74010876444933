import { withStyles } from "@material-ui/core/styles";
import { Grid, Grow, IconButton, Slider } from "@material-ui/core";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import PauseIcon from "@material-ui/icons/Pause";
import { useEffect, useState } from "react";
import OndemandVideoIcon from "@material-ui/icons/OndemandVideo";
// import SessionsModal from "./Modals/SessionsModal";
import Modal from "../../Modal";
import { useStoreActions, useStoreState } from "easy-peasy";
import Button from "../../common/Button";
const styles = (theme) => ({
    container: {
        position: "absolute",
        left: "50%",
        transform: "translateZ(0px)",
        zIndex: 100,
        bottom: 105,
    },
    controlButtons: {
        width: "48px",
        height: "48px",
        margin: "0 5px",
    },
});

const StreamControls = (props) => {
    const { classes } = props;
    const { sessionsList, mux_playing } = useStoreState((state) => state.state);

    const [play, setPlay] = useState(true);
    const [mute, setMute] = useState(false);
    const [showVolumeControl, setShowVolumeControl] = useState(false);
    const [showStreamModal, setShowStreamModal] = useState(false);
    const [volumeValue, setVolumeValue] = useState(100);
    const muxplayer = document.getElementById("muxplayerAuditorium");
    // console.log(muxplayer);
    const handleVolumeChange = (event, newValue) => {
        setVolumeValue(newValue);
        muxplayer.volume = Number(newValue / 100);
       // console.log("new volume", volumeValue)
    };

    useEffect(() => {
        if (volumeValue == 0) {
            setMute(true);
        } else {
            setMute(false);
        }
    }, [volumeValue]);
    const handleFullScreen = () => {
        if (muxplayer) {
            muxplayer.allowFullscreen = true;
            muxplayer.requestFullscreen();
            // console.log(muxplayer, "volume");
        }
    };
    return (
        <div className={classes.container} id="streamingControlsContainer">
            <div
                style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "auto",
                    borderRadius: "30px",
                    background: "rgb(22, 23, 24)",
                    padding: "5px 0",
                    position: "relative",
                    left: "-50%",
                }}
                onMouseLeave={() => {
                   // console.log("mouse left the icon");
                    setShowVolumeControl(false);
                }}
                id="streamControls"
                onMouseEnter={()=>{
                    
                    if(!mute){
                        //console.log("mouse enter the icon");
                    setShowVolumeControl(true);}
                }}
            >
                {mux_playing ? (
                    <Button
                        OpenIcon={PauseIcon}
                        handleClick={() => {
                            setPlay(false);
                            muxplayer.pause();
                        }}
                        customIconContainer={classes.controlButtons}
                    />
                ) : (
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    //         setPlay(false);
                    //         muxplayer.pause();
                    //     }}
                    // >
                    //     <PauseIcon />
                    // </IconButton>
                    <Button
                        OpenIcon={PlayArrowIcon}
                        handleClick={() => {
                            setPlay(true);
                            muxplayer.play();
                        }}
                        customIconContainer={classes.controlButtons}
                    />
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    // setPlay(true);
                    // muxplayer.play();
                    //     }}
                    // >
                    //     <PlayArrowIcon />
                    // </IconButton>
                )}
                {!mute ? (
                    <Button
                        OpenIcon={VolumeUpIcon}
                        handleClick={() => {
                            setMute(true);
                            muxplayer.volume = 0;
                            setVolumeValue(0);
                        }}
                        customIconContainer={classes.controlButtons}
                    />
                ) : (
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    // setMute(true);
                    // muxplayer.volume = 0;
                    // setVolumeValue(0);
                    //     }}
                    // onMouseOver={() => {
                    //     console.log("enter");
                    //     setShowVolumeControl(true);
                    // }}
                    // >
                    //     <VolumeUpIcon />
                    // </IconButton>
                    <Button
                        OpenIcon={VolumeOffIcon}
                        handleClick={() => {
                            muxplayer.volume = 0.1;
                            setMute(false);
                        }}
                        customIconContainer={classes.controlButtons}
                        handleMouseOver={() => {
                            //console.log("entedr");
                            setShowVolumeControl(true);
                        }}
                    />
                    // <IconButton
                    //     className="shadow"
                    //     style={{ background: "white", margin: "0 5px" }}
                    //     onClick={() => {
                    // muxplayer.volume = 0.1;
                    // setMute(false);
                    //     }}
                    // >
                    //     <VolumeOffIcon />
                    // </IconButton>
                )}

                <Grow in={showVolumeControl} timeout={500}>
                    <span>
                        <Slider
                            style={{
                                position: "relative",
                                top: "10px",
                                width: "100px",
                                margin: "0 10px",
                                display: showVolumeControl ? "block" : "none",
                                opacity: showVolumeControl ? 1 : 0,
                                transition: "all 2s linear",
                                color: "white",
                            }}
                            // onMouseLeave={() => {
                            //     console.log("enter");
                            //     setShowVolumeControl(false);
                            // }}
                            value={volumeValue}
                            onChange={handleVolumeChange}
                            aria-labelledby="continuous-slider"
                        />
                    </span>
                </Grow>
                <Button
                    OpenIcon={FullscreenIcon}
                    handleClick={() => {
                        handleFullScreen();
                    }}
                    customIconContainer={classes.controlButtons}
                />
                {/* <IconButton
                    className="shadow"
                    style={{ background: "white", margin: "0 5px" }}
                    onClick={() => {
                        handleFullScreen();
                    }}
                >
                    <FullscreenIcon />
                </IconButton> */}
                {/* <Button
                    OpenIcon={OndemandVideoIcon}
                    handleClick={() => {
                        setShowStreamModal(true);
                        return <SessionsModal />;
                    }}
                    customIconContainer={classes.controlButtons}
                /> */}
                {/* <IconButton
                    className="shadow"
                    style={{ background: "white", margin: "0 5px" }}
                    onClick={() => {
                        setShowStreamModal(true);
                        return <SessionsModal />;
                    }}
                >
                    <OndemandVideoIcon />
                </IconButton> */}
            </div>
            {/* {showStreamModal ? ( */}
                <Modal
                    handleModalClose={() => {
                        setShowStreamModal(false);
                    }}
                    show={showStreamModal}
                    title="Conference Sessions"
                    sessionsList={sessionsList}
                    // close={modalBusinessCloseHandler}
                />
            {/* ) : null} */}
        </div>
    );
};

export default withStyles(styles)(StreamControls);
