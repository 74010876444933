import { useStoreState, useStoreActions } from "easy-peasy";
import { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Button } from "reactstrap";
import CloudDownloadIcon from "@material-ui/icons/CloudDownload";
import EmailIcon from "@material-ui/icons/Email";
import IconButton from "@material-ui/core/IconButton";
import Modal from "../Modal";
import Forbidden from "../../components/common/Forbidden";
import isEmpty from "lodash/isEmpty";
import {QRCodeUrl} from "../../../../store/restApi/api";

const Jobs = () => {

    const { exhibitorInfo } = useStoreState((state) => state.boothLiveState);

    const { eventConfig } = useStoreState((state) => state.state);
    const event_id = eventConfig?.data?.[0]?.event_id;
    const event_code= eventConfig?.eventCode;

    const [boothData, setBoothData] = useState(undefined);
    const [jobsData, setJobsData] = useState(undefined);
    const [loader, setLoader] = useState(undefined);

    const { getJobs } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );
    const [boothId] = useState(
        new URLSearchParams(window.location.hash.split("?")[1]).get("booth_id")
    );

    const { getBoothDetails } = useStoreActions(
        (boothLiveState) => boothLiveState.boothLiveState
    );

    useEffect(() => {
        if(boothId==="" || boothId==="null")
        {
            setLoader(true);  
        } 
        if (boothId && event_id) {
            getBoothDetails({
                booth_id: boothId,
                event_id: event_id
            }).then((res) => {
                setBoothData(res.payload);
            });
        }
    }, [boothId]);

    useEffect(() => {
        let exhibitor_id = boothData?.exhibitorInfo?.exhibitor_id;
        if (boothData) {
            getJobs({
                booth_id: boothId,
                event_id: event_id,
                exhibitor_id: exhibitor_id
            }).then((res) => {
                setJobsData(res.payload.jobsData);
            });
            document.title = boothData?.boothAssets?.booth_meta_title+" - Jobs";
        }
    }, [boothData]);

    useEffect(() => {
        if(typeof jobsData!="undefined")
        {    
            if(jobsData=="")
            {
                setLoader(true);  
            }
            else
            {
                setLoader(undefined);
            }
        }
        console.log(jobsData)
    }, [jobsData]);

    //Job details function 
    const job_details_fn = (job_details) =>{      
        let link=QRCodeUrl+"jobdetails?booth_id="+boothId+"&job_id="+job_details?.id+"&event_code="+event_code+"";
        window.open(link,"_self");
    };

    return (

        <div style={{ padding: "100px 0" }}>

                {(loader)?(
                    <Grid
                        container
                        item
                        justify="center"
                        style={{
                            margin: 5,
                        }}                
                        direction="column"
                    >
                        <Grid item className="h4 text-center p-2">
                        No Data Found
                        </Grid>
                    </Grid>       
                ):(
                <>
                <Grid container justify="center" direction="column">
                    <Grid
                        container
                        item
                        justify="center"
                        style={{
                            margin: 5,
                        }}                       
                        direction="column"
                    >
                        {(typeof jobsData!="undefined")?(
                            <>
                            <Grid item className="h4 text-center p-2">                                
                                {exhibitorInfo?.exhibitor_company_name}
                            </Grid>
                            <Grid item className="h6 text-center">
                                Jobs
                            </Grid>
                            </> 
                        ):(null)}
                {(jobsData!="")?(
                    <>
                    {jobsData?.map((jobdata, i) => {
                        return (
                                <Grid
                                    container
                                    item
                                    justify="center"
                                    style={{
                                        margin: 5,
                                        padding: 10
                                    }}
                                >
                                    <Grid
                                        item
                                        xs={10}
                                        md={6}
                                        className="font-weight-bold"
                                        style={{
                                            borderBottom:
                                                i == jobdata?.length - 1
                                                    ? "none"
                                                    : "1.5px solid #1c1d1f",
                                            padding: 10
                                        }}
                                    >
                                        <b className="jtitle">{jobdata?.job_title}</b>  
                                        <div className="small">
                                            <b>Location: </b>{jobdata?.job_location}
                                        </div>                                     
                                    </Grid>
                                    <Grid
                                        item
                                        xs={2}
                                        style={{
                                            display: "flex",
                                            justifyContent: "right",
                                            borderBottom:
                                                i == jobdata?.length - 1
                                                    ? "none"
                                                    : "1.5px solid #1c1d1f",
                                            padding: 10
                                        }}
                                    >

                                        <Grid
                                            item
                                            style={{
                                                display: "flex",
                                                flexDirection: "column",
                                                padding: 5,
                                            }}
                                        >
                                            <div className="small muted">
                                            <Button onClick={()=>job_details_fn(jobdata)}>View Details</Button>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </Grid>                           
                            
                        );
                    })}
                    </>
                ):(null)}
                    </Grid>
                </Grid>
                </>
            )}
        </div>
    );
};

export default Jobs;
