


export const videocall = (staff_info , user_info, videoCallAction ,sendMessage ) => {
 
   
    let payLoad = {
        channel: staff_info?.id,
        message: user_info?.displayname,
        meta: {
            ToUUID: staff_info?.id,
            ToUserName : staff_info?.displayname,
            FromUUID: user_info?.id,
            fromUser : user_info?.displayname,
            chatType : "VIDEO",
            chatURL : "https://meet.jit.si/"+user_info?.id
        },
    };
    videoCallAction({})
    videoCallAction(payLoad)
    sendMessage(payLoad);
}