        import axios from "axios";
        import {Api_Key} from '../../App';
        var CryptoJS = require("crypto-js");
        
        //-------------------------------- Encrypt function ------------------------------------------>
        const Encrypt_function = (payload, secret_key) => {
            let Encryption_payload = CryptoJS.AES.encrypt(
                JSON.stringify(payload),
                secret_key
            );
            let Encrypted_json = {
                iv: Encryption_payload.iv.toString(),
                s: Encryption_payload.salt.toString(),
                ct: Encryption_payload.ciphertext.toString(CryptoJS.enc.Base64),
            };

            let Encrypted_json_string = {
                encrypt_payload_string: JSON.stringify(Encrypted_json),
            };
            return Encrypted_json_string;
        };

        /*-------------------------------- Decrypt function ------------------------------------------*/
        const Decrypt_function = (response, secret_key) => {
            var salt = response?.data?.salt;
            var iv = response?.data?.iv;
            var iterations = response?.data?.iterations;
            var key = CryptoJS.PBKDF2(secret_key, salt, {
                hasher: CryptoJS.algo.SHA256,
                keySize: 64 / 8,
                iterations: iterations,
            });
            var decrypted = CryptoJS.AES.decrypt(response?.data?.data, key, {
                iv: CryptoJS.enc.Utf8.parse(iv),
            });
            let decrypt_data = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
            return decrypt_data;
        };
        /*-------------------------------- Decrypt function ------------------------------------------*/

        //<------------------------encryption dev-app-2------------------------------------------------>
        // export const baseUrl = `https://dev-app-2.hexafair.com/platform/resources/api/v2/`;  
        // export const profile_img_baseUrl = `https://dev-app-2.hexafair.com/platform/resources/data/`;
        // export const QRCodeUrl = "https://hf-hybrid-dev.hexafair.com/demosite/#/";
        //export const QRCodeUrl = "http://localhost:3000/#/";
        //<------------------------encryption dev-app-2------------------------------------------------>
        
        //<------------------------encryption live------------------------------------------------>
        
        export const baseUrl = `https://app.hexafair.com/platform/resources/api/v2`;
        export const profile_img_baseUrl = `https://app.hexafair.com/platform/resources/data/`;
        export const QRCodeUrl = "https://events.hexafair.com/onlive/#/";
        // export const QRCodeUrl = "http://localhost:3000/#/";
        
        //<------------------------encryption live------------------------------------------------>
        

        // const baseUrl = "https://app.veespaces.com/platform/resources/api";

        //helper method for getting config
        const getConfig = (url, method, contentType, data, sendHeader = true) => {
            if (sendHeader)
                return {
                    url: url,
                    method: method,
                    headers: {
                        "Content-Type": contentType,
                    },
                    data: data,
                };
            else {
                return {
                    url: url,
                    method: method,
                    data: data,
                };
            }
        };

        export const getBoothsList = async (payload) => {
            // console.log("boothlist_payload", payload)
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios
                .post(baseUrl + "/booths-list.php", encrypt_payload, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((res) => {
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);
                    // console.log(decrypt_data,"exhblis")

                    /*--------------Decrypt-------------*/

                    return decrypt_data;
                })
                .catch((err) => {
                    // console.log(err);
                });
            return response;
        };

        export const getBoothData = async (payload) => {
            const res = await axios.post(baseUrl + "/booth_data_new.php", {
                ...payload,
            });
            //console.log("responsePositions", res?.data);
            return res?.data;
        };

        export const getSponsorBannerList = async (payload) => {      
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios
                .post(baseUrl + "/event_sponsor_banners.php", encrypt_payload, {
                    headers: { "Content-Type": "application/json" },
                })
                .then((res) => {
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);                    
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                })
                .catch((err) => {
                    // console.log(err);
                });
            return response;
        };

        export const getSpeakersList = async (payload) => {
            /*----------------Encrypt-------------*/
          
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            
            /*----------------Encrypt-------------*/

            const response = await axios

                .post(
                    baseUrl + "/event_speakers.php",
                    JSON.stringify(encrypt_payload),
                    { headers: { "Content-Type": "application/json" } }
                )
                .then((res) => {                    
                 
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                })
                .catch((err) => {
                    // console.log(err);
                });

            return response;
        };

        export const getSponsorsLogo = async (payload) => {
            const response = await axios
                .post(
                    baseUrl + "/event_sponsors_logos.php",
                    { event_id: payload?.eventId },
                    { headers: { "Content-Type": "application/json" } }
                )
                .then((res) => {
                    return res?.data;
                })
                .catch((err) => {
                    // console.log(err);
                });

            return response;
        };

        // export const getEventDetails = async (payload) => {
        //     // console.log(payload);
        //     const response = await axios
        //         .post(baseUrl + "/event_config.php", payload, {
        //             headers: { "Content-Type": "application/json" },
        //         })
        //         .then((res) => {
        //             console.log(res)
        //             return res?.data;
        //         })
        //         .catch((err) => {
        //             console.log(err);
        //         });
        //     return response;
        // };

        export const getEventConfig = async (payload) => {
            // /*----------------Encrypt-------------*/
            // let encrypt_payload=Encrypt_function(payload,"HEXAFAIREVENT");
            // /*----------------Encrypt-------------*/
            // const response = await axios(
            //     getConfig(
            //         baseUrl + "/event_config.php",
            //         "post",
            //         "application/json",
            //         JSON.stringify(encrypt_payload)
            //     )
            // );
            // /*--------------Decrypt-------------*/
            // let decrypt_data=Decrypt_function(response,"HEXAFAIREVENT");
            // /*--------------Decrypt-------------*/
            // return decrypt_data;

            const response = await axios(
                
                getConfig(
                    baseUrl + "/event_config.php",
                    "post",
                    "application/json",
                    
                    JSON.stringify(payload)
                )
            );
            
            return response?.data;
        };

        export const login = async (payload) => {
            /*----------------Encrypt-------------*/            
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(payload, unique_key);
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl+"/login.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            );
                if(response?.data?.hasOwnProperty('salt'))
                {
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(response, unique_key);
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                }
                else
                {                   
                    return response?.data;
                }           
        };

        export const getCountryCodes = async (payload) => {
            /*----------------Encrypt-------------*/
            
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(payload, unique_key);
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/get_phone_number_country_code.php",
                    "post",
                    "application.json"
                )
            )
                .then((response) => {
                /*--------------Decrypt-------------*/
                let decrypt_data = Decrypt_function(response, unique_key);
                /*--------------Decrypt-------------*/
                return decrypt_data;
                    // return response?.data;
                })
                .catch((error) => {
                    // console.log("countryCodes error", error);
                });
            return response;
        };

        export const register = async (payload) => {
            const response = await axios(
                getConfig(
                    baseUrl + "/attendee_registration.php",
                    "post",
                    "application/json",
                    JSON.stringify(payload)
                )
            );

            return response?.data;
        };

        export const logout = async (payload) => {
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(payload, unique_key);
            /*----------------Encrypt-------------*/
            await fetch(
                baseUrl + `/logout.php?`,

                {
                    method: "post",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(encrypt_payload),
                }
            )
                .then((res) => {
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                })
                .catch((err) => {
                    // console.log(err);
                });
        };

        //forget password
        export const forget_password = async (payload) => {
           
             /*----------------Encrypt-------------*/
             let unique_key = Api_Key;
             let encrypt_payload = Encrypt_function(payload, unique_key);
             /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/get_password_to_reset.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;
                    if(response?.data?.hasOwnProperty("salt"))
                    {
                        /*--------------Decrypt-------------*/
                        let decrypt_data = Decrypt_function(response, unique_key);
                       
                        /*--------------Decrypt-------------*/
                        return decrypt_data;
                    }
                    else
                    {
                        return response?.data;
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const change_password = async (payload) => {
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(payload, unique_key);
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/get_password_to_reset.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    if(response?.data?.hasOwnProperty('salt'))
                    {
                        /*--------------Decrypt-------------*/
                        let decrypt_data = Decrypt_function(response, unique_key);
                        /*--------------Decrypt-------------*/
                        return decrypt_data;
                    }
                    else
                    {
                        return response?.data;
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const notInterested = async (payload) => {
             /*----------------Encrypt-------------*/
             let unique_key = Api_Key;
             let encrypt_payload = Encrypt_function(payload, unique_key);
             /*----------------Encrypt-------------*/
            await fetch(baseUrl + `/api_for_feedback_not_interested.php?`, {
                method: "post",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(encrypt_payload),
            })
                .then((res) => {
                    // return res.status;
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                })
                .catch((err) => {
                    console.log(err);
                });
        };


        export const myConnections = async (payload) => {

            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
           
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/my_connections.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const myBoxVideosView = async (payload) => {

            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
           
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/mybox-videos-viewed.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const myBoxCourseView = async (payload) => {

            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
        
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/mybox_courses_viewed.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;
                    if(response?.data?.hasOwnProperty('salt'))
                    {

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                    }else{
                        return response?.data;
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };


        export const attendeePostQuestion=async (payload) => {
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
           
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            // const response = await axios
            //     .get(baseUrl + "/attendee_post_question.php?data="+JSON.stringify(encrypt_payload),{
            //         headers: { "Content-Type": "application/json" },
            //     })
                const response = await axios(
                    getConfig(
                        baseUrl + "/attendee_post_question.php",
                        "post",
                        "application/json",
                        JSON.stringify(encrypt_payload)
                    )
                )
                .then((res) => {
                    if(res?.data?.hasOwnProperty('salt'))
                    {
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);
                   
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                    }
                    else
                    {
                        return res?.data;
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            return response;
        };

        export const attendeePostedQuestion=async (payload) => {
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            // const response = await axios
            //     .get(baseUrl + "/attendee_posted_questions.php?data="+JSON.stringify(encrypt_payload),{
            //         headers: { "Content-Type": "application/json" },
            //     })
                const response = await axios(
                    getConfig(
                        baseUrl + "/attendee_posted_questions.php",
                        "post",
                        "application/json",
                        JSON.stringify(encrypt_payload)
                    )
                )
                .then((res) => {
                  
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);
                  
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                })
                .catch((err) => {
                    console.log(err);
                });
            return response;
        };

        export const pollsQuestion=async (payload) => {
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
           
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            // const response = await axios
            //     .get(baseUrl + "/polls_form_questions.php??"+JSON.stringify(encrypt_payload),{
            //         headers: { "Content-Type": "application/json" },
            //     })
                const response = await axios(
                    getConfig(
                        baseUrl + "/polls_form_questions.php",
                        "post",
                        "application/json",
                        JSON.stringify(encrypt_payload)
                    )
                )
                .then((res) => {
                  
                    /*--------------Decrypt-------------*/
                    let decrypt_data = Decrypt_function(res, unique_key);
                    
                    /*--------------Decrypt-------------*/
                    return decrypt_data;
                })
                .catch((err) => {
                    console.log(err);
                });
            return response;
        };

        export const myBoxDocumentsView = async (payload) => {

            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
           
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/mybox-documents-viewed.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const myBoxJobsView = async (payload) => {

            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
           
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/mybox_jobs_viewed.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;
                    if(response?.data?.hasOwnProperty('salt'))
                    {
                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                    } 
                    else{
                        return response?.data;
                    }
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const myBoxProductsView = async (payload) => {

            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
           
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/mybox_product_viewed.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const leaderBoard = async (payload) => {

            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/leaderboard_top_attendee.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const userList = async (payload) => {
console.log(payload)
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/recommended_attendees.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {
                    // return response?.data;

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);  
                     console.log("att", decrypt_data);                   
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };

        export const editProfile = async (payload) => {
console.log(payload);
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(
                payload,
                unique_key
            );
            /*----------------Encrypt-------------*/
            const response = await axios(
                getConfig(
                    baseUrl + "/edit_attendee_profile_details.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
                .then((response) => {                 

                     /*--------------Decrypt-------------*/
                     let decrypt_data = Decrypt_function(response, unique_key);
                     console.log(decrypt_data);
                     /*--------------Decrypt-------------*/
                     return decrypt_data;
                })
                .catch((error) => {
                    //console.log(error);
                });
            return response;
        };


        export const feedbacQuestion = async (payload) => {
            /*----------------Encrypt-------------*/
            let unique_key = Api_Key;
            let encrypt_payload = Encrypt_function(payload, unique_key);
            /*----------------Encrypt-------------*/
       
                const response = await axios(
                    getConfig(
                        baseUrl + "/event_feedback_form_questions.php",
                        "post",
                        "application/json",
                        JSON.stringify(encrypt_payload)
                    )
                )
                .then((response) => {
                /*--------------Decrypt-------------*/
                let decrypt_data = Decrypt_function(response, unique_key);
                /*--------------Decrypt-------------*/                
                return decrypt_data;                   
               })
               .catch((err) => {
                   console.log(err);
               });
               return response;
       };

       export const feedbackResponse=async (payload) => {
        /*----------------Encrypt-------------*/
        let unique_key = Api_Key;
        let encrypt_payload = Encrypt_function(
            payload,
            unique_key
        );
        /*----------------Encrypt-------------*/
        // const response = await axios
        //     .get(baseUrl + "/event_feedback_form_response.php?data="+JSON.stringify(encrypt_payload),{
        //         headers: { "Content-Type": "application/json" },
        //     })
            const response = await axios(
                 
                getConfig(
                    baseUrl + "/event_feedback_form_response.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
            // event_feedback_form_response.php
            .then((res) => {
             
                if(res?.data?.hasOwnProperty('salt'))
                {
                /*--------------Decrypt-------------*/
                let decrypt_data = Decrypt_function(res, unique_key);
                
                /*--------------Decrypt-------------*/
                return decrypt_data;
                }else{
                    return res?.data;
                }
            })
            .catch((err) => {
                console.log(err);
            });
        return response;
    };


    export const questionsUpvote = async (payload) => {
        /*----------------Encrypt-------------*/
        let unique_key = Api_Key;
        let encrypt_payload = Encrypt_function(payload, unique_key);
        /*----------------Encrypt-------------*/
   
            const response = await axios(
                getConfig(
                    baseUrl + "/update_questions_upvote.php",
                    "post",
                    "application/json",
                    JSON.stringify(encrypt_payload)
                )
            )
            .then((response) => {
            /*--------------Decrypt-------------*/
            let decrypt_data = Decrypt_function(response, unique_key);
            /*--------------Decrypt-------------*/                
            return decrypt_data;                   
           })
           .catch((err) => {
               console.log(err);
           });
           return response;
   };
 

   export const pollsResponse = async (payload) => {

    /*----------------Encrypt-------------*/
    let unique_key = Api_Key;
   
    let encrypt_payload = Encrypt_function(
        payload,
        unique_key
    );
    /*----------------Encrypt-------------*/
    const response = await axios(
        getConfig(
            baseUrl + "/polls_form_response.php",
            "post",
            "application/json",
            JSON.stringify(encrypt_payload)
        )
    )
        .then((response) => {
            // return response?.data;
            if(response?.data?.hasOwnProperty('salt'))
            {
             /*--------------Decrypt-------------*/
             let decrypt_data = Decrypt_function(response, unique_key);
             
             /*--------------Decrypt-------------*/
             return decrypt_data;
            } 
            else{
                return response?.data;
            }
        })
        .catch((error) => {
            //console.log(error);
        });
    return response;
};



export const breakOutRoom = async (payload) => {

    const response = await axios(
        getConfig(
            baseUrl + "/breakout_roomlist.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
           
            }).catch((error) => {
            ////console.log(error);
        });
    return response;
};

export const add_member = async (payload) => {

    const response = await axios(
        getConfig(
            baseUrl + "/add_member_to_breakout_room.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response?.data;
           
            }).catch((error) => {
            ////console.log(error);
        });
    return response;
};

export const remove_member = async (payload) => {

    const response = await axios(
        getConfig(
            baseUrl + "/remove_member_from_breakout_room.php",
            "post",
            "application/json",
            JSON.stringify(payload)
        )
    )
        .then((response) => {
            return response;
           
            }).catch((error) => {
            ////console.log(error);
        });
    return response;
};
